@charset "UTF-8";
/**********
 * Mercury agency theme for EBK.
 */
/*!
 * The Mercury Template (https://mercury-template.opencms.org/) - Copyright Alkacon Software GmbH & Co. KG
 * Licensed under the GNU Affero General Public License (https://www.gnu.org/licenses/).
 *
 */
/* fira-sans-300 - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/fira-sans-v17-latin_latin-ext-300.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* fira-sans-300italic - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: "Fira Sans";
  font-style: italic;
  font-weight: 300;
  src: url("../fonts/fira-sans-v17-latin_latin-ext-300italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* fira-sans-regular - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/fira-sans-v17-latin_latin-ext-regular.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* fira-sans-italic - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: "Fira Sans";
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/fira-sans-v17-latin_latin-ext-italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* fira-sans-500 - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/fira-sans-v17-latin_latin-ext-500.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* fira-sans-500italic - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: "Fira Sans";
  font-style: italic;
  font-weight: 500;
  src: url("../fonts/fira-sans-v17-latin_latin-ext-500italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* fira-sans-600 - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/fira-sans-v17-latin_latin-ext-600.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* fira-sans-600italic - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: "Fira Sans";
  font-style: italic;
  font-weight: 600;
  src: url("../fonts/fira-sans-v17-latin_latin-ext-600italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* fira-sans-700 - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/fira-sans-v17-latin_latin-ext-700.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* fira-sans-700italic - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: "Fira Sans";
  font-style: italic;
  font-weight: 700;
  src: url("../fonts/fira-sans-v17-latin_latin-ext-700italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* replace the old "twitter" icon from font awesome with the "x" icon using an inline font */
@font-face {
  font-family: "x";
  src: url(data:application/octet-stream;base64,d09GMgABAAAAAAH4AAsAAAAAA/QAAAGqAAEAAAAAAAAAAAAAAAAAAAAAAAAAAAAABmAAVBEICoEAgRgBNgIkAxQLDAAEIAWCPgcgGz8DyK4G7OYDYRkhJyqqeGZ/w7wMX87uQh2lEcRfvrOv/NK1pDUMYJAqnUAKH0c0oBH5mtL5oKI5Z+8+BApVUiAHqFrrK319+Mu9349ugh7Q1mjs8bvdWoKhZwWcaHaZ8AHZa5y2/fJ/PNFsx4PBeMCvEnkJdBdhEGIYZ3ESjQJNKQuA05PZbbQHVbauC6poQk7vWEVq0wc8sh6vrABdYmHPBXy1NIy2vGiIFwgA2ANkpC0ESskS9wIjKWNk/mqjgTwtumVACQCCOuZABUZQYQnOABwBCCKW3csgfcIfv2bCsM/ZG3Ew4D0OAHD+hDtz5v5Wv6LKP8bvvY825b8t3AHdFuRLiH8tBALPt3NpJif+B3wAbJ4rT0KtDwMGBFpsowD5xX8HtQAAt1BAQNWKAQkAAGb+CCAIAkgSJwTIZIMAhXwQoCo4AT2yR4CRSRKlkiFGCVM7w5ktBf4i0pEQM4csdzNucJ/GS4aqo4s5OnHtbCkMKr3BPfy51hhNjO0isHBscF/6inEUC6vdf4K3jqydrTOFjdlijhbOmDXF0oOiYGWnYmdnS0UZAAAA);
  font-weight: normal;
  font-style: normal;
}
.fa.fa-twitter,
.fab.fa-twitter {
  font-family: "x" !important;
}

/*!
 * Bootstrap - included in part with Mercury - v5.3.3 (https://getbootstrap.com/)
 * Copyright 2011-2024 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--my-body-font-family);
  font-size: var(--my-body-font-size);
  font-weight: var(--my-body-font-weight);
  line-height: var(--my-body-line-height);
  color: var(--my-body-color);
  text-align: var(--my-body-text-align);
  background-color: var(--my-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--my-border-width) solid;
  opacity: 0.25;
}

h6, h5, h4, h3, h2, h1 {
  margin-top: 0;
  margin-bottom: 1.5rem;
  font-weight: 500;
  line-height: 1.25;
  color: var(--my-heading-color);
}

h1 {
  font-size: 2.8rem;
}

h2 {
  font-size: 2.4rem;
}

h3 {
  font-size: 2rem;
}

h4 {
  font-size: 1.8rem;
}

h5 {
  font-size: 1.8rem;
}

h6 {
  font-size: 1.8rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: 600;
}

small {
  font-size: 0.875em;
}

mark {
  padding: 0.1875em;
  color: var(--my-highlight-color);
  background-color: var(--my-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--my-link-color-rgb), var(--my-link-opacity, 1));
  text-decoration: none;
}
a:hover {
  --my-link-color-rgb: var(--my-link-hover-color-rgb);
  text-decoration: underline;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--my-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  color: var(--my-code-color);
  word-wrap: break-word;
  font-size: 0.875em;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  color: var(--my-body-bg);
  background-color: var(--my-body-color);
  font-size: 0.875em;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--my-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  line-height: inherit;
  font-size: 1.5rem;
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --my-gutter-x: 30px;
  --my-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--my-gutter-x) * 0.5);
  padding-left: calc(var(--my-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 552px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 764px) {
  .container-md, .container-sm, .container {
    max-width: 744px;
  }
}
@media (min-width: 1014px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 992px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1170px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
:root {
  --my-breakpoint-xs: 0;
  --my-breakpoint-sm: 552px;
  --my-breakpoint-md: 764px;
  --my-breakpoint-lg: 1014px;
  --my-breakpoint-xl: 1200px;
  --my-breakpoint-xxl: 1400px;
}

.row {
  --my-gutter-x: 30px;
  --my-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--my-gutter-y));
  margin-right: calc(-0.5 * var(--my-gutter-x));
  margin-left: calc(-0.5 * var(--my-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--my-gutter-x) * 0.5);
  padding-left: calc(var(--my-gutter-x) * 0.5);
  margin-top: var(--my-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.row-cols-7 > * {
  flex: 0 0 auto;
  width: 14.28571429%;
}

.row-cols-8 > * {
  flex: 0 0 auto;
  width: 12.5%;
}

.row-cols-9 > * {
  flex: 0 0 auto;
  width: 11.11111111%;
}

.row-cols-10 > * {
  flex: 0 0 auto;
  width: 10%;
}

.row-cols-11 > * {
  flex: 0 0 auto;
  width: 9.09090909%;
}

.row-cols-12 > * {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --my-gutter-x: 0;
}

.g-0,
.gy-0 {
  --my-gutter-y: 0;
}

.g-1,
.gx-1 {
  --my-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --my-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --my-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --my-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --my-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --my-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --my-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --my-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --my-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --my-gutter-y: 3rem;
}

@media (min-width: 552px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .row-cols-sm-7 > * {
    flex: 0 0 auto;
    width: 14.28571429%;
  }
  .row-cols-sm-8 > * {
    flex: 0 0 auto;
    width: 12.5%;
  }
  .row-cols-sm-9 > * {
    flex: 0 0 auto;
    width: 11.11111111%;
  }
  .row-cols-sm-10 > * {
    flex: 0 0 auto;
    width: 10%;
  }
  .row-cols-sm-11 > * {
    flex: 0 0 auto;
    width: 9.09090909%;
  }
  .row-cols-sm-12 > * {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --my-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --my-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --my-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --my-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --my-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --my-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --my-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --my-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --my-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --my-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --my-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --my-gutter-y: 3rem;
  }
}
@media (min-width: 764px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .row-cols-md-7 > * {
    flex: 0 0 auto;
    width: 14.28571429%;
  }
  .row-cols-md-8 > * {
    flex: 0 0 auto;
    width: 12.5%;
  }
  .row-cols-md-9 > * {
    flex: 0 0 auto;
    width: 11.11111111%;
  }
  .row-cols-md-10 > * {
    flex: 0 0 auto;
    width: 10%;
  }
  .row-cols-md-11 > * {
    flex: 0 0 auto;
    width: 9.09090909%;
  }
  .row-cols-md-12 > * {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --my-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --my-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --my-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --my-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --my-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --my-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --my-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --my-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --my-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --my-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --my-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --my-gutter-y: 3rem;
  }
}
@media (min-width: 1014px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .row-cols-lg-7 > * {
    flex: 0 0 auto;
    width: 14.28571429%;
  }
  .row-cols-lg-8 > * {
    flex: 0 0 auto;
    width: 12.5%;
  }
  .row-cols-lg-9 > * {
    flex: 0 0 auto;
    width: 11.11111111%;
  }
  .row-cols-lg-10 > * {
    flex: 0 0 auto;
    width: 10%;
  }
  .row-cols-lg-11 > * {
    flex: 0 0 auto;
    width: 9.09090909%;
  }
  .row-cols-lg-12 > * {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --my-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --my-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --my-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --my-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --my-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --my-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --my-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --my-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --my-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --my-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --my-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --my-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .row-cols-xl-7 > * {
    flex: 0 0 auto;
    width: 14.28571429%;
  }
  .row-cols-xl-8 > * {
    flex: 0 0 auto;
    width: 12.5%;
  }
  .row-cols-xl-9 > * {
    flex: 0 0 auto;
    width: 11.11111111%;
  }
  .row-cols-xl-10 > * {
    flex: 0 0 auto;
    width: 10%;
  }
  .row-cols-xl-11 > * {
    flex: 0 0 auto;
    width: 9.09090909%;
  }
  .row-cols-xl-12 > * {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --my-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --my-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --my-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --my-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --my-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --my-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --my-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --my-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --my-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --my-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --my-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --my-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .row-cols-xxl-7 > * {
    flex: 0 0 auto;
    width: 14.28571429%;
  }
  .row-cols-xxl-8 > * {
    flex: 0 0 auto;
    width: 12.5%;
  }
  .row-cols-xxl-9 > * {
    flex: 0 0 auto;
    width: 11.11111111%;
  }
  .row-cols-xxl-10 > * {
    flex: 0 0 auto;
    width: 10%;
  }
  .row-cols-xxl-11 > * {
    flex: 0 0 auto;
    width: 9.09090909%;
  }
  .row-cols-xxl-12 > * {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --my-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --my-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --my-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --my-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --my-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --my-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --my-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --my-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --my-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --my-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --my-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --my-gutter-y: 3rem;
  }
}
.table {
  --my-table-color-type: initial;
  --my-table-bg-type: initial;
  --my-table-color-state: initial;
  --my-table-bg-state: initial;
  --my-table-color: var(--my-emphasis-color);
  --my-table-bg: var(--my-body-bg);
  --my-table-border-color: var(--my-highlight-color);
  --my-table-accent-bg: transparent;
  --my-table-striped-color: var(--my-emphasis-color);
  --my-table-striped-bg: #eee;
  --my-table-active-color: var(--my-emphasis-color);
  --my-table-active-bg: rgba(var(--my-emphasis-color-rgb), 0.1);
  --my-table-hover-color: var(--my-emphasis-color);
  --my-table-hover-bg: rgba(var(--my-emphasis-color-rgb), 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--my-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  color: var(--my-table-color-state, var(--my-table-color-type, var(--my-table-color)));
  background-color: var(--my-table-bg);
  border-bottom-width: var(--my-border-width);
  box-shadow: inset 0 0 0 9999px var(--my-table-bg-state, var(--my-table-bg-type, var(--my-table-accent-bg)));
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--my-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--my-border-width) 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--my-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --my-table-color-type: var(--my-table-striped-color);
  --my-table-bg-type: var(--my-table-striped-bg);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --my-table-color-type: var(--my-table-striped-color);
  --my-table-bg-type: var(--my-table-striped-bg);
}

.table-active {
  --my-table-color-state: var(--my-table-active-color);
  --my-table-bg-state: var(--my-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --my-table-color-state: var(--my-table-hover-color);
  --my-table-bg-state: var(--my-table-hover-bg);
}

.table-primary {
  --my-table-color: #000;
  --my-table-bg: #ebcccf;
  --my-table-border-color: #bca3a6;
  --my-table-striped-bg: #dfc2c5;
  --my-table-striped-color: #000;
  --my-table-active-bg: #d4b8ba;
  --my-table-active-color: #000;
  --my-table-hover-bg: #d9bdbf;
  --my-table-hover-color: #000;
  color: var(--my-table-color);
  border-color: var(--my-table-border-color);
}

.table-secondary {
  --my-table-color: #000;
  --my-table-bg: #e2e3e5;
  --my-table-border-color: #b5b6b7;
  --my-table-striped-bg: #d7d8da;
  --my-table-striped-color: #000;
  --my-table-active-bg: #cbccce;
  --my-table-active-color: #000;
  --my-table-hover-bg: #d1d2d4;
  --my-table-hover-color: #000;
  color: var(--my-table-color);
  border-color: var(--my-table-border-color);
}

.table-success {
  --my-table-color: #000;
  --my-table-bg: #d1e7dd;
  --my-table-border-color: #a7b9b1;
  --my-table-striped-bg: #c7dbd2;
  --my-table-striped-color: #000;
  --my-table-active-bg: #bcd0c7;
  --my-table-active-color: #000;
  --my-table-hover-bg: #c1d6cc;
  --my-table-hover-color: #000;
  color: var(--my-table-color);
  border-color: var(--my-table-border-color);
}

.table-info {
  --my-table-color: #000;
  --my-table-bg: #cff4fc;
  --my-table-border-color: #a6c3ca;
  --my-table-striped-bg: #c5e8ef;
  --my-table-striped-color: #000;
  --my-table-active-bg: #badce3;
  --my-table-active-color: #000;
  --my-table-hover-bg: #bfe2e9;
  --my-table-hover-color: #000;
  color: var(--my-table-color);
  border-color: var(--my-table-border-color);
}

.table-warning {
  --my-table-color: #000;
  --my-table-bg: #fff3cd;
  --my-table-border-color: #ccc2a4;
  --my-table-striped-bg: #f2e7c3;
  --my-table-striped-color: #000;
  --my-table-active-bg: #e6dbb9;
  --my-table-active-color: #000;
  --my-table-hover-bg: #ece1be;
  --my-table-hover-color: #000;
  color: var(--my-table-color);
  border-color: var(--my-table-border-color);
}

.table-danger {
  --my-table-color: #000;
  --my-table-bg: #f8d7da;
  --my-table-border-color: #c6acae;
  --my-table-striped-bg: #eccccf;
  --my-table-striped-color: #000;
  --my-table-active-bg: #dfc2c4;
  --my-table-active-color: #000;
  --my-table-hover-bg: #e5c7ca;
  --my-table-hover-color: #000;
  color: var(--my-table-color);
  border-color: var(--my-table-border-color);
}

.table-light {
  --my-table-color: #000;
  --my-table-bg: #f8f9fa;
  --my-table-border-color: #c6c7c8;
  --my-table-striped-bg: #ecedee;
  --my-table-striped-color: #000;
  --my-table-active-bg: #dfe0e1;
  --my-table-active-color: #000;
  --my-table-hover-bg: #e5e6e7;
  --my-table-hover-color: #000;
  color: var(--my-table-color);
  border-color: var(--my-table-border-color);
}

.table-dark {
  --my-table-color: #fff;
  --my-table-bg: #212529;
  --my-table-border-color: #4d5154;
  --my-table-striped-bg: #2c3034;
  --my-table-striped-color: #fff;
  --my-table-active-bg: #373b3e;
  --my-table-active-color: #fff;
  --my-table-hover-bg: #323539;
  --my-table-hover-color: #fff;
  color: var(--my-table-color);
  border-color: var(--my-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 551.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 763.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1013.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-menu {
  --my-dropdown-zindex: 150001;
  --my-dropdown-min-width: 10rem;
  --my-dropdown-padding-x: 0;
  --my-dropdown-padding-y: 0.5rem;
  --my-dropdown-spacer: 0.125rem;
  --my-dropdown-font-size: 1.5rem;
  --my-dropdown-color: var(--my-body-color);
  --my-dropdown-bg: var(--my-body-bg);
  --my-dropdown-border-color: var(--my-border-color-translucent);
  --my-dropdown-border-radius: 0;
  --my-dropdown-border-width: var(--my-border-width);
  --my-dropdown-inner-border-radius: calc(0 - var(--my-border-width));
  --my-dropdown-divider-bg: var(--my-border-color-translucent);
  --my-dropdown-divider-margin-y: 0.5rem;
  --my-dropdown-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  --my-dropdown-link-color: var(--my-body-color);
  --my-dropdown-link-hover-color: var(--my-body-color);
  --my-dropdown-link-hover-bg: var(--my-tertiary-bg);
  --my-dropdown-link-active-color: #fff;
  --my-dropdown-link-active-bg: #990011;
  --my-dropdown-link-disabled-color: var(--my-tertiary-color);
  --my-dropdown-item-padding-x: 1rem;
  --my-dropdown-item-padding-y: 0.25rem;
  --my-dropdown-header-color: #6c757d;
  --my-dropdown-header-padding-x: 1rem;
  --my-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--my-dropdown-zindex);
  display: none;
  min-width: var(--my-dropdown-min-width);
  padding: var(--my-dropdown-padding-y) var(--my-dropdown-padding-x);
  margin: 0;
  font-size: var(--my-dropdown-font-size);
  color: var(--my-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--my-dropdown-bg);
  background-clip: padding-box;
  border: var(--my-dropdown-border-width) solid var(--my-dropdown-border-color);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--my-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 552px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 764px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1014px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--my-dropdown-spacer);
}
.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--my-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--my-dropdown-spacer);
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--my-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--my-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--my-dropdown-item-padding-y) var(--my-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--my-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--my-dropdown-link-hover-color);
  text-decoration: none;
  background-color: var(--my-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--my-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--my-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--my-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--my-dropdown-header-padding-y) var(--my-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 1.2rem;
  color: var(--my-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--my-dropdown-item-padding-y) var(--my-dropdown-item-padding-x);
  color: var(--my-dropdown-link-color);
}

.dropdown-menu-dark {
  --my-dropdown-color: #dee2e6;
  --my-dropdown-bg: #343a40;
  --my-dropdown-border-color: var(--my-border-color-translucent);
  --my-dropdown-box-shadow: ;
  --my-dropdown-link-color: #dee2e6;
  --my-dropdown-link-hover-color: #fff;
  --my-dropdown-divider-bg: var(--my-border-color-translucent);
  --my-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --my-dropdown-link-active-color: #fff;
  --my-dropdown-link-active-bg: #990011;
  --my-dropdown-link-disabled-color: #adb5bd;
  --my-dropdown-header-color: #adb5bd;
}

.badge {
  --my-badge-padding-x: 0.65em;
  --my-badge-padding-y: 0.35em;
  --my-badge-font-size: 0.75em;
  --my-badge-font-weight: 600;
  --my-badge-color: #fff;
  --my-badge-border-radius: var(--my-border-radius);
  display: inline-block;
  padding: var(--my-badge-padding-y) var(--my-badge-padding-x);
  font-size: var(--my-badge-font-size);
  font-weight: var(--my-badge-font-weight);
  line-height: 1;
  color: var(--my-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.modal {
  --my-modal-zindex: 151050;
  --my-modal-width: 500px;
  --my-modal-padding: 1rem;
  --my-modal-margin: 0.5rem;
  --my-modal-color: ;
  --my-modal-bg: var(--my-body-bg);
  --my-modal-border-color: var(--my-border-color-translucent);
  --my-modal-border-width: var(--my-border-width);
  --my-modal-border-radius: var(--my-border-radius-lg);
  --my-modal-box-shadow: var(--my-box-shadow-sm);
  --my-modal-inner-border-radius: calc(var(--my-border-radius-lg) - (var(--my-border-width)));
  --my-modal-header-padding-x: 1rem;
  --my-modal-header-padding-y: 1rem;
  --my-modal-header-padding: 1rem 1rem;
  --my-modal-header-border-color: var(--my-border-color);
  --my-modal-header-border-width: var(--my-border-width);
  --my-modal-title-line-height: 1.5;
  --my-modal-footer-gap: 0.5rem;
  --my-modal-footer-bg: ;
  --my-modal-footer-border-color: var(--my-border-color);
  --my-modal-footer-border-width: var(--my-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--my-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--my-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transform: translate(0, -50px);
  transition: transform 0.3s ease-out;
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--my-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--my-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--my-modal-color);
  pointer-events: auto;
  background-color: var(--my-modal-bg);
  background-clip: padding-box;
  border: var(--my-modal-border-width) solid var(--my-modal-border-color);
  outline: 0;
}

.modal-backdrop {
  --my-backdrop-zindex: 151000;
  --my-backdrop-bg: #000;
  --my-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--my-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--my-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--my-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: var(--my-modal-header-padding);
  border-bottom: var(--my-modal-header-border-width) solid var(--my-modal-header-border-color);
}
.modal-header .btn-close {
  padding: calc(var(--my-modal-header-padding-y) * 0.5) calc(var(--my-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--my-modal-header-padding-y)) calc(-0.5 * var(--my-modal-header-padding-x)) calc(-0.5 * var(--my-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--my-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--my-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--my-modal-padding) - var(--my-modal-footer-gap) * 0.5);
  background-color: var(--my-modal-footer-bg);
  border-top: var(--my-modal-footer-border-width) solid var(--my-modal-footer-border-color);
}
.modal-footer > * {
  margin: calc(var(--my-modal-footer-gap) * 0.5);
}

@media (min-width: 552px) {
  .modal {
    --my-modal-margin: 1.75rem;
    --my-modal-box-shadow: var(--my-box-shadow);
  }
  .modal-dialog {
    max-width: var(--my-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --my-modal-width: 300px;
  }
}
@media (min-width: 1014px) {
  .modal-lg,
  .modal-xl {
    --my-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --my-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 551.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 763.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1013.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --my-tooltip-zindex: 150000;
  --my-tooltip-max-width: 200px;
  --my-tooltip-padding-x: 1.5rem;
  --my-tooltip-padding-y: 0.75rem;
  --my-tooltip-margin: ;
  --my-tooltip-font-size: 1.2rem;
  --my-tooltip-color: #fff;
  --my-tooltip-bg: #333;
  --my-tooltip-border-radius: var(--my-border-radius);
  --my-tooltip-opacity: 0.9;
  --my-tooltip-arrow-width: 0.8rem;
  --my-tooltip-arrow-height: 0.4rem;
  z-index: var(--my-tooltip-zindex);
  display: block;
  margin: var(--my-tooltip-margin);
  font-family: "Fira Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--my-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--my-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--my-tooltip-arrow-width);
  height: var(--my-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: calc(-1 * var(--my-tooltip-arrow-height));
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--my-tooltip-arrow-height) calc(var(--my-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--my-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: calc(-1 * var(--my-tooltip-arrow-height));
  width: var(--my-tooltip-arrow-height);
  height: var(--my-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--my-tooltip-arrow-width) * 0.5) var(--my-tooltip-arrow-height) calc(var(--my-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--my-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: calc(-1 * var(--my-tooltip-arrow-height));
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--my-tooltip-arrow-width) * 0.5) var(--my-tooltip-arrow-height);
  border-bottom-color: var(--my-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: calc(-1 * var(--my-tooltip-arrow-height));
  width: var(--my-tooltip-arrow-height);
  height: var(--my-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--my-tooltip-arrow-width) * 0.5) 0 calc(var(--my-tooltip-arrow-width) * 0.5) var(--my-tooltip-arrow-height);
  border-left-color: var(--my-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--my-tooltip-max-width);
  padding: var(--my-tooltip-padding-y) var(--my-tooltip-padding-x);
  color: var(--my-tooltip-color);
  text-align: center;
  background-color: var(--my-tooltip-bg);
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-theme {
  color: #fff !important;
  background-color: RGBA(var(--my-theme-rgb), var(--my-bg-opacity, 1)) !important;
}

.text-bg-akzent1 {
  color: #fff !important;
  background-color: RGBA(var(--my-akzent1-rgb), var(--my-bg-opacity, 1)) !important;
}

.text-bg-akzent2 {
  color: #fff !important;
  background-color: RGBA(var(--my-akzent2-rgb), var(--my-bg-opacity, 1)) !important;
}

.text-bg-akzent3 {
  color: #000 !important;
  background-color: RGBA(var(--my-akzent3-rgb), var(--my-bg-opacity, 1)) !important;
}

.link-theme {
  color: RGBA(var(--my-theme-rgb), var(--my-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--my-theme-rgb), var(--my-link-underline-opacity, 1)) !important;
}
.link-theme:hover, .link-theme:focus {
  color: RGBA(122, 0, 14, var(--my-link-opacity, 1)) !important;
  text-decoration-color: RGBA(122, 0, 14, var(--my-link-underline-opacity, 1)) !important;
}

.link-akzent1 {
  color: RGBA(var(--my-akzent1-rgb), var(--my-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--my-akzent1-rgb), var(--my-link-underline-opacity, 1)) !important;
}
.link-akzent1:hover, .link-akzent1:focus {
  color: RGBA(28, 70, 110, var(--my-link-opacity, 1)) !important;
  text-decoration-color: RGBA(28, 70, 110, var(--my-link-underline-opacity, 1)) !important;
}

.link-akzent2 {
  color: RGBA(var(--my-akzent2-rgb), var(--my-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--my-akzent2-rgb), var(--my-link-underline-opacity, 1)) !important;
}
.link-akzent2:hover, .link-akzent2:focus {
  color: RGBA(138, 46, 39, var(--my-link-opacity, 1)) !important;
  text-decoration-color: RGBA(138, 46, 39, var(--my-link-underline-opacity, 1)) !important;
}

.link-akzent3 {
  color: RGBA(var(--my-akzent3-rgb), var(--my-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--my-akzent3-rgb), var(--my-link-underline-opacity, 1)) !important;
}
.link-akzent3:hover, .link-akzent3:focus {
  color: RGBA(189, 185, 197, var(--my-link-opacity, 1)) !important;
  text-decoration-color: RGBA(189, 185, 197, var(--my-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--my-emphasis-color-rgb), var(--my-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--my-emphasis-color-rgb), var(--my-link-underline-opacity, 1)) !important;
}
.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--my-emphasis-color-rgb), var(--my-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--my-emphasis-color-rgb), var(--my-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--my-focus-ring-x, 0) var(--my-focus-ring-y, 0) var(--my-focus-ring-blur, 0) var(--my-focus-ring-width) var(--my-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--my-link-color-rgb), var(--my-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--my-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--my-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --my-aspect-ratio: 100%;
}

.ratio-4x3 {
  --my-aspect-ratio: 75%;
}

.ratio-16x9 {
  --my-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --my-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 552px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 764px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1014px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden, .sr-only,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption), .sr-only:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--my-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-6 {
  order: 6 !important;
}

.order-last {
  order: 6 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.float-right {
  float: right !important;
}

.float-left {
  float: left !important;
}

@media (min-width: 552px) {
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-6 {
    order: 6 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-left {
    float: left !important;
  }
}
@media (min-width: 764px) {
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-6 {
    order: 6 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-left {
    float: left !important;
  }
}
@media (min-width: 1014px) {
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-6 {
    order: 6 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-left {
    float: left !important;
  }
}
@media (min-width: 1200px) {
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-6 {
    order: 6 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-left {
    float: left !important;
  }
}
@media (min-width: 1400px) {
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-6 {
    order: 6 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .float-xxl-right {
    float: right !important;
  }
  .float-xxl-left {
    float: left !important;
  }
}
/**********
 * Source file: _bs5-adjustments.scss
 */
.visible-xs {
  display: none !important;
}

.visible-sm {
  display: none !important;
}

.visible-md {
  display: none !important;
}

.visible-lg {
  display: none !important;
}

.visible-xl {
  display: none !important;
}

.visible-xxl {
  display: none !important;
}

@media (max-width: 551.98px) {
  .visible-xs {
    display: block !important;
  }
  .hidden-xs {
    display: none !important;
  }
  html:not(.opencms-page-editor) .hide-xs {
    display: none !important;
  }
  html.opencms-page-editor .hide-xs {
    opacity: 0.5;
    background-image: repeating-linear-gradient(135deg, transparent, transparent 10px, rgba(0, 0, 0, 0.1) 10px, rgba(0, 0, 0, 0.1) 20px);
  }
  html.opencms-page-editor .hide-xs.type-slider .slide-wrapper {
    transition: none !important;
  }
  html.opencms-page-editor .hide-xs.type-slider .slide-wrapper.slide-active {
    opacity: 0.5 !important;
  }
}
@media (max-width: 763.98px) {
  .hidden-xs-sm {
    display: none !important;
  }
  html:not(.opencms-page-editor) .hide-xs-sm {
    display: none !important;
  }
  html.opencms-page-editor .hide-xs-sm {
    opacity: 0.5;
    background-image: repeating-linear-gradient(135deg, transparent, transparent 10px, rgba(0, 0, 0, 0.1) 10px, rgba(0, 0, 0, 0.1) 20px);
  }
  html.opencms-page-editor .hide-xs-sm.type-slider .slide-wrapper {
    transition: none !important;
  }
  html.opencms-page-editor .hide-xs-sm.type-slider .slide-wrapper.slide-active {
    opacity: 0.5 !important;
  }
}
@media (max-width: 1013.98px) {
  .hidden-xs-sm-md {
    display: none !important;
  }
  html:not(.opencms-page-editor) .hide-xs-sm-md {
    display: none !important;
  }
  html.opencms-page-editor .hide-xs-sm-md {
    opacity: 0.5;
    background-image: repeating-linear-gradient(135deg, transparent, transparent 10px, rgba(0, 0, 0, 0.1) 10px, rgba(0, 0, 0, 0.1) 20px);
  }
  html.opencms-page-editor .hide-xs-sm-md.type-slider .slide-wrapper {
    transition: none !important;
  }
  html.opencms-page-editor .hide-xs-sm-md.type-slider .slide-wrapper.slide-active {
    opacity: 0.5 !important;
  }
}
@media (max-width: 1199.98px) {
  .hidden-xs-sm-md-lg {
    display: none !important;
  }
  html:not(.opencms-page-editor) .hide-xs-sm-md-lg {
    display: none !important;
  }
  html.opencms-page-editor .hide-xs-sm-md-lg {
    opacity: 0.5;
    background-image: repeating-linear-gradient(135deg, transparent, transparent 10px, rgba(0, 0, 0, 0.1) 10px, rgba(0, 0, 0, 0.1) 20px);
  }
  html.opencms-page-editor .hide-xs-sm-md-lg.type-slider .slide-wrapper {
    transition: none !important;
  }
  html.opencms-page-editor .hide-xs-sm-md-lg.type-slider .slide-wrapper.slide-active {
    opacity: 0.5 !important;
  }
}
@media (max-width: 1399.98px) {
  .hidden-xs-sm-md-lg-xl {
    display: none !important;
  }
  html:not(.opencms-page-editor) .hide-xs-sm-md-lg-xl {
    display: none !important;
  }
  html.opencms-page-editor .hide-xs-sm-md-lg-xl {
    opacity: 0.5;
    background-image: repeating-linear-gradient(135deg, transparent, transparent 10px, rgba(0, 0, 0, 0.1) 10px, rgba(0, 0, 0, 0.1) 20px);
  }
  html.opencms-page-editor .hide-xs-sm-md-lg-xl.type-slider .slide-wrapper {
    transition: none !important;
  }
  html.opencms-page-editor .hide-xs-sm-md-lg-xl.type-slider .slide-wrapper.slide-active {
    opacity: 0.5 !important;
  }
}
@media (min-width: 552px) and (max-width: 763.98px) {
  .visible-sm {
    display: block !important;
  }
  .hidden-sm {
    display: none !important;
  }
  html:not(.opencms-page-editor) .hide-sm {
    display: none !important;
  }
  html.opencms-page-editor .hide-sm {
    opacity: 0.5;
    background-image: repeating-linear-gradient(135deg, transparent, transparent 10px, rgba(0, 0, 0, 0.1) 10px, rgba(0, 0, 0, 0.1) 20px);
  }
  html.opencms-page-editor .hide-sm.type-slider .slide-wrapper {
    transition: none !important;
  }
  html.opencms-page-editor .hide-sm.type-slider .slide-wrapper.slide-active {
    opacity: 0.5 !important;
  }
}
@media (min-width: 764px) and (max-width: 1013.98px) {
  .visible-md {
    display: block !important;
  }
  .hidden-md {
    display: none !important;
  }
  html:not(.opencms-page-editor) .hide-md {
    display: none !important;
  }
  html.opencms-page-editor .hide-md {
    opacity: 0.5;
    background-image: repeating-linear-gradient(135deg, transparent, transparent 10px, rgba(0, 0, 0, 0.1) 10px, rgba(0, 0, 0, 0.1) 20px);
  }
  html.opencms-page-editor .hide-md.type-slider .slide-wrapper {
    transition: none !important;
  }
  html.opencms-page-editor .hide-md.type-slider .slide-wrapper.slide-active {
    opacity: 0.5 !important;
  }
}
@media (min-width: 1014px) and (max-width: 1199.98px) {
  .visible-lg {
    display: block !important;
  }
  .hidden-lg {
    display: none !important;
  }
  html:not(.opencms-page-editor) .hide-lg {
    display: none !important;
  }
  html.opencms-page-editor .hide-lg {
    opacity: 0.5;
    background-image: repeating-linear-gradient(135deg, transparent, transparent 10px, rgba(0, 0, 0, 0.1) 10px, rgba(0, 0, 0, 0.1) 20px);
  }
  html.opencms-page-editor .hide-lg.type-slider .slide-wrapper {
    transition: none !important;
  }
  html.opencms-page-editor .hide-lg.type-slider .slide-wrapper.slide-active {
    opacity: 0.5 !important;
  }
}
@media (min-width: 552px) {
  .hidden-sm-md-lg-xl {
    display: none !important;
  }
  .hidden-sm-up {
    display: none !important;
  }
  html:not(.opencms-page-editor) .hide-sm-md-lg-xl {
    display: none !important;
  }
  html.opencms-page-editor .hide-sm-md-lg-xl {
    opacity: 0.5;
    background-image: repeating-linear-gradient(135deg, transparent, transparent 10px, rgba(0, 0, 0, 0.1) 10px, rgba(0, 0, 0, 0.1) 20px);
  }
  html.opencms-page-editor .hide-sm-md-lg-xl.type-slider .slide-wrapper {
    transition: none !important;
  }
  html.opencms-page-editor .hide-sm-md-lg-xl.type-slider .slide-wrapper.slide-active {
    opacity: 0.5 !important;
  }
  html:not(.opencms-page-editor) .hide-sm-up {
    display: none !important;
  }
  html.opencms-page-editor .hide-sm-up {
    opacity: 0.5;
    background-image: repeating-linear-gradient(135deg, transparent, transparent 10px, rgba(0, 0, 0, 0.1) 10px, rgba(0, 0, 0, 0.1) 20px);
  }
  html.opencms-page-editor .hide-sm-up.type-slider .slide-wrapper {
    transition: none !important;
  }
  html.opencms-page-editor .hide-sm-up.type-slider .slide-wrapper.slide-active {
    opacity: 0.5 !important;
  }
}
@media (min-width: 764px) {
  ol,
  ul {
    padding-left: 40px;
  }
  .hidden-md-lg-xl {
    display: none !important;
  }
  .hidden-md-up {
    display: none !important;
  }
  html:not(.opencms-page-editor) .hide-md-lg-xl {
    display: none !important;
  }
  html.opencms-page-editor .hide-md-lg-xl {
    opacity: 0.5;
    background-image: repeating-linear-gradient(135deg, transparent, transparent 10px, rgba(0, 0, 0, 0.1) 10px, rgba(0, 0, 0, 0.1) 20px);
  }
  html.opencms-page-editor .hide-md-lg-xl.type-slider .slide-wrapper {
    transition: none !important;
  }
  html.opencms-page-editor .hide-md-lg-xl.type-slider .slide-wrapper.slide-active {
    opacity: 0.5 !important;
  }
  html:not(.opencms-page-editor) .hide-md-up {
    display: none !important;
  }
  html.opencms-page-editor .hide-md-up {
    opacity: 0.5;
    background-image: repeating-linear-gradient(135deg, transparent, transparent 10px, rgba(0, 0, 0, 0.1) 10px, rgba(0, 0, 0, 0.1) 20px);
  }
  html.opencms-page-editor .hide-md-up.type-slider .slide-wrapper {
    transition: none !important;
  }
  html.opencms-page-editor .hide-md-up.type-slider .slide-wrapper.slide-active {
    opacity: 0.5 !important;
  }
}
@media (min-width: 1014px) {
  .hidden-lg-xl {
    display: none !important;
  }
  .hidden-lg-up {
    display: none !important;
  }
  html:not(.opencms-page-editor) .hide-lg-xl {
    display: none !important;
  }
  html.opencms-page-editor .hide-lg-xl {
    opacity: 0.5;
    background-image: repeating-linear-gradient(135deg, transparent, transparent 10px, rgba(0, 0, 0, 0.1) 10px, rgba(0, 0, 0, 0.1) 20px);
  }
  html.opencms-page-editor .hide-lg-xl.type-slider .slide-wrapper {
    transition: none !important;
  }
  html.opencms-page-editor .hide-lg-xl.type-slider .slide-wrapper.slide-active {
    opacity: 0.5 !important;
  }
  html:not(.opencms-page-editor) .hide-lg-up {
    display: none !important;
  }
  html.opencms-page-editor .hide-lg-up {
    opacity: 0.5;
    background-image: repeating-linear-gradient(135deg, transparent, transparent 10px, rgba(0, 0, 0, 0.1) 10px, rgba(0, 0, 0, 0.1) 20px);
  }
  html.opencms-page-editor .hide-lg-up.type-slider .slide-wrapper {
    transition: none !important;
  }
  html.opencms-page-editor .hide-lg-up.type-slider .slide-wrapper.slide-active {
    opacity: 0.5 !important;
  }
}
@media (min-width: 1200px) {
  .hidden-xl-up {
    display: none !important;
  }
  html:not(.opencms-page-editor) .hide-xl-up {
    display: none !important;
  }
  html.opencms-page-editor .hide-xl-up {
    opacity: 0.5;
    background-image: repeating-linear-gradient(135deg, transparent, transparent 10px, rgba(0, 0, 0, 0.1) 10px, rgba(0, 0, 0, 0.1) 20px);
  }
  html.opencms-page-editor .hide-xl-up.type-slider .slide-wrapper {
    transition: none !important;
  }
  html.opencms-page-editor .hide-xl-up.type-slider .slide-wrapper.slide-active {
    opacity: 0.5 !important;
  }
}
@media (min-width: 1200px) {
  .visible-xl {
    display: block !important;
  }
  .hidden-xl {
    display: none !important;
  }
  .hidden-xl-up {
    display: none !important;
  }
  html:not(.opencms-page-editor) .hide-xl {
    display: none !important;
  }
  html.opencms-page-editor .hide-xl {
    opacity: 0.5;
    background-image: repeating-linear-gradient(135deg, transparent, transparent 10px, rgba(0, 0, 0, 0.1) 10px, rgba(0, 0, 0, 0.1) 20px);
  }
  html.opencms-page-editor .hide-xl.type-slider .slide-wrapper {
    transition: none !important;
  }
  html.opencms-page-editor .hide-xl.type-slider .slide-wrapper.slide-active {
    opacity: 0.5 !important;
  }
  html:not(.opencms-page-editor) .hide-xl-up {
    display: none !important;
  }
  html.opencms-page-editor .hide-xl-up {
    opacity: 0.5;
    background-image: repeating-linear-gradient(135deg, transparent, transparent 10px, rgba(0, 0, 0, 0.1) 10px, rgba(0, 0, 0, 0.1) 20px);
  }
  html.opencms-page-editor .hide-xl-up.type-slider .slide-wrapper {
    transition: none !important;
  }
  html.opencms-page-editor .hide-xl-up.type-slider .slide-wrapper.slide-active {
    opacity: 0.5 !important;
  }
}
@media (min-width: 1400px) {
  .visible-xxl {
    display: block !important;
  }
  .hidden-xxl {
    display: none !important;
  }
  html:not(.opencms-page-editor) .hide-xxl {
    display: none !important;
  }
  html.opencms-page-editor .hide-xxl {
    opacity: 0.5;
    background-image: repeating-linear-gradient(135deg, transparent, transparent 10px, rgba(0, 0, 0, 0.1) 10px, rgba(0, 0, 0, 0.1) 20px);
  }
  html.opencms-page-editor .hide-xxl.type-slider .slide-wrapper {
    transition: none !important;
  }
  html.opencms-page-editor .hide-xxl.type-slider .slide-wrapper.slide-active {
    opacity: 0.5 !important;
  }
}
.tooltip .tooltip-inner {
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
  text-align: left;
}

/* Required from BS4 type.scss which we did not include */
small,
.small {
  font-size: 0.875em;
  font-weight: 300;
}

/* Other staff missing in Boostrap 5 */
.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.row > .pull-left {
  float: none;
  order: -1;
}

.row > .pull-right {
  float: none;
  order: 13;
}

.no-gutters {
  --my-gutter-x: 0;
  --my-gutter-y: 0;
}

.flex-fill {
  flex: 1 1 auto !important;
}

/*!
 *
 * The Mercury Template (https://mercury-template.opencms.org/) - Copyright Alkacon Software GmbH & Co. KG
 *
 */
/**********
 * Source file: _animations.scss
 */
@media (min-width: 764px) {
  html.desktop .effect-inset:not(.effect-piece) > .visual .effect-box,
  html.desktop .effect-shadow:not(.effect-piece) > .visual .effect-box,
  html.desktop .effect-raise:not(.effect-piece) > .visual .effect-box {
    box-shadow: none;
    transition-property: transform, box-shadow, transform-origin;
    transition-duration: 0.33s;
    transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
    transition-delay: 0s;
    z-index: 4;
  }
  html.desktop .effect-inset:not(.effect-piece):hover > .visual .effect-box,
  html.desktop .effect-shadow:not(.effect-piece):hover > .visual .effect-box,
  html.desktop .effect-raise:not(.effect-piece):hover > .visual .effect-box {
    border-radius: var(--my-image-border-radius);
  }
  html.desktop .effect-inset.effect-piece,
  html.desktop .effect-shadow.effect-piece,
  html.desktop .effect-raise.effect-piece {
    box-shadow: none;
    transition-property: transform, box-shadow, transform-origin;
    transition-duration: 0.33s;
    transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
    transition-delay: 0s;
    z-index: 4;
  }
  html.desktop .effect-inset.effect-piece > .heading,
  html.desktop .effect-inset.effect-piece > .body,
  html.desktop .effect-inset.effect-piece > .visual .effect-box,
  html.desktop .effect-inset.effect-piece > .visual .subtitle,
  html.desktop .effect-inset.effect-piece > .link,
  html.desktop .effect-shadow.effect-piece > .heading,
  html.desktop .effect-shadow.effect-piece > .body,
  html.desktop .effect-shadow.effect-piece > .visual .effect-box,
  html.desktop .effect-shadow.effect-piece > .visual .subtitle,
  html.desktop .effect-shadow.effect-piece > .link,
  html.desktop .effect-raise.effect-piece > .heading,
  html.desktop .effect-raise.effect-piece > .body,
  html.desktop .effect-raise.effect-piece > .visual .effect-box,
  html.desktop .effect-raise.effect-piece > .visual .subtitle,
  html.desktop .effect-raise.effect-piece > .link {
    box-shadow: none;
    transition-property: transform, box-shadow, transform-origin;
    transition-duration: 0.33s;
    transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
    transition-delay: 0s;
    z-index: 4;
  }
  html.desktop .effect-inset.effect-piece:hover,
  html.desktop .effect-shadow.effect-piece:hover,
  html.desktop .effect-raise.effect-piece:hover {
    z-index: 6;
    border-radius: var(--my-image-border-radius);
  }
  html.desktop .effect-inset.effect-piece:hover > .visual .subtitle,
  html.desktop .effect-inset.effect-piece:hover > .body,
  html.desktop .effect-shadow.effect-piece:hover > .visual .subtitle,
  html.desktop .effect-shadow.effect-piece:hover > .body,
  html.desktop .effect-raise.effect-piece:hover > .visual .subtitle,
  html.desktop .effect-raise.effect-piece:hover > .body {
    transform: scale(0.93);
  }
  html.desktop .effect-inset.effect-piece:hover > .visual + .body,
  html.desktop .effect-shadow.effect-piece:hover > .visual + .body,
  html.desktop .effect-raise.effect-piece:hover > .visual + .body {
    transform-origin: center top;
  }
  html.desktop .effect-inset.effect-piece:hover > .heading,
  html.desktop .effect-shadow.effect-piece:hover > .heading,
  html.desktop .effect-raise.effect-piece:hover > .heading {
    transform: scale(0.93) translate3d(0px, 10px, 0px);
  }
  html.desktop .effect-inset.effect-piece:hover > .link,
  html.desktop .effect-shadow.effect-piece:hover > .link,
  html.desktop .effect-raise.effect-piece:hover > .link {
    transform: scale(0.93) translate3d(0px, -10px, 0px);
  }
  html.desktop .effect-raise.effect-piece:hover > .visual + .body,
  html.desktop .effect-inset.effect-piece:hover > .visual + .body {
    transform-origin: center;
  }
  html.desktop .effect-raise:not(.effect-piece):hover > .visual .effect-box {
    transform: scale(1.075);
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.3);
  }
  html.desktop .effect-raise.effect-piece:hover {
    transform: scale(1.075);
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.3);
  }
  html.desktop .effect-shadow:not(.effect-piece):hover > .visual .effect-box {
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.3);
  }
  html.desktop .effect-shadow.effect-piece:hover {
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.3);
  }
  html.desktop .effect-inset:not(.effect-piece):hover > .visual .effect-box {
    transform: scale(0.93);
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.3) inset;
  }
  html.desktop .effect-inset.effect-piece:hover {
    transform: scale(0.93);
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.3) inset;
  }
  html.desktop .effect-remove-margin .effect-box {
    box-shadow: none;
    transition-property: transform, box-shadow, transform-origin;
    transition-duration: 0.33s;
    transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
    transition-delay: 0s;
    z-index: 4;
    transition-property: margin;
  }
  html.desktop .effect-remove-margin:hover .effect-box {
    margin: 0 !important;
  }
}
html.desktop .effect-rotate:hover .image-src-box > .animated {
  transform: scale(1.25) rotate(2deg);
}
html.desktop .effect-rotate .image-src-box > .animated {
  transition: transform 1s cubic-bezier(0.25, 0.46, 0.45, 0.94), opacity 750ms;
}
html.desktop .effect-rotate .image-src-box {
  border-radius: var(--my-image-border-radius);
  overflow: hidden;
  z-index: 5;
}

.effect-bordered .image-src-box {
  border: 1px solid #ddd;
}

.clickme-showme {
  cursor: pointer;
}
.clickme-showme > .clickme {
  display: none;
}
.clickme-showme > .showme {
  display: block;
}

@keyframes fadeTopMenu {
  from {
    opacity: 0;
    transform: scaleY(0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes fadeRightMenu {
  from {
    opacity: 0;
    transform: scaleX(0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes fadeInMenu {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.list-spinner,
.spinner {
  top: 45%;
  width: 30px;
  height: auto;
  font-size: 30px;
  position: absolute;
  transform: translateY(-50%);
  z-index: 1000;
  left: 50%;
}
.list-spinner, a .list-spinner,
.spinner,
a .spinner {
  color: #fff;
}
.list-spinner .spinner-icon,
.spinner .spinner-icon {
  animation: spinner 1s infinite;
  animation-timing-function: steps(4, end);
}

.spinnerInnerBox {
  position: relative;
  left: -150%;
  background-color: black;
  width: 300%;
  padding: 100%;
  border-radius: 12px;
  line-height: 0;
  opacity: 0.65;
}

.spinnerBottom {
  top: initial;
  position: fixed;
  bottom: 80px;
}

/**********
 * Source file: _basics.scss
 */
html {
  --my-toolbar-height: 0px;
}
@media (max-width: 1013.98px) {
  html {
    font-size: 12px;
  }
}
@media (min-width: 1014px) {
  html {
    font-size: 10px;
  }
}
html.opencms-page-editor {
  --my-toolbar-height: 52px;
  padding-top: var(--my-toolbar-height);
  scroll-behavior: auto;
}
html *:focus,
html .btn:focus,
html .dropdown > a:focus,
html .dropdown-menu > a:focus {
  outline: none;
  box-shadow: none;
}
html .opencms:focus,
html .opencms *:focus {
  outline: none;
  box-shadow: none;
}
html.keyboard-nav *:not(.mce-edit-focus):focus,
html.keyboard-nav .dropdown > a:focus,
html.keyboard-nav .dropdown-menu > a:focus {
  outline-color: #dd0011;
  outline-style: solid;
  outline-width: 3px;
  outline-offset: 2px;
  box-shadow: none;
  z-index: 650000;
}
html.keyboard-nav .media-box:focus-within,
html.keyboard-nav .focus-within:focus-within {
  outline-color: #dd0011;
  outline-style: solid;
  outline-width: 3px;
  outline-offset: 2px;
  box-shadow: none;
  z-index: 650000;
}
html.keyboard-nav .opencms:focus,
html.keyboard-nav .opencms *:focus {
  outline-offset: -2px;
}
html.mercury-page {
  height: 100vh;
  min-height: 100%;
}
html.mercury-page #mercury-page {
  min-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
}
html.hasscript .hide-hasscript {
  display: none;
}

.default-font, .download-list.dl-list-compact .dl-content .dl-title, .type-contact.compact .fn.n,
.type-contact.compact .org,
.type-contact.compact .pos {
  font-family: var(--my-body-font-family);
  font-size: var(--my-body-font-size);
  font-weight: var(--my-body-font-weight);
  line-height: var(--my-body-line-height);
}

@media (max-width: 1013.98px) {
  .lead {
    font-size: 1.8rem;
  }
}
@media (min-width: 1014px) {
  .lead {
    font-size: 2rem;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: var(--my-font-weight-headings);
  font-family: var(--my-font-family-headings);
  font-style: var(--my-font-style-headings);
  color: var(--my-headings-color);
  margin-bottom: var(--my-headings-margin-bottom);
}

.h1, .h2, .h3, .type-liturgy .calendar-simple, .accordion .sub-header, .onclick-activation .oa-heading, .h4, .type-gottesdienst.teaser.gd.calendar-sheet-piece .gd-hd, .styled-form header, .detail-page .sub-header, .h5, .h6 {
  font-weight: var(--my-font-weight-headings);
  font-family: var(--my-font-family-headings);
  font-style: var(--my-font-style-headings);
  margin-top: 0;
  margin-bottom: var(--my-headings-margin-bottom);
  color: var(--my-headings-color);
  line-height: 1.25;
}

@media (max-width: 1013.98px) {
  h1, .h1 {
    font-size: 2.4rem;
  }
  h2, .h2 {
    font-size: 2rem;
  }
  h3, .h3, .type-liturgy .calendar-simple, .accordion .sub-header, .onclick-activation .oa-heading {
    font-size: 1.8rem;
  }
  h4, .h4, .type-gottesdienst.teaser.gd.calendar-sheet-piece .gd-hd, .styled-form header, .detail-page .sub-header {
    font-size: 1.6rem;
  }
  @container (max-width: 300px) {
    .adjust-headings {
      container-type: inline-size;
    }
    .adjust-headings h1, .adjust-headings .h1 {
      font-size: calc(21.6px + 12 * clamp(0px, (100cqw - 100px) / 200, 1px));
    }
    .adjust-headings h2, .adjust-headings .h2 {
      font-size: calc(21.6px + 7.2 * clamp(0px, (100cqw - 100px) / 200, 1px));
    }
    .adjust-headings h3, .adjust-headings .h3, .adjust-headings .type-liturgy .calendar-simple, .type-liturgy .adjust-headings .calendar-simple, .adjust-headings .accordion .sub-header, .accordion .adjust-headings .sub-header, .adjust-headings .onclick-activation .oa-heading, .onclick-activation .adjust-headings .oa-heading {
      font-size: calc(21.6px + 2.4 * clamp(0px, (100cqw - 100px) / 200, 1px));
    }
    .adjust-headings h4, .adjust-headings .h4, .adjust-headings .type-gottesdienst.teaser.gd.calendar-sheet-piece .gd-hd, .type-gottesdienst.teaser.gd.calendar-sheet-piece .adjust-headings .gd-hd, .adjust-headings .styled-form header, .styled-form .adjust-headings header, .adjust-headings .detail-page .sub-header, .detail-page .adjust-headings .sub-header {
      font-size: calc(21.6px + 0 * clamp(0px, (100cqw - 100px) / 200, 1px));
    }
  }
}
@media (min-width: 1014px) {
  h1, .h1 {
    font-size: 2.8rem;
  }
  h2, .h2 {
    font-size: 2.4rem;
  }
  h3, .h3, .type-liturgy .calendar-simple, .accordion .sub-header, .onclick-activation .oa-heading {
    font-size: 2rem;
  }
  h4, .h4, .type-gottesdienst.teaser.gd.calendar-sheet-piece .gd-hd, .styled-form header, .detail-page .sub-header {
    font-size: 1.8rem;
  }
  @container (max-width: 300px) {
    .adjust-headings {
      container-type: inline-size;
    }
    .adjust-headings h1, .adjust-headings .h1 {
      font-size: calc(18px + 10 * clamp(0px, (100cqw - 100px) / 200, 1px));
    }
    .adjust-headings h2, .adjust-headings .h2 {
      font-size: calc(18px + 6 * clamp(0px, (100cqw - 100px) / 200, 1px));
    }
    .adjust-headings h3, .adjust-headings .h3, .adjust-headings .type-liturgy .calendar-simple, .type-liturgy .adjust-headings .calendar-simple, .adjust-headings .accordion .sub-header, .accordion .adjust-headings .sub-header, .adjust-headings .onclick-activation .oa-heading, .onclick-activation .adjust-headings .oa-heading {
      font-size: calc(18px + 2 * clamp(0px, (100cqw - 100px) / 200, 1px));
    }
    .adjust-headings h4, .adjust-headings .h4, .adjust-headings .type-gottesdienst.teaser.gd.calendar-sheet-piece .gd-hd, .type-gottesdienst.teaser.gd.calendar-sheet-piece .adjust-headings .gd-hd, .adjust-headings .styled-form header, .styled-form .adjust-headings header, .adjust-headings .detail-page .sub-header, .detail-page .adjust-headings .sub-header {
      font-size: calc(18px + 0 * clamp(0px, (100cqw - 100px) / 200, 1px));
    }
  }
}
h5, .h5 {
  font-size: 1.8rem;
}

h6, .h6 {
  font-size: 1.8rem;
}

@media (min-width: 764px) {
  a:focus, a:hover {
    transition-property: color, background-color, border-color;
    transition-duration: 0.33s;
    transition-timing-function: ease;
  }
}
a:hover {
  outline: none;
}
a.uncolored {
  color: inherit;
}
a.uncolored:focus, a.uncolored:hover {
  color: var(--my-link-hover-color);
}
a.uncolored.fully:focus, a.uncolored.fully:hover {
  color: inherit;
}

a.external::after {
  font-family: var(--my-icon-font-family);
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: " \f08e";
  margin-left: 2px;
}

.no-external a.external::after,
.type-iconbox a.external::after,
.section-visual a.external::after {
  content: "";
  display: none;
}

.badge {
  border-radius: 4px !important;
}

span.badge {
  color: #fff;
  background-color: #aaa;
  font-weight: 300;
  padding: 4px 7px;
}

span.badge-theme {
  color: #fff;
  background: #990011;
}

span.badge-akzent1 {
  color: #fff;
  background: #235789;
}

span.badge-akzent2 {
  color: #fff;
  background: #ac3931;
}

span.badge-akzent3 {
  color: #fff;
  background: #ada8b6;
}

.visually-hidden-focusable-fixed:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden-focusable-fixed:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}
.visually-hidden-focusable-fixed:active, .visually-hidden-focusable-fixed:focus {
  position: fixed;
  top: 0;
  left: 0;
}

html.opencms-page-editor .visually-hidden-focusable-fixed:active, html.opencms-page-editor .visually-hidden-focusable-fixed:focus {
  top: 52px;
}

#keyboard-toggle::after {
  font-family: var(--my-icon-font-family);
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-left: 1rem;
  content: "\f096";
}
#keyboard-toggle[aria-checked=true]::after {
  content: "\f046";
}

.anchor-link-parent .anchor-link {
  display: none;
}
.anchor-link-parent .anchor-link::after {
  content: " #";
}
.anchor-link-parent:hover .anchor-link {
  display: inline;
  text-decoration: none;
}

html.opencms-editor-active .anchor-link-parent:hover .anchor-link {
  display: none;
}

@media print {
  html {
    font-size: 8px;
  }
  header,
  footer,
  aside {
    display: none !important;
  }
  main {
    width: 100% !important;
  }
  main > .container > .row > div {
    flex: 0 0 100%;
    max-width: initial;
  }
  body {
    font-size: 12pt;
    line-height: 1.35;
    padding: 0;
    margin: 0;
  }
  img,
  .element,
  .teaser {
    page-break-inside: avoid;
  }
}
/**********
 * Source file: _element.scss
 */
.element,
.subelement,
.default-margin,
.element-margin {
  margin-bottom: var(--my-element-margin);
}

.element::after,
.subelement::after {
  display: block;
  clear: both;
  content: "";
}
.element.no-default-margin,
.subelement.no-default-margin {
  margin-bottom: 0;
}
.element.default-margin,
.subelement.default-margin {
  margin-bottom: var(--my-default-margin);
}
.element.default-margin-small,
.subelement.default-margin-small {
  margin-bottom: var(--my-default-margin-small);
}
.element.default-margin-verysmall,
.subelement.default-margin-verysmall {
  margin-bottom: var(--my-default-margin-verysmall);
}

.detail-page > .subelement:last-child,
.element > .subelement:last-child {
  margin-bottom: 0;
}

.element:not(.piece) > .heading {
  overflow: hidden;
  text-overflow: ellipsis;
  hyphens: auto;
  overflow-wrap: anywhere;
  word-break: break-word;
}

/**********
 * Source file: _placeholder.scss
 */
.placeholder {
  position: relative;
  background-color: var(--my-highlight-color);
}
.placeholder::after {
  font-family: var(--my-icon-font-family);
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  display: block;
  bottom: 0;
  left: 50%;
  top: 50%;
  width: 50px;
  height: 50px;
  font-weight: normal;
  font-size: 40px;
  color: rgba(0, 0, 0, 0.5);
  transform: translate(-50%, -50%);
  text-align: center;
}

.box .placeholder {
  border: 1px solid var(--my-highlight-border-color);
}

.opencms-page-editor .placeholder {
  cursor: pointer;
}
.opencms-page-editor .placeholder::before {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  width: 100%;
  content: attr(data-placeholder) " ";
  display: block;
  padding: 10px;
  text-align: center;
  background-color: #ffa826;
  color: #fff;
}
.opencms-page-editor .placeholder.error::before {
  background-color: #b31b34;
}
.opencms-page-editor .placeholder.static::before {
  position: static;
}

/**********
 * Source file: _buttons.scss.
 */
.btn {
  margin: 0;
  padding: 0.6rem 1.2rem;
  display: inline-block;
  text-align: center;
  user-select: none;
  box-shadow: none;
  border: none;
  text-decoration: none;
  font-family: var(--my-btn-font-family);
  white-space: nowrap;
  font-weight: 400;
  border-radius: var(--my-btn-border-radius);
  color: var(--my-btn-fg);
  background-color: var(--my-btn-bg);
}
.btn:focus, .btn:hover, .btn.active, .open .dropdown-toggle .btn {
  color: var(--my-btn-hover-fg);
  background-color: var(--my-btn-hover-bg);
  text-decoration: none;
  cursor: pointer;
  transition-property: color, background-color, border-color;
  transition-duration: 0.33s;
  transition-timing-function: ease;
}
.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
  background-color: #ddd;
  color: #444;
  cursor: not-allowed;
}

.btn-sm {
  padding: 0.2rem 0.8rem;
}

.btn-xs {
  font-size: 1.2rem;
  padding: 0.2rem 0.8rem;
}

.btn-default {
  color: var(--my-btn-fg);
  background-color: var(--my-btn-bg);
}
.btn-default:focus, .btn-default:hover, .btn-default.active, .open .dropdown-toggle .btn-default {
  color: var(--my-btn-hover-fg);
  background-color: var(--my-btn-hover-bg);
  text-decoration: none;
  cursor: pointer;
  transition-property: color, background-color, border-color;
  transition-duration: 0.33s;
  transition-timing-function: ease;
}
.btn-default.disabled, .btn-default[disabled], fieldset[disabled] .btn-default {
  background-color: #ddd;
  color: #444;
  cursor: not-allowed;
}

.btn-colored {
  color: var(--my-btn-fg);
  background-color: var(--my-btn-bg);
}

.btn-block {
  display: block;
  width: 100%;
  text-align: center;
}

.btn-wrap {
  white-space: normal;
}

.btn-group {
  position: relative;
  display: inline-flex;
  flex-wrap: wrap;
  vertical-align: middle;
}
.btn-group > .btn {
  position: relative;
  flex: 0 1 auto;
}

.btn-toggle {
  display: block;
  width: 100%;
  text-align: left;
  position: relative;
}
.btn-toggle::after {
  font-family: var(--my-icon-font-family);
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f078";
  line-height: 1;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  height: 100%;
  transform: rotate(0);
  transition: transform 0.33s;
  right: 1.2rem;
}
.btn-toggle[aria-expanded=true]::after, .btn-toggle.open::after {
  transform: rotate(-180deg);
}

/**********
 * Source file: _contact.scss
 */
.type-contact {
  --my-contact-spacer-y: calc(0.5 * var(--my-default-margin-small));
  overflow-wrap: anywhere;
  word-break: break-word;
  hyphens: auto;
}
.type-contact .fn.n,
.type-contact .org,
.type-contact .adr,
.type-contact .title,
.type-contact .pos,
.type-contact .note.top,
.type-contact .mail,
.type-contact .facility-icons {
  margin-top: var(--my-contact-spacer-y);
  margin-bottom: var(--my-contact-spacer-y);
}
.type-contact .contactlink,
.type-contact .link,
.type-contact .note.bottom,
.type-contact .website,
.type-contact .vcard {
  margin-top: var(--my-paragraph-margin-bottom);
  margin-bottom: var(--my-paragraph-margin-bottom);
}
.type-contact .org:has(+ .pos) {
  margin-bottom: 0;
}
.type-contact .org + .pos {
  margin-top: 0;
}
.type-contact .fn.n {
  --my-piece-headings-margin-bottom: var(--my-contact-spacer-y);
  margin-top: 0;
}
.type-contact .tablerow {
  display: table-row;
  hyphens: none;
  overflow-wrap: normal;
  word-break: normal;
  margin-top: 0;
  margin-bottom: 0;
}
.type-contact .tablerow > span {
  padding-right: var(--my-ws-margin);
  display: table-cell;
}
.type-contact .website a,
.type-contact .mail a {
  overflow-wrap: anywhere;
  word-break: break-word;
}
.type-contact .mail {
  hyphenate-character: " ";
  -webkit-hyphenate-character: " ";
}
.type-contact > div:last-of-type {
  margin-bottom: 0;
}
.type-contact .tablerow > span.icon-label {
  white-space: nowrap;
}
.type-contact > * > .image-src-box {
  margin-bottom: calc(2 * var(--my-contact-spacer-y));
}
.type-contact .contactlink {
  margin-top: var(--my-contact-spacer-y);
}
.type-contact .body .text-box > *:first-child {
  margin-top: 0;
}
.type-contact .body .text-box > *:last-child {
  margin-bottom: 0;
}
.type-contact .title {
  font-style: italic;
}
.type-contact .only-icon {
  padding-left: var(--my-ws-margin);
}
.type-contact .ico,
.type-contact .fa {
  margin-right: var(--my-ws-margin);
  width: calc(4 * var(--my-ws-margin));
}
.type-contact .img-responsive {
  width: 100%;
}
.type-contact .suborg-list .teaser:last-of-type {
  margin-bottom: 0;
}
.type-contact .piece.full > .visual {
  margin-left: 0;
  margin-right: 0;
}
.type-contact.compact .fn.n {
  font-weight: 600;
}
.type-contact.compact .fn.n:has(+ .pos) {
  margin-bottom: 0;
}
.type-contact.compact .fn.n + .pos {
  margin-top: 0;
}
.type-contact.compact .fn.n,
.type-contact.compact .org,
.type-contact.compact .pos,
.type-contact.compact .title,
.type-contact.compact .mail,
.type-contact.compact .facility-icons {
  margin-top: 0;
  margin-bottom: 0;
}
.type-contact.compact .note.bottom,
.type-contact.compact .contactlink,
.type-contact.compact .link,
.type-contact.compact .website,
.type-contact.compact .vcard {
  margin-top: var(--my-contact-spacer-y);
  margin-bottom: var(--my-contact-spacer-y);
}
.type-contact.compact .note > * {
  margin-bottom: var(--my-contact-spacer-y);
}
.type-contact .note > * {
  margin-bottom: var(--my-paragraph-margin-bottom);
}
.type-contact .note > *:last-child {
  margin-bottom: 0;
}
.type-contact.box::after {
  display: block;
  clear: both;
  content: "";
}

/**********
 * Source file: _container.scss
 */
#modelinfo, .oct-alert, .container-box {
  padding: 10px;
  text-align: center !important;
  overflow: hidden !important;
  z-index: 5 !important;
  white-space: normal !important;
  min-height: 100px;
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  font-weight: 300 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#modelinfo > *, .oct-alert > *, .container-box > * {
  font-size: 14px !important;
  font-family: inherit !important;
  font-weight: inherit !important;
  margin: 5px !important;
  line-height: 1.2em !important;
}
#modelinfo > .head, .oct-alert > .head, .container-box > .head, #modelinfo > h1, .oct-alert > h1, .container-box > h1, #modelinfo > h2, .oct-alert > h2, .container-box > h2, #modelinfo > h3, .oct-alert > h3, .container-box > h3, #modelinfo > h4, .oct-alert > h4, .container-box > h4 {
  font-size: 22px !important;
}
#modelinfo .text, .oct-alert .text, .container-box .text {
  font-size: 14px !important;
}
#modelinfo .small, .oct-alert .small, .container-box .small {
  font-size: 12.5px !important;
}

.oct-alert, .container-box {
  border-radius: 8px !important;
}

#modelinfo .head, .container-box .head {
  color: #444 !important;
  background-color: transparent !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.container-box {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.container-box .text {
  color: #7f8691 !important;
  background-color: transparent !important;
}
.container-box .text.capital {
  text-transform: capitalize !important;
}
.container-box .main,
.container-box .small {
  color: #7f8691 !important;
}
.container-box.simple {
  border: 1px solid #144382;
}
.container-box.complex {
  border: 1px solid #b31b34;
}
.container-box.attachment {
  border: 1px solid #ffa826;
}

@keyframes octReveal {
  from {
    max-height: 0;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
  75% {
    max-height: 0;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
  100% {
    max-height: 1000px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
  }
}
.oct-reveal {
  animation-name: octReveal;
  animation-duration: 3s;
  min-height: initial;
}

.oct-alert:not(.tile-col) {
  margin-top: 10px;
  margin-bottom: 10px;
}
.oct-alert .head,
.oct-alert .text {
  background-color: transparent !important;
}
.oct-alert > div,
.oct-alert > h1, .oct-alert > h2, .oct-alert > h3, .oct-alert > h4,
.oct-alert .head,
.oct-alert .text,
.oct-alert .small {
  color: #fff !important;
}
.oct-alert.oct-alert-editor .head {
  color: #444 !important;
}
.oct-alert.oct-alert-editor .text {
  color: #444 !important;
}

.container-box.box-area {
  background-color: rgba(255, 201, 210, 0.75) !important;
  background: linear-gradient(166deg, #ffc9d2 0%, #ff96a8 100%) !important;
}

.container-box.box-segment {
  background-color: rgba(255, 227, 231, 0.75) !important;
  background: linear-gradient(166deg, #ffe3e7 0%, #ffb0bb 100%) !important;
}

.container-box.box-grid {
  background-color: rgba(255, 218, 199, 0.75) !important;
  background: linear-gradient(166deg, #ffdac7 0%, #ffb894 100%) !important;
}

.container-box.box-row {
  background-color: rgba(255, 229, 191, 0.75) !important;
  background: linear-gradient(166deg, #ffe5bf 0%, #ffd08c 100%) !important;
}

.container-box.box-element {
  background-color: rgba(232, 243, 248, 0.75) !important;
  background: linear-gradient(166deg, #e8f3f8 0%, #c1dfec 100%) !important;
}

.container-box.box-special {
  background-color: rgba(239, 250, 180, 0.75) !important;
  background: linear-gradient(166deg, #effab4 0%, #e5f784 100%) !important;
}

.oct-alert.oct-alert-editor {
  background-color: #eed2ee !important;
  background: linear-gradient(166deg, #f5e5f5 0%, #e7bfe7 100%) !important;
}

.oct-alert.oct-alert-warning {
  background-color: #ffa826 !important;
  background: linear-gradient(166deg, #ffb240 0%, #ff9e0d 100%) !important;
}

.oct-alert.oct-alert-error {
  background-color: #8b1528 !important;
  background: linear-gradient(166deg, #a1182e 0%, #751222 100%) !important;
}

.oct-alert.box-noheight {
  min-height: 0 !important;
}

.oct-alert.new-element .head {
  display: inline-block;
  padding: 2px 12px !important;
  border-radius: 4px !important;
  background-color: #2197db !important;
}

#modelinfo-border {
  border: 4px solid #eed2ee !important;
  padding: 0 !important;
}

#modelinfo {
  background-color: rgba(238, 210, 238, 0.75) !important;
}
#modelinfo .text,
#modelinfo .small {
  color: #7f8691 !important;
}

.label-reusegroup, .label-copygroup, .label-special, .label-detailonly, .label-detail, .label-author, .label-editor, .label-developer {
  display: inline;
  font-size: 12px !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  font-weight: 300 !important;
  line-height: 1 !important;
  padding: 4px 6px !important;
  margin-left: 5px !important;
  border-radius: 4px !important;
  white-space: nowrap !important;
  color: #fff !important;
  text-transform: uppercase !important;
}

.label-developer {
  background-color: #b31b34 !important;
}

.label-editor {
  background-color: #ffa826 !important;
}

.label-author {
  background-color: #144382 !important;
}

.label-detail {
  background-color: #1bb39a !important;
}

.label-detailonly {
  background-color: #1bb39a !important;
}

.label-special {
  background-color: #ff264a !important;
}

.label-copygroup {
  background-color: #ff264a !important;
}

.label-reusegroup {
  background-color: #2197db !important;
}

.oct-meta-info {
  position: relative;
  transition: all 0.33s;
  --my-link-color: #fff;
  --my-link-hover-color: #fff;
  --my-link-color-rgb: 255, 255, 255;
  --my-link-hover-color-rgb: 255, 255, 255;
  border-radius: 4px !important;
  color: #fff !important;
  background-color: #ffa826 !important;
  background: linear-gradient(166deg, #ffb240 0%, #ff9e0d 100%) !important;
}
.oct-meta-info a:hover {
  text-decoration: underline;
}
.oct-meta-info.severe {
  background-color: #b31b34 !important;
  background: linear-gradient(166deg, #c91e3a 0%, #9d182e 100%) !important;
}
.oct-meta-info.padded {
  margin-top: 10px;
  padding: 10px;
}

.btn.oct-meta-info {
  background: initial !important;
  background-color: #ffa826 !important;
}
.btn.oct-meta-info:focus, .btn.oct-meta-info:hover {
  background-color: #ffbf3f !important;
  transition: all 0.33s;
}

.oct-anchor-set {
  position: relative;
  visibility: hidden;
}

.oct-anchor-none {
  display: none;
}

.oct-meta {
  min-height: 25px !important;
  display: inline-block;
  padding-right: 25px !important;
  position: relative;
  margin-bottom: 2px;
  align-self: flex-start;
}
.oct-meta.element {
  margin-bottom: var(--my-element-margin);
}
.oct-meta > .marker {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  padding: 4px 8px !important;
  display: inline-block;
  --my-link-color: #fff;
  --my-link-hover-color: #fff;
  --my-link-color-rgb: 255, 255, 255;
  --my-link-hover-color-rgb: 255, 255, 255;
  border-radius: 4px !important;
  color: #fff !important;
  background-color: #ffa826 !important;
  background: linear-gradient(166deg, #ffb240 0%, #ff9e0d 100%) !important;
}
.oct-meta > .marker a:hover {
  text-decoration: underline;
}
.oct-meta > .marker > .ico,
.oct-meta > .marker > .fa {
  margin-right: var(--my-ws-margin);
}

.online-warn {
  background-color: #eee;
  color: #444;
  overflow: hidden;
  padding: var(--my-box-padding);
  border-radius: var(--my-border-radius);
}
.online-warn .warn-head {
  margin-bottom: 0;
}
.online-warn .warn-head + .warn-text {
  margin-top: var(--my-headings-margin-bottom);
}

.presized .placeholder .online-warn {
  position: absolute;
  top: 0;
  left: var(--my-box-padding);
}

.presized .placeholder .online-warn,
.onclick-activation .online-warn,
.effect-box .online-warn {
  background-color: transparent;
  color: inherit;
}

/**********
 * Source file: _datepicker.scss
 */
/*
 * Scss manually converted from .styl (stylus) file.
 * Stylus functions/syntax => sass function/syntax:
 *    alpha => rgba
 */
/*
 * Scss manually converted from .styl (stylus) file.
 * Stylus functions/syntax => sass function/syntax:
 *    alpha => rgba
 *    if $noCalendarBorder is defined => @if global-variable-exists(noCalendarBorder) {
 *      note: no $ before the variable in sass
 */
.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 307.875px;
  box-sizing: border-box;
  touch-action: manipulation;
  background: #ffffff;
  box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
}
.flatpickr-calendar.open, .flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible;
}
.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999;
}
.flatpickr-calendar.animate.open {
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}
.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}
.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}
.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  box-shadow: none !important;
}
.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}
.flatpickr-calendar .hasWeeks .dayContainer, .flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #e6e6e6;
}
.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}
.flatpickr-calendar:before, .flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  left: 22px;
}
.flatpickr-calendar.rightMost:before, .flatpickr-calendar.rightMost:after {
  left: auto;
  right: 22px;
}
.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}
.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}
.flatpickr-calendar.arrowTop:before, .flatpickr-calendar.arrowTop:after {
  bottom: 100%;
}
.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #e6e6e6;
}
.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #ffffff;
}
.flatpickr-calendar.arrowBottom:before, .flatpickr-calendar.arrowBottom:after {
  top: 100%;
}
.flatpickr-calendar.arrowBottom:before {
  border-top-color: #e6e6e6;
}
.flatpickr-calendar.arrowBottom:after {
  border-top-color: #ffffff;
}
.flatpickr-calendar:focus {
  outline: 0;
}

.flatpickr-wrapper {
  position: relative;
  display: inline-block;
}

.flatpickr-months {
  display: flex;
}
.flatpickr-months .flatpickr-month {
  background: transparent;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
  height: 34px;
  line-height: 1;
  text-align: center;
  position: relative;
  user-select: none;
  overflow: hidden;
  flex: 1;
}
.flatpickr-months .flatpickr-prev-month, .flatpickr-months .flatpickr-next-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  line-height: 16px;
  height: 34px;
  padding: 10px;
  z-index: 3;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
}
.flatpickr-months .flatpickr-prev-month.disabled, .flatpickr-months .flatpickr-next-month.disabled {
  display: none;
}
.flatpickr-months .flatpickr-prev-month i, .flatpickr-months .flatpickr-next-month i {
  position: relative;
}
.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month, .flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  /*!
  /*rtl:begin:ignore*/ /*
*/
  left: 0;
  /*!
  /*rtl:end:ignore*/ /*
*/
}
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month, .flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  /*!
  /*rtl:begin:ignore*/ /*
*/
  right: 0;
  /*!
  /*rtl:end:ignore*/ /*
*/
}
.flatpickr-months .flatpickr-prev-month:hover, .flatpickr-months .flatpickr-next-month:hover {
  color: #990011;
}
.flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-months .flatpickr-next-month:hover svg {
  fill: #990011;
}
.flatpickr-months .flatpickr-prev-month svg, .flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px;
}
.flatpickr-months .flatpickr-prev-month svg path, .flatpickr-months .flatpickr-next-month svg path {
  transition: fill 0.1s;
  fill: inherit;
}

.numInputWrapper {
  position: relative;
  height: auto;
}
.numInputWrapper input, .numInputWrapper span {
  display: inline-block;
}
.numInputWrapper input {
  width: 100%;
}
.numInputWrapper input::-ms-clear {
  display: none;
}
.numInputWrapper input::-webkit-outer-spin-button, .numInputWrapper input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}
.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(68, 68, 68, 0.15);
  box-sizing: border-box;
}
.numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.1);
}
.numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2);
}
.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute;
}
.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0;
}
.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(68, 68, 68, 0.6);
  top: 26%;
}
.numInputWrapper span.arrowDown {
  top: 50%;
}
.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(68, 68, 68, 0.6);
  top: 40%;
}
.numInputWrapper span svg {
  width: inherit;
  height: auto;
}
.numInputWrapper span svg path {
  fill: rgba(0, 0, 0, 0.5);
}
.numInputWrapper:hover {
  background: rgba(0, 0, 0, 0.05);
}
.numInputWrapper:hover span {
  opacity: 1;
}

.flatpickr-current-month {
  font-size: 135%;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 7.48px 0 0 0;
  line-height: 1;
  height: 34px;
  display: inline-block;
  text-align: center;
  transform: translate3d(0px, 0px, 0px);
}
.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: 0.5ch;
  padding: 0;
}
.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, 0.05);
}
.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch\0 ;
  display: inline-block;
}
.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba(0, 0, 0, 0.9);
}
.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: rgba(0, 0, 0, 0.9);
}
.flatpickr-current-month input.cur-year {
  background: transparent;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0 0 0 0.5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
}
.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}
.flatpickr-current-month input.cur-year[disabled], .flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(0, 0, 0, 0.5);
  background: transparent;
  pointer-events: none;
}

.flatpickr-monthDropdown-months {
  appearance: menulist;
  background: transparent;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  margin: -1px 0 0 0;
  outline: none;
  padding: 0 0 0 0.5ch;
  position: relative;
  vertical-align: initial;
  -webkit-box-sizing: border-box;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  width: auto;
}
.flatpickr-monthDropdown-months:focus, .flatpickr-monthDropdown-months:active {
  outline: none;
}
.flatpickr-monthDropdown-months:hover {
  background: rgba(0, 0, 0, 0.05);
}

.flatpickr-weekdays {
  background: transparent;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
  height: 28px;
}
.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: flex;
  flex: 1;
}

span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: transparent;
  color: rgba(0, 0, 0, 0.54);
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  flex: 1;
  font-weight: bolder;
}

.dayContainer, .flatpickr-weeks {
  padding: 1px 0 0 0;
}

.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  width: 307.875px;
}
.flatpickr-days:focus {
  outline: 0;
}

.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  box-sizing: border-box;
  display: inline-block;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -ms-flex-pack: justify;
  justify-content: space-around;
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;
}
.dayContainer + .dayContainer {
  box-shadow: -1px 0 0 #e6e6e6;
}

.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  box-sizing: border-box;
  color: #444;
  cursor: pointer;
  font-weight: 300;
  width: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  justify-content: center;
  text-align: center;
}
.flatpickr-day.inRange, .flatpickr-day.today.inRange, .flatpickr-day:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day.nextMonthDay:hover, .flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  color: #fff;
  background: #990011;
  border-color: #990011;
}
.flatpickr-day.today {
  border-color: #990011;
}
.flatpickr-day.today:hover, .flatpickr-day.today:focus {
  border-color: #990011;
  background: #990011;
  color: #fff;
}
.flatpickr-day.selected, .flatpickr-day.selected.inRange, .flatpickr-day.selected:focus, .flatpickr-day.selected:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange, .flatpickr-day.startRange.inRange, .flatpickr-day.startRange:focus, .flatpickr-day.startRange:hover, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange, .flatpickr-day.endRange.inRange, .flatpickr-day.endRange:focus, .flatpickr-day.endRange:hover, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.endRange.nextMonthDay {
  background: #990011;
  box-shadow: none;
  color: #fff;
  border-color: #990011;
}
.flatpickr-day.selected.startRange, .flatpickr-day.startRange.startRange, .flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
}
.flatpickr-day.selected.endRange, .flatpickr-day.startRange.endRange, .flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0;
}
.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  box-shadow: -10px 0 0 #990011;
}
.flatpickr-day.selected.startRange.endRange, .flatpickr-day.startRange.startRange.endRange, .flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px;
}
.flatpickr-day.inRange {
  border-radius: 0;
  box-shadow: -5px 0 0 #990011, 5px 0 0 #990011;
}
.flatpickr-day.disabled, .flatpickr-day.disabled:hover, .flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay, .flatpickr-day.notAllowed, .flatpickr-day.notAllowed.prevMonthDay, .flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(68, 68, 68, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default;
}
.flatpickr-day.disabled, .flatpickr-day.disabled:hover {
  cursor: not-allowed;
  color: rgba(68, 68, 68, 0.1);
}
.flatpickr-day.week.selected {
  border-radius: 0;
  box-shadow: -5px 0 0 #990011, 5px 0 0 #990011;
}
.flatpickr-day.hidden {
  visibility: hidden;
}

.rangeMode .flatpickr-day {
  margin-top: 1px;
}

.flatpickr-weekwrapper {
  display: inline-block;
  float: left;
}
.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  box-shadow: 1px 0 0 #e6e6e6;
}
.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px;
}
.flatpickr-weekwrapper span.flatpickr-day, .flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(68, 68, 68, 0.3);
  background: transparent;
  cursor: default;
  border: none;
}

.flatpickr-innerContainer {
  display: flex;
  box-sizing: border-box;
  overflow: hidden;
}

.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  box-sizing: border-box;
}

.flatpickr-time {
  text-align: center;
  outline: 0;
  line-height: 40px;
  max-height: 40px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
}
.flatpickr-time:after {
  content: "";
  display: table;
  clear: both;
}
.flatpickr-time .numInputWrapper {
  flex: 1;
  width: 40%;
  height: 40px;
  float: left;
}
.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #444;
}
.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #444;
}
.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}
.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}
.flatpickr-time input {
  background: transparent;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  color: #444;
  font-size: 14px;
  position: relative;
  box-sizing: border-box;
}
.flatpickr-time input.flatpickr-hour {
  font-weight: bold;
}
.flatpickr-time input.flatpickr-minute, .flatpickr-time input.flatpickr-second {
  font-weight: 300;
}
.flatpickr-time input:focus {
  outline: 0;
  border: 0;
}
.flatpickr-time .flatpickr-time-separator, .flatpickr-time .flatpickr-am-pm {
  height: inherit;
  display: inline-block;
  float: left;
  line-height: inherit;
  color: #444;
  font-weight: bold;
  width: 2%;
  user-select: none;
  align-self: center;
}
.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 300;
}
.flatpickr-time input:hover, .flatpickr-time input:focus, .flatpickr-time .flatpickr-am-pm:hover, .flatpickr-time .flatpickr-am-pm:focus {
  background: #b30014;
}

.flatpickr-input[readonly] {
  cursor: pointer;
}

@keyframes fpFadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.flatpickr .input-group-append {
  cursor: pointer;
}

/**********
 * Source file: _detail-page.scss
 */
.detail-page {
  --my-detail-visual-spacer-x: var(--my-default-margin-small);
  --my-detail-visual-spacer-y: var(--my-default-margin-small);
  --my-detail-content-spacer-y: var(--my-element-margin);
  margin-bottom: var(--my-element-margin);
}
.detail-page .piece > .visual .overlay {
  display: flex;
  flex-direction: column;
  position: relative;
}
.detail-page .piece > .visual .overlay .image-src-box {
  order: 2;
}
.detail-page .piece > .visual .overlay .visual-overlay {
  order: 1;
}
.detail-page .detail-visual .visual-info {
  font-size: var(--my-body-font-size);
  font-weight: var(--my-body-font-weight);
  line-height: 1.25;
  display: flex;
  flex-direction: column;
}
.detail-page .detail-visual .visual-info > div + div,
.detail-page .detail-visual .visual-info .infogroup > div + div {
  margin-top: var(--my-detail-visual-spacer-y);
}
.detail-page .detail-visual .visual-info .location .locname {
  margin-bottom: calc(0.5 * var(--my-detail-visual-spacer-y));
}
.detail-page .detail-visual .visual-info .location .adressInfo {
  margin-bottom: 0;
}
.detail-page .detail-visual .visual-info .location .adressInfo p:last-child {
  margin-bottom: 0;
}
.detail-page .detail-visual .visual-info .location .locdata > *:not(:first-child) {
  margin-top: var(--my-detail-visual-spacer-y);
}
.detail-page .detail-visual .visual-info .info {
  display: flex;
}
.detail-page .detail-visual .visual-info .info::before {
  font-family: var(--my-icon-font-family);
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: top;
  display: inline-block;
  width: 2.5rem;
  padding-right: var(--my-detail-visual-spacer-x);
}
.detail-page .detail-visual .visual-info .info > div {
  display: inline-block;
}
.detail-page .detail-visual .visual-info .date::before {
  content: "\f133";
}
.detail-page .detail-visual .visual-info .person::before {
  content: "\f2c0";
}
.detail-page .detail-visual .visual-info .location::before {
  content: "\f041";
}
.detail-page .detail-visual .visual-info .images::before {
  content: "\f03e";
}
.detail-page .detail-visual .visual-info .type::before {
  content: "\f05a";
}
.detail-page .detail-visual .visual-text {
  font-weight: 600;
}
.detail-page .detail-visual .visual-text strong {
  font-style: italic;
}
.detail-page .sub-header {
  margin-bottom: var(--my-headings-margin-bottom);
}
.detail-page.layout-0 .detail-visual.piece.phv:not(.phb) > .visual {
  margin-bottom: 0;
}
.detail-page.layout-1 .detail-visual.piece.phv.phh > .visual {
  margin-bottom: var(--my-headings-margin-bottom);
}
.detail-page .detail-visual:not(.only-heading) + .detail-content,
.detail-page .detail-visual:not(.only-heading) + .detail-addition {
  margin-top: var(--my-detail-content-spacer-y);
}
.detail-page .detail-visual:not(.only-heading) + .detail-content:empty,
.detail-page .detail-visual:not(.only-heading) + .detail-addition:empty {
  margin-top: 0;
}
.detail-page .detail-visual:not(.only-heading) + .attachment-container {
  margin-top: var(--my-element-margin);
}
.detail-page .detail-content::after {
  display: block;
  clear: both;
  content: "";
}
.detail-page .detail-content > .piece:not(:last-of-type) {
  margin-bottom: var(--my-detail-content-spacer-y);
}
.detail-page .detail-content + div {
  margin-top: var(--my-element-margin);
}
.detail-page .detail-content + div.attachment-container:empty {
  display: none;
}
.detail-page .detail-content > .piece {
  width: 100%;
}
.detail-page .detail-addition:last-of-type.element, .detail-page .detail-addition:last-of-type.subelement,
.detail-page .detail-addition:last-of-type .detail-page:last-of-type,
.detail-page .detail-addition:last-of-type .element:last-of-type,
.detail-page .detail-addition:last-of-type .subelement:last-of-type {
  margin-bottom: 0;
}
@media (min-width: 764px) {
  .detail-page .overlay {
    display: block;
  }
  .detail-page .visual-overlay {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 80%;
    pointer-events: none;
    z-index: 7;
    transform: translate(-50%, -50%);
  }
  .detail-page .visual-overlay .intro-headline {
    margin: 0;
  }
  .detail-page .visual-overlay .intro-headline > span {
    margin: 0;
    text-align: center;
    font-weight: 300;
    color: #fff;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    line-height: 1.15;
  }
  .detail-page .visual-overlay .intro-headline > .intro {
    font-size: 2rem;
    margin-bottom: calc(0.5 * var(--my-detail-visual-spacer-y));
  }
  .detail-page .visual-overlay .intro-headline > .headline {
    font-size: 4.2rem;
  }
  .detail-page .visual-darken {
    position: absolute;
    pointer-events: none;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 7;
    background-image: linear-gradient(rgba(99, 99, 99, 0), rgba(99, 99, 99, 0.3) 30%, rgba(99, 99, 99, 0.3) 70%, rgba(99, 99, 99, 0));
  }
  .detail-page .detail-visual.full .visual-info {
    flex-direction: row;
    justify-content: space-between;
  }
  .detail-page .detail-visual.full .visual-info > div + div {
    margin-top: 0;
  }
  .detail-page .detail-visual.full .visual-info .person {
    order: 1;
  }
  .detail-page .detail-visual.full .visual-info .infogroup,
  .detail-page .detail-visual.full .visual-info .date {
    order: 10;
  }
  .detail-page .detail-visual.full:not(.pnv) .visual-info.right {
    justify-content: flex-end;
  }
}
@media (min-width: 764px) and (max-width: 1013.98px) {
  .detail-page .visual-overlay .intro-headline {
    transform: scale(0.8333333333);
  }
}

html.opencms-page-editor .detail-page.oc-detail-preview {
  opacity: 0.75;
  background-image: repeating-linear-gradient(135deg, transparent, transparent 10px, rgba(0, 0, 0, 0.1) 10px, rgba(0, 0, 0, 0.1) 20px);
}

.meta-info-check.mi-valid .ico {
  color: #1bb39a;
}
.meta-info-check.mi-invalid.mi-required .mi-notice,
.meta-info-check.mi-invalid.mi-required .ico {
  color: #b31b34;
}
.meta-info-check.mi-invalid.mi-optional .mi-notice,
.meta-info-check.mi-invalid.mi-optional .ico {
  color: #ffa826;
}
.meta-info-check .ico {
  min-width: calc(2 * var(--my-ws-margin));
}
.meta-info-check .mi-label {
  font-weight: var(--my-font-weight-bold);
}
.meta-info-check .mi-notice {
  margin-left: var(--my-ws-margin);
}

/**********
 * Source file: _dynamic.scss
 */
.type-wallsio:not(.external-cookie-notice).remove-margin .wallsio-container {
  margin-left: -21px;
  margin-right: -21px;
}
.type-wallsio:not(.external-cookie-notice).remove-margin .wallsio-container .wallsio-load-more-button {
  margin-left: 21px;
}
.type-wallsio:not(.external-cookie-notice) .wallsio-load-more-button {
  margin: 0;
  padding: 0.6rem 1.2rem;
  display: inline-block;
  text-align: center;
  user-select: none;
  box-shadow: none;
  border: none;
  text-decoration: none;
  font-family: var(--my-btn-font-family);
  white-space: nowrap;
  font-weight: 400;
  border-radius: var(--my-btn-border-radius);
  color: var(--my-btn-fg);
  background-color: var(--my-btn-bg);
}
.type-wallsio:not(.external-cookie-notice) .wallsio-load-more-button:focus, .type-wallsio:not(.external-cookie-notice) .wallsio-load-more-button:hover, .type-wallsio:not(.external-cookie-notice) .wallsio-load-more-button.active, .open .dropdown-toggle .type-wallsio:not(.external-cookie-notice) .wallsio-load-more-button {
  color: var(--my-btn-hover-fg);
  background-color: var(--my-btn-hover-bg);
  text-decoration: none;
  cursor: pointer;
  transition-property: color, background-color, border-color;
  transition-duration: 0.33s;
  transition-timing-function: ease;
}
.type-wallsio:not(.external-cookie-notice) .wallsio-load-more-button.disabled, .type-wallsio:not(.external-cookie-notice) .wallsio-load-more-button[disabled], fieldset[disabled] .type-wallsio:not(.external-cookie-notice) .wallsio-load-more-button {
  background-color: #ddd;
  color: #444;
  cursor: not-allowed;
}

/**********
 * Source file: _event.scss
 */
.calendar-sheet {
  --my-cs-color: var(--my-text-dark);
  --my-cs-accent-color: var(--my-main-theme-base);
  --my-cs-bg-color: var(--my-grey-lighter);
  --my-cs-border-color: var(--my-grey-light);
  --my-cs-side-margin: calc(0.5 * var(--my-gutter-x));
  --my-cs-bar-height: 15px;
  --my-cs-bar-width: 80%;
}

.calendar-sheet-piece {
  --my-cs-width: 165px;
  --my-cs-border-radius: var(--my-border-radius);
}

.calendar-sheet {
  background-color: var(--my-cs-bg-color);
  font-family: var(--my-font-family-headings);
}
.calendar-sheet > span {
  color: var(--my-cs-color);
}
.calendar-sheet > .day-number {
  font-size: 24px;
  font-weight: 600;
  color: var(--my-cs-accent-color);
  line-height: 30px;
}

@media (max-width: 763.98px) {
  .area-wide .calendar-sheet-piece .visual .image-src-box.presized {
    padding-bottom: 0 !important;
    height: auto;
  }
  .area-wide .calendar-sheet {
    border-bottom: 2px solid var(--my-cs-accent-color);
    display: inline-block;
    width: 100%;
    padding: 10px 0;
  }
  .area-wide .calendar-sheet .day-name {
    margin-right: calc(0.5 * var(--my-cs-side-margin));
    float: right;
  }
  .area-wide .calendar-sheet .month-year,
  .area-wide .calendar-sheet .day-number {
    margin-left: calc(0.5 * var(--my-cs-side-margin));
    float: left;
  }
  .area-wide .calendar-sheet .day-name,
  .area-wide .calendar-sheet .day-number,
  .area-wide .calendar-sheet .month-year {
    line-height: 30px;
  }
}
@media (min-width: 764px) {
  .area-wide .calendar-sheet-piece.fixed.flex > .visual {
    min-width: calc(var(--my-cs-width) + 0.5 * var(--my-gutter-x));
    max-width: calc(var(--my-cs-width) + 0.5 * var(--my-gutter-x));
  }
  .area-wide .calendar-sheet-piece.fixed.flex > .body {
    flex: 0 0 calc(100% - (var(--my-cs-width) + 0.5 * var(--my-gutter-x)));
    max-width: calc(100% - (var(--my-cs-width) + 0.5 * var(--my-gutter-x)));
  }
  .area-wide .calendar-sheet-piece.fixed.float > .visual {
    min-width: var(--my-cs-width);
    max-width: var(--my-cs-width);
  }
  .area-wide .calendar-sheet {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid var(--my-cs-border-color);
    border-radius: var(--my-cs-border-radius);
    text-align: center;
    overflow: hidden;
  }
  .area-wide .calendar-sheet::before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: calc(0.5 * (100% - var(--my-cs-bar-width)));
    height: var(--my-cs-bar-height);
    border-bottom: none;
    margin: 0;
    background-color: var(--my-cs-accent-color);
    width: var(--my-cs-bar-width);
  }
  .area-wide .calendar-sheet > span {
    display: block;
  }
  .area-wide .calendar-sheet .day-name,
  .area-wide .calendar-sheet .month-year {
    padding: 10px;
  }
}

.area-narrow .calendar-sheet-piece .visual .image-src-box.presized {
  padding-bottom: 0 !important;
  height: auto;
}
.area-narrow .calendar-sheet {
  border-bottom: 2px solid var(--my-cs-accent-color);
  display: inline-block;
  width: 100%;
  padding: 10px 0;
}
.area-narrow .calendar-sheet .day-name {
  margin-right: calc(0.5 * var(--my-cs-side-margin));
  float: right;
}
.area-narrow .calendar-sheet .month-year,
.area-narrow .calendar-sheet .day-number {
  margin-left: calc(0.5 * var(--my-cs-side-margin));
  float: left;
}
.area-narrow .calendar-sheet .day-name,
.area-narrow .calendar-sheet .day-number,
.area-narrow .calendar-sheet .month-year {
  line-height: 30px;
}
.area-narrow .calendar-sheet-piece.piece.flex {
  --my-piece-visual-size: 100%;
  --my-piece-body-size: 100%;
}
.area-narrow .calendar-sheet-piece.piece.flex > .visual,
.area-narrow .calendar-sheet-piece.piece.flex > .body {
  padding-left: 0;
  padding-right: 0;
}

table.submissions {
  table-layout: fixed;
  width: 100%;
}
table.submissions tr > td:last-of-type {
  font-weight: 600;
}
table.submissions tr.emphasize > td {
  font-weight: 600;
  font-style: italic;
}
table.submissions td {
  padding-bottom: var(--my-default-margin-verysmall);
  vertical-align: top;
}

.submission-actions .buttons {
  text-align: right;
}

.submission-actions .buttons button {
  margin-left: var(--my-default-margin-verysmall);
}

.submission-actions > button {
  float: right;
  margin-left: var(--my-default-margin-verysmall);
}

.book-info > span {
  display: inline-block;
}
.book-info .book-msg {
  background-color: var(--my-highlight-color);
  color: var(--my-highlight-fg-color);
}
.book-info .book-msg,
.book-info .oct-meta-info {
  border-radius: var(--my-border-radius);
  font-weight: normal;
}
.book-info .book-msg:not(.last-child),
.book-info .oct-meta-info:not(.last-child) {
  margin-right: var(--my-ws-margin);
}

.detail-page .book-info .book-msg,
.detail-page .book-info .oct-meta-info {
  padding: 0.6rem 1.2rem;
}

.teaser .book-info,
.accordion .book-info {
  font-size: 80%;
  line-height: 1.2;
  margin-top: 8px;
}
.teaser .book-info .book-msg,
.teaser .book-info .oct-meta-info,
.accordion .book-info .book-msg,
.accordion .book-info .oct-meta-info {
  display: inline-block;
  padding: 1px 8px;
  margin-bottom: 8px;
}

.formdata-manage .acco-header .acco-toggle {
  padding-right: calc(6rem + var(--my-at-spacing-x)) !important;
}
.formdata-manage .acco-item-select {
  text-align: right;
}
.formdata-manage .acco-item-select > a {
  margin-left: var(--my-default-margin-verysmall);
  margin-bottom: var(--my-default-margin-verysmall);
  vertical-align: bottom;
}
.formdata-manage .acco-item-select-all, .formdata-manage .acco-item-select-none {
  display: none;
}
.formdata-manage .acco-item-check-wrapper {
  line-height: 1;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  height: 100%;
  right: calc(3rem + var(--my-at-spacing-x));
  z-index: 25;
}
.formdata-manage .acco-item-check {
  scale: 1.4;
  display: none;
}

@media (min-width: 764px) {
  .formdata-manage .acco-item-select {
    margin-top: var(--my-default-margin-small);
  }
}
.tile-col .book-info .book-msg::after {
  content: "\a";
  white-space: pre;
}

.cost-table .ct-category {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--my-paragraph-margin-bottom);
}
.cost-table .ct-price {
  font-weight: var(--my-font-weight-bold);
}

@media (min-width: 1014px) {
  .event-costs {
    display: flow-root;
    width: 100%;
  }
  .cost-table {
    display: table;
  }
  .cost-table .ct-category {
    display: table-row;
    margin-bottom: calc(0.5 * var(--my-paragraph-margin-bottom));
  }
  .cost-table .ct-price {
    display: table-cell;
    font-weight: var(--my-font-weight-bold);
  }
  .cost-table .ct-class {
    display: table-cell;
    width: 50%;
    padding-left: var(--my-ws-margin);
  }
  .cost-table .ct-link {
    display: table-cell;
    padding-left: var(--my-ws-margin);
  }
}
/**********
 * Source file: _footer.scss
 */
@media (max-width: 763.98px) {
  .area-foot {
    line-height: 1.8;
  }
}
.area-foot .topfoot {
  --my-body-color: #444;
  --my-body-bg: #eee;
  --my-headings-color: #444;
  --my-link-color: #575757;
  --my-link-hover-color: #575757;
  --my-link-color-rgb: 87, 87, 87;
  --my-link-hover-color-rgb: 87, 87, 87;
}
.area-foot .subfoot {
  --my-body-color: #fff;
  --my-body-bg: #990011;
  --my-headings-color: #fff;
  --my-link-color: #e6e6e6;
  --my-link-hover-color: #e6e6e6;
  --my-link-color-rgb: 230, 230, 230;
  --my-link-hover-color-rgb: 230, 230, 230;
}
.area-foot .topfoot,
.area-foot .subfoot {
  display: flex;
  flex-direction: column;
  padding-top: var(--my-element-margin);
  color: var(--my-body-color);
  background-color: var(--my-body-bg);
}

#topcontrol {
  color: var(--my-nav-toggle-fg);
  background: var(--my-nav-toggle-bg);
  z-index: 150150;
  width: 40px;
  height: 40px;
  font-size: 25px;
  line-height: 36px;
  display: block;
  position: fixed;
  visibility: visible;
  bottom: 15px;
  border-radius: 0 !important;
  cursor: pointer;
  pointer-events: none;
  opacity: 0;
  right: 30px;
}
#topcontrol.show {
  transition: opacity 0.33s;
  pointer-events: auto;
  opacity: 1;
}
#topcontrol::after {
  font-family: var(--my-icon-font-family);
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 40px;
  line-height: 38px;
  content: "\f106";
  position: absolute;
  text-align: center;
}
@media (max-width: 763.98px) {
  #topcontrol {
    width: 30px;
    height: 30px;
  }
  #topcontrol::after {
    width: 30px;
    line-height: 28px;
  }
}

/**********
 * Source file: _form.scss
 */
.styled-form {
  --my-form-text-color: var(--my-body-color);
  --my-form-background-color: var(--my-body-bg);
  --my-form-input-color: var(--my-text-dark);
  --my-form-input-background-color: var(--my-white);
  --my-form-placeholder-color: var(--my-form-border-color);
  --my-form-border-color: var(--my-grey);
  --my-form-icon-color: var(--my-form-input-color);
  --my-form-label-color: var(--my-form-text-color);
  --my-form-captcha-background-color: var(--my-white);
  --my-form-radio-checked-color: var(--my-main-theme);
  --my-form-radio-checked-border-color: var(--my-form-border-color);
  --my-form-radio-unchecked-color: var(--my-grey);
  --my-form-radio-unchecked-border-color: var(--my-form-border-color);
  --my-form-error-background-color: #ffeeee;
  --my-form-error-text-color: #bb2222;
  --my-form-border-radius: var(--my-border-radius);
  --my-form-input-border-radius: var(--my-btn-border-radius);
  --my-form-font-family: var(--my-body-font-family);
  --my-form-spacing: 2rem;
  --my-form-padding-y: 2rem;
  --my-form-padding-x: calc(2rem * 1.5);
  --my-form-section-margin-bottom: 2rem;
  margin: 0;
  outline: none;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.5;
  font-family: var(--my-form-font-family);
  border: 1px solid var(--my-form-border-color);
  border-radius: var(--my-form-border-radius);
  color: var(--my-form-text-color);
  background: var(--my-form-background-color);
  hyphens: auto;
  overflow-wrap: anywhere;
  word-break: break-word;
}
.styled-form.element, .styled-form.subelement {
  margin-bottom: var(--my-element-margin);
}
.styled-form header {
  border-bottom: 1px solid var(--my-form-border-color);
  padding: var(--my-form-padding-y) var(--my-form-padding-x);
}
.styled-form fieldset {
  display: block;
  padding: var(--my-form-padding-y) var(--my-form-padding-x);
  border: none;
}
.styled-form fieldset p:last-child {
  margin-bottom: 0;
}
.styled-form .webform_subfields fieldset {
  padding: 0;
}
.styled-form fieldset + fieldset {
  border-top: 1px solid var(--my-form-border-color);
}
.styled-form .webform_subfields,
.styled-form .section,
.styled-form section {
  margin-bottom: var(--my-form-section-margin-bottom);
}
.styled-form .webform_subfields:last-child,
.styled-form .section:last-child,
.styled-form section:last-child {
  margin-bottom: 0 !important;
}
.styled-form footer {
  display: block;
  padding: var(--my-form-padding-y) var(--my-form-padding-x);
  border-top: 1px solid var(--my-form-border-color);
}
.styled-form footer:after {
  content: "";
  display: table;
  clear: both;
}
.styled-form label,
.styled-form .label {
  display: block;
  font-weight: 600;
  margin-bottom: calc(0.25 * var(--my-form-spacing));
}
.styled-form .note {
  margin-top: calc(0.25 * var(--my-form-spacing));
  padding: 0;
  font-size: 100%;
  line-height: 1.5;
}
.styled-form .input,
.styled-form .select,
.styled-form .textarea,
.styled-form .radio,
.styled-form .checkbox,
.styled-form .toggle,
.styled-form .button {
  position: relative;
  display: block;
}
.styled-form input.datepicker.flatpickr-mobile,
.styled-form input.datepicker.input {
  position: static;
}
.styled-form input.datepicker:not([value*="."]):not([value*="/"]):not([value*="-"]) + input.datepicker.flatpickr-mobile::before {
  color: var(--my-form-placeholder-color);
  content: attr(placeholder);
}
.styled-form .input input,
.styled-form .select select,
.styled-form .textarea textarea {
  font-weight: 400;
  font-size: 1.5rem;
  color: var(--my-form-label-color);
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 7px 10px;
  outline: none;
  border-width: 1px;
  border-style: solid;
  border-radius: var(--my-form-input-border-radius);
  color: var(--my-form-input-color);
  background-color: var(--my-form-input-background-color);
  appearance: none;
}
.styled-form .input input::placeholder,
.styled-form .select select::placeholder,
.styled-form .textarea textarea::placeholder {
  font-weight: 400;
  font-size: 1.5rem;
  color: var(--my-form-placeholder-color);
}
.styled-form .input.button {
  display: flex;
  flex: 1 1 auto;
}
.styled-form .input.button input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.styled-form .input.button button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.styled-form .input.button input {
  border-right-style: none;
}
.styled-form .input.button button.btn {
  padding-top: 0;
  padding-bottom: 0;
  line-height: 1;
  border: 1px solid var(--my-form-border-color);
  border-left-style: none;
  white-space: nowrap;
}
.styled-form .input-file .button {
  margin: 0;
  padding: 0.6rem 1.2rem;
  display: inline-block;
  text-align: center;
  user-select: none;
  box-shadow: none;
  border: none;
  text-decoration: none;
  font-family: var(--my-btn-font-family);
  white-space: nowrap;
  font-weight: 400;
  border-radius: var(--my-btn-border-radius);
  color: var(--my-btn-fg);
  background-color: var(--my-btn-bg);
}
.styled-form .input-file .button:focus, .styled-form .input-file .button:hover, .styled-form .input-file .button.active, .open .dropdown-toggle .styled-form .input-file .button {
  color: var(--my-btn-hover-fg);
  background-color: var(--my-btn-hover-bg);
  text-decoration: none;
  cursor: pointer;
  transition-property: color, background-color, border-color;
  transition-duration: 0.33s;
  transition-timing-function: ease;
}
.styled-form .input-file .button.disabled, .styled-form .input-file .button[disabled], fieldset[disabled] .styled-form .input-file .button {
  background-color: #ddd;
  color: #444;
  cursor: not-allowed;
}
.styled-form .input-file .button {
  position: absolute;
  top: 4px;
  right: 4px;
  float: none;
  height: calc(100% - 8px);
  padding: 0 var(--my-form-spacing);
  font-size: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.styled-form .input-file .button input {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  cursor: pointer;
  opacity: 0;
  width: 100%;
  height: 100%;
}
.styled-form .select i {
  position: absolute;
  top: 0;
  right: 2px;
  width: 28px;
  height: 100%;
  pointer-events: none;
  color: var(--my-form-input-color);
}
.styled-form .select i::before, .styled-form .select i::after {
  content: "";
  position: absolute;
  right: 10px;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}
.styled-form .select i::before {
  top: calc(50% - 5px - 3px);
  border-bottom: 5px solid var(--my-form-icon-color);
}
.styled-form .select i::after {
  bottom: calc(50% - 5px - 3px);
  border-top: 5px solid var(--my-form-icon-color);
}
.styled-form .select select {
  cursor: pointer;
  padding-right: 28px;
}
.styled-form .select select[disabled] {
  color: var(--my-form-placeholder-color);
}
.styled-form .select-multiple select {
  height: auto;
}
.styled-form .textarea textarea {
  height: auto;
  resize: none;
}
.styled-form .textarea-resizable textarea {
  resize: vertical;
}
.styled-form .textarea-expandable textarea:focus {
  height: auto;
}
.styled-form .radio,
.styled-form .checkbox {
  font-weight: 400;
  padding-left: 35px;
  color: var(--my-form-label-color);
  cursor: pointer;
}
.styled-form .radio:last-child,
.styled-form .checkbox:last-child {
  margin-bottom: 0;
}
.styled-form .radio input,
.styled-form .checkbox input {
  position: absolute;
  left: -9999px;
}
.styled-form .radio i,
.styled-form .checkbox i {
  position: absolute;
  top: 3px;
  left: 0;
  display: block;
  width: 20px;
  height: 20px;
  outline: none;
  border-width: 1px;
  border-style: solid;
  background-color: var(--my-form-input-background-color);
}
.styled-form .radio input + i::after,
.styled-form .checkbox input + i::after {
  position: absolute;
  opacity: 0;
  transition: opacity 0.1s;
}
.styled-form .radio input:checked + i::after,
.styled-form .checkbox input:checked + i::after {
  opacity: 1;
  color: var(--my-form-radio-checked-color);
}
.styled-form .radio i {
  border-radius: 50%;
}
.styled-form .radio input + i::after {
  content: "";
  top: 4px;
  left: 4px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.styled-form .checkbox input + i::after {
  font-family: var(--my-icon-font-family);
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f00c";
  top: -5px;
  left: -1px;
  font-size: 23px;
  line-height: 23px;
  font-weight: normal;
  text-align: center;
}
.styled-form .toggle {
  font-weight: 400;
  padding-right: 70px;
  color: var(--my-form-label-color);
  cursor: pointer;
}
.styled-form .toggle:last-child {
  margin-bottom: 0;
}
.styled-form .toggle input {
  position: absolute;
  left: -9999px;
}
.styled-form .toggle input:checked + i::before {
  right: 36px;
  background-color: var(--my-form-radio-checked-color);
}
.styled-form .toggle input:checked + i::after {
  content: "ON";
  text-align: right;
  color: var(--my-form-radio-checked-color);
}
.styled-form .toggle i {
  content: "";
  position: absolute;
  top: 4px;
  right: 0;
  display: block;
  width: 55px;
  height: 20px;
  border-width: 1px;
  border-style: solid;
  border-radius: 12px;
  background-color: var(--my-form-input-background-color);
}
.styled-form .toggle i::before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 4px;
  right: 4px;
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  opacity: 1;
  transition: right 0.2s;
  background-color: var(--my-form-radio-unchecked-color);
}
.styled-form .toggle i::after {
  content: "OFF";
  position: absolute;
  top: 3px;
  right: 8px;
  left: 8px;
  font-style: normal;
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  text-align: left;
  color: var(--my-form-radio-unchecked-color);
}
.styled-form .icon-append,
.styled-form .icon-prepend {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 34px;
  height: calc(100% - 6px);
  top: 3px;
}
.styled-form .icon-append.ico-svg > svg,
.styled-form .icon-prepend.ico-svg > svg {
  vertical-align: middle;
}
.styled-form .icon-append {
  right: 0;
  border-left-width: 1px;
  border-left-style: solid;
}
.styled-form .icon-prepend {
  left: 0;
  border-right-width: 1px;
  border-right-style: solid;
}
.styled-form .input .icon-append ~ input,
.styled-form .input .icon-append + textarea,
.styled-form .textarea .icon-append ~ input,
.styled-form .textarea .icon-append + textarea {
  padding-right: 34px;
}
.styled-form .input .icon-prepend ~ input,
.styled-form .input .icon-prepend + textarea,
.styled-form .textarea .icon-prepend ~ input,
.styled-form .textarea .icon-prepend + textarea {
  padding-left: 42px;
}
.styled-form .textarea .icon-append,
.styled-form .textarea .icon-prepend {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  align-self: unset;
  top: 3px;
  width: 31px;
  height: 31px;
}
.styled-form .textarea .icon-append {
  padding-right: 0;
  right: 3px;
  border-bottom-left-radius: var(--my-form-input-border-radius);
}
.styled-form .textarea .icon-prepend {
  padding-left: 0;
  left: 3px;
  border-bottom-right-radius: var(--my-form-input-border-radius);
}
.styled-form .input input,
.styled-form .select select,
.styled-form .textarea textarea,
.styled-form .radio i,
.styled-form .checkbox i,
.styled-form .icon-append,
.styled-form .icon-prepend {
  border-color: var(--my-form-border-color);
}
.styled-form .icon-append,
.styled-form .icon-prepend {
  color: var(--my-form-icon-color);
}
.styled-form .radio input + i::after {
  background-color: var(--my-form-radio-checked-color);
}
.styled-form .checkbox input + i::after {
  color: var(--my-form-radio-checked-color);
}
.styled-form .radio i,
.styled-form .checkbox i,
.styled-form .toggle i {
  border-color: var(--my-form-radio-unchecked-border-color);
}
.styled-form .radio input:checked + i,
.styled-form .checkbox input:checked + i,
.styled-form .toggle input:checked + i {
  border-color: var(--my-form-radio-checked-border-color);
}
.styled-form .checkbox i {
  border-radius: var(--my-form-input-border-radius);
}
.styled-form.state-error .state-error input,
.styled-form.state-error .state-error select,
.styled-form.state-error .state-error textarea,
.styled-form.state-error .radio.state-error i,
.styled-form.state-error .checkbox.state-error i,
.styled-form.state-error .toggle.state-error i,
.styled-form.state-error .toggle.state-error input:checked + i {
  background-color: var(--my-form-error-background-color);
}
.styled-form.state-error .note-error {
  color: var(--my-form-error-text-color);
}
.styled-form.state-error .note-error::before {
  font-family: var(--my-icon-font-family);
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f071";
  margin-right: 10px;
}
.styled-form .state-disabled.input input, .styled-form .state-disabled.select, .styled-form .state-disabled.textarea, .styled-form .state-disabled.radio, .styled-form .state-disabled.checkbox, .styled-form .state-disabled.toggle, .styled-form .state-disabled.button {
  cursor: default;
  opacity: 0.5;
}
.styled-form .state-disabled-alt.input input, .styled-form .state-disabled-alt.select, .styled-form .state-disabled-alt.textarea, .styled-form .state-disabled-alt.radio, .styled-form .state-disabled-alt.checkbox, .styled-form .state-disabled-alt.toggle, .styled-form .state-disabled-alt.button {
  cursor: default;
}
.styled-form .state-disabled-alt.radio input:checked + i::after {
  background-color: var(--my-form-radio-unchecked-color);
}
.styled-form .state-disabled-alt.checkbox input:checked + i::after {
  color: var(--my-form-radio-unchecked-color);
}
.styled-form .input .captcha {
  background-color: var(--my-form-captcha-background-color);
  margin-bottom: calc(0.5 * var(--my-form-spacing));
  border: 1px dashed var(--my-form-border-color);
  border-radius: var(--my-form-input-border-radius);
  overflow: hidden;
}
.styled-form .input .captcha img {
  max-width: 100% !important;
}
@media (max-width: 551.98px) {
  .styled-form header,
  .styled-form footer,
  .styled-form fieldset {
    padding: calc(0.5 * var(--my-form-padding-y)) calc(0.5 * var(--my-form-padding-x));
  }
  .styled-form .section,
  .styled-form section {
    margin-bottom: calc(0.5 * var(--my-form-section-margin-bottom));
  }
  .styled-form .input input::placeholder,
  .styled-form .select select::placeholder,
  .styled-form .textarea textarea::placeholder {
    font-size: 1.2rem;
  }
}
@media (min-width: 1014px) {
  .styled-form .toggle i,
  .styled-form .radio i {
    top: 0;
  }
  .styled-form .checkbox i {
    top: 1px;
  }
  .styled-form .inline-group {
    margin-right: -30px;
    margin-bottom: calc(-1 * 0.25 * var(--my-form-spacing));
  }
  .styled-form .inline-group::after {
    content: "";
    display: table;
    clear: both;
  }
  .styled-form .inline-group .checkbox {
    float: left;
    margin-right: 30px;
    margin-bottom: calc(0.25 * var(--my-form-spacing));
  }
  .styled-form .webform_subfields fieldset {
    padding-left: var(--my-form-padding-x);
    padding-right: var(--my-form-padding-x);
  }
}
.styled-form.compact {
  --my-form-spacing: 1rem;
  --my-form-padding-y: 1rem;
  --my-form-padding-x: 1rem;
  --my-form-section-margin-bottom: 1rem;
}
.styled-form.compact .input input,
.styled-form.compact .select select,
.styled-form.compact .textarea textarea {
  padding: 4px 10px;
}
.styled-form.compact .input .icon-append ~ input,
.styled-form.compact .input .icon-append + textarea,
.styled-form.compact .textarea .icon-append ~ input,
.styled-form.compact .textarea .icon-append + textarea {
  padding-right: 34px;
}
.styled-form.compact .input .icon-prepend ~ input,
.styled-form.compact .input .icon-prepend + textarea,
.styled-form.compact .textarea .icon-prepend ~ input,
.styled-form.compact .textarea .icon-prepend + textarea {
  padding-left: 42px;
}
.styled-form.compact .select i::before {
  top: 9px;
}
.styled-form.compact .select i::after {
  bottom: 9px;
}
.styled-form.compact .select select {
  padding-right: 28px;
}
.styled-form.compact .icon-append,
.styled-form.compact .icon-prepend {
  padding-top: 2px;
  padding-bottom: 2px;
}
.styled-form.no-border {
  border: none;
}
.styled-form.no-border form,
.styled-form.no-border header,
.styled-form.no-border footer,
.styled-form.no-border fieldset {
  border: none;
  padding: 0;
  margin-bottom: calc(0.5 * var(--my-form-spacing));
}
.styled-form.no-bg {
  background-color: inherit;
}

html.keyboard-nav .styled-form .radio input:focus + i,
html.keyboard-nav .styled-form .checkbox input:focus + i {
  outline-color: #dd0011;
  outline-style: solid;
  outline-width: 3px;
  outline-offset: 2px;
  box-shadow: none;
  z-index: 650000;
}

.type-webform {
  --my-form-spacing: 2rem;
}
.type-webform .form-header {
  margin-bottom: var(--my-form-spacing);
}
.type-webform .form-footer {
  margin-top: var(--my-form-spacing);
}
.type-webform .form-footer > p:last-of-type {
  margin-bottom: 0;
}
@media (max-width: 551.98px) {
  .type-webform .table.table-responsive {
    font-size: 12px;
  }
}
.type-webform .btn.resetbutton {
  --my-btn-fg: #444;
  --my-btn-bg: #ddd;
  --my-btn-hover-fg: #fff;
  --my-btn-hover-bg: #dd0011;
}
.type-webform .formbtn-row {
  display: block;
}
.type-webform .formbtn-row .formbtn {
  margin-bottom: var(--my-form-spacing);
}
.type-webform .formbtn-row .formbtn:last-of-type {
  margin-bottom: 0;
}
.type-webform .formbtn-row .formbtn.btn,
.type-webform .formbtn-row .formbtn .btn {
  width: 100%;
  display: block;
}
.type-webform table.webform-input {
  background-color: var(--my-body-bg);
}
.type-webform table.webform-input td.label {
  white-space: nowrap;
}
.type-webform table.webform-input td.value {
  width: 100%;
}
@media (min-width: 764px) {
  .type-webform .formbtn-row {
    display: flex;
    justify-content: center;
  }
  .type-webform .formbtn-row .formbtn {
    margin-bottom: 0;
    margin-left: calc(0.5 * var(--my-form-spacing));
    margin-right: calc(0.5 * var(--my-form-spacing));
    width: auto;
  }
  .type-webform .formbtn-row .formbtn.btn,
  .type-webform .formbtn-row .formbtn .btn {
    width: auto;
    min-width: 200px;
  }
}
.type-webform .checkpage .correctaction {
  margin-bottom: var(--my-form-spacing);
}
@media (min-width: 764px) {
  .type-webform .checkpage .correctaction {
    margin-bottom: 0;
  }
}

/**********
 * Source file: _grid.scss
 */
.row-square::after,
.row-12::after {
  display: block;
  clear: both;
  content: "";
}

.row-12,
.flex-col {
  display: flex;
  flex-direction: column;
}

.row-12.box > div.element:last-child {
  margin-bottom: 0;
}

.row-has-background {
  display: flex;
  flex-direction: column;
  padding-top: var(--my-element-margin);
}

.row-has-background + .area-content:not(.row-has-background) {
  margin-top: var(--my-element-margin);
}

.colored-row {
  --my-main-theme: var(--my-link-color);
  --my-main-theme-rgb: var(--my-link-color-rgb);
  --my-main-theme-fg: var(--my-link-fg-color);
  --my-main-theme-hover: var(--my-link-hover-color);
  --my-main-theme-hover-fg: var(--my-link-hover-fg-color);
  --my-headings-color: var(--my-body-color);
  --my-intro-headings-color: var(--my-main-theme);
  --my-highlight-color: rgb(var(--my-highlight-color-rgb), 0.25);
  --my-highlight-fg-color: var(--my-body-color);
  --my-highlight-border-color: var(--my-body-color);
  --my-highlight-border-fg-color: var(--my-body-bg);
  --my-btn-bg: var(--my-link-color);
  --my-btn-fg: var(--my-link-fg-color);
  --my-btn-hover-bg: var(--my-link-hover-color);
  --my-btn-hover-fg: var(--my-link-hover-fg-color);
  --my-btn-border: var(--my-btn-bg);
  --my-btn-hover-border: var(--my-btn-hover-bg);
  color: var(--my-body-color);
  background-color: var(--my-body-bg);
}
.colored-row.bg-highlight, .colored-row.bg-switch:nth-of-type(even) {
  --my-body-bg: #eee;
  --my-body-bg-rgb: 238, 238, 238;
  --my-body-color: #444;
  --my-body-color-rgb: 68, 68, 68;
  --my-link-color: #990011;
  --my-link-color-rgb: 153, 0, 17;
  --my-link-hover-color: #dd0011;
  --my-link-hover-color-rgb: 221, 0, 17;
  --my-link-fg-color: #fff;
  --my-link-hover-fg-color: #fff;
}
.colored-row.bg-theme {
  --my-body-bg: #990011;
  --my-body-bg-rgb: 153, 0, 17;
  --my-body-color: #fff;
  --my-body-color-rgb: 255, 255, 255;
  --my-link-color: #cccccc;
  --my-link-color-rgb: 204, 204, 204;
  --my-link-hover-color: #fff;
  --my-link-hover-color-rgb: 255, 255, 255;
  --my-link-fg-color: #444;
  --my-link-hover-fg-color: #444;
}
.colored-row.bg-grey-light {
  --my-body-bg: #ddd;
  --my-body-bg-rgb: 221, 221, 221;
  --my-body-color: #444;
  --my-body-color-rgb: 68, 68, 68;
  --my-link-color: #990011;
  --my-link-color-rgb: 153, 0, 17;
  --my-link-hover-color: #dd0011;
  --my-link-hover-color-rgb: 221, 0, 17;
  --my-link-fg-color: #fff;
  --my-link-hover-fg-color: #fff;
}
.colored-row.bg-grey {
  --my-body-bg: #aaa;
  --my-body-bg-rgb: 170, 170, 170;
  --my-body-color: #fff;
  --my-body-color-rgb: 255, 255, 255;
  --my-link-color: #990011;
  --my-link-color-rgb: 153, 0, 17;
  --my-link-hover-color: #fff;
  --my-link-hover-color-rgb: 255, 255, 255;
  --my-link-fg-color: #fff;
  --my-link-hover-fg-color: #444;
}

.effect-parallax-bg {
  padding-top: 0;
  background-image: none;
  background-position: 50% 0;
  background-size: cover;
  background-clip: border-box;
  background-origin: border-box;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
@supports (-webkit-touch-callout: none) {
  .effect-parallax-bg {
    background-position: 50% 50% !important;
    background-attachment: scroll;
  }
}

.padded-small {
  padding-top: 50px;
  padding-bottom: calc(50px - var(--my-element-margin));
}
.padded-small .row-12.box {
  margin-bottom: var(--my-element-margin);
}

.padded-medium {
  padding-top: 100px;
  padding-bottom: calc(100px - var(--my-element-margin));
}
.padded-medium .row-12.box {
  margin-bottom: var(--my-element-margin);
}

.padded-large {
  padding-top: 200px;
  padding-bottom: calc(200px - var(--my-element-margin));
}
.padded-large .row-12.box {
  margin-bottom: var(--my-element-margin);
}

.tile-margin-0.row {
  margin-bottom: var(--my-element-margin);
  --my-gutter-x: 0px;
  --my-gutter-y: 0px;
}
.tile-margin-0.row > .tile-col {
  margin-bottom: 0;
}
.tile-margin-0.row ~ .list-editbox {
  margin-top: 0px;
}

.tile-margin-2.row {
  margin-bottom: var(--my-element-margin);
  --my-gutter-x: 4px;
  --my-gutter-y: 4px;
}
@media (max-width: 763.98px) {
  .tile-margin-2.row {
    --my-gutter-x: calc(0.5 * var(--my-element-margin));
    --my-gutter-y: calc(0.5 * var(--my-element-margin));
  }
}
.tile-margin-2.row > .tile-col {
  margin-bottom: 0;
}
.tile-margin-2.row ~ .list-editbox {
  margin-top: 4px;
}

.tile-margin-5.row {
  margin-bottom: var(--my-element-margin);
  --my-gutter-x: 10px;
  --my-gutter-y: 10px;
}
@media (max-width: 763.98px) {
  .tile-margin-5.row {
    --my-gutter-x: calc(0.5 * var(--my-element-margin));
    --my-gutter-y: calc(0.5 * var(--my-element-margin));
  }
}
.tile-margin-5.row > .tile-col {
  margin-bottom: 0;
}
.tile-margin-5.row ~ .list-editbox {
  margin-top: 10px;
}

.tile-margin-10.row {
  margin-bottom: var(--my-element-margin);
  --my-gutter-x: 20px;
  --my-gutter-y: 20px;
}
.tile-margin-10.row > .tile-col {
  margin-bottom: 0;
}
.tile-margin-10.row ~ .list-editbox {
  margin-top: 20px;
}

.tile-margin-15.row {
  margin-bottom: var(--my-element-margin);
  --my-gutter-x: 30px;
  --my-gutter-y: 30px;
}
.tile-margin-15.row > .tile-col {
  margin-bottom: 0;
}
.tile-margin-15.row ~ .list-editbox {
  margin-top: 30px;
}

/**********
 * Source file: _header.scss
 */
header {
  --my-nav-mobile-indent: 10px;
  --my-nav-mobile-indent-offset: 30px;
  --my-nav-mobile-toggle-side: 30px;
  --my-nav-mobile-toggle-side-xs: 15px;
}

.area-header.fix-xs {
  position: relative;
}
.area-header.fix-xs .head {
  position: relative;
  min-height: 28px;
}
.area-header.fix-xs .sticky > .isfixed .hidden-fixed,
.area-header.fix-xs .sticky > .notfixed .hidden-notfixed {
  display: none;
}
.area-header.fix-xs .sticky.csssetting > .isfixed,
.area-header.fix-xs .sticky.always > .isfixed,
.area-header.fix-xs .sticky.upscroll > .isfixed {
  position: fixed;
  top: 0;
  padding-top: 0;
  width: 100%;
  z-index: 150000;
  animation-duration: 1s;
  animation-name: fixHeader;
  opacity: 0.97;
  background-color: #fff;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.3);
}

html.opencms-page-editor .area-header.fix-xs .sticky.csssetting > .isfixed,
html.opencms-page-editor .area-header.fix-xs .sticky.always > .isfixed,
html.opencms-page-editor .area-header.fix-xs .sticky.upscroll > .isfixed {
  padding-top: 52px;
}

html.noscript .area-header.fix-xs .nav-main-items li.expand:hover > .nav-menu {
  display: block;
}

@media (min-width: 552px) {
  .area-header.fix-sm {
    position: relative;
  }
  .area-header.fix-sm .head {
    position: relative;
    min-height: 28px;
  }
  .area-header.fix-sm .sticky > .isfixed .hidden-fixed,
  .area-header.fix-sm .sticky > .notfixed .hidden-notfixed {
    display: none;
  }
  .area-header.fix-sm .sticky.csssetting > .isfixed,
  .area-header.fix-sm .sticky.always > .isfixed,
  .area-header.fix-sm .sticky.upscroll > .isfixed {
    position: fixed;
    top: 0;
    padding-top: 0;
    width: 100%;
    z-index: 150000;
    animation-duration: 1s;
    animation-name: fixHeader;
    opacity: 0.97;
    background-color: #fff;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.3);
  }
  html.opencms-page-editor .area-header.fix-sm .sticky.csssetting > .isfixed,
  html.opencms-page-editor .area-header.fix-sm .sticky.always > .isfixed,
  html.opencms-page-editor .area-header.fix-sm .sticky.upscroll > .isfixed {
    padding-top: 52px;
  }
  html.noscript .area-header.fix-sm .nav-main-items li.expand:hover > .nav-menu {
    display: block;
  }
}
@media (min-width: 764px) {
  .area-header.fix-md {
    position: relative;
  }
  .area-header.fix-md .head {
    position: relative;
    min-height: 28px;
  }
  .area-header.fix-md .sticky > .isfixed .hidden-fixed,
  .area-header.fix-md .sticky > .notfixed .hidden-notfixed {
    display: none;
  }
  .area-header.fix-md .sticky.csssetting > .isfixed,
  .area-header.fix-md .sticky.always > .isfixed,
  .area-header.fix-md .sticky.upscroll > .isfixed {
    position: fixed;
    top: 0;
    padding-top: 0;
    width: 100%;
    z-index: 150000;
    animation-duration: 1s;
    animation-name: fixHeader;
    opacity: 0.97;
    background-color: #fff;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.3);
  }
  html.opencms-page-editor .area-header.fix-md .sticky.csssetting > .isfixed,
  html.opencms-page-editor .area-header.fix-md .sticky.always > .isfixed,
  html.opencms-page-editor .area-header.fix-md .sticky.upscroll > .isfixed {
    padding-top: 52px;
  }
  html.noscript .area-header.fix-md .nav-main-items li.expand:hover > .nav-menu {
    display: block;
  }
}
@media (min-width: 1014px) {
  .area-header.fix-lg {
    position: relative;
  }
  .area-header.fix-lg .head {
    position: relative;
    min-height: 28px;
  }
  .area-header.fix-lg .sticky > .isfixed .hidden-fixed,
  .area-header.fix-lg .sticky > .notfixed .hidden-notfixed {
    display: none;
  }
  .area-header.fix-lg .sticky.csssetting > .isfixed,
  .area-header.fix-lg .sticky.always > .isfixed,
  .area-header.fix-lg .sticky.upscroll > .isfixed {
    position: fixed;
    top: 0;
    padding-top: 0;
    width: 100%;
    z-index: 150000;
    animation-duration: 1s;
    animation-name: fixHeader;
    opacity: 0.97;
    background-color: #fff;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.3);
  }
  html.opencms-page-editor .area-header.fix-lg .sticky.csssetting > .isfixed,
  html.opencms-page-editor .area-header.fix-lg .sticky.always > .isfixed,
  html.opencms-page-editor .area-header.fix-lg .sticky.upscroll > .isfixed {
    padding-top: 52px;
  }
  html.noscript .area-header.fix-lg .nav-main-items li.expand:hover > .nav-menu {
    display: block;
  }
}
header.bh.lp-r .nav-main-container {
  left: -90vw;
  transition: left 0.5s, opacity 0.25s;
}
header.bh.lp-r .nav-main-container > .nav-main-group {
  display: none;
}

html.active-nav header.bh.lp-r .nav-main-container {
  opacity: 1;
  left: 0;
}
html.active-nav header.bh.lp-r .nav-main-container > .nav-main-group {
  display: block;
}

header.bh.lp-l .nav-main-container {
  right: -90vw;
  transition: right 0.5s, opacity 0.25s;
}
header.bh.lp-l .nav-main-container > .nav-main-group {
  display: none;
}

html.active-nav header.bh.lp-l .nav-main-container {
  opacity: 1;
  right: 0;
}
html.active-nav header.bh.lp-l .nav-main-container > .nav-main-group {
  display: block;
}

header.bh .head {
  display: flex;
  flex-direction: column;
}
header.bh .h-meta {
  display: none;
}
header.bh .h-logo-row {
  display: flex;
  order: 6;
  padding-top: 15px;
  padding-bottom: 15px;
  justify-content: space-between;
}
header.bh .h-logo-col {
  display: flex;
  align-items: center;
}
header.bh .h-logo-col .h-logo {
  width: 100%;
  display: block;
}
header.bh .h-logo-col .h-logo > a.imglink {
  width: 100%;
  display: block;
}
header.bh .h-toggle-col {
  display: flex;
  align-items: center;
  max-width: 40px;
  min-width: 40px;
}
header.bh .h-title-row {
  display: flex;
  order: 10;
  padding-bottom: 7.5px;
}
header.bh.ls-col .h-logo-col .h-logo > a.imglink {
  width: 100%;
  display: block;
}
header.bh.ls-col .h-title-col {
  display: none;
  align-items: center;
  flex-grow: 10000;
  order: 2;
  padding-right: 30px;
  padding-left: 30px;
}
header.bh.ls-col .h-title-col .h-title {
  width: 100%;
  color: #444;
}
header.bh.ls-12 .h-logo-row {
  position: relative;
}
header.bh.ls-12 .h-logo-col {
  width: 100%;
}
header.bh.ls-12 .h-title-col {
  display: none;
}
header.bh.ls-12 .h-toggle-col {
  position: absolute;
  top: 0;
  bottom: 0;
}
header.bh.ls-12.lp-l .h-toggle-col {
  right: 15px;
}
header.bh.ls-12.lp-r .h-toggle-col {
  left: 15px;
}
header.bh.lp-l .h-logo-col {
  order: 1;
}
header.bh.lp-l .h-toggle-col {
  order: 3;
  justify-content: flex-end;
  margin-right: 0;
}
header.bh.lp-l .h-logo-row .h-title {
  text-align: right;
}
header.bh.lp-l .h-title-row .h-title {
  text-align: left;
}
header.bh.lp-r .h-logo-col {
  order: 3;
}
header.bh.lp-r .h-toggle-col {
  order: 1;
  justify-content: flex-start;
  margin-left: 0;
}
header.bh.lp-r .h-logo-row .h-title {
  text-align: left;
}
header.bh.lp-r .h-title-row .h-title {
  text-align: left;
}
header.bh.title-left .h-logo-row .h-title,
header.bh.title-left .h-title-row .h-title,
header.bh.title-left .h-ac .h-ac-title {
  text-align: left;
}
header.bh.title-right .h-logo-row .h-title,
header.bh.title-right .h-title-row .h-title,
header.bh.title-right .h-ac .h-ac-title {
  text-align: right;
}
header.bh.title-center .h-logo-row .h-title,
header.bh.title-center .h-title-row .h-title,
header.bh.title-center .h-ac .h-ac-title {
  text-align: center;
}
header.bh .h-title {
  font-size: 2rem;
}
header.bh .h-title,
header.bh .h-ac .h-ac-title {
  overflow: hidden;
  text-overflow: ellipsis;
  hyphens: auto;
  overflow-wrap: anywhere;
  word-break: break-word;
  line-height: 1.2;
}
header.bh .h-ac {
  display: block;
  order: 20;
  position: relative;
}
header.bh .h-ac > div > .element,
header.bh .h-ac .h-ac-element > div > .element,
header.bh .h-ac .co-lg-xl > div > .element {
  margin-bottom: 0;
}
header.bh .h-ac .h-ac-title {
  position: absolute;
  left: 50%;
  top: 50%;
  pointer-events: none;
  z-index: 7;
  transform: translate(-50%, -50%);
  margin: 0;
  font-weight: 600;
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.75);
  font-size: 3rem;
}
header.bh.ac-above-logo .h-ac {
  order: 2;
  margin-top: 15px;
}
header.bh.ac-above-logo.ac-full-size .h-ac {
  margin-top: 0;
}
header.bh.header-isfixed .h-ac {
  display: none;
}
header.bh .image-src-box,
header.bh .type-slider .slide-wrapper,
header.bh .type-slider .slider-box {
  border-radius: 0;
}
header.bh .nav-toggle-label {
  margin: 0;
}
header.bh.fixtoggle #nav-toggle-label-open {
  position: fixed;
  z-index: 150198;
}
header.bh #nav-toggle-label-open .nav-toggle {
  width: 40px;
  height: 40px;
  border: 0 solid #fff;
}
header.bh .nav-menu-header {
  display: flex;
  background-color: #fff;
  padding: 15px var(--my-nav-mobile-indent-offset);
}
header.bh .nav-menu-header .nav-menu-toggle {
  display: flex;
  align-items: center;
  padding-right: 30px;
}
header.bh .nav-menu-header .nav-menu-logo {
  display: flex;
  flex-grow: 1000;
  align-items: center;
  justify-content: flex-end;
}
header.bh .nav-menu-header .nav-menu-logo .mobile-logolink {
  display: flex;
  width: 100%;
  max-width: 350px;
}
header.bh.lsr-04 .nav-menu-header .nav-menu-logo .mobile-logolink {
  width: 80%;
}
header.bh.lsr-08 .nav-menu-header .nav-menu-logo .mobile-logolink {
  width: 60%;
}
header.bh.lsr-12 .nav-menu-header .nav-menu-logo .mobile-logolink {
  width: 40%;
}
header.bh.lp-l .nav-menu-header .nav-menu-toggle {
  order: 2;
  padding-right: 0;
  padding-left: 30px;
}
header.bh.lp-l .nav-menu-header .nav-menu-logo {
  order: 1;
  justify-content: flex-start;
}
header.bh.lp-l.ls-0 .nav-menu-header {
  justify-content: flex-end;
}
header.bh .nav-main-items li.expand > a::after {
  right: var(--my-nav-mobile-toggle-side);
}
header.bh .nav-main-items li.expand > a {
  padding-right: calc(var(--my-nav-mobile-toggle-side) + 28px) !important;
}
header.bh .nav-main-items .nav-meta-icons > ul {
  margin: 0;
  padding: 0 var(--my-nav-mobile-indent-offset);
  border-bottom: solid 1px #ddd;
}
header.bh .nav-main-items .nav-meta-icons > ul > li {
  display: inline-block;
}
header.bh .nav-main-items .nav-meta-icons > ul > li > a {
  display: inline-block;
  border-bottom: none;
  font-size: 24px;
  padding-left: 0;
  padding-right: 0;
  margin-right: 12px;
}
header.bh .nav-main-items .nav-meta-icons > ul > li > a .ico,
header.bh .nav-main-items .nav-meta-icons > ul > li > a .fa {
  min-width: 24px;
}
header.bh .nav-mega-menu .nav-side li > a {
  padding-left: calc(var(--my-nav-mobile-toggle-side) + var(--my-nav-mobile-indent) * 2);
}
header.bh .mega .nav-mega-menu .teaser,
header.bh .mega .nav-mega-menu .element:not(.type-nav-side):not(.ls-navigation) {
  display: none;
}
header.bh .mega .nav-mega-menu > .row > div {
  max-width: none;
  flex: none;
}
@media (max-width: 551.98px) {
  header.bh .nav-menu-header {
    padding-left: var(--my-nav-mobile-toggle-side-xs);
    padding-right: var(--my-nav-mobile-toggle-side-xs);
  }
  header.bh .nav-main-items li > a {
    padding-left: var(--my-nav-mobile-toggle-side-xs);
  }
  header.bh .nav-main-items li.expand > a::after {
    right: var(--my-nav-mobile-toggle-side-xs);
  }
  header.bh .nav-main-items li.expand > a {
    padding-right: calc(var(--my-nav-mobile-toggle-side-xs) + 28px) !important;
  }
  header.bh .nav-main-items .nav-meta-icons > ul {
    padding: 0 var(--my-nav-mobile-toggle-side-xs);
  }
  header.bh .nav-mega-menu .nav-side li > a {
    padding-left: calc(var(--my-nav-mobile-toggle-side-xs) + var(--my-nav-mobile-indent));
  }
  header.bh .nav-menu > li > a {
    padding-left: calc(var(--my-nav-mobile-toggle-side-xs) + var(--my-nav-mobile-indent));
  }
  header.bh .nav-menu > li > ul > li > a {
    padding-left: calc(var(--my-nav-mobile-toggle-side-xs) + var(--my-nav-mobile-indent) * 2);
  }
  header.bh .nav-menu > li > ul > li > ul > li a {
    padding-left: calc(var(--my-nav-mobile-toggle-side-xs) + var(--my-nav-mobile-indent) * 3);
  }
  header.bh .nav-menu > li > ul > li > ul > li > ul > li a {
    padding-left: calc(var(--my-nav-mobile-toggle-side-xs) + var(--my-nav-mobile-indent) * 4);
  }
}
header.bh .header-group {
  border-bottom: 1px solid #ddd;
}
header.bh.header-isfixed .h-logo-row {
  padding-top: 3.75px;
  padding-bottom: 3.75px;
}
header.bh.header-isfixed .h-title-col,
header.bh.header-isfixed .h-title-row {
  display: none;
}
header.bh.header-isfixed.ls-12.lp-r .h-logo {
  margin-left: auto;
}
header.bh.header-isfixed.ls-12.lp-l .h-logo {
  margin-right: auto;
}
header.bh.header-isfixed.ls-12 .h-logo {
  max-width: 50%;
}
header.bh.ls-12 .h-logo-row {
  padding-top: 0;
  padding-bottom: 0;
}
header.bh.ls-1 .h-logo-col {
  width: 44px;
}
header.bh.ls-1.header-isfixed .h-logo-col {
  width: 44px;
}
@media (min-width: 764px) {
  header.bh.ls-1 .h-logo-col {
    flex: 0 0 31px;
    max-width: 31px;
  }
  header.bh.ls-1 .h-title-col {
    flex: 0 0 643px;
    max-width: 643px;
  }
}
@media (min-width: 1014px) {
  header.bh.ls-1 .h-logo-col {
    flex: 0 0 52px;
    max-width: 52px;
  }
  header.bh.ls-1 .h-title-col {
    flex: 0 0 870px;
    max-width: 870px;
  }
  header.bh.ls-1.header-isfixed .h-logo-col {
    flex: 0 0 31px;
    max-width: 31px;
  }
  header.bh.ls-1.header-isfixed .h-title-col {
    flex: 0 0 891px;
    max-width: 891px;
  }
}
@media (min-width: 1200px) {
  header.bh.ls-1 .h-logo-col {
    flex: 0 0 67px;
    max-width: 67px;
  }
  header.bh.ls-1 .h-title-col {
    flex: 0 0 1033px;
    max-width: 1033px;
  }
  header.bh.ls-1.header-isfixed .h-title-col {
    flex: 0 0 1069px;
    max-width: 1069px;
  }
}
header.bh.ls-2 .h-logo-col {
  width: 90px;
}
header.bh.ls-2.header-isfixed .h-logo-col {
  width: 90px;
}
@media (min-width: 764px) {
  header.bh.ls-2 .h-logo-col {
    flex: 0 0 94px;
    max-width: 94px;
  }
  header.bh.ls-2 .h-title-col {
    flex: 0 0 580px;
    max-width: 580px;
  }
}
@media (min-width: 1014px) {
  header.bh.ls-2 .h-logo-col {
    flex: 0 0 135px;
    max-width: 135px;
  }
  header.bh.ls-2 .h-title-col {
    flex: 0 0 787px;
    max-width: 787px;
  }
  header.bh.ls-2.header-isfixed .h-logo-col {
    flex: 0 0 94px;
    max-width: 94px;
  }
  header.bh.ls-2.header-isfixed .h-title-col {
    flex: 0 0 828px;
    max-width: 828px;
  }
}
@media (min-width: 1200px) {
  header.bh.ls-2 .h-logo-col {
    flex: 0 0 165px;
    max-width: 165px;
  }
  header.bh.ls-2 .h-title-col {
    flex: 0 0 935px;
    max-width: 935px;
  }
  header.bh.ls-2.header-isfixed .h-title-col {
    flex: 0 0 1006px;
    max-width: 1006px;
  }
}
header.bh.ls-3 .h-logo-col {
  width: 135px;
}
header.bh.ls-3.header-isfixed .h-logo-col {
  width: 135px;
}
@media (min-width: 764px) {
  header.bh.ls-3 .h-logo-col {
    flex: 0 0 156px;
    max-width: 156px;
  }
  header.bh.ls-3 .h-title-col {
    flex: 0 0 518px;
    max-width: 518px;
  }
}
@media (min-width: 1014px) {
  header.bh.ls-3 .h-logo-col {
    flex: 0 0 218px;
    max-width: 218px;
  }
  header.bh.ls-3 .h-title-col {
    flex: 0 0 704px;
    max-width: 704px;
  }
  header.bh.ls-3.header-isfixed .h-logo-col {
    flex: 0 0 156px;
    max-width: 156px;
  }
  header.bh.ls-3.header-isfixed .h-title-col {
    flex: 0 0 766px;
    max-width: 766px;
  }
}
@media (min-width: 1200px) {
  header.bh.ls-3 .h-logo-col {
    flex: 0 0 262px;
    max-width: 262px;
  }
  header.bh.ls-3 .h-title-col {
    flex: 0 0 838px;
    max-width: 838px;
  }
  header.bh.ls-3.header-isfixed .h-title-col {
    flex: 0 0 944px;
    max-width: 944px;
  }
}
header.bh.ls-4 .h-logo-col {
  width: 179px;
}
header.bh.ls-4.header-isfixed .h-logo-col {
  width: 179px;
}
@media (min-width: 764px) {
  header.bh.ls-4 .h-logo-col {
    flex: 0 0 217px;
    max-width: 217px;
  }
  header.bh.ls-4 .h-title-col {
    flex: 0 0 457px;
    max-width: 457px;
  }
}
@media (min-width: 1014px) {
  header.bh.ls-4 .h-logo-col {
    flex: 0 0 300px;
    max-width: 300px;
  }
  header.bh.ls-4 .h-title-col {
    flex: 0 0 622px;
    max-width: 622px;
  }
  header.bh.ls-4.header-isfixed .h-logo-col {
    flex: 0 0 217px;
    max-width: 217px;
  }
  header.bh.ls-4.header-isfixed .h-title-col {
    flex: 0 0 705px;
    max-width: 705px;
  }
}
@media (min-width: 1200px) {
  header.bh.ls-4 .h-logo-col {
    flex: 0 0 359px;
    max-width: 359px;
  }
  header.bh.ls-4 .h-title-col {
    flex: 0 0 741px;
    max-width: 741px;
  }
  header.bh.ls-4.header-isfixed .h-title-col {
    flex: 0 0 883px;
    max-width: 883px;
  }
}
header.bh.ls-5 .h-logo-col {
  width: 225px;
}
header.bh.ls-5.header-isfixed .h-logo-col {
  width: 225px;
}
@media (min-width: 764px) {
  header.bh.ls-5 .h-logo-col {
    flex: 0 0 280px;
    max-width: 280px;
  }
  header.bh.ls-5 .h-title-col {
    flex: 0 0 394px;
    max-width: 394px;
  }
}
@media (min-width: 1014px) {
  header.bh.ls-5 .h-logo-col {
    flex: 0 0 383px;
    max-width: 383px;
  }
  header.bh.ls-5 .h-title-col {
    flex: 0 0 539px;
    max-width: 539px;
  }
  header.bh.ls-5.header-isfixed .h-logo-col {
    flex: 0 0 280px;
    max-width: 280px;
  }
  header.bh.ls-5.header-isfixed .h-title-col {
    flex: 0 0 642px;
    max-width: 642px;
  }
}
@media (min-width: 1200px) {
  header.bh.ls-5 .h-logo-col {
    flex: 0 0 457px;
    max-width: 457px;
  }
  header.bh.ls-5 .h-title-col {
    flex: 0 0 643px;
    max-width: 643px;
  }
  header.bh.ls-5.header-isfixed .h-title-col {
    flex: 0 0 820px;
    max-width: 820px;
  }
}
header.bh.ls-6 .h-logo-col {
  width: 270px;
}
header.bh.ls-6.header-isfixed .h-logo-col {
  width: 270px;
}
@media (min-width: 764px) {
  header.bh.ls-6 .h-logo-col {
    flex: 0 0 342px;
    max-width: 342px;
  }
  header.bh.ls-6 .h-title-col {
    flex: 0 0 332px;
    max-width: 332px;
  }
}
@media (min-width: 1014px) {
  header.bh.ls-6 .h-logo-col {
    flex: 0 0 466px;
    max-width: 466px;
  }
  header.bh.ls-6 .h-title-col {
    flex: 0 0 456px;
    max-width: 456px;
  }
  header.bh.ls-6.header-isfixed .h-logo-col {
    flex: 0 0 342px;
    max-width: 342px;
  }
  header.bh.ls-6.header-isfixed .h-title-col {
    flex: 0 0 580px;
    max-width: 580px;
  }
}
@media (min-width: 1200px) {
  header.bh.ls-6 .h-logo-col {
    flex: 0 0 555px;
    max-width: 555px;
  }
  header.bh.ls-6 .h-title-col {
    flex: 0 0 545px;
    max-width: 545px;
  }
  header.bh.ls-6.header-isfixed .h-title-col {
    flex: 0 0 758px;
    max-width: 758px;
  }
}
@media (min-width: 1014px) {
  header.bh.header-isfixed.ls-col .h-title-col, header.bh.show-title.ls-col .h-title-col {
    display: flex;
  }
  header.bh.ls-col .h-title-row {
    display: none;
  }
  header.bh .h-title {
    font-size: 2.4rem;
  }
  header.bh .h-ac .h-ac-title {
    font-size: 4.8rem;
  }
  header.bh .h-meta {
    order: 8;
    display: flex;
    flex-grow: 0;
    flex-basis: auto;
    flex-direction: column;
    padding-top: 3.75px;
  }
  header.bh .h-meta .header-links {
    display: flex;
    flex-basis: auto;
  }
  header.bh.meta-left .header-links {
    justify-content: flex-start;
  }
  header.bh.meta-center .header-links {
    justify-content: center;
  }
  header.bh.meta-right .header-links {
    justify-content: flex-end;
  }
  header.bh.meta-above .h-meta {
    order: 1;
  }
  header.bh .type-nav-breadcrumbs {
    display: flex;
  }
  header.bh.bc-right .type-nav-breadcrumbs {
    justify-content: flex-end;
  }
  header.bh.bc-center .type-nav-breadcrumbs {
    justify-content: center;
  }
}

.area-header {
  margin-bottom: 2rem;
}
.area-header.no-margin {
  margin-bottom: 0;
}
.area-header .type-nav-breadcrumbs.element {
  margin-bottom: 0;
}
.area-header .header-links {
  margin: 0;
}
.area-header .header-links.oc-small-element {
  min-height: auto !important;
}
.area-header .header-links > ul {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 1.2rem;
}
.area-header .header-links > ul > li:not(:last-child)::after {
  border-right-color: #ddd;
}
.area-header .header-links > ul > li > a {
  color: #444;
  text-transform: uppercase;
}
.area-header .header-links > ul > li > a:hover {
  text-decoration: none;
  color: #444;
}
.area-header .header-links.cap-none > ul > li > a {
  text-transform: none;
}

.type-header-config {
  background-color: #eed2ee;
  color: #444 !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  line-height: 30px;
}

@keyframes fixHeader {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 0.97;
    transform: none;
  }
}
.head {
  background: #fff;
}

html.opencms-group-editor.opencms-page-editor .area-header .sticky > .isfixed .hidden-fixed,
html.opencms-group-editor.opencms-page-editor .area-header .sticky > .notfixed .hidden-notfixed {
  display: block;
  opacity: 0.5;
  background-image: repeating-linear-gradient(135deg, transparent, transparent 10px, rgba(0, 0, 0, 0.1) 10px, rgba(0, 0, 0, 0.1) 20px);
}
@media (max-width: 1013.98px) {
  html.opencms-group-editor.opencms-page-editor .area-header .hidden-xs,
  html.opencms-group-editor.opencms-page-editor .area-header .hidden-sm,
  html.opencms-group-editor.opencms-page-editor .area-header .hidden-md {
    display: flex !important;
    opacity: 0.5;
    background-image: repeating-linear-gradient(135deg, transparent, transparent 10px, rgba(0, 0, 0, 0.1) 10px, rgba(0, 0, 0, 0.1) 20px);
  }
}
html.opencms-group-editor.opencms-page-editor:not(.active-nav) .area-header.bh .h-nav {
  border-top: 4px solid #eed2ee !important;
  background-color: rgba(238, 210, 238, 0.75) !important;
}
html.opencms-group-editor.opencms-page-editor:not(.active-nav) .area-header.bh .nav-main-container {
  position: static;
  height: auto;
  width: auto;
  right: auto;
  left: auto;
  opacity: initial;
  background-color: transparent;
  transition: none;
}
html.opencms-group-editor.opencms-page-editor:not(.active-nav) .area-header.bh .nav-main-container .nav-main-group {
  display: block;
}
html.opencms-group-editor.opencms-page-editor:not(.active-nav) .area-header.bh .nav-main-container .nav-main-group.has-sidelogo {
  padding-top: 0;
}
html.opencms-group-editor.opencms-page-editor header.fh.ls-0 .h-logo,
html.opencms-group-editor.opencms-page-editor header.bh.ls-0 .h-logo {
  display: block;
  position: absolute;
  width: 125px;
  height: 25px;
  padding: 0;
  --my-link-color: #fff;
  --my-link-hover-color: #fff;
  --my-link-color-rgb: 255, 255, 255;
  --my-link-hover-color-rgb: 255, 255, 255;
  border-radius: 4px !important;
  color: #fff !important;
  background-color: #ffa826 !important;
  background: linear-gradient(166deg, #ffb240 0%, #ff9e0d 100%) !important;
}
html.opencms-group-editor.opencms-page-editor header.fh.ls-0 .h-logo a:hover,
html.opencms-group-editor.opencms-page-editor header.bh.ls-0 .h-logo a:hover {
  text-decoration: underline;
}
html.opencms-group-editor.opencms-page-editor header.fh.ls-0 .h-logo > .no-image,
html.opencms-group-editor.opencms-page-editor header.bh.ls-0 .h-logo > .no-image {
  position: absolute;
  width: 125px;
  height: 25px;
  right: 0;
  padding-bottom: 0 !important;
}
html.opencms-group-editor.opencms-page-editor header.fh.ls-0.title-left .h-logo {
  right: 0;
}
html.opencms-group-editor.opencms-page-editor header.bh.ls-0.lp-r .h-logo-col {
  position: relative;
}
html.opencms-group-editor.opencms-page-editor header.bh.ls-0.lp-r .h-logo {
  right: 0;
}
html.opencms-group-editor .area-header {
  margin-bottom: 0;
}

/**********
 * Source file: _images.scss
 */
@media screen {
  img.lazyloading, img.lazyload {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' version='1' viewBox='0 0 16 16'%3E%3Cstyle%3E@keyframes kr%7Bfrom%7Btransform: rotate(0)%7Dto%7Btransform: rotate(360deg)%7D%7D%3C/style%3E%3Cg style='animation-name:kr;animation-duration:2s;animation-iteration-count:infinite;animation-timing-function:linear;transform-origin:50%25 50%25;width:16px;height:16px'%3E%3Cpath fill='none' stroke='%23000' stroke-dasharray='32.4' stroke-linecap='round' stroke-width='2.1' d='M8 1.125A6.875 6.875 0 1 1 1.125 8' style='animation-name:kr;animation-duration:1.33s;animation-iteration-count:infinite;animation-timing-function:cubic-bezier(0.4, 0, 0.2, 1);transform-origin:50%25 50%25'/%3E%3C/g%3E%3C/svg%3E") no-repeat scroll 50% 50% #f6f6f6;
    background-size: 24px 24px;
    background-color: #f6f6f6;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.3) inset;
    opacity: 0.25;
  }
}
img.lazyloaded {
  background: initial;
  box-shadow: initial;
}

main img.lazyloaded,
aside img.lazyloaded {
  opacity: 1;
  transition: opacity 300ms;
}

.image-src-box {
  width: 100%;
}
.image-src-box img,
.image-src-box .img {
  width: 100%;
}
.image-src-box img.external,
.image-src-box .img.external {
  object-fit: cover;
  object-position: center;
}
.image-src-box.presized, .image-src-box.original-size {
  position: relative;
}
.image-src-box.presized > .copyright.image-copyright, .image-src-box.original-size > .copyright.image-copyright {
  position: absolute;
  line-height: 1;
  padding: var(--my-copyright-padding);
  font-family: var(--my-copyright-font-family);
  font-size: var(--my-copyright-font-size);
  border-radius: var(--my-copyright-border-radius);
  text-align: var(--my-copyright-pos-right, right);
  right: var(--my-copyright-pos-right, 0);
  text-shadow: var(--my-copyright-text-shadow, none);
  display: inline-block;
  color: var(--my-copyright-color);
  background-color: var(--my-copyright-bg-color);
  opacity: var(--my-copyright-opacity);
  position: absolute;
  bottom: 0;
  pointer-events: none;
  z-index: 7;
  box-shadow: none;
  overflow-wrap: anywhere;
  word-break: break-word;
  hyphens: auto;
}
.image-src-box.presized:not(.use-ratio) {
  height: 0;
  display: block;
}
.image-src-box.presized:not(.use-ratio) img,
.image-src-box.presized:not(.use-ratio) .img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.image-src-box.presized.use-ratio {
  aspect-ratio: auto;
  width: 100%;
  height: auto;
}
.image-src-box.presized.use-ratio img,
.image-src-box.presized.use-ratio .img {
  height: auto;
}
.image-src-box.original-size img,
.image-src-box.original-size .img {
  height: auto;
}

.svg-inline.img > svg {
  width: 100%;
  height: auto;
}
.svg-inline.img.fill-body-color > svg {
  fill: var(--my-body-color);
}
.svg-inline.img.fill-theme-color > svg {
  fill: var(--my-main-theme);
}
.svg-inline.img.fill-highlight-color > svg {
  fill: var(--my-highlight-color);
}
.svg-inline.img.fill-headings-color > svg {
  fill: var(--my-headings-color);
}

.ico-img,
.ico-svg {
  display: inline-block;
  line-height: 1;
}

.ico-img > img {
  vertical-align: bottom;
  height: 1em;
  width: auto;
}

.ico-svg > svg {
  vertical-align: bottom;
  fill: currentColor;
  height: 1em;
  width: auto;
}

.copyright {
  --my-copyright-padding: 2px 4px;
  --my-copyright-font-size: 8px;
  --my-copyright-color: #000;
  --my-copyright-bg-color: rgba(255, 255, 255, 0.6);
  --my-copyright-opacity: 0.8;
  --my-copyright-pos-right: initial;
  --my-copyright-border-radius: 0 0 0 0;
  --my-copyright-font-family: var(--my-body-font-family);
  --my-copyright-text-shadow: none;
}

/**********
 * Source file: _imageseries.scss
 */
/*! PhotoSwipe main CSS by Dmytro Semenov | photoswipe.com */
.pswp {
  --pswp-bg: #000;
  --pswp-placeholder-bg: #222;
  --pswp-root-z-index: 100000;
  --pswp-preloader-color: rgba(79, 79, 79, 0.4);
  --pswp-preloader-color-secondary: rgba(255, 255, 255, 0.9);
  /* defined via js:
  --pswp-transition-duration: 333ms; */
  --pswp-icon-color: #fff;
  --pswp-icon-color-secondary: #4f4f4f;
  --pswp-icon-stroke-color: #4f4f4f;
  --pswp-icon-stroke-width: 2px;
  --pswp-error-text-color: var(--pswp-icon-color);
}

/*
	Styles for basic PhotoSwipe (pswp) functionality (sliding area, open/close transitions)
*/
.pswp {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: var(--pswp-root-z-index);
  display: none;
  touch-action: none;
  outline: 0;
  opacity: 0.003;
  contain: layout style size;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* Prevents focus outline on the root element,
  (it may be focused initially) */
.pswp:focus {
  outline: 0;
}

.pswp * {
  box-sizing: border-box;
}

.pswp img {
  max-width: none;
}

.pswp--open {
  display: block;
}

.pswp,
.pswp__bg {
  transform: translateZ(0);
  will-change: opacity;
}

.pswp__bg {
  opacity: 0.005;
  background: var(--pswp-bg);
}

.pswp,
.pswp__scroll-wrap {
  overflow: hidden;
}

.pswp__scroll-wrap,
.pswp__bg,
.pswp__container,
.pswp__item,
.pswp__content,
.pswp__img,
.pswp__zoom-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.pswp__img,
.pswp__zoom-wrap {
  width: auto;
  height: auto;
}

.pswp--click-to-zoom.pswp--zoom-allowed .pswp__img {
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.pswp--click-to-zoom.pswp--zoomed-in .pswp__img {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.pswp--click-to-zoom.pswp--zoomed-in .pswp__img:active {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

/* :active to override grabbing cursor */
.pswp--no-mouse-drag.pswp--zoomed-in .pswp__img,
.pswp--no-mouse-drag.pswp--zoomed-in .pswp__img:active,
.pswp__img {
  cursor: -webkit-zoom-out;
  cursor: -moz-zoom-out;
  cursor: zoom-out;
}

/* Prevent selection and tap highlights */
.pswp__container,
.pswp__img,
.pswp__button,
.pswp__counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pswp__item {
  /* z-index for fade transition */
  z-index: 1;
  overflow: hidden;
}

.pswp__hidden {
  display: none !important;
}

/* Allow to click through pswp__content element, but not its children */
.pswp__content {
  pointer-events: none;
}

.pswp__content > * {
  pointer-events: auto;
}

/*

  PhotoSwipe UI

*/
/*
	Error message appears when image is not loaded
	(JS option errorMsg controls markup)
*/
.pswp__error-msg-container {
  display: grid;
}

.pswp__error-msg {
  margin: auto;
  font-size: 1em;
  line-height: 1;
  color: var(--pswp-error-text-color);
}

/*
class pswp__hide-on-close is applied to elements that
should hide (for example fade out) when PhotoSwipe is closed
and show (for example fade in) when PhotoSwipe is opened
 */
.pswp .pswp__hide-on-close {
  opacity: 0.005;
  will-change: opacity;
  transition: opacity var(--pswp-transition-duration) cubic-bezier(0.4, 0, 0.22, 1);
  z-index: 10; /* always overlap slide content */
  pointer-events: none; /* hidden elements should not be clickable */
}

/* class pswp--ui-visible is added when opening or closing transition starts */
.pswp--ui-visible .pswp__hide-on-close {
  opacity: 1;
  pointer-events: auto;
}

/* <button> styles, including css reset */
.pswp__button {
  position: relative;
  display: block;
  width: 50px;
  height: 60px;
  padding: 0;
  margin: 0;
  overflow: hidden;
  cursor: pointer;
  background: none;
  border: 0;
  box-shadow: none;
  opacity: 0.85;
  -webkit-appearance: none;
  -webkit-touch-callout: none;
}

.pswp__button:hover,
.pswp__button:active,
.pswp__button:focus {
  transition: none;
  padding: 0;
  background: none;
  border: 0;
  box-shadow: none;
  opacity: 1;
}

.pswp__button:disabled {
  opacity: 0.3;
  cursor: auto;
}

.pswp__icn {
  fill: var(--pswp-icon-color);
  color: var(--pswp-icon-color-secondary);
}

.pswp__icn {
  position: absolute;
  top: 14px;
  left: 9px;
  width: 32px;
  height: 32px;
  overflow: hidden;
  pointer-events: none;
}

.pswp__icn-shadow {
  stroke: var(--pswp-icon-stroke-color);
  stroke-width: var(--pswp-icon-stroke-width);
  fill: none;
}

.pswp__icn:focus {
  outline: 0;
}

/*
	div element that matches size of large image,
	large image loads on top of it,
	used when msrc is not provided
*/
div.pswp__img--placeholder,
.pswp__img--with-bg {
  background: var(--pswp-placeholder-bg);
}

.pswp__top-bar {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  z-index: 10;
  /* allow events to pass through top bar itself */
  pointer-events: none !important;
}

.pswp__top-bar > * {
  pointer-events: auto;
  /* this makes transition significantly more smooth,
     even though inner elements are not animated */
  will-change: opacity;
}

/*

  Close button

*/
.pswp__button--close {
  margin-right: 6px;
}

/*

  Arrow buttons

*/
.pswp__button--arrow {
  position: absolute;
  top: 0;
  width: 75px;
  height: 100px;
  top: 50%;
  margin-top: -50px;
}

.pswp__button--arrow:disabled {
  display: none;
  cursor: default;
}

.pswp__button--arrow .pswp__icn {
  top: 50%;
  margin-top: -30px;
  width: 60px;
  height: 60px;
  background: none;
  border-radius: 0;
}

.pswp--one-slide .pswp__button--arrow {
  display: none;
}

/* hide arrows on touch screens */
.pswp--touch .pswp__button--arrow {
  visibility: hidden;
}

/* show arrows only after mouse was used */
.pswp--has_mouse .pswp__button--arrow {
  visibility: visible;
}

.pswp__button--arrow--prev {
  right: auto;
  left: 0px;
}

.pswp__button--arrow--next {
  right: 0px;
}

.pswp__button--arrow--next .pswp__icn {
  left: auto;
  right: 14px;
  /* flip horizontally */
  transform: scale(-1, 1);
}

/*

  Zoom button

*/
.pswp__button--zoom {
  display: none;
}

.pswp--zoom-allowed .pswp__button--zoom {
  display: block;
}

/* "+" => "-" */
.pswp--zoomed-in .pswp__zoom-icn-bar-v {
  display: none;
}

/*

  Loading indicator

*/
.pswp__preloader {
  position: relative;
  overflow: hidden;
  width: 50px;
  height: 60px;
  margin-right: auto;
}

.pswp__preloader .pswp__icn {
  opacity: 0;
  transition: opacity 0.2s linear;
  animation: pswp-clockwise 600ms linear infinite;
}

.pswp__preloader--active .pswp__icn {
  opacity: 0.85;
}

@keyframes pswp-clockwise {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*

  "1 of 10" counter

*/
.pswp__counter {
  height: 30px;
  margin: 15px 0 0 20px;
  font-size: 14px;
  line-height: 30px;
  color: var(--pswp-icon-color);
  text-shadow: 1px 1px 3px var(--pswp-icon-color-secondary);
  opacity: 0.85;
}

.pswp--one-slide .pswp__counter {
  display: none;
}

.type-imageseries {
  --my-is-square-margin: 2px;
  --my-is-slide-border-size: 0px;
  --my-is-slide-border-color: var(--my-grey);
  --my-is-slide-bgcolor: var(--my-grey-light);
}
.type-imageseries.element .images {
  font-weight: var(--my-font-weight-bold);
}
.type-imageseries > .series {
  position: relative;
}
.type-imageseries > .series > button.btn {
  margin-top: calc(2 * var(--my-is-square-margin));
  margin-left: var(--my-is-square-margin);
  margin-right: var(--my-is-square-margin);
  width: calc(100% - 2 * var(--my-is-square-margin));
}
.type-imageseries > .series .imagedata {
  display: none;
}
.type-imageseries.square-list > .series, .type-imageseries.slide-list > .series {
  margin-left: calc(-1 * var(--my-is-square-margin));
  margin-right: calc(-1 * var(--my-is-square-margin));
}
.type-imageseries.square-list > .series > .images, .type-imageseries.slide-list > .series > .images {
  --my-sq-margin: var(--my-is-square-margin);
  margin-top: calc(-1 * var(--my-is-square-margin));
  margin-bottom: calc(-1 * var(--my-is-square-margin));
}
.type-imageseries.slide-list .image-col .wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  border: var(--my-is-slide-border-size) solid var(--my-is-slide-border-color);
  border-radius: var(--my-image-border-radius);
}
.type-imageseries.slide-list .image-col .zoom-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--my-is-slide-bgcolor);
}
.type-imageseries.slide-list .image-col img {
  object-fit: contain;
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
}
.type-imageseries.masonry-list .row.images {
  margin-bottom: 0;
}
.type-imageseries.detail-page > .detail-content,
.type-imageseries.detail-page > .series {
  margin-top: var(--my-detail-content-spacer-y);
}
.type-imageseries.detail-page > .detail-visual.no-info + .detail-content,
.type-imageseries.detail-page > .detail-visual.no-info + .series {
  margin-top: 0;
}
.type-imageseries.detail-page.only-series > .series {
  margin-top: 0;
}
.type-imageseries .effect-box {
  position: relative;
}
.type-imageseries .effect-box .centered {
  display: inline-block;
  color: #fff;
  position: absolute;
  right: 16px;
  bottom: 12px;
  text-shadow: 1px 1px 1px #000;
  font-size: 20px;
  pointer-events: none;
  z-index: 7;
}
.type-imageseries .image-col .content {
  border-radius: var(--my-image-border-radius);
}
.type-imageseries .image-col .copyright {
  line-height: 1;
  padding: var(--my-copyright-padding);
  font-family: var(--my-copyright-font-family);
  font-size: var(--my-copyright-font-size);
  border-radius: var(--my-copyright-border-radius);
  text-align: var(--my-copyright-pos-right, right);
  right: var(--my-copyright-pos-right, 0);
  text-shadow: var(--my-copyright-text-shadow, none);
  display: inline-block;
  color: var(--my-copyright-color);
  background-color: var(--my-copyright-bg-color);
  opacity: var(--my-copyright-opacity);
  position: absolute;
  bottom: 0;
  pointer-events: none;
  z-index: 7;
  box-shadow: none;
  overflow-wrap: anywhere;
  word-break: break-word;
  hyphens: auto;
}
.type-imageseries .tile-col {
  display: flex;
}
.type-imageseries .tile-col .zoom {
  position: relative;
  width: 100%;
}
.type-imageseries .btn-append {
  display: block;
  width: 100%;
}

html.noscript .type-imageseries .series {
  display: none;
}

.pswp {
  --pswp-root-z-index: 300000;
  --pswp-placeholder-bg: none;
  --my-zoomer-title-font-family: var(--my-font-family-headings);
  --my-zoomer-title-font-weight: var(--my-font-weight-headings);
  --my-zoomer-title-font-size: 1.8rem;
  --my-zoomer-title-line-height: 1.25;
  --my-zoomer-copyright-font-size: 1.1rem;
  --my-zoomer-copyright-line-height: 1.2;
}
.pswp .pswp__caption {
  position: absolute;
  left: 50%;
  bottom: 16px;
  transform: translateX(-50%);
  width: 100vw;
  display: flex;
  justify-content: center;
  hyphens: auto;
  overflow-wrap: anywhere;
  word-break: break-word;
}
.pswp .caption-wrapper {
  text-align: center;
  border-radius: var(--my-border-radius);
  max-width: calc(100% - 2 * var(--my-ws-margin));
  padding: calc(0.5 * var(--my-ws-margin)) var(--my-ws-margin);
  background-color: rgba(0, 0, 0, 0.5);
}
.pswp .title {
  color: #eee;
  font-family: var(--my-zoomer-title-font-family);
  font-weight: var(--my-zoomer-title-font-weight);
  font-size: var(--my-zoomer-title-font-size);
  line-height: var(--my-zoomer-title-line-height);
  text-shadow: 1px 1px 1px #000;
}
.pswp .copyright {
  color: #eee;
  font-size: var(--my-zoomer-copyright-font-size);
  line-height: var(--my-zoomer-copyright-line-height);
  text-shadow: 1px 1px 1px #000;
}
@media (min-width: 552px) {
  .pswp .pswp__caption {
    max-width: 90%;
  }
}

.comein {
  transform: translateY(120px);
  animation: come-in 0.8s ease forwards;
}
.comein:nth-child(even) {
  transform: translateY(150px);
  animation-duration: 1s;
}

@keyframes come-in {
  to {
    transform: translateY(0);
  }
}
html.keyboard-nav a:focus .zoom-overlay .zoom-icon,
.zoom:hover .zoom-overlay .zoom-icon {
  opacity: 1;
  transform: scale(1);
  border: 0;
  box-shadow: none;
}

.zoom-overlay {
  cursor: zoom-in;
  position: relative;
  display: block;
  width: 100%;
  transition: all 0.1s ease-in-out 0s;
}
.zoom-overlay .zoom-icon {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transform: scale(0);
  transition: all 0.4s ease-in-out 0s;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
}
.zoom-overlay .zoom-icon:before {
  background: transparent;
}
.zoom-overlay .zoom-icon:after {
  background: transparent;
}
.zoom-overlay .zoom-icon span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  font-size: 30px;
  color: #fff;
}

.square-col .zoom-overlay {
  height: 100%;
}

.zoom-overlay .zoom-icon,
.image-src-box,
.image-src-box > img {
  border-radius: var(--my-image-border-radius);
}

.image-src-box.zoomer {
  position: relative;
  z-index: 1;
}
.image-src-box.zoomer > img {
  cursor: zoom-in;
}
.image-src-box.zoomer::after {
  font-family: var(--my-icon-font-family);
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f002";
  display: inline-block;
  color: #fff;
  position: absolute;
  right: 16px;
  bottom: 12px;
  text-shadow: 1px 1px 1px #000;
  font-size: 20px;
  pointer-events: none;
  z-index: 7;
}

a .image-src-box.zoomer > img {
  cursor: pointer;
}
a .image-src-box::after {
  content: "";
}

.area-header.ac-full-size .h-ac .type-slider .slider-box,
.area-header.ac-full-size .h-ac .type-slider .slide-wrapper,
.area-header.ac-full-size .h-ac .type-slider .slide-container,
.area-header.ac-full-size .h-ac .type-slider .image-src-box,
.area-header.ac-full-size .h-ac .piece.full .image-src-box,
.area-header.ac-full-size .h-ac .element.tile-col .image-src-box,
.area-header.ac-full-size .h-ac .element.tile-col .content-box,
.area-wide.area-full-row .type-slider .slider-box,
.area-wide.area-full-row .type-slider .slide-wrapper,
.area-wide.area-full-row .type-slider .slide-container,
.area-wide.area-full-row .type-slider .image-src-box,
.area-wide.area-full-row .piece.full .image-src-box,
.area-wide.area-full-row .element.tile-col .image-src-box,
.area-wide.area-full-row .element.tile-col .content-box {
  border-radius: 0;
}
.area-header.ac-full-size .h-ac .type-slider .slide-wrapper img,
.area-header.ac-full-size .h-ac .piece.full .image-src-box img,
.area-header.ac-full-size .h-ac .element.tile-col .image-src-box img,
.area-wide.area-full-row .type-slider .slide-wrapper img,
.area-wide.area-full-row .piece.full .image-src-box img,
.area-wide.area-full-row .element.tile-col .image-src-box img {
  border-radius: 0;
}

.image-src-box > img {
  overflow: hidden;
}

/**********
 * Source file: _linksequence.scss.
 */
.ls-navigation.line-icon li a, .ls-bullets.line-icon li, .ls-navigation.default-icon li a, .ls-bullets.default-icon li,
.ls-row.default-icon li, .ls-navigation.custom-icon li a, .ls-bullets.custom-icon li,
.ls-row.custom-icon li {
  padding-left: calc(var(--my-ls-li-padding));
}
.ls-navigation.line-icon li a::before, .ls-bullets.line-icon li::before, .ls-navigation.default-icon li a::before, .ls-bullets.default-icon li::before,
.ls-row.default-icon li::before, .ls-navigation.custom-icon li a::before, .ls-bullets.custom-icon li::before,
.ls-row.custom-icon li::before {
  float: left;
  margin-left: calc(-1 * var(--my-ls-li-padding));
  display: inline-block;
  line-height: 1.5;
}

.ls-navigation.custom-icon li a::before, .ls-bullets.custom-icon li::before,
.ls-row.custom-icon li::before {
  font-family: var(--my-icon-font-family);
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: inherit;
  line-height: inherit;
}

.ls-navigation.default-icon li a::before, .ls-bullets.default-icon li::before,
.ls-row.default-icon li::before {
  font-family: sans-serif;
  font-size: inherit;
  content: "•";
}

.ls-navigation.line-icon li a::before, .ls-bullets.line-icon li::before {
  font-family: sans-serif;
  content: "|";
}

.type-linksequence {
  --my-ls-li-padding: calc(2.5 * var(--my-ws-margin));
  hyphens: auto;
  overflow-wrap: anywhere;
  word-break: break-word;
}
.type-linksequence li.ls-li a:hover {
  text-decoration: none;
}
.type-linksequence li.ls-li a:hover .ls-item span:not(.ls-icon) {
  text-decoration: underline;
}
.type-linksequence li.ls-li .ls-item.icon-last {
  display: inline-flex;
  align-items: baseline;
  flex-direction: row;
}
.type-linksequence li.ls-li .ls-item.icon-last .ls-icon {
  order: 10;
}
.type-linksequence li.ls-li .ls-item.icon-last .ls-icon + span {
  margin-left: 0;
  margin-right: var(--my-ws-margin);
}

.ls-bullets ul,
.ls-row ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.ls-row {
  --my-ls-row-spacing: calc(2 * var(--my-ws-margin));
}
.ls-row li {
  display: inline-block;
  list-style: outside none none;
  position: relative;
}
.ls-row.line-icon li:not(:last-of-type)::after {
  content: "|";
  margin: 0 calc(0.5 * var(--my-ls-row-spacing));
}
.ls-row.no-icon li:not(:last-of-type) {
  margin-right: var(--my-ls-row-spacing);
}
.ls-row.custom-icon li, .ls-row.default-icon li {
  padding-left: 0;
}
.ls-row.custom-icon li::before, .ls-row.default-icon li::before {
  float: none;
  padding-right: var(--my-ws-margin);
  margin-left: 0;
}
.ls-row.custom-icon li:not(:last-of-type), .ls-row.default-icon li:not(:last-of-type) {
  padding-right: var(--my-ls-row-spacing);
}
.ls-row.custom-icon li:not(:last-of-type)::before, .ls-row.default-icon li:not(:last-of-type)::before {
  margin-left: 0;
}
.ls-row.custom-icon.icon-last li, .ls-row.default-icon.icon-last li {
  display: inline-flex;
  align-items: baseline;
  flex-direction: row;
}
.ls-row.custom-icon.icon-last li::before, .ls-row.default-icon.icon-last li::before {
  order: 10;
  padding-right: 0;
  margin-left: var(--my-ws-margin);
}

.ls-navigation li.ls-li a:hover .ls-item span:not(.ls-icon) {
  text-decoration: none;
}

.ls-icon + span {
  --my-ls-row-spacing: calc(2 * var(--my-ws-margin));
  margin-left: var(--my-ws-margin);
}

.ls-expand .heading {
  overflow: visible !important;
  margin-bottom: 0;
}
.ls-expand .heading button {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  color: inherit;
  text-align: inherit;
  font: inherit;
  border-radius: 0;
  appearance: none;
  position: relative;
  cursor: pointer;
  display: block;
  width: 100%;
  margin-bottom: var(--my-headings-margin-bottom);
}
.ls-expand .heading button::after {
  font-family: var(--my-icon-font-family);
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f078";
  line-height: 1;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  height: 100%;
  transform: rotate(-180deg);
  transition: transform 0.33s;
  right: 0;
}
.ls-expand .heading button.collapsed {
  margin-bottom: 0;
  transition: margin-bottom 0.2s linear 0.2s;
}
.ls-expand .heading button.collapsed::after {
  transform: rotate(0);
}
@media (min-width: 1014px) {
  .ls-expand.disable-lg .collapse:not(.show) {
    display: block;
  }
  .ls-expand.disable-lg .collapsing {
    height: unset !important;
    transition: none;
  }
  .ls-expand.disable-lg button {
    cursor: default;
  }
  .ls-expand.disable-lg button::after {
    content: none;
  }
  .ls-expand.disable-lg button.collapsed {
    margin-bottom: var(--my-headings-margin-bottom);
    transition: none;
  }
  .ls-expand.disable-lg button.collapsed::after {
    transform: rotate(0);
  }
}

/**********
 * Source file: _map.scss
 */
.type-map {
  --my-map-info-scale: 1;
  --my-map-popup-color: #444;
  --my-map-popup-bg: #fff;
  --my-map-font-weight: var(--my-body-font-weight);
  --my-map-header-weight: var(--my-font-weight-headings);
  --my-map-font-size: calc(var(--my-map-info-scale) * 14px);
  --my-map-header-size: calc(var(--my-map-info-scale) * 18px);
  --my-map-marker-padding: calc(var(--my-map-info-scale) * 5px);
  --my-map-marker-spacer-y: calc(var(--my-map-info-scale) * 5px);
}
@media (max-width: 551.98px) {
  .type-map {
    --my-map-info-scale: 0.8;
  }
}
.type-map .mapbuttons {
  margin-top: calc(0.5 * var(--my-element-margin));
}
.type-map .mapbuttons .btn {
  margin-top: calc(0.25 * var(--my-element-margin));
  margin-right: var(--my-ws-margin);
}
.type-map:not(.visible) .mapbuttons {
  display: none;
}
.type-map .presized:not(.enlarged) {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
}
.type-map .presized:not(.enlarged).presized-vars {
  padding-bottom: var(--my-presized-padding);
}
@media (min-width: 764px) {
  .type-map .presized:not(.enlarged).presized-vars {
    padding-bottom: var(--my-presized-padding-lg);
  }
}
.type-map .presized:not(.enlarged) .mapwindow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--my-border-radius);
  overflow: hidden;
  z-index: 5;
}
.type-map .presized:not(.enlarged) .mapwindow.placeholder::after {
  content: "\f279";
}
.type-map .presized.enlarged {
  padding-bottom: 0 !important;
}
.type-map .map-marker {
  --my-contact-spacer-y: var(--my-map-marker-spacer-y);
  display: block;
  color: var(--my-map-popup-color);
  font-size: var(--my-map-font-size);
  font-weight: var(--my-map-font-weight);
  line-height: 1.2;
  padding: var(--my-map-marker-padding) !important;
  margin: 0 !important;
  overflow-wrap: anywhere;
  word-break: break-word;
  hyphens: auto;
}
.type-map .map-marker .fn.n,
.type-map .map-marker .org,
.type-map .map-marker .adr,
.type-map .map-marker .title,
.type-map .map-marker .pos,
.type-map .map-marker .note.top,
.type-map .map-marker .mail,
.type-map .map-marker .facility-icons {
  margin-top: var(--my-contact-spacer-y);
  margin-bottom: var(--my-contact-spacer-y);
}
.type-map .map-marker .contactlink,
.type-map .map-marker .link,
.type-map .map-marker .note.bottom,
.type-map .map-marker .website,
.type-map .map-marker .vcard {
  margin-top: var(--my-paragraph-margin-bottom);
  margin-bottom: var(--my-paragraph-margin-bottom);
}
.type-map .map-marker .org:has(+ .pos) {
  margin-bottom: 0;
}
.type-map .map-marker .org + .pos {
  margin-top: 0;
}
.type-map .map-marker .fn.n {
  --my-piece-headings-margin-bottom: var(--my-contact-spacer-y);
  margin-top: 0;
}
.type-map .map-marker .tablerow {
  display: table-row;
  hyphens: none;
  overflow-wrap: normal;
  word-break: normal;
  margin-top: 0;
  margin-bottom: 0;
}
.type-map .map-marker .tablerow > span {
  padding-right: var(--my-ws-margin);
  display: table-cell;
}
.type-map .map-marker .website a,
.type-map .map-marker .mail a {
  overflow-wrap: anywhere;
  word-break: break-word;
}
.type-map .map-marker .mail {
  hyphenate-character: " ";
  -webkit-hyphenate-character: " ";
}
.type-map .map-marker > div:last-of-type {
  margin-bottom: 0;
}
@media (max-width: 551.98px) {
  .type-map .map-marker .heading {
    word-break: break-all;
  }
}
.type-map .map-marker h3, .type-map .map-marker .h3, .type-map .map-marker .type-liturgy .calendar-simple, .type-liturgy .type-map .map-marker .calendar-simple, .type-map .map-marker .accordion .sub-header, .accordion .type-map .map-marker .sub-header, .type-map .map-marker .onclick-activation .oa-heading, .onclick-activation .type-map .map-marker .oa-heading, .type-map .map-marker h4, .type-map .map-marker .h4, .type-map .map-marker .type-gottesdienst.teaser.gd.calendar-sheet-piece .gd-hd, .type-gottesdienst.teaser.gd.calendar-sheet-piece .type-map .map-marker .gd-hd, .type-map .map-marker .detail-page .sub-header, .detail-page .type-map .map-marker .sub-header, .type-map .map-marker .styled-form header, .styled-form .type-map .map-marker header {
  line-height: 1.1;
  font-size: var(--my-map-header-size);
  font-weight: var(--my-map-header-weight);
  color: var(--my-map-popup-color);
}
.type-map .map-marker h4, .type-map .map-marker .h4, .type-map .map-marker .type-gottesdienst.teaser.gd.calendar-sheet-piece .gd-hd, .type-gottesdienst.teaser.gd.calendar-sheet-piece .type-map .map-marker .gd-hd, .type-map .map-marker .detail-page .sub-header, .detail-page .type-map .map-marker .sub-header, .type-map .map-marker .styled-form header, .styled-form .type-map .map-marker header {
  font-size: var(--my-map-font-size);
  font-weight: var(--my-map-header-weight);
}
.type-map .map-marker .facility-icons {
  --my-acc-icon-size: calc(var(--my-map-info-scale) * 0.75 * 3.5rem);
  --my-acc-font-size: calc(var(--my-map-info-scale) * 0.75 * 2rem);
  --my-acc-margin: calc(var(--my-map-info-scale) * 0.75 * var(--my-ws-margin));
}
.type-map .map-marker .link > a,
.type-map .map-marker .website > a {
  font-weight: var(--my-font-weight-bold);
}
.type-map .map-marker .link,
.type-map .map-marker .marker-route {
  margin-top: calc(var(--my-map-info-scale) * var(--my-paragraph-margin-bottom));
}
.type-map .map-marker .marker-route form {
  margin-top: calc(var(--my-map-info-scale) * var(--my-contact-spacer-y));
}
.type-map .map-marker .marker-route .form-control {
  padding: calc(var(--my-map-info-scale) * 0.6rem - 1px);
  margin-right: calc(var(--my-map-info-scale) * 0.5 * var(--my-ws-margin));
  border: 1px solid var(--my-grey);
  border-radius: 0;
}

.map-osm .maplibregl-map,
.map-osm .maplibregl-popup-content {
  color: var(--my-map-popup-color);
  background-color: var(--my-map-popup-bg);
  font-family: var(--my-body-font-family);
  font-weight: var(--my-body-font-weight);
}
.map-osm .maplibregl-popup {
  max-height: max(200px, 50%) !important;
  max-width: min(400px, 75%) !important;
}
.map-osm .maplibregl-popup-content {
  border-radius: var(--my-border-radius);
  padding: 10px 20px 10px 10px;
  font-size: 1rem;
  line-height: 1.2;
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
  overflow: auto;
  scrollbar-width: thin;
  position: static;
}
.map-osm .maplibregl-popup-close-button {
  color: var(--my-map-popup-color);
  font-size: 20px;
  padding-inline: 0px;
  padding: 10px;
  line-height: 10px;
}
.map-osm .maplibregl-popup-close-button:hover {
  background-color: #fff;
  opacity: 0.8;
  color: var(--my-main-theme);
}
.map-osm .maplibregl-popup-anchor-top .maplibregl-popup-tip,
.map-osm .maplibregl-popup-anchor-top-left .maplibregl-popup-tip,
.map-osm .maplibregl-popup-anchor-top-right .maplibregl-popup-tip {
  border-bottom-color: var(--my-map-popup-bg);
}
.map-osm .maplibregl-popup-anchor-top .maplibregl-popup-close-button,
.map-osm .maplibregl-popup-anchor-top-left .maplibregl-popup-close-button,
.map-osm .maplibregl-popup-anchor-top-right .maplibregl-popup-close-button {
  top: 10px;
}
.map-osm .maplibregl-popup-anchor-bottom .maplibregl-popup-tip,
.map-osm .maplibregl-popup-anchor-bottom-left .maplibregl-popup-tip,
.map-osm .maplibregl-popup-anchor-bottom-right .maplibregl-popup-tip {
  border-top-color: var(--my-map-popup-bg);
}
.map-osm .maplibregl-popup-anchor-left .maplibregl-popup-tip {
  border-right-color: var(--my-map-popup-bg);
}
.map-osm .maplibregl-popup-anchor-right .maplibregl-popup-tip {
  border-left-color: var(--my-map-popup-bg);
}
.map-osm .maplibregl-popup-anchor-right .maplibregl-popup-close-button {
  right: 10px;
}
.map-osm .maplibregl-marker {
  cursor: pointer;
}
.map-osm .maplibregl-ctrl-attrib a {
  color: #888 !important;
}

.map-google .gm-style-iw-chr {
  position: absolute;
  top: 0;
  right: 0;
}
.map-google .gm-style-iw-chr button {
  width: 40px !important;
  height: 40px !important;
}
.map-google .gm-style-iw-d {
  padding-top: 12px;
  padding-right: 12px;
}

/**********
 * Source file: _media.scss
 */
.type-media.square-col .preview {
  height: 100%;
}
.type-media .piece.full .visual.size-limited {
  margin-left: 0;
}
@media (max-width: 763.98px) {
  .type-media.text-below-on-xs.square-col .preview .image-src-box {
    position: relative;
  }
  .type-media.text-below-on-xs.square-col .preview .image-src-box > img {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: none !important;
  }
  .type-media.text-below-on-xs.square-1-1 .content.revealed, .type-media.text-below-on-xs.square-1-1 .preview .image-src-box {
    padding-bottom: 100%;
  }
  .type-media.text-below-on-xs.square-4-3 .content.revealed, .type-media.text-below-on-xs.square-4-3 .preview .image-src-box {
    padding-bottom: 75%;
  }
  .type-media.text-below-on-xs.square-16-9 .content.revealed, .type-media.text-below-on-xs.square-16-9 .preview .image-src-box {
    padding-bottom: 56.25%;
  }
}
.type-media .media-box {
  position: relative;
}
.type-media .content.revealed .copyright,
.type-media .content-removed .copyright {
  display: none;
}
.type-media .revealed .preview {
  cursor: auto;
}
.type-media .preview {
  position: relative;
  cursor: pointer;
  height: 100%;
}
.type-media .preview .centered {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.type-media .preview .icon {
  color: #fff;
  text-shadow: 1px 1px 1px #000, 0px 0px 5px rgba(0, 0, 0, 0.5);
  font-size: 16px;
  text-align: center;
  z-index: 6;
}
.type-media .preview .icon svg {
  filter: drop-shadow(1px 1px 1px #000) drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.5));
}
.type-media .preview .icon span {
  opacity: 0.7;
  display: block;
  font-size: 60px;
}
.type-media .preview .icon div {
  opacity: 1;
}
.type-media .preview .media-overlay-top,
.type-media .preview .media-overlay-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px 20px;
  font-size: 16px;
  line-height: 1.2;
  text-shadow: 1px 1px 1px #000, 0px 0px 1px #000;
  margin: 0;
  color: #fff;
  z-index: 6;
}
.type-media .preview .media-overlay-top {
  top: 10px;
  bottom: initial;
  max-height: 75%;
  overflow: hidden;
}
.type-media .preview .media-overlay-top > .main-header > .title,
.type-media .preview .media-overlay-top > .sub-header,
.type-media .preview .media-overlay-top .intro-headline > .intro,
.type-media .preview .media-overlay-top h1, .type-media .preview .media-overlay-top h2, .type-media .preview .media-overlay-top h3 {
  color: #fff;
  text-shadow: 1px 1px 1px #000, 0px 0px 1px #000;
}
.type-media .preview .media-date {
  float: right;
}
.type-media .preview:hover .icon span {
  opacity: 1;
}
.type-media .preview.placeholder {
  cursor: auto;
}
.type-media .preview.placeholder::after {
  content: "\f04b";
}
.type-media .col-visual,
.type-media .teaser-visual {
  position: relative;
}
.type-media .content {
  border-radius: var(--my-image-border-radius);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  background-color: #000;
  overflow: hidden;
}
.type-media .content.compact {
  position: relative;
}
.type-media .content > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.type-media .content.pbg-main {
  background-color: var(--my-main-theme);
}
.type-media .content.pbg-facebook {
  background-color: #4267b2;
}
.type-media .content.pbg-twitter {
  background-color: #000000;
}
.type-media .content.revealed {
  background-color: transparent;
}
.type-media .preview.video .centered.image {
  height: 100%;
}
.type-media .preview.video .centered.image > .image-src-box {
  height: 100%;
}
.type-media .preview.video .centered.image > .image-src-box > img {
  height: 100%;
  object-fit: cover;
}
@media (min-width: 1200px) {
  .type-media.square-large.square-16-9 .preview.video .centered.image {
    width: 104%;
  }
}
.type-media .presized {
  position: relative;
  width: 100%;
  height: initial;
}
.type-media .presized:not(.enlarged) {
  padding-bottom: 100%;
  overflow: hidden;
}
.type-media .presized:not(.enlarged).presized-vars {
  padding-bottom: var(--my-presized-padding);
}
@media (min-width: 764px) {
  .type-media .presized:not(.enlarged).presized-vars {
    padding-bottom: var(--my-presized-padding-lg);
  }
}
.type-media .presized.enlarged {
  padding-bottom: 0 !important;
}
.type-media .presized.enlarged .content {
  position: static;
  height: auto;
}
.type-media .presized .fitin {
  width: 100%;
  height: 100%;
}

.type-media.audio .content {
  background-color: transparent;
}
.type-media.audio.presized .audio-player,
.type-media.audio .presized .audio-player {
  position: absolute;
  z-index: 25;
  bottom: 0;
  width: 100%;
  padding: 10px;
}
.type-media.audio .preview.narrow .audio-stop,
.type-media.audio .preview.narrow .audio-skip {
  display: none;
}
.type-media.audio .preview.narrow .audio-play {
  width: auto;
}
.type-media.audio .content.compact .preview {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.type-media.audio .content.compact .preview .audio-player .audio-box {
  color: var(--my-highlight-fg-color);
  background-color: var(--my-highlight-color);
}
.type-media.audio .content.compact .preview .audio-player .audio-box .audio-progress {
  border-color: var(--my-highlight-fg-color);
}
.type-media.audio .content.compact .preview .audio-player .audio-box .audio-progress .progress-bar {
  background-color: var(--my-highlight-fg-color);
}
.type-media.audio .content.compact .preview .media-overlay-top {
  position: static;
  padding: 10px 10px 0 10px;
  order: -1;
}
.type-media.audio .content.compact .preview .media-overlay-top > .main-header > .title,
.type-media.audio .content.compact .preview .media-overlay-top > .sub-header,
.type-media.audio .content.compact .preview .media-overlay-top h1, .type-media.audio .content.compact .preview .media-overlay-top h2, .type-media.audio .content.compact .preview .media-overlay-top h3 {
  color: #222;
  text-shadow: none;
}

.audio-player .audio-box {
  display: flex;
  flex-direction: column;
  color: #444;
  background-color: rgba(255, 255, 255, 0.75);
  padding: 10px;
  font-size: 14px;
  border-radius: var(--my-border-radius);
}
.audio-player .audio-box .audio-headline {
  margin-bottom: 10px;
  font-weight: 600;
}
.audio-player .audio-box .audio-progress {
  display: block;
  margin-bottom: 10px;
  border: 1px solid #444;
  border-radius: var(--my-border-radius);
}
.audio-player .audio-box .audio-progress .progress-bar {
  height: 10px;
  background-color: #444;
  transition: none;
  width: 0;
}
.audio-player .audio-box .audio-controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.audio-player .audio-box .audio-controls > div {
  display: flex;
  flex-basis: 33.33333%;
}
.audio-player .audio-box .audio-controls > div.audio-pos, .audio-player .audio-box .audio-controls > div.audio-length {
  font-weight: 600;
}
.audio-player .audio-box .audio-controls > div.audio-buttons {
  justify-content: center;
  align-items: center;
}
.audio-player .audio-box .audio-controls > div.audio-buttons > .ico,
.audio-player .audio-box .audio-controls > div.audio-buttons > .fa {
  cursor: pointer;
}
.audio-player .audio-box .audio-controls > div.audio-buttons > .audio-play {
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
  width: 56px;
  font-size: 28px;
}
.audio-player .audio-box .audio-controls > div.audio-length {
  justify-content: flex-end;
}

.square-col > .content .audio-player .copyright {
  position: relative;
}
.square-col > .content .audio-player .copyright > div {
  line-height: 1;
  padding: var(--my-copyright-padding);
  font-family: var(--my-copyright-font-family);
  font-size: var(--my-copyright-font-size);
  border-radius: var(--my-copyright-border-radius);
  text-align: var(--my-copyright-pos-right, right);
  right: var(--my-copyright-pos-right, 0);
  text-shadow: var(--my-copyright-text-shadow, none);
  display: inline-block;
  color: var(--my-copyright-color);
  background-color: var(--my-copyright-bg-color);
  opacity: var(--my-copyright-opacity);
  position: absolute;
  bottom: 0;
  pointer-events: none;
  z-index: 7;
  box-shadow: none;
  overflow-wrap: anywhere;
  word-break: break-word;
  hyphens: auto;
}

.piece > .visual .audio-player .copyright > div,
.square-col > .content .audio-player .copyright > div {
  padding-right: 10px;
  background-color: transparent;
  bottom: 1px;
}

.onclick-activation {
  position: relative;
  cursor: pointer;
  margin-bottom: var(--my-element-margin);
  border-radius: var(--my-image-border-radius);
}
.onclick-activation .oa-heading,
.onclick-activation .oa-notice {
  color: #fff;
  text-shadow: 1px 1px 1px #000, 0px 0px 1px #000;
  padding: 0 var(--my-default-margin);
  margin: 0;
  z-index: 7;
}
.onclick-activation .oa-icon {
  color: #fff;
  text-shadow: 1px 1px 1px #000, 0px 0px 5px rgba(0, 0, 0, 0.5);
  text-align: center;
  z-index: 6;
}
.onclick-activation .oa-icon span {
  opacity: 0.7;
  display: block;
  font-size: 60px;
}
.onclick-activation .oa-icon div {
  opacity: 1;
}
.onclick-activation .centered {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.onclick-activation:hover .oa-icon span {
  opacity: 1;
}
.onclick-activation.no-image {
  background-color: var(--my-main-theme);
}
.onclick-activation.compact .oa-heading,
.onclick-activation.compact .oa-notice {
  position: relative;
  padding-top: var(--my-default-margin-small);
  padding-bottom: var(--my-default-margin-small);
}
.onclick-activation.compact .centered {
  top: var(--my-default-margin-small);
  left: auto;
  transform: none;
}
.onclick-activation.compact .oa-icon {
  text-align: right;
  padding-right: var(--my-default-margin);
}
.onclick-activation.compact.has-icon .oa-heading {
  padding-right: 80px;
}
.onclick-activation.presized .oa-heading,
.onclick-activation.presized .oa-notice {
  position: absolute;
}
.onclick-activation.presized .oa-heading {
  top: var(--my-default-margin);
}
.onclick-activation.presized .oa-notice {
  bottom: var(--my-default-margin-small);
}
@media (max-width: 763.98px) {
  .onclick-activation.presized.no-image .oa-heading,
  .onclick-activation.presized.no-image .oa-notice, .onclick-activation.presized.has-image .oa-heading,
  .onclick-activation.presized.has-image .oa-notice {
    position: relative;
    padding-top: var(--my-default-margin-small);
    padding-bottom: var(--my-default-margin-small);
  }
  .onclick-activation.presized.no-image .centered, .onclick-activation.presized.has-image .centered {
    top: var(--my-default-margin-small);
    left: auto;
    transform: none;
  }
  .onclick-activation.presized.no-image .oa-icon, .onclick-activation.presized.has-image .oa-icon {
    text-align: right;
    padding-right: var(--my-default-margin);
  }
  .onclick-activation.presized.no-image.has-icon .oa-heading, .onclick-activation.presized.has-image.has-icon .oa-heading {
    padding-right: 80px;
  }
  .onclick-activation.presized.no-image {
    padding-bottom: 0 !important;
  }
  .onclick-activation.presized.no-image .oa-heading {
    top: auto;
  }
  .onclick-activation.presized.no-image .oa-notice {
    bottom: auto;
  }
  .onclick-activation.presized.has-image .oa-heading,
  .onclick-activation.presized.has-image .oa-notice {
    position: absolute;
  }
  .onclick-activation.presized.has-image .oa-heading {
    top: 0;
  }
  .onclick-activation.presized.has-image .oa-notice {
    bottom: 0;
  }
  .onclick-activation .oa-heading {
    font-size: 1.6rem;
    line-height: 1.1;
  }
  .onclick-activation .oa-notice {
    font-size: 1.2rem;
    line-height: 1.2;
  }
}

.element .onclick-activation:last-child {
  margin-bottom: 0;
}

/**********
 * Source file: _nav-components.scss
 */
.nav-breadcrumbs {
  list-style-type: none;
  margin: 0;
  padding: var(--my-default-margin-verysmall) 0 0 0;
}
.nav-breadcrumbs > li {
  display: inline;
  list-style: outside none none;
  position: relative;
  font-size: 1.2rem;
  color: #444;
}
.nav-breadcrumbs > li:not(:last-child)::after {
  content: "";
  display: inline-block;
  margin-right: calc(0.8 * var(--my-default-margin-verysmall));
}
.nav-breadcrumbs > li img {
  height: 0.96rem;
  width: 0.96rem;
  object-fit: contain;
  vertical-align: baseline;
}
.nav-breadcrumbs > li i + span,
.nav-breadcrumbs > li img + span {
  margin-left: calc(0.8 * var(--my-default-margin-verysmall));
}
.nav-breadcrumbs > li + li::before {
  content: "\f0da";
  display: inline-block;
  font-family: var(--my-icon-font-family);
  font-size: 1.2rem;
  margin-right: calc(1.5 * var(--my-default-margin-verysmall));
  color: #aaa;
}
.nav-breadcrumbs > li a {
  color: #444;
}
.nav-breadcrumbs > li a:hover {
  color: #444;
}

.nav-side {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  hyphens: auto;
  overflow-wrap: anywhere;
  word-break: break-word;
}
@media (max-width: 551.98px) {
  .nav-side {
    margin-left: -15px;
    margin-right: -15px;
    border-left: none;
    border-right: none;
  }
}
.nav-side > li:first-child {
  border-top: none;
}
.nav-side li {
  position: relative;
  display: block;
  border-top: 1px solid #ddd;
}
.nav-side li > a {
  cursor: pointer;
  display: block;
  padding: 6px 30px 6px 2px;
}
@media (max-width: 551.98px) {
  .nav-side li > a {
    padding-left: 15px;
  }
}
.nav-side li > a:not(.btn) {
  color: #444;
}
.nav-side li > a:not(.btn):focus, .nav-side li > a:not(.btn):hover {
  color: #990011;
  background: #ddd;
  text-decoration: none;
}
.nav-side li > a[aria-expanded][aria-controls]::after {
  font-family: var(--my-icon-font-family);
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f107";
  position: absolute;
  display: inline-block;
  right: 0;
  width: 40px;
  font-weight: normal;
  text-align: center;
  transform: rotate(0) translate(0px, 0px);
  transition: transform 0.33s;
}
.nav-side li > a[aria-expanded=true][aria-controls]::after {
  transform: rotate(-180deg) translate(0px, 2px);
  transition: transform 0.33s;
}
.nav-side li > a.nav-label + a[aria-controls] {
  position: absolute;
  display: inline-block;
  border: none;
  width: 40px;
  padding-left: 0;
  padding-right: 0;
  top: 0;
  right: 0;
}
.nav-side li > a.nav-label + a[aria-controls]::after {
  left: 0;
  width: 100%;
}
.nav-side li.currentpage > a:not(.btn) {
  background: transparent;
  color: #990011;
}
.nav-side li.currentpage > a:not(.btn):hover {
  color: #990011;
  background: #ddd;
  text-decoration: none;
}
.nav-side li ul {
  padding: 0;
  list-style: none;
}
.nav-side li > ul > li > a {
  padding-left: 30px;
}
.nav-side li > ul > li > ul > li > a {
  padding-left: 45px;
}
.nav-side li > ul > li > ul > li > ul > li > a {
  padding-left: 60px;
}
.nav-side li > ul > li > ul > li > ul > li > ul > li a {
  padding-left: 75px;
}
@media (min-width: 764px) {
  .nav-side li > ul > li > a {
    padding-left: 17px;
  }
  .nav-side li > ul > li > ul > li > a {
    padding-left: 32px;
  }
  .nav-side li > ul > li > ul > li > ul > li > a {
    padding-left: 47px;
  }
  .nav-side li > ul > li > ul > li > ul > li > ul > li a {
    padding-left: 62px;
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .nav-side li > a[aria-expanded][aria-controls]::after {
      transition: none;
    }
    .nav-side li > a[aria-expanded=true][aria-controls]::after {
      transition: none;
    }
  }
}
/**********
 * Source file: _nav-main.scss
 */
.nav-main-items {
  pointer-events: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  position: static;
  padding: 0;
  margin: 0;
  list-style: none;
  hyphens: auto;
  overflow-wrap: anywhere;
  word-break: break-word;
}
.nav-main-items li {
  display: flex;
  flex-direction: column;
}
.nav-main-items li > a {
  bottom: 0;
  color: #444;
  line-height: 24px;
  padding: 6px var(--my-nav-mobile-indent-offset);
  border-bottom: solid 1px #ddd;
  font-size: 16px;
  font-weight: 400;
}
.nav-main-items li > a:hover {
  text-decoration: none;
}
.nav-main-items > li > a {
  color: #444;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 400;
}
.nav-main-items li.expand {
  position: relative;
}
.nav-main-items li.expand > a[aria-controls]::after {
  font-family: var(--my-icon-font-family);
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f107";
  position: absolute;
  display: inline-block;
  font-weight: normal;
  font-size: 25px;
  right: 15px;
  transform: rotate(0);
  transition: transform 0.33s;
  top: 4px;
  width: 28px;
  text-align: center;
  border-radius: 0;
  line-height: 28px;
  background-color: #444;
  color: #fff;
}
.nav-main-items li.expand > a.nav-label + a[aria-controls] {
  position: absolute;
  right: 0;
  top: 0;
  height: 36px;
  display: block;
  border: none;
  padding: 6px 0;
  margin: 0;
}
.nav-main-items li.expand.expand.ed > a[aria-controls]::after {
  transform: rotate(-180deg);
  transition: transform 0.33s;
}
.nav-main-items li.active.expand.ed > a, .nav-main-items li.active > a {
  color: #444;
  background-color: #ddd;
}
.nav-main-items li.active.final > a {
  font-weight: 600;
}
.nav-main-items li.expand.ed > a {
  color: #990011;
  background-color: #ddd;
  border-color: #ddd;
}
.nav-main-items li > .nav-menu {
  transition: max-height 0.33s cubic-bezier(0, 0.65, 0.35, 1);
  max-height: 0;
  display: block;
  overflow: hidden;
}
.nav-main-items li.expand.ed > .nav-menu {
  transition: max-height 0.66s ease-in;
  max-height: 2000px;
}

html.keyboard-nav .nav-main-items li > .nav-menu {
  transition: none;
  max-height: none;
  display: none;
  overflow: hidden;
}
html.keyboard-nav .nav-main-items li.expand.ed > .nav-menu {
  display: block;
}

.nav-menu {
  box-shadow: none;
  width: auto;
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 0;
  list-style: none;
}
.nav-menu > li > a {
  padding-left: calc(var(--my-nav-mobile-indent-offset) + 2 * var(--my-nav-mobile-indent));
}
.nav-menu > li > ul > li > a {
  padding-left: calc(var(--my-nav-mobile-indent-offset) + 4 * var(--my-nav-mobile-indent));
}
.nav-menu > li > ul > li > ul > li a {
  padding-left: calc(var(--my-nav-mobile-indent-offset) + 6 * var(--my-nav-mobile-indent));
}
.nav-menu > li > ul > li > ul > li > ul > li a {
  padding-left: calc(var(--my-nav-mobile-indent-offset) + 7 * var(--my-nav-mobile-indent));
}
.nav-menu > li > ul > li > ul > li > ul > li > ul > li a {
  padding-left: calc(var(--my-nav-mobile-indent-offset) + 8 * var(--my-nav-mobile-indent));
}
.nav-menu > li > ul > li > ul > li > ul > li > ul > li > ul > li a {
  padding-left: calc(var(--my-nav-mobile-indent-offset) + 9 * var(--my-nav-mobile-indent));
}
.nav-menu > li > ul > li > ul > li > ul > li > ul > li > ul > li > ul > li a {
  padding-left: calc(var(--my-nav-mobile-indent-offset) + 10 * var(--my-nav-mobile-indent));
}
.nav-menu > li > ul > li > ul > li > ul > li > ul > li > ul > li > ul > li > ul > li a {
  padding-left: calc(var(--my-nav-mobile-indent-offset) + 11 * var(--my-nav-mobile-indent));
}
.nav-menu > li > ul > li > ul > li > ul > li > ul > li > ul > li > ul > li > ul > li > ul > li a {
  padding-left: calc(var(--my-nav-mobile-indent-offset) + 12 * var(--my-nav-mobile-indent));
}

#nav-toggle-label {
  display: inherit;
  margin-bottom: 0;
  max-width: none;
  pointer-events: auto;
}

.nav-toggle-btn {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  color: inherit;
  text-align: inherit;
  font: inherit;
  border-radius: 0;
  appearance: none;
  float: right;
  display: block;
  position: relative;
  cursor: pointer;
  z-index: 150001;
}
.nav-toggle-btn .nav-toggle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 40px;
  height: 40px;
  font-size: 0;
  text-indent: -9999px;
  box-shadow: none;
  border-radius: 0;
  border: none;
  transition: background 0.33s;
  color: var(--my-nav-toggle-fg);
  background-color: var(--my-nav-toggle-bg);
}
.nav-toggle-btn .nav-burger {
  display: block;
  position: absolute;
  top: 19px;
  left: 8px;
  right: 8px;
  height: 2px;
  background: var(--my-nav-toggle-fg);
  transition: background 0s 0.33s;
}
.nav-toggle-btn .nav-burger::before, .nav-toggle-btn .nav-burger::after {
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--my-nav-toggle-fg);
  content: "";
  transition-duration: 0.33s, 0.33s;
  transition-delay: 0.33s, 0s;
}
.nav-toggle-btn .nav-burger::before {
  top: -8px;
  /* autoprefixer: ignore next */
  transition-property: top, transform;
}
.nav-toggle-btn .nav-burger::after {
  bottom: -8px;
  /* autoprefixer: ignore next */
  transition-property: bottom, transform;
}
.nav-toggle-btn.active-nav .nav-burger {
  background: none;
}
.nav-toggle-btn.active-nav .nav-burger::before, .nav-toggle-btn.active-nav .nav-burger::after {
  transition-delay: 0s, 0.33s;
}
.nav-toggle-btn.active-nav .nav-burger::before {
  top: 0;
  transform: rotate(45deg);
}
.nav-toggle-btn.active-nav .nav-burger::after {
  bottom: 0;
  transform: rotate(-45deg);
}

.nav-main-container {
  position: fixed;
  top: 0;
  height: 100vh;
  background-color: #ddd;
  z-index: 150200;
  pointer-events: auto;
  overflow-x: hidden;
  opacity: 0;
}
.nav-main-container .nav-main-group {
  display: block;
  position: relative;
  border: 0;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  background-color: #fff;
  padding-top: calc(30px + 40px);
  min-width: calc(250px + 15px);
  width: 90vw;
}
.nav-main-container .nav-main-group.has-sidelogo {
  padding-top: 0;
}
.nav-main-container .nav-main-group.meta-seperate {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.nav-main-container .nav-main-group.meta-seperate .nav-main-items {
  flex-grow: 100;
}
.nav-main-container .nav-main-group.meta-seperate .nav-meta-items {
  margin: 0;
  padding: 0;
}
.nav-main-container .nav-main-group .nav-main-items {
  border-top: solid 1px #ddd;
}
@media (min-width: 552px) {
  .nav-main-container .nav-main-group {
    width: 500px;
  }
}
@media (min-width: 764px) {
  .nav-main-container .nav-main-group {
    width: 500px;
  }
}
@media (min-width: 1014px) {
  .nav-main-container .nav-main-group {
    width: 500px;
  }
}

.head-overlay {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  background-color: #000;
  z-index: 150199;
  opacity: 0;
  transition: opacity 0.5s, height 0.001s 0.5s, width 0.001s 0.5s;
}

html.active-nav .area-header .head-overlay {
  width: 100vw;
  height: 100vh;
  opacity: 0.75;
  transition: opacity 0.5s;
}
html.active-nav.keyboard-nav .nav-main-items *:focus,
html.active-nav.keyboard-nav .nav-main-items .dropdown > a:focus,
html.active-nav.keyboard-nav .nav-main-items .dropdown-menu > a:focus {
  outline-color: #dd0011;
  outline-style: solid;
  outline-width: 2px;
  outline-offset: -2px;
  box-shadow: none;
  z-index: 650000;
}

#mercury-page {
  position: relative;
}

html.opencms-page-editor #nav-toggle-label {
  top: calc(52px + 15px);
}
html.opencms-page-editor .nav-main-container .nav-main-group {
  padding-top: calc(30px + 40px + 52px);
}
html.opencms-page-editor .nav-main-container .nav-main-group.has-sidelogo {
  padding-top: 52px;
}

html.noscript .area-header .nav-main-items li {
  display: flex !important;
}
html.noscript .area-header .nav-main-items li > .nav-menu {
  max-height: none;
}

#nav-main-search {
  display: none !important;
}

.nav-mega-menu .nav-side {
  margin: 0;
}
.nav-mega-menu .nav-side li {
  border: 0;
}
.nav-mega-menu .nav-side li > a {
  padding: 6px 15px 6px calc(15px + 2 * var(--my-nav-mobile-indent));
}
.nav-mega-menu .element {
  margin-bottom: 0;
}

#mega-menu-editor {
  background: #aaa;
  padding-top: var(--my-default-margin);
  padding-bottom: var(--my-default-margin);
  height: calc(100vh - 52px);
}
#mega-menu-editor > .container {
  padding: 0;
}
#mega-menu-editor .nav-main-container {
  right: 0 !important;
}
#mega-menu-editor .nav-main-items,
#mega-menu-editor li.mega {
  display: block;
}
#mega-menu-editor .nav-menu.nav-mega-menu {
  max-height: none;
  display: block;
  overflow: visible;
  position: static;
  margin-left: calc(15px - var(--my-default-margin));
  animation-name: none;
}

/**********
 * Source file: _opening-hours.scss
 */
.opening-hours {
  --my-hours-spacing: 30px;
}
.opening-hours .hours-table {
  margin-bottom: var(--my-paragraph-margin-bottom);
}
.opening-hours .hours-table .hours-hr {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--my-paragraph-margin-bottom);
}
.opening-hours .hours-table .hours-days {
  font-weight: var(--my-font-weight-bold);
}

@media (min-width: 552px) {
  .opening-hours .hours-table {
    display: table;
  }
  .opening-hours .hours-table .hours-time {
    display: table-row;
  }
  .opening-hours .hours-table .hours-label,
  .opening-hours .hours-table .hours-timerange {
    display: table-cell;
  }
  .opening-hours .hours-table .hours-timerange {
    padding-right: var(--my-hours-spacing);
  }
}
@media (min-width: 764px) {
  .hours-heading::before {
    display: block;
    clear: both;
    content: "";
  }
  .opening-hours .hours-table .hours-hr {
    display: table-row;
    margin-bottom: 0;
  }
  .opening-hours .hours-table .hours-days,
  .opening-hours .hours-table .hours-openings {
    display: table-cell;
  }
  .opening-hours .hours-table .hours-times {
    display: table;
  }
  .opening-hours .hours-table .hours-days {
    padding-right: var(--my-hours-spacing);
  }
}
@media (min-width: 1014px) {
  .area-narrow .opening-hours .hours-table .hours-hr {
    display: flex;
    margin-bottom: var(--my-paragraph-margin-bottom);
  }
  .area-narrow .opening-hours .hours-table .hours-days,
  .area-narrow .opening-hours .hours-table .hours-openings,
  .area-narrow .opening-hours .hours-table .hours-label,
  .area-narrow .opening-hours .hours-table .hours-timerange,
  .area-narrow .opening-hours .hours-table .hours-times {
    display: block;
  }
  .area-narrow .opening-hours .hours-table .hours-days,
  .area-narrow .opening-hours .hours-table .hours-timerange {
    padding-right: 0;
  }
}
/**********
 * Source file: _pagination.scss
 */
.pagination {
  --my-pag-bg: var(--my-body-bg);
  --my-pag-color: var(--my-body-color);
  --my-pag-font-size: var(--my-body-font-size);
  --my-pag-line-height: var(--my-body-line-height);
  --my-pag-border-color: var(--my-highlight-border-color);
  --my-pag-border-radius: var(--my-btn-border-radius);
  --my-pag-border: 1px solid var(--my-highlight-border-color);
  --my-pag-item-jump-spacing: 1rem;
  --my-pag-item-margin: 0 -1px 0 0;
  --my-pag-item-padding: 5px 12px;
  --my-pag-disabled-fg-color: var(--my-pag-border-color);
  --my-pag-active-fg-color: var(--my-main-theme-fg);
  --my-pag-active-bg-color: var(--my-main-theme);
  --my-pag-hover-fg-color: var(--my-main-theme-hover-fg);
  --my-pag-hover-bg-color: var(--my-main-theme-hover);
  --my-pag-font-family: var(--my-body-font-family);
  display: initial;
  margin: 0;
  padding-left: 0;
}
.pagination > li {
  display: inline-block;
}
.pagination > li > a {
  display: inline-block;
  position: relative;
  color: var(--my-pag-color);
  background-color: var(--my-pag-bg);
  border: 1px solid var(--my-highlight-border-color);
  margin: var(--my-pag-item-margin);
  padding: var(--my-pag-item-padding);
  text-align: center;
  text-decoration: none;
  font-family: var(--my-pag-font-family);
  font-size: var(--my-pag-font-size);
  line-height: var(--my-pag-line-height);
  min-width: calc(var(--my-pag-font-size) * var(--my-pag-line-height) + 10px);
}
.pagination > li > a .number {
  font-weight: 300;
}
.pagination > li.previous {
  margin-right: var(--my-pag-item-jump-spacing);
}
.pagination > li.next {
  margin-left: var(--my-pag-item-jump-spacing);
}
.pagination > li.first.gap::after {
  content: "...";
  display: inline-block;
  margin: 0 var(--my-pag-item-jump-spacing);
}
.pagination > li:first-child > a {
  margin-left: 0;
  border-top-left-radius: var(--my-pag-border-radius);
  border-bottom-left-radius: var(--my-pag-border-radius);
}
.pagination > li:last-child > a {
  margin-right: 0;
  border-top-right-radius: var(--my-pag-border-radius);
  border-bottom-right-radius: var(--my-pag-border-radius);
}
.pagination > li > a:hover,
.pagination > li.active > a:hover {
  color: var(--my-pag-hover-fg-color);
  background: var(--my-pag-hover-bg-color);
  border-color: var(--my-pag-hover-bg-color);
}
.pagination > li.active > a {
  color: var(--my-pag-active-fg-color);
  background-color: var(--my-pag-active-bg-color);
  border-color: var(--my-pag-active-bg-color);
}
.pagination > .disabled {
  cursor: not-allowed;
}
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
  color: var(--my-pag-disabled-fg-color);
  background-color: var(--my-pag-bg);
  border-color: var(--my-pag-disabled-fg-color);
  pointer-events: none;
}

/**********
 * Source file: _piece.scss
 */
.piece {
  --my-gutter-x: 30px;
  --my-gutter-factor: 1;
  --my-visual-last-margin-top: var(--my-paragraph-margin-bottom);
  --my-visual-margin-bottom: var(--my-paragraph-margin-bottom);
  --my-text-to-link-margin: var(--my-paragraph-margin-bottom);
  --my-piece-paragraph-margin-bottom: var(--my-paragraph-margin-bottom);
  --my-piece-headings-margin-bottom: var(--my-headings-margin-bottom);
  --my-intro-headings-margin-bottom: calc(0.25 * var(--my-paragraph-margin-bottom));
  --my-piece-width: 100%;
  --my-piece-visual-size: var(--my-piece-width);
  --my-piece-body-size: var(--my-piece-width);
  --my-piece-visual-size-adjust: 0px;
  --my-piece-body-size-adjust: 0px;
  display: block;
  position: relative;
}
.piece > .visual {
  margin-bottom: var(--my-visual-margin-bottom);
  width: 100%;
}
.piece > .visual .copyright {
  position: relative;
}
.piece > .visual .copyright > div {
  line-height: 1;
  padding: var(--my-copyright-padding);
  font-family: var(--my-copyright-font-family);
  font-size: var(--my-copyright-font-size);
  border-radius: var(--my-copyright-border-radius);
  text-align: var(--my-copyright-pos-right, right);
  right: var(--my-copyright-pos-right, 0);
  text-shadow: var(--my-copyright-text-shadow, none);
  display: inline-block;
  color: var(--my-copyright-color);
  background-color: var(--my-copyright-bg-color);
  opacity: var(--my-copyright-opacity);
  position: absolute;
  bottom: 0;
  pointer-events: none;
  z-index: 7;
  box-shadow: none;
  overflow-wrap: anywhere;
  word-break: break-word;
  hyphens: auto;
}
.piece > .visual .subtitle {
  margin-top: calc(0.5 * var(--my-paragraph-margin-bottom));
  line-height: 1.15;
  font-style: italic;
  font-size: 13px;
}
.piece > .visual > a {
  display: block;
}
.piece.disabled {
  background-image: repeating-linear-gradient(135deg, transparent, transparent 10px, rgba(0, 0, 0, 0.1) 10px, rgba(0, 0, 0, 0.1) 20px);
}
.piece.full > .visual {
  margin-left: auto;
  margin-right: auto;
}
.piece.full.align-right > .visual {
  margin-right: 0;
}
.piece.only-visual > .visual {
  margin-bottom: 0;
}
.piece.pvl > .visual {
  margin-bottom: 0;
}
.piece.pvl > .visual + .link {
  margin-top: var(--my-visual-last-margin-top);
}
.piece.pvl > .body + .visual,
.piece.pvl > .link + .visual {
  margin-top: var(--my-visual-last-margin-top);
}
.piece.paj .heading > .piece-heading,
.piece.paj > .visual > .subtitle,
.piece.paj > .body + .link,
.piece.paj > .body .link,
.piece.paj > .body .text {
  text-align: justify;
}
.piece.pac .heading > .piece-heading,
.piece.pac > .heading + .link,
.piece.pac > .visual > .subtitle,
.piece.pac > .body + .link,
.piece.pac > .body .link,
.piece.pac > .body .text {
  text-align: center;
}
.piece.par .heading > .piece-heading,
.piece.par .heading > .piece-heading-link,
.piece.par > .heading + .link,
.piece.par > .visual > .subtitle,
.piece.par > .body + .link,
.piece.par > .body .link,
.piece.par > .body .text {
  text-align: right;
}
.piece.pmv > .visual {
  min-width: 32px;
}
.piece .heading {
  hyphens: auto;
  overflow-wrap: anywhere;
  word-break: break-word;
}
.piece .heading > .piece-heading {
  text-overflow: ellipsis;
  overflow: hidden;
}
.piece .heading > .piece-heading-link {
  display: block;
}
.piece .heading > .piece-heading-link:hover {
  text-decoration: none;
}
.piece .heading + .link {
  margin-top: var(--my-text-to-link-margin);
}
.piece .heading:last-child > .piece-heading-link > .piece-heading,
.piece .heading:last-child > .piece-heading {
  margin-bottom: 0;
}
.piece .piece-heading {
  margin-bottom: var(--my-headings-margin-bottom);
}
.piece > .body .text {
  hyphens: auto;
  overflow-wrap: anywhere;
  word-break: break-word;
}
.piece > .body .text > .detail-content > *,
.piece > .body .text > a > *,
.piece > .body .text > * {
  margin-bottom: var(--my-piece-paragraph-margin-bottom);
}
.piece > .body .text > .detail-content > *:last-child,
.piece > .body .text > a > *:last-child,
.piece > .body .text > *:last-child {
  margin-bottom: 0;
}
.piece > .body .text h1, .piece > .body .text h2, .piece > .body .text h3, .piece > .body .text h4, .piece > .body .text h5, .piece > .body .text h6 {
  margin-bottom: var(--my-piece-headings-margin-bottom);
}
.piece > .body + .link,
.piece > .body .text + .link {
  margin-top: var(--my-text-to-link-margin);
}
.piece.element.type-section:not(.box).only-heading {
  margin-bottom: var(--my-headings-margin-bottom);
}
.piece.element.type-section:not(.box).only-text:has(+ .piece.element.type-section.only-text:not(.box)), .piece.element.type-section:not(.box).only-text:has(+ .piece.element.type-section.pnh:not(.box):not(.flex)), .piece.element.type-section:not(.box).phb:not(.pvl):not(.flex):has(+ .piece.element.type-section.only-text:not(.box)), .piece.element.type-section:not(.box).phb:not(.pvl):not(.flex):has(+ .piece.element.type-section.pnh:not(.box):not(.flex)) {
  margin-bottom: var(--my-piece-paragraph-margin-bottom);
}
.piece.element.type-section:not(.box).only-text:has(+ .piece.element.type-section.only-text:not(.box)).phl, .piece.element.type-section:not(.box).only-text:has(+ .piece.element.type-section.pnh:not(.box):not(.flex)).phl, .piece.element.type-section:not(.box).phb:not(.pvl):not(.flex):has(+ .piece.element.type-section.only-text:not(.box)).phl, .piece.element.type-section:not(.box).phb:not(.pvl):not(.flex):has(+ .piece.element.type-section.pnh:not(.box):not(.flex)).phl {
  margin-bottom: var(--my-text-to-link-margin);
}
.piece > .visual {
  width: var(--my-piece-visual-size);
}
@media (max-width: 763.98px) {
  .piece.p-xs-1 {
    --my-piece-visual-size: calc((var(--my-piece-width) + var(--my-gutter-x)) * 0.0833333333 - (var(--my-gutter-factor) * var(--my-gutter-x) )  + var(--my-piece-visual-size-adjust));
  }
  .piece.p-xs-2 {
    --my-piece-visual-size: calc((var(--my-piece-width) + var(--my-gutter-x)) * 0.1666666667 - (var(--my-gutter-factor) * var(--my-gutter-x) )  + var(--my-piece-visual-size-adjust));
  }
  .piece.p-xs-3 {
    --my-piece-visual-size: calc((var(--my-piece-width) + var(--my-gutter-x)) * 0.25 - (var(--my-gutter-factor) * var(--my-gutter-x) )  + var(--my-piece-visual-size-adjust));
  }
  .piece.p-xs-4 {
    --my-piece-visual-size: calc((var(--my-piece-width) + var(--my-gutter-x)) * 0.3333333333 - (var(--my-gutter-factor) * var(--my-gutter-x) )  + var(--my-piece-visual-size-adjust));
  }
  .piece.p-xs-5 {
    --my-piece-visual-size: calc((var(--my-piece-width) + var(--my-gutter-x)) * 0.4166666667 - (var(--my-gutter-factor) * var(--my-gutter-x) )  + var(--my-piece-visual-size-adjust));
  }
  .piece.p-xs-6 {
    --my-piece-visual-size: calc((var(--my-piece-width) + var(--my-gutter-x)) * 0.5 - (var(--my-gutter-factor) * var(--my-gutter-x) )  + var(--my-piece-visual-size-adjust));
  }
  .piece.p-xs-7 {
    --my-piece-visual-size: calc((var(--my-piece-width) + var(--my-gutter-x)) * 0.5833333333 - (var(--my-gutter-factor) * var(--my-gutter-x) )  + var(--my-piece-visual-size-adjust));
  }
  .piece.p-xs-8 {
    --my-piece-visual-size: calc((var(--my-piece-width) + var(--my-gutter-x)) * 0.6666666667 - (var(--my-gutter-factor) * var(--my-gutter-x) )  + var(--my-piece-visual-size-adjust));
  }
  .piece.p-xs-9 {
    --my-piece-visual-size: calc((var(--my-piece-width) + var(--my-gutter-x)) * 0.75 - (var(--my-gutter-factor) * var(--my-gutter-x) )  + var(--my-piece-visual-size-adjust));
  }
  .piece.p-xs-10 {
    --my-piece-visual-size: calc((var(--my-piece-width) + var(--my-gutter-x)) * 0.8333333333 - (var(--my-gutter-factor) * var(--my-gutter-x) )  + var(--my-piece-visual-size-adjust));
  }
  .piece.p-xs-11 {
    --my-piece-visual-size: calc((var(--my-piece-width) + var(--my-gutter-x)) * 0.9166666667 - (var(--my-gutter-factor) * var(--my-gutter-x) )  + var(--my-piece-visual-size-adjust));
  }
  .piece.p-xs-0 > .visual {
    display: none;
  }
}

html.keyboard-nav .piece .heading > .piece-heading {
  overflow: visible;
}

.flex-col > .piece.flex {
  flex-grow: 1;
}
.flex-col > .piece.full:only-child,
.flex-col > .type-contact.box:only-child,
.flex-col > .pivot:not(.piece).box:only-child {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

@media (min-width: 764px) {
  .piece {
    --my-piece-body-size: calc(var(--my-piece-width) - var(--my-piece-visual-size) - 1px);
  }
  .piece.full.only-visual::before, .piece.phv::before {
    display: block;
    clear: both;
    content: "";
  }
  .piece.par.only-link > .link {
    text-align: right;
  }
  .piece.flex {
    --my-gutter-factor: 0.5;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
  .piece.flex > .heading,
  .piece.flex > .link {
    flex: auto;
  }
  .piece.flex > .heading,
  .piece.flex > .link,
  .piece.flex > .visual,
  .piece.flex > .body {
    position: relative;
    width: 100%;
  }
  .piece.flex > .visual {
    margin-bottom: 0;
    flex: 0 0 var(--my-piece-visual-size);
  }
  .piece.flex > .body {
    flex: 0 0 var(--my-piece-body-size);
  }
  .piece.flex.right > .visual {
    order: 13;
    padding-left: calc(var(--my-gutter-factor) * var(--my-gutter-x));
  }
  .piece.flex.right > .body {
    padding-right: calc(var(--my-gutter-factor) * var(--my-gutter-x));
  }
  .piece.flex.left > .visual {
    padding-right: calc(var(--my-gutter-factor) * var(--my-gutter-x));
  }
  .piece.flex.left > .body {
    padding-left: calc(var(--my-gutter-factor) * var(--my-gutter-x));
  }
  .piece.flex.p-md-7 .btn, .piece.flex.p-md-8 .btn, .piece.flex.p-md-9 .btn, .piece.flex.p-md-10 .btn, .piece.flex.p-md-11 .btn {
    white-space: normal;
  }
  .piece.float.left > .visual {
    float: left;
    margin-right: var(--my-gutter-x);
  }
  .piece.float.left > .body ul {
    display: flex;
    flex-direction: column;
  }
  .piece.float.right > .visual {
    float: right;
    margin-left: var(--my-gutter-x);
  }
  .piece.p-md-1 {
    --my-piece-visual-size: calc((var(--my-piece-width) + var(--my-gutter-x)) * 0.0833333333 - (var(--my-gutter-factor) * var(--my-gutter-x) )  + var(--my-piece-visual-size-adjust));
  }
  .piece.p-md-2 {
    --my-piece-visual-size: calc((var(--my-piece-width) + var(--my-gutter-x)) * 0.1666666667 - (var(--my-gutter-factor) * var(--my-gutter-x) )  + var(--my-piece-visual-size-adjust));
  }
  .piece.p-md-3 {
    --my-piece-visual-size: calc((var(--my-piece-width) + var(--my-gutter-x)) * 0.25 - (var(--my-gutter-factor) * var(--my-gutter-x) )  + var(--my-piece-visual-size-adjust));
  }
  .piece.p-md-4 {
    --my-piece-visual-size: calc((var(--my-piece-width) + var(--my-gutter-x)) * 0.3333333333 - (var(--my-gutter-factor) * var(--my-gutter-x) )  + var(--my-piece-visual-size-adjust));
  }
  .piece.p-md-5 {
    --my-piece-visual-size: calc((var(--my-piece-width) + var(--my-gutter-x)) * 0.4166666667 - (var(--my-gutter-factor) * var(--my-gutter-x) )  + var(--my-piece-visual-size-adjust));
  }
  .piece.p-md-6 {
    --my-piece-visual-size: calc((var(--my-piece-width) + var(--my-gutter-x)) * 0.5 - (var(--my-gutter-factor) * var(--my-gutter-x) )  + var(--my-piece-visual-size-adjust));
  }
  .piece.p-md-7 {
    --my-piece-visual-size: calc((var(--my-piece-width) + var(--my-gutter-x)) * 0.5833333333 - (var(--my-gutter-factor) * var(--my-gutter-x) )  + var(--my-piece-visual-size-adjust));
  }
  .piece.p-md-8 {
    --my-piece-visual-size: calc((var(--my-piece-width) + var(--my-gutter-x)) * 0.6666666667 - (var(--my-gutter-factor) * var(--my-gutter-x) )  + var(--my-piece-visual-size-adjust));
  }
  .piece.p-md-9 {
    --my-piece-visual-size: calc((var(--my-piece-width) + var(--my-gutter-x)) * 0.75 - (var(--my-gutter-factor) * var(--my-gutter-x) )  + var(--my-piece-visual-size-adjust));
  }
  .piece.p-md-10 {
    --my-piece-visual-size: calc((var(--my-piece-width) + var(--my-gutter-x)) * 0.8333333333 - (var(--my-gutter-factor) * var(--my-gutter-x) )  + var(--my-piece-visual-size-adjust));
  }
  .piece.p-md-11 {
    --my-piece-visual-size: calc((var(--my-piece-width) + var(--my-gutter-x)) * 0.9166666667 - (var(--my-gutter-factor) * var(--my-gutter-x) )  + var(--my-piece-visual-size-adjust));
  }
  .piece.p-md-0 > .visual {
    display: none;
  }
  .piece.p-md-0.flex > .body {
    flex: auto;
  }
}
.intro-headline > span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.intro-headline > .intro {
  line-height: 1.25;
  font-size: 65%;
  font-weight: 600;
  color: var(--my-intro-headings-color);
  margin-bottom: var(--my-intro-headings-margin-bottom);
}

a .intro-headline {
  text-decoration: none;
}
a .intro-headline > .headline {
  text-decoration: unset;
}
a .intro-headline:hover {
  text-decoration: none;
}
a .intro-headline:hover > .headline {
  text-decoration: unset;
}

.element .body-text {
  hyphens: auto;
  overflow-wrap: anywhere;
  word-break: break-word;
}
.element .body-text > * {
  margin-bottom: var(--my-paragraph-margin-bottom);
}
.element .body-text > *:last-child {
  margin-bottom: 0;
}

/**********
 * Source file: _poi.scss
 */
@media (min-width: 764px) {
  .type-poi .poi-head {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.facility-icons {
  line-height: 1;
  margin-top: var(--my-paragraph-margin-bottom);
  margin-bottom: var(--my-paragraph-margin-bottom);
  hyphens: initial;
  --my-acc-icon-size: 3.5rem;
  --my-acc-font-size: 2rem;
  --my-acc-margin: var(--my-ws-margin);
}
.facility-icons .acc-icon {
  position: relative;
  width: var(--my-acc-icon-size);
  padding: 0 0 var(--my-acc-icon-size) 0 !important;
  display: inline-block;
  color: var(--my-main-theme-fg);
  background-color: var(--my-main-theme);
  border-radius: var(--my-border-radius);
  margin-right: var(--my-acc-margin);
  font-size: var(--my-acc-font-size);
}
.facility-icons .acc-icon:last-of-type {
  margin-right: 0;
}
.facility-icons .acc-icon .ico {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  font-weight: normal;
  margin-right: 0;
  width: auto;
}

/**********
 * Source file: _privacy-policy.scss
 */
.pp-banner .container {
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 100vh;
}
.pp-banner .container > div:not(:last-of-type) {
  margin-bottom: var(--my-default-margin-small);
}
.pp-banner .banner {
  --my-body-bg: #222;
  --my-body-bg-rgb: 34, 34, 34;
  --my-body-color: #fff;
  --my-body-color-rgb: 255, 255, 255;
  --my-link-color: #cccccc;
  --my-link-color-rgb: 204, 204, 204;
  --my-link-hover-color: #fff;
  --my-link-hover-color-rgb: 255, 255, 255;
  --my-link-fg-color: #444;
  --my-link-hover-fg-color: #444;
  --my-main-theme: var(--my-link-color);
  --my-main-theme-rgb: var(--my-link-color-rgb);
  --my-main-theme-fg: var(--my-link-fg-color);
  --my-main-theme-hover: var(--my-link-hover-color);
  --my-main-theme-hover-fg: var(--my-link-hover-fg-color);
  --my-headings-color: var(--my-body-color);
  --my-intro-headings-color: var(--my-main-theme);
  --my-highlight-color: rgb(var(--my-highlight-color-rgb), 0.25);
  --my-highlight-fg-color: var(--my-body-color);
  --my-highlight-border-color: var(--my-body-color);
  --my-highlight-border-fg-color: var(--my-body-bg);
  --my-btn-bg: var(--my-link-color);
  --my-btn-fg: var(--my-link-fg-color);
  --my-btn-hover-bg: var(--my-link-hover-color);
  --my-btn-hover-fg: var(--my-link-hover-fg-color);
  --my-btn-border: var(--my-btn-bg);
  --my-btn-hover-border: var(--my-btn-hover-bg);
  color: var(--my-body-color);
  background-color: var(--my-body-bg);
  display: none;
  box-shadow: 0px -3px 10px 2px rgba(0, 0, 0, 0.3);
  opacity: 0.95;
  padding: 20px;
  width: 100vw;
}
.pp-banner .banner.fixed {
  z-index: 150100;
  left: 0;
  position: fixed;
}
.pp-banner .banner.fixed.top {
  top: 0;
}
.pp-banner .banner.fixed.bottom {
  bottom: 0;
}
.pp-banner .title {
  font-size: 2.4rem;
  line-height: 1.25;
  order: 1;
}
.pp-banner .message {
  order: 2;
}
.pp-banner .message p:last-of-type {
  margin-bottom: 0;
}
.pp-banner .message a {
  font-weight: 600;
}
.pp-banner .selection {
  display: flex;
  align-items: center;
  order: 3;
}
.pp-banner .selection .options {
  flex-grow: 1;
}
.pp-banner .selection .options label {
  margin-right: calc(2 * var(--my-ws-margin));
  margin-bottom: 0;
  cursor: pointer;
  position: relative;
  display: inline-flex;
  align-items: center;
}
.pp-banner .selection .options label input {
  position: absolute;
  left: -9999px;
}
.pp-banner .selection .options label i {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 20px;
  height: 20px;
  outline: none;
  background-color: #fff;
  margin-right: 10px;
}
.pp-banner .selection .options label input + i::after {
  font-family: var(--my-icon-font-family);
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f00c";
  position: absolute;
  opacity: 0;
  top: -4px;
  left: -1px;
  font-size: 23px;
  line-height: 23px;
  font-weight: normal;
  text-align: center;
}
.pp-banner .selection .options label input:checked + i::after {
  opacity: 1;
  color: #64d241;
}
.pp-banner .selection .options label input[disabled] + i {
  background-color: #ddd;
}
.pp-banner .selection .options label input[disabled] + i::after {
  color: #444;
}
.pp-banner .buttons .btn {
  border: none;
  font-size: 1.8rem;
  min-width: 100px;
}
.pp-banner .buttons .btn:last-of-type {
  margin-left: calc(2 * var(--my-ws-margin));
}
.pp-banner .buttons .btn-accept {
  --my-btn-fg: #fff;
  --my-btn-bg: #64d241;
  --my-btn-hover-fg: #fff;
  --my-btn-hover-bg: #70ef4a;
}
.pp-banner .buttons .btn-save {
  --my-btn-fg: #fff;
  --my-btn-bg: #3c3c3c;
  --my-btn-hover-fg: #fff;
  --my-btn-hover-bg: #70ef4a;
}
.pp-banner .btn-close {
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 2.5rem;
  line-height: 1;
  padding: 5px 8px;
  min-width: auto;
  border: none;
}
.pp-banner .btn-close:not(:hover):not(:focus) {
  color: var(--my-body-color);
  background-color: transparent;
}
.pp-banner .links {
  order: 4;
  font-size: 80%;
}
.pp-banner .links ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.pp-banner .links li {
  display: inline;
  margin: 0;
}
.pp-banner .links li + li {
  margin-left: calc(2 * var(--my-ws-margin));
}
.pp-banner .links a {
  font-weight: 600;
}
@media (max-width: 1013.98px) {
  .pp-banner .title {
    text-align: center;
    font-size: 1.8rem;
    max-width: 100%;
    display: block;
    float: none;
  }
  .pp-banner .container {
    padding: 0;
  }
  .pp-banner .selection {
    font-size: 1.25rem;
    flex-direction: column;
    align-items: flex-start;
  }
  .pp-banner .selection .options {
    flex-grow: initial;
    margin-bottom: var(--my-default-margin-small);
  }
  .pp-banner .buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 100%;
  }
  .pp-banner .buttons .btn {
    margin: 0;
    font-size: 1.5rem;
  }
  .pp-banner .btn-close {
    font-size: 1.5rem;
    right: 10px;
  }
  .pp-banner .message {
    font-size: 1.2rem;
    line-height: 1.25;
    text-align: justify;
    hyphens: auto;
  }
  .pp-banner .links {
    font-size: 1rem;
  }
}
@media (max-width: 763.98px) {
  .pp-banner .banner {
    padding: 15px 15px 10px 15px;
  }
  .pp-banner .selection {
    order: 6;
    margin-bottom: 0 !important;
  }
  .pp-banner .buttons {
    justify-content: space-around;
  }
  .pp-banner .buttons .btn {
    margin-bottom: 5px;
  }
  .pp-banner .btn-close {
    right: 5px;
    top: 8px;
    padding: 4px 6px;
  }
  .pp-banner .links {
    margin-bottom: var(--my-default-margin-small);
  }
}
@media (max-width: 551.98px) {
  .pp-banner .selection .options {
    display: flex;
    flex-direction: column;
  }
  .pp-banner .selection .options label + label {
    margin-top: var(--my-default-margin-small);
  }
  .pp-banner .message {
    margin-bottom: var(--my-default-margin-verysmall) !important;
  }
  .pp-banner .buttons .btn {
    font-size: 1.25rem;
    padding: 0.2rem 0.8rem;
  }
  .pp-banner .buttons .btn:last-of-type {
    margin-left: 0;
  }
  .pp-banner .links li {
    display: list-item;
  }
  .pp-banner .links li + li {
    margin-left: 0;
  }
}

#privacy-policy-banner-noscript .banner {
  display: block;
}
#privacy-policy-banner-noscript .banner .message {
  font-size: 2.8rem;
  line-height: 1.25;
}

html.opencms-page-editor .pp-toggle .toggle-check + .toggle-label, .pp-toggle .toggle-check[disabled] + .toggle-label {
  pointer-events: none;
  cursor: auto;
}
html.opencms-page-editor .pp-toggle .toggle-check + .toggle-label .toggle-inner::before, .pp-toggle .toggle-check[disabled] + .toggle-label .toggle-inner::before,
html.opencms-page-editor .pp-toggle .toggle-check + .toggle-label .toggle-inner::after,
.pp-toggle .toggle-check[disabled] + .toggle-label .toggle-inner::after {
  background-color: #ddd !important;
  color: #444 !important;
}
html.opencms-page-editor .pp-toggle .toggle-check + .toggle-label .toggle-inner::before, .pp-toggle .toggle-check[disabled] + .toggle-label .toggle-inner::before,
html.opencms-page-editor .pp-toggle .toggle-check + .toggle-label .toggle-inner::after,
.pp-toggle .toggle-check[disabled] + .toggle-label .toggle-inner::after {
  background-image: repeating-linear-gradient(135deg, transparent, transparent 10px, rgba(0, 0, 0, 0.1) 10px, rgba(0, 0, 0, 0.1) 20px);
}

.type-privacy-policy > div + div {
  margin-top: calc(2 * var(--my-paragraph-margin-bottom));
}

.pp-toggle {
  position: relative;
  display: flex;
}
.pp-toggle .toggle-label {
  display: block;
  cursor: pointer;
  min-height: 4.2rem;
  margin-bottom: 0;
}
.pp-toggle .toggle-label:hover .toggle-inner::before, .pp-toggle .toggle-label:hover .toggle-inner::after {
  color: var(--my-body-color) !important;
  background-color: var(--my-body-bg) !important;
}
.pp-toggle .toggle-text {
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 1;
  margin-left: calc(2 * var(--my-ws-margin));
}
.pp-toggle .toggle-box {
  width: 20rem;
  position: relative;
  display: block;
  overflow: hidden;
  border: 0.1rem solid #444;
  border-radius: var(--my-border-radius);
}
.pp-toggle .toggle-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
}
.pp-toggle .toggle-inner::before, .pp-toggle .toggle-inner::after {
  display: block;
  float: left;
  width: 50%;
  height: 4rem;
  line-height: 4rem;
  padding: 0;
  font-size: 18px;
  font-weight: 600;
  box-sizing: border-box;
}
.pp-toggle .toggle-inner::before {
  content: attr(data-checked) " ";
  padding-left: 10px;
  background-color: var(--my-btn-bg);
  color: var(--my-btn-fg);
}
.pp-toggle .toggle-inner::after {
  content: attr(data-unchecked) " ";
  padding-right: 10px;
  background-color: #ddd;
  color: #444;
  text-align: right;
}
.pp-toggle .toggle-slider {
  display: block;
  width: 3.6rem;
  margin: 0.2rem;
  background-color: #ddd;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 15.8rem;
  border: 0.1rem solid #444;
  border-radius: calc(0.5 * var(--my-border-radius));
}
.pp-toggle.animated .toggle-inner {
  transition: margin 0.3s ease-in 0s;
}
.pp-toggle.animated .toggle-slider {
  transition: all 0.3s ease-in 0s;
}
.pp-toggle .toggle-check {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}
.pp-toggle .toggle-check:checked + .toggle-label .toggle-inner {
  margin-left: 0 !important;
}
.pp-toggle .toggle-check:checked + .toggle-label .toggle-slider {
  right: 0 !important;
}
@media (max-width: 763.98px) {
  .pp-toggle {
    flex-direction: column;
  }
  .pp-toggle .toggle-text {
    margin-left: 0;
    margin-bottom: var(--my-default-margin-small);
  }
  .pp-toggle .toggle-label {
    order: 1;
  }
}

.pp-element {
  padding: var(--my-box-padding);
  border-radius: var(--my-border-radius);
  color: var(--my-highlight-fg-color);
  background-color: var(--my-highlight-color);
}

#pp-matomo-jst > div + div {
  margin-top: var(--my-default-margin-small);
}
#pp-matomo-jst .jst-msg {
  hyphens: auto;
  overflow-wrap: anywhere;
  word-break: break-word;
}
#pp-matomo-jst .jst-msg > *:last-child {
  margin-bottom: 0;
}
#pp-matomo-jst .jst-btn label.checkbox {
  display: flex;
  align-items: center;
  gap: var(--my-default-margin-small);
  font-weight: 600;
  margin-bottom: 0;
  cursor: pointer;
}
#pp-matomo-jst .jst-btn label.checkbox input {
  position: absolute;
  left: -9999px;
}
#pp-matomo-jst .jst-btn label.checkbox i {
  border-radius: var(--my-btn-border-radius);
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  outline: none;
  border-width: 1px;
  border-style: solid;
  border-color: var(--my-form-border-color);
  background-color: var(--my-white);
}
#pp-matomo-jst .jst-btn label.checkbox span {
  display: block;
  flex-shrink: 10;
}
#pp-matomo-jst .jst-btn label.checkbox input:checked + i {
  border-color: var(--my-form-border-color);
}
#pp-matomo-jst .jst-btn label.checkbox input:checked + i::after {
  font-family: var(--my-icon-font-family);
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f00c";
  color: var(--my-main-theme);
  position: absolute;
  z-index: 10;
  top: -5px;
  left: -1px;
  font-size: 23px;
  line-height: 23px;
  font-weight: normal;
  text-align: center;
  overflow: visible;
}

html.keyboard-nav .pp-banner .selection input:focus + i {
  outline-color: #dd0011;
  outline-style: solid;
  outline-width: 3px;
  outline-offset: 2px;
  box-shadow: none;
  z-index: 650000;
}
html.keyboard-nav .pp-toggle .toggle-check:focus + .toggle-label .toggle-box {
  outline-color: #dd0011;
  outline-style: solid;
  outline-width: 3px;
  outline-offset: 2px;
  box-shadow: none;
  z-index: 650000;
}

#mercury-page .external-cookie-notice {
  --my-body-bg: #eee;
  --my-body-bg-rgb: 238, 238, 238;
  --my-body-color: #444;
  --my-body-color-rgb: 68, 68, 68;
  --my-link-color: #990011;
  --my-link-color-rgb: 153, 0, 17;
  --my-link-hover-color: #dd0011;
  --my-link-hover-color-rgb: 221, 0, 17;
  --my-link-fg-color: #fff;
  --my-link-hover-fg-color: #fff;
  --my-main-theme: var(--my-link-color);
  --my-main-theme-rgb: var(--my-link-color-rgb);
  --my-main-theme-fg: var(--my-link-fg-color);
  --my-main-theme-hover: var(--my-link-hover-color);
  --my-main-theme-hover-fg: var(--my-link-hover-fg-color);
  --my-headings-color: var(--my-body-color);
  --my-intro-headings-color: var(--my-main-theme);
  --my-highlight-color: rgb(var(--my-highlight-color-rgb), 0.25);
  --my-highlight-fg-color: var(--my-body-color);
  --my-highlight-border-color: var(--my-body-color);
  --my-highlight-border-fg-color: var(--my-body-bg);
  --my-btn-bg: var(--my-link-color);
  --my-btn-fg: var(--my-link-fg-color);
  --my-btn-hover-bg: var(--my-link-hover-color);
  --my-btn-hover-fg: var(--my-link-hover-fg-color);
  --my-btn-border: var(--my-btn-bg);
  --my-btn-hover-border: var(--my-btn-hover-bg);
  color: var(--my-body-color);
  background-color: var(--my-body-bg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: scroll !important;
  padding: var(--my-box-padding);
  scrollbar-width: none;
  cursor: auto;
  font: 300 1.2rem/1.5 "Fira Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  border-radius: var(--my-border-radius);
  hyphens: auto;
  overflow-wrap: anywhere;
  word-break: break-word;
}
@media (min-width: 1014px) {
  #mercury-page .external-cookie-notice {
    font-size: 1.5rem;
  }
}
#mercury-page .external-cookie-notice.cookie-tiny-font {
  justify-content: flex-start;
  font-size: 1.2rem;
  cursor: pointer;
}
#mercury-page .external-cookie-notice.cookie-tiny-font .cookie-message {
  margin-bottom: 0;
}
#mercury-page .external-cookie-notice .cookie-header {
  font-weight: var(--my-font-weight-headings);
  font-family: var(--my-font-family-headings);
  font-style: var(--my-font-style-headings);
  font-size: 125%;
}
#mercury-page .external-cookie-notice .cookie-header,
#mercury-page .external-cookie-notice .cookie-message,
#mercury-page .external-cookie-notice .cookie-switch {
  margin-bottom: var(--my-default-margin-small);
}
#mercury-page .external-cookie-notice .cookie-footer {
  font-size: 85%;
}
#mercury-page .external-cookie-notice .cookie-footer > *:last-child {
  margin-bottom: 0;
}
#mercury-page .external-cookie-notice .pp-toggle .toggle-label {
  min-height: 2.2rem;
}
#mercury-page .external-cookie-notice .pp-toggle .toggle-box {
  width: 15rem;
}
#mercury-page .external-cookie-notice .pp-toggle .toggle-slider {
  width: 1.6rem;
  right: 12.8rem;
  background-color: #444;
}
#mercury-page .external-cookie-notice .pp-toggle .toggle-inner::before, #mercury-page .external-cookie-notice .pp-toggle .toggle-inner::after {
  height: 2rem;
  line-height: 2rem;
  font-size: 85%;
}
#mercury-page .external-cookie-notice .pp-toggle .toggle-text {
  font-size: inherit;
}

#mercury-page .modal .modal-dialog.cookie-notice .external-cookie-notice {
  padding: 1rem;
}
#mercury-page .modal .modal-dialog.cookie-notice .external-cookie-notice .modal-footer {
  border-top: none;
}
#mercury-page .modal .modal-dialog.cookie-notice .external-cookie-notice .modal-footer .btn + .btn {
  margin-left: calc(2 * var(--my-ws-margin));
}

/**********
 * Source file: _search.scss
 */
.type-search .styled-form {
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  background: inherit;
}
.type-search .show-more {
  margin-top: var(--my-default-margin-small);
}
.type-search .search-results-header {
  margin-bottom: var(--my-default-margin);
}
.type-search .search-results-header::after {
  display: block;
  clear: both;
  content: "";
}
.type-search .search-sort,
.type-search .search-result {
  margin-bottom: var(--my-element-margin);
}
.type-search .search-query,
.type-search .search-input,
.type-search .search-sort-options {
  margin-bottom: var(--my-default-margin);
}
.type-search .search-results-count {
  font-weight: 600;
}
.type-search .type-list-filter .filterbox > div {
  border: 1px solid var(--my-grey-light);
  border-top: none;
  border-bottom-right-radius: var(--my-border-radius);
  border-bottom-left-radius: var(--my-border-radius);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
}
.type-search .type-list-filter .filterbox > button.btn.li-label:not(.collapsed) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.type-search .type-list-filter .filterbox > div {
  padding: 15px;
}
.type-search .type-list-filter .filterbox:last-of-type {
  margin-bottom: var(--my-default-margin);
}
.type-search .search-result-heading {
  margin-bottom: var(--my-default-margin-verysmall);
}
.type-search .search-result-heading .result-title {
  margin-right: var(--my-default-margin-small);
  vertical-align: middle;
}
.type-search .search-result-heading a:hover {
  text-decoration: none;
}
.type-search .search-result-heading + .search-result-date {
  margin-top: calc(-1 * var(--my-default-margin-verysmall));
  margin-bottom: var(--my-default-margin-verysmall);
  color: var(--my-grey);
}
.type-search .search-result-site {
  font-weight: var(--my-font-weight-headings);
}
.type-search .search-badge {
  font-family: var(--my-body-font-family);
  font-weight: var(--my-body-font-weight);
  font-size: 60%;
  padding: 0.2rem 0.75rem;
  border-radius: var(--my-btn-border-radius);
  color: var(--my-grey);
  border: 1px solid var(--my-grey);
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
}
.type-search .search-badge:has(+ .search-badge) {
  margin-right: var(--my-default-margin-verysmall);
}
.type-search .search-badge.badge-top {
  font-weight: var(--my-font-weight-bold);
  color: inherit;
  border-color: inherit;
}
@media (min-width: 1014px) {
  .type-search .search-query,
  .type-search .search-sort,
  .type-search .search-results,
  .type-search .search-facets {
    --my-piece-width: 100%;
  }
  .type-search .search-query.has-facets,
  .type-search .search-sort.has-facets,
  .type-search .search-results.has-facets {
    width: calc((var(--my-piece-width) + var(--my-gutter-x)) * 0.6666666667 - var(--my-gutter-x) );
    float: left;
  }
  .type-search .search-facets {
    width: calc((var(--my-piece-width) + var(--my-gutter-x)) * 0.3333333333 - var(--my-gutter-x) );
    float: right;
  }
  .type-search .search-results-head {
    float: left;
    margin-bottom: 0;
  }
  .type-search .search-results-count {
    float: right;
  }
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

/**********
 * Source file: _section.scss
 */
.type-iconbox,
.imageflip {
  --my-body-bg: #990011;
  --my-body-bg-rgb: 153, 0, 17;
  --my-body-color: #fff;
  --my-body-color-rgb: 255, 255, 255;
  --my-link-color: #cccccc;
  --my-link-color-rgb: 204, 204, 204;
  --my-link-hover-color: #fff;
  --my-link-hover-color-rgb: 255, 255, 255;
  --my-link-fg-color: #444;
  --my-link-hover-fg-color: #444;
  --my-main-theme: var(--my-link-color);
  --my-main-theme-rgb: var(--my-link-color-rgb);
  --my-main-theme-fg: var(--my-link-fg-color);
  --my-main-theme-hover: var(--my-link-hover-color);
  --my-main-theme-hover-fg: var(--my-link-hover-fg-color);
  --my-headings-color: var(--my-body-color);
  --my-intro-headings-color: var(--my-main-theme);
  --my-highlight-color: rgb(var(--my-highlight-color-rgb), 0.25);
  --my-highlight-fg-color: var(--my-body-color);
  --my-highlight-border-color: var(--my-body-color);
  --my-highlight-border-fg-color: var(--my-body-bg);
  --my-btn-bg: var(--my-link-color);
  --my-btn-fg: var(--my-link-fg-color);
  --my-btn-hover-bg: var(--my-link-hover-color);
  --my-btn-hover-fg: var(--my-link-hover-fg-color);
  --my-btn-border: var(--my-btn-bg);
  --my-btn-hover-border: var(--my-btn-hover-bg);
}

.type-iconbox {
  color: var(--my-body-color);
  background-color: var(--my-body-bg);
  padding: var(--my-box-padding);
  text-align: center;
  display: flex;
  flex-direction: column;
  border-radius: 0;
  overflow: hidden;
  z-index: 5;
}
.type-iconbox a:not(.btn):not(.uncolored) {
  color: inherit;
}
.type-iconbox:only-child {
  flex-grow: 1;
  flex-shrink: 1;
}
.type-iconbox .icon-title {
  color: inherit;
  flex-grow: 1;
  margin-bottom: var(--my-box-padding);
  line-height: 1.25;
  font-size: 1.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  hyphens: auto;
  overflow-wrap: anywhere;
  word-break: break-word;
}
.type-iconbox .icon-text {
  margin-top: var(--my-box-padding);
  margin-bottom: 0;
}
.type-iconbox .icon-text > p {
  overflow-wrap: anywhere;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}
.type-iconbox .icon-text > p:last-of-type {
  margin-bottom: 0;
}
.type-iconbox .icon-image {
  flex-grow: 2;
  font-size: 32px;
  display: flex;
  justify-content: center;
}
.type-iconbox .icon-link {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  text-decoration: none;
}
.type-iconbox .icon-link:hover {
  text-decoration: none;
}
.type-iconbox .icon-link:hover h1, .type-iconbox .icon-link:hover h2, .type-iconbox .icon-link:hover h3, .type-iconbox .icon-link:hover h4, .type-iconbox .icon-link:hover h5, .type-iconbox .icon-link:hover h6 {
  text-decoration: none;
}
@media (min-width: 764px) {
  .type-iconbox .icon-title {
    font-size: 2rem;
  }
}

.type-linkbox {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  text-overflow: ellipsis;
  z-index: 5;
}
.type-linkbox > .body {
  flex-basis: auto;
  flex-grow: 1;
}
.type-linkbox > .body .headline {
  overflow: hidden;
  text-overflow: ellipsis;
}
.type-linkbox > a, .type-linkbox > a:focus, .type-linkbox > a:hover {
  color: var(--my-body-color);
  text-decoration: none;
}
.type-linkbox > a:hover span.piece-text-link {
  text-decoration: underline;
}
.type-linkbox .linkbox-link,
.type-linkbox .linkbox-content.piece.full,
.type-linkbox .linkbox-content.piece.full .body {
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-grow: 100000;
}
.type-linkbox .linkbox-content.piece.full .heading {
  flex-grow: 1;
}
.type-linkbox.valign-center .linkbox-link,
.type-linkbox.valign-center .linkbox-content.piece.full {
  justify-content: center;
}
.type-linkbox.valign-center .linkbox-content.piece.full .body,
.type-linkbox.valign-center .linkbox-content.piece.full .heading {
  flex-grow: initial;
}

.imageflip {
  color: var(--my-body-color);
  perspective: 1000px;
  transform-style: preserve-3d;
  position: relative;
}
.imageflip:hover {
  z-index: 50;
}
.imageflip:hover .back {
  transform: rotateY(0deg);
  visibility: visible;
  opacity: 1;
}
.imageflip:hover .front:not(.noflip) {
  transform: rotateY(180deg);
  pointer-events: none;
}
.imageflip .flipper {
  width: 100%;
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
}
.imageflip .front,
.imageflip .back {
  width: 100%;
  backface-visibility: hidden;
  transition: 0.6s;
  transform-style: preserve-3d;
  position: absolute;
  top: 0;
  left: 0;
}
.imageflip .front {
  z-index: 2;
  transform: rotateY(0deg);
}
.imageflip .back {
  transform: rotateY(-180deg);
  visibility: hidden;
  opacity: 0;
}
.imageflip .text-section {
  border-radius: var(--my-image-border-radius);
  padding: var(--my-box-padding);
  height: 100%;
  overflow: auto;
  color: var(--my-body-color);
  background-color: var(--my-body-bg);
}
.imageflip .text-section.front:not(.noflip) {
  overflow: hidden;
}
.imageflip .image-section .heading {
  position: absolute;
  top: 0;
  left: 0;
  padding: calc(0.5 * var(--my-box-padding));
  z-index: 9;
}
.imageflip .image-section .heading .text-heading {
  overflow: hidden;
  text-overflow: ellipsis;
  hyphens: auto;
  overflow-wrap: anywhere;
  word-break: break-word;
  border-radius: var(--my-image-border-radius);
  background-color: rgba(var(--my-highlight-color-rgb), 0.75);
  padding: calc(0.5 * var(--my-box-padding));
  margin-bottom: 0;
}
.imageflip.color-white {
  --my-body-bg: #fff;
  --my-body-bg-rgb: 255, 255, 255;
  --my-body-color: #444;
  --my-body-color-rgb: 68, 68, 68;
  --my-link-color: #990011;
  --my-link-color-rgb: 153, 0, 17;
  --my-link-hover-color: #dd0011;
  --my-link-hover-color-rgb: 221, 0, 17;
  --my-link-fg-color: #fff;
  --my-link-hover-fg-color: #fff;
}
.imageflip.color-white .text-section {
  border: 1px solid var(--my-body-color);
}
.imageflip.color-grey {
  --my-body-bg: #eee;
  --my-body-bg-rgb: 238, 238, 238;
  --my-body-color: #444;
  --my-body-color-rgb: 68, 68, 68;
  --my-link-color: #990011;
  --my-link-color-rgb: 153, 0, 17;
  --my-link-hover-color: #dd0011;
  --my-link-hover-color-rgb: 221, 0, 17;
  --my-link-fg-color: #fff;
  --my-link-hover-fg-color: #fff;
}
.imageflip.color-theme .image-section .heading .text-heading {
  background-color: rgba(var(--my-body-bg-rgb), 0.75);
}

@media (min-width: 764px) {
  html.desktop .effect-flipraise .imageflip:hover .back,
  html.desktop .effect-flipraise.imageflip:hover .back {
    top: calc(-2 * var(--my-box-padding));
    left: calc(-2 * var(--my-box-padding));
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.25);
    width: calc(100% + 4 * var(--my-box-padding));
    height: calc(100% + 4 * var(--my-box-padding));
  }
  html.desktop .effect-flipraise .imageflip:hover .back.text-section,
  html.desktop .effect-flipraise.imageflip:hover .back.text-section {
    padding: var(--my-box-padding);
  }
}
/**********
 * Source file: _sitemap.scss
 */
.type-sitemap {
  --my-sm-color: var(--my-main-theme-hover-fg);
  --my-sm-bg-color: var(--my-main-theme-hover);
  --my-sm-hover-color: var(--my-main-theme-fg);
  --my-sm-hover-bg-color: var(--my-main-theme);
  --my-sm-padding-y: calc(0.25 * 2rem);
  --my-sm-padding-x: calc(0.5 * 2rem);
  --my-sm-border-radius: var(--my-border-radius);
}
.type-sitemap ul,
.type-sitemap li {
  margin: 0;
  padding: 0;
  list-style: none;
}
.type-sitemap li a {
  color: var(--my-sm-color);
  background-color: var(--my-sm-bg-color);
  font-size: var(--my-body-font-size);
  line-height: 1.3;
  padding: var(--my-sm-padding-y) var(--my-sm-padding-x);
  width: 100%;
  display: block;
  text-decoration: none;
}
.type-sitemap li a:hover {
  color: var(--my-sm-hover-color);
  background-color: var(--my-sm-hover-bg-color);
  text-decoration: none;
}
.type-sitemap li a.tree-toggle {
  position: relative;
  padding-right: calc(3 * var(--my-sm-padding-x));
}
.type-sitemap li a.tree-toggle:after {
  font-family: var(--my-icon-font-family);
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f078";
  line-height: 1;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  height: 100%;
  transform: rotate(-180deg);
  transition: transform 0.33s;
  right: var(--my-sm-padding-x);
}
.type-sitemap li a.tree-toggle.collapsed:after {
  transform: rotate(0);
}
.type-sitemap li.top {
  float: left;
  width: 100%;
  margin-bottom: var(--my-sm-padding-y);
  border-radius: var(--my-sm-border-radius);
  overflow: hidden;
  z-index: 5;
}
.type-sitemap li.top > a {
  color: var(--my-sm-hover-color);
  background-color: var(--my-sm-hover-bg-color);
  font-weight: 600;
}
.type-sitemap li.top::after {
  content: " ";
  display: block;
  float: left;
  padding-right: var(--my-sm-padding-x);
}
@media (min-width: 1014px) {
  .type-sitemap .sitemap-col-6 > li.top {
    width: calc(50% - var(--my-sm-padding-x));
    margin-right: var(--my-sm-padding-x);
  }
  .type-sitemap .sitemap-col-6 > li.top:nth-of-type(2n+1) {
    clear: both;
  }
}
@media (min-width: 1014px) and (max-width: 1199.98px) {
  .type-sitemap .sitemap-col-4 > li.top {
    width: calc(50% - var(--my-sm-padding-x));
    margin-right: var(--my-sm-padding-x);
  }
  .type-sitemap .sitemap-col-4 > li.top:nth-of-type(2n+1) {
    clear: both;
  }
}
@media (min-width: 1200px) {
  .type-sitemap .sitemap-col-4 > li.top {
    width: calc(33.333333% - var(--my-sm-padding-x));
    margin-right: var(--my-sm-padding-x);
  }
  .type-sitemap .sitemap-col-4 > li.top:nth-of-type(3n+1) {
    clear: both;
  }
}
@media (min-width: 764px) and (max-width: 1013.98px) {
  .type-sitemap .sitemap-col-3 > li.top {
    width: calc(50% - var(--my-sm-padding-x));
    margin-right: var(--my-sm-padding-x);
  }
  .type-sitemap .sitemap-col-3 > li.top:nth-of-type(2n+1) {
    clear: both;
  }
}
@media (min-width: 1014px) and (max-width: 1199.98px) {
  .type-sitemap .sitemap-col-3 > li.top {
    width: calc(33.333333% - var(--my-sm-padding-x));
    margin-right: var(--my-sm-padding-x);
  }
  .type-sitemap .sitemap-col-3 > li.top:nth-of-type(3n+1) {
    clear: both;
  }
}
@media (min-width: 1200px) {
  .type-sitemap .sitemap-col-3 > li.top {
    width: calc(25% - var(--my-sm-padding-x));
    margin-right: var(--my-sm-padding-x);
  }
  .type-sitemap .sitemap-col-3 > li.top:nth-of-type(4n+1) {
    clear: both;
  }
}
.type-sitemap li.top > ul > li > ul li a {
  padding-left: calc(2 * var(--my-sm-padding-x));
}
.type-sitemap li.top > ul > li > ul > li > ul li a {
  padding-left: calc(3 * var(--my-sm-padding-x));
}
.type-sitemap li.top > ul > li > ul > li > ul > li > ul li a {
  padding-left: calc(4 * var(--my-sm-padding-x));
}
.type-sitemap li.top > ul > li > ul > li > ul > li > ul > li > ul li a {
  padding-left: calc(5 * var(--my-sm-padding-x));
}

/**********
 * Source file: _slider.scss
 */
.type-slider {
  --my-slider-slide-border-radius: var(--my-border-radius);
  --my-slider-caption-border-radius: var(--my-border-radius);
}
.type-slider.hero-slider .slide-wrapper.hide-noscript {
  visibility: hidden;
  max-height: 0;
}
.type-slider.logo-slider .slider-box.lm-10 {
  margin-left: -10px;
  margin-right: -10px;
}
.type-slider.logo-slider .slider-box.lm-10 .slide-wrapper .visual {
  margin: 10px;
}
.type-slider .slider-box {
  position: relative;
  overflow: hidden;
  border-radius: var(--my-slider-slide-border-radius);
  --my-slider-bg: 153, 0, 17;
  --my-slider-bg-opacity: 0.75;
  --my-slider-fg: #fff;
  --my-slider-caption-top: var(--my-slider-bg);
  --my-slider-caption-sub: var(--my-slider-bg);
}
.type-slider .slider-box.custom .caption {
  background-color: transparent !important;
  padding: 0;
  border-radius: 0;
}
.type-slider .slider-box.custom .caption strong,
.type-slider .slider-box.custom .caption small {
  display: inline-block;
  float: left;
  clear: both;
}
.type-slider .slider-box.custom .caption.right strong,
.type-slider .slider-box.custom .caption.right small {
  float: right;
}
.type-slider .slider-box.custom .caption.center strong,
.type-slider .slider-box.custom .caption.center small {
  float: none;
}
.type-slider .slider-box.custom .caption strong {
  background-color: rgb(var(--my-slider-caption-top), var(--my-slider-bg-opacity));
  padding-top: var(--my-default-margin-verysmall);
  padding-bottom: var(--my-default-margin-verysmall);
}
.type-slider .slider-box.custom .caption small {
  background-color: rgb(var(--my-slider-caption-sub), var(--my-slider-bg-opacity));
  padding-top: 2px;
  padding-bottom: 2px;
  margin-top: var(--my-default-margin-verysmall);
}
.type-slider .slider-box.custom .caption small:nth-of-type(2) {
  margin-top: 0;
}
@media (min-width: 764px) {
  .type-slider .slider-box.custom .caption.center .subcaption br {
    display: block;
  }
  .type-slider .slider-box.custom .caption strong,
  .type-slider .slider-box.custom .caption small {
    border-radius: var(--my-slider-caption-border-radius);
  }
}
.type-slider .slide-definitions {
  display: flex;
}
.type-slider .slide-container,
.type-slider .slide-wrapper {
  border-radius: var(--my-slider-slide-border-radius);
  overflow: hidden;
  z-index: 5;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 10;
}
.type-slider .slide-wrapper .caption {
  hyphens: auto;
  overflow-wrap: anywhere;
  word-break: break-word;
  padding: var(--my-default-margin-verysmall) 0;
  position: absolute;
  max-width: calc(100% - 2 * var(--my-default-margin));
  background-color: rgb(var(--my-slider-bg), var(--my-slider-bg-opacity));
  border-radius: var(--my-slider-caption-border-radius);
  overflow: hidden;
  z-index: 5;
}
.type-slider .slide-wrapper .caption.bottom {
  bottom: var(--my-default-margin);
}
.type-slider .slide-wrapper .caption.top {
  top: var(--my-default-margin);
}
.type-slider .slide-wrapper .caption.middle {
  top: 50%;
  transform: translate(0, -50%);
}
.type-slider .slide-wrapper .caption.left {
  left: var(--my-default-margin);
}
.type-slider .slide-wrapper .caption.right {
  right: var(--my-default-margin);
  text-align: right;
}
.type-slider .slide-wrapper .caption.center {
  text-align: center;
  white-space: nowrap;
  left: 50%;
  transform: translate(-50%, 0);
}
.type-slider .slide-wrapper .caption.middle.center {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.type-slider .slide-wrapper .caption .subcaption br {
  display: none;
}
.type-slider .slide-wrapper .caption strong,
.type-slider .slide-wrapper .caption small {
  display: block;
  margin: 0;
  padding: 0 var(--my-default-margin-small);
  color: var(--my-slider-fg);
  text-shadow: 1px 1px 1px rgba(51, 51, 51, 0.5);
  font-family: var(--my-font-family-headings);
  font-weight: var(--my-font-weight-headings);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.type-slider .slide-wrapper .caption strong {
  font-size: 1.8rem;
  line-height: 1.25;
}
.type-slider .slide-wrapper .caption strong + .subcaption small:first-of-type {
  margin-top: var(--my-default-margin-verysmall);
}
.type-slider .slide-wrapper .caption small {
  font-family: var(--my-body-font-family);
  font-size: var(--my-body-font-size);
  line-height: var(--my-body-line-height);
}
.type-slider .slide-wrapper .caption small:nth-of-type(2) {
  margin-top: 0;
}
@media (min-width: 552px) {
  .type-slider .slide-wrapper .caption strong {
    font-size: 2.4rem;
  }
}
@media (min-width: 764px) {
  .type-slider .slide-wrapper .caption strong {
    font-size: 2.8rem;
  }
  .type-slider .slide-wrapper .caption small {
    font-size: 2rem;
    line-height: 1.25;
  }
}
.type-slider .slide-wrapper .visual {
  position: relative;
}
.type-slider .slide-wrapper .img-responsive {
  width: 100%;
}
.type-slider .slide-wrapper img.lazyloading, .type-slider .slide-wrapper img.lazyload {
  background-color: transparent;
  opacity: 1;
}
.type-slider .slide-wrapper img.lazyloaded {
  transition: none;
}
.type-slider .slide-wrapper a,
.type-slider .slide-wrapper a:focus,
.type-slider .slide-wrapper a:hover {
  text-decoration: none;
}
.type-slider .slide-wrapper .copyright {
  line-height: 1;
  padding: var(--my-copyright-padding);
  font-family: var(--my-copyright-font-family);
  font-size: var(--my-copyright-font-size);
  border-radius: var(--my-copyright-border-radius);
  text-align: var(--my-copyright-pos-right, right);
  right: var(--my-copyright-pos-right, 0);
  text-shadow: var(--my-copyright-text-shadow, none);
  display: inline-block;
  color: var(--my-copyright-color);
  background-color: var(--my-copyright-bg-color);
  opacity: var(--my-copyright-opacity);
  position: absolute;
  bottom: 0;
  pointer-events: none;
  z-index: 7;
  box-shadow: none;
  overflow-wrap: anywhere;
  word-break: break-word;
  hyphens: auto;
}
@media (max-width: 763.98px) {
  .type-slider.text-below-on-xs .slider-dots {
    bottom: 2px;
  }
  .type-slider.text-below-on-xs .slider-nav-btn {
    display: none !important;
  }
  .type-slider.text-below-on-xs .slide-wrapper,
  .type-slider.text-below-on-xs .image-src-box,
  .type-slider.text-below-on-xs .image-src-box img {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .type-slider.text-below-on-xs .captions {
    background-color: rgb(var(--my-slider-bg));
    width: 100%;
    display: flex;
    flex-grow: 10;
  }
  .type-slider.text-below-on-xs .slider-box.custom .captions {
    width: 100%;
    background-color: rgb(var(--my-slider-caption-sub));
  }
  .type-slider.text-below-on-xs .slider-box.custom .caption {
    width: 100%;
  }
  .type-slider.text-below-on-xs .slider-box.custom .caption strong {
    display: block;
    width: 100%;
    padding: var(--my-default-margin-verysmall) var(--my-default-margin-small);
    background-color: rgb(var(--my-slider-caption-top));
  }
  .type-slider.text-below-on-xs .slider-box.custom .caption strong, .type-slider.text-below-on-xs .slider-box.custom .caption small {
    float: none;
    clear: none;
  }
  .type-slider.text-below-on-xs .slider-box.custom .caption small {
    display: inline;
    margin-top: 0;
  }
  .type-slider.text-below-on-xs .slide-wrapper .caption {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    position: static;
    max-width: initial;
    min-height: calc(5rem + 10px);
    background-color: rgb(var(--my-slider-bg));
    opacity: 1 !important;
    text-align: initial !important;
    white-space: initial !important;
    transform: initial !important;
  }
  .type-slider.text-below-on-xs .slide-wrapper strong {
    font-size: 2.4rem;
    line-height: 1.25;
    font-weight: var(--my-font-weight-headings);
  }
  .type-slider.text-below-on-xs .slide-wrapper small {
    display: inline;
    padding: 0;
  }
  .type-slider.text-below-on-xs .slide-wrapper small:nth-of-type(2)::before {
    display: inline-block;
    content: " ";
  }
  .type-slider.text-below-on-xs .slide-wrapper .subcaption {
    display: block;
    padding: var(--my-default-margin-verysmall) var(--my-default-margin-small);
  }
}

.use-embla-slider.hero-slider .slider-initialized .slide-wrapper.hide-noscript {
  visibility: visible;
  max-height: none;
}
.use-embla-slider.hero-slider .mw-0 .slide-wrapper {
  margin-right: 0;
}
.use-embla-slider.hero-slider .mw-sm .slide-wrapper {
  margin-right: var(--my-default-margin-small);
}
.use-embla-slider.hero-slider .mw-md .slide-wrapper {
  margin-right: var(--my-default-margin);
}
.use-embla-slider.hero-slider .mw-lg .slide-wrapper {
  margin-right: calc(2 * var(--my-default-margin));
}
.use-embla-slider.hero-slider .slide-wrapper {
  flex: 0 0 100%;
}
.use-embla-slider.hero-slider .slide-wrapper .caption {
  transition: opacity 1.5s linear;
  opacity: 0;
}
.use-embla-slider.hero-slider .slide-wrapper.slide-active .caption {
  opacity: 1;
}
.use-embla-slider.hero-slider .tr-timed:not(.slider-initialized) * {
  visibility: hidden;
}
.use-embla-slider.hero-slider .tr-fade:not(.is-dragging).slider-initialized {
  z-index: 0;
}
.use-embla-slider.hero-slider .tr-fade:not(.is-dragging).slider-initialized .slide-definitions {
  transform: none !important;
  display: grid;
}
.use-embla-slider.hero-slider .tr-fade:not(.is-dragging).slider-initialized .slide-wrapper {
  transform: none !important;
  grid-row: 1;
  grid-column: 1;
  opacity: 0;
  z-index: 1;
  transition: opacity 2s;
}
.use-embla-slider.hero-slider .tr-fade:not(.is-dragging).slider-initialized .slide-wrapper.slide-active {
  opacity: 1;
  z-index: 10;
  transition: opacity 1.5s;
}
.use-embla-slider.hero-slider .tr-fade:not(.is-dragging).sp-slow .slide-wrapper {
  transition: opacity 2.5s;
}
.use-embla-slider.hero-slider .tr-fade:not(.is-dragging).sp-slow .slide-wrapper.slide-active {
  transition: opacity 3s;
}
.use-embla-slider.hero-slider .tr-fade:not(.is-dragging).sp-fast .slide-wrapper {
  transition: opacity 1s;
}
.use-embla-slider.hero-slider .tr-fade:not(.is-dragging).sp-fast .slide-wrapper.slide-active {
  transition: opacity 0.75s;
}
.use-embla-slider.logo-slider .all-in-view .slide-definitions {
  justify-content: center;
}
.use-embla-slider.logo-slider .all-in-view .slider-nav-btn {
  display: none;
}

.slider-box button,
.slider-box [type=button] {
  -webkit-appearance: auto;
  border: none;
  color: transparent;
  background-color: transparent;
  display: block;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
}

.slider-nav-btn {
  display: flex;
  position: absolute;
  bottom: 50%;
  transform: translateY(50%);
  z-index: 1100;
  padding: 0;
  margin: 0;
  opacity: 0;
  transition: opacity 0.5s linear;
}
.slider-nav-btn:hover, .slider-nav-btn:focus {
  opacity: 1;
}
.slider-nav-btn::before {
  content: " ";
  display: block;
  width: 40px;
  height: 40px;
  background-size: 40px;
}
.slider-nav-btn.prev-btn::before, .slider-nav-btn.next-btn::before {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNTYiIGhlaWdodD0iMjU2Ij4NCiAgPHBhdGggZD0iTTI1NSAxMjhhMTI3IDEyNyAwIDEgMS0yNTQtLjEgMTI3IDEyNyAwIDAgMSAyNTMuOS4xeiIvPg0KICA8cGF0aCBmaWxsPSIjZmZmIiBkPSJNMTQ0IDIyNGwzNi0zNi02MC02MCA2MC02MC0zNi0zNi05NiA5NnoiLz4NCjwvc3ZnPg0K");
}
.slider-nav-btn.prev-btn {
  left: 20px;
}
.slider-nav-btn.next-btn {
  right: 20px;
}
.slider-nav-btn.next-btn::before {
  transform: rotate(180deg);
}

.slider-dots {
  display: flex;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  bottom: 5px;
  z-index: 1100;
  list-style: none;
  text-align: center;
  padding: 0;
  margin: 0;
  opacity: 0;
  transition: opacity 0.5s linear;
}
.slider-dots button {
  position: relative;
  display: inline-block;
  margin: 0 0.25rem;
  padding: 5px;
}
.slider-dots button::before {
  content: " ";
  display: block;
  width: 20px;
  height: 20px;
  background-size: 20px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNTYiIGhlaWdodD0iMjU2Ij4NCiAgPHBhdGggZD0iTTI1NSAxMjhhMTI3IDEyNyAwIDEgMS0yNTQtLjEgMTI3IDEyNyAwIDAgMSAyNTMuOS4xeiIvPg0KICA8cGF0aCBmaWxsPSIjZmZmIiBkPSJNMjA4IDEyOGE4MCA4MCAwIDEgMS0xNjAgMCA4MCA4MCAwIDAgMSAxNjAgMHoiLz4NCjwvc3ZnPg==");
  opacity: 0.5;
}
.slider-dots button:hover::before, .slider-dots button:focus::before {
  opacity: 1;
}
.slider-dots button.active::before {
  opacity: 1;
}

html.mobile button:hover::before {
  opacity: 0.5;
}
html.mobile button.active::before {
  opacity: 1;
}

html.keyboard-nav .type-slider .slider-box .slider-nav-btn,
.type-slider .slider-box:hover .slider-nav-btn {
  opacity: 0.5;
}
html.keyboard-nav .type-slider .slider-box .slider-nav-btn:hover, html.keyboard-nav .type-slider .slider-box .slider-nav-btn:focus,
.type-slider .slider-box:hover .slider-nav-btn:hover,
.type-slider .slider-box:hover .slider-nav-btn:focus {
  opacity: 1;
}
html.keyboard-nav .type-slider .slider-box .slider-dots,
.type-slider .slider-box:hover .slider-dots {
  opacity: 1;
}

html.noscript .use-embla-slider.hero-slider .tr-timed:not(.slider-initialized) * {
  visibility: visible;
}

/**********
 * Source file: _socialicons.scss
 */
.social-icons .fas,
.social-icons .fab {
  font-family: var(--my-icon-font-family);
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-size: inherit;
}

.social-icons {
  --my-sl-margin: var(--my-default-margin-small);
  --my-sl-size: 20px;
  --my-sl-box-height: calc(2 * var(--my-sl-size));
  --my-sl-border-width: 0px;
  --my-sl-border-radius: var(--my-btn-border-radius);
  --my-sl-color: var(--my-body-color);
  --my-sl-bg: var(--my-grey);
  list-style: none;
  font-size: 0;
  padding: 0;
  line-height: 1;
}
.social-icons ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}
.social-icons.element {
  margin-bottom: calc(var(--my-element-margin) - var(--my-sl-margin));
}
.social-icons li {
  --my-sl-box-inner-height: calc(var(--my-sl-box-height) - 2 * var(--my-sl-border-width));
  display: inline-block;
  margin-top: 0;
  margin-left: 0;
  margin-right: var(--my-sl-margin);
  margin-bottom: var(--my-sl-margin);
  vertical-align: middle;
}
.social-icons li a {
  color: var(--my-sl-color);
  background-color: var(--my-sl-bg);
  width: var(--my-sl-box-height);
  height: var(--my-sl-box-height);
  display: block;
  border: var(--my-sl-border-width) solid var(--my-sl-color);
  font-size: var(--my-sl-size);
  border-radius: var(--my-sl-border-radius);
}
.social-icons li a * {
  color: var(--my-sl-color);
}
.social-icons li a:hover {
  text-decoration: none;
}
.social-icons li a:hover * {
  color: #fff;
  transition-property: color, background-color, border-color;
  transition-duration: 0.33s;
  transition-timing-function: ease;
}
.social-icons li:last-of-type {
  margin-right: 0;
}
.social-icons .share_text {
  display: none;
  text-align: left;
  padding-right: var(--my-ws-margin);
  font-size: var(--my-body-font-size);
  font-family: var(--my-btn-font-family);
  font-weight: 400;
}
.social-icons .ico,
.social-icons .ico-svg,
.social-icons .fa,
.social-icons .fas,
.social-icons .fab {
  line-height: 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: var(--my-sl-box-inner-height);
  height: var(--my-sl-box-inner-height);
  font-size: var(--my-sl-size);
}
.social-icons.outlined {
  --my-sl-color: var(--my-link-color);
  --my-sl-bg: var(--my-body-bg);
  --my-sl-border-width: calc(0.1 * var(--my-sl-size));
}
.social-icons.rounded {
  --my-sl-border-radius: 100%;
}
.social-icons.enlarged {
  --my-sl-size: 30px;
}

.ls-buttons .social-icons {
  margin-bottom: calc(-1 * var(--my-sl-margin));
}

.social-icons.verbose li a {
  width: initial;
}
.social-icons.verbose .share_text {
  display: inline-block;
  line-height: var(--my-sl-box-height);
}

.social-icons.verbose a {
  width: initial;
  display: flex;
}
.social-icons.verbose a .share_text {
  display: inline;
  display: flex;
  align-items: center;
}
.social-icons.stacked li {
  width: 100%;
  margin-right: 0;
}
.social-icons.stacked li a {
  width: 100%;
}
@media (min-width: 552px) {
  .social-icons.verbose-sm a {
    width: initial;
    display: flex;
  }
  .social-icons.verbose-sm a .share_text {
    display: inline;
    display: flex;
    align-items: center;
  }
  .social-icons.stacked-sm li {
    width: 100%;
    margin-right: 0;
  }
  .social-icons.stacked-sm li a {
    width: 100%;
  }
}
@media (min-width: 764px) {
  .social-icons.verbose-md a {
    width: initial;
    display: flex;
  }
  .social-icons.verbose-md a .share_text {
    display: inline;
    display: flex;
    align-items: center;
  }
  .social-icons.stacked-md li {
    width: 100%;
    margin-right: 0;
  }
  .social-icons.stacked-md li a {
    width: 100%;
  }
}
@media (min-width: 1014px) {
  .social-icons.verbose-lg a {
    width: initial;
    display: flex;
  }
  .social-icons.verbose-lg a .share_text {
    display: inline;
    display: flex;
    align-items: center;
  }
  .social-icons.stacked-lg li {
    width: 100%;
    margin-right: 0;
  }
  .social-icons.stacked-lg li a {
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .social-icons.verbose-xl a {
    width: initial;
    display: flex;
  }
  .social-icons.verbose-xl a .share_text {
    display: inline;
    display: flex;
    align-items: center;
  }
  .social-icons.stacked-xl li {
    width: 100%;
    margin-right: 0;
  }
  .social-icons.stacked-xl li a {
    width: 100%;
  }
}

.social-icons li.facebook a:hover {
  background-color: #4267b2;
  border-color: #4267b2;
}

.social-icons li.youtube a:hover {
  background-color: #ff0000;
  border-color: #ff0000;
}

.social-icons li.twitter a:hover {
  background-color: #000000;
  border-color: #000000;
}

.social-icons li.github a:hover {
  background-color: #777777;
  border-color: #777777;
}

.social-icons li.xing a:hover {
  background-color: #026466;
  border-color: #026466;
}

.social-icons li.rss a:hover {
  background-color: #ee802f;
  border-color: #ee802f;
}

.social-icons li.whatsapp a:hover {
  background-color: #25D366;
  border-color: #25D366;
}

.social-icons li.linkedin a:hover {
  background-color: #2867b2;
  border-color: #2867b2;
}

.social-icons li.instagram a:hover {
  background-color: #c13584;
  border-color: #c13584;
}

.social-icons li.mail a:hover {
  background-color: #999999;
  border-color: #999999;
}

.social-icons li.wallsio a:hover {
  background-color: #000000;
  border-color: #000000;
}

.social-icons li.slideshare a:hover {
  background-color: #e98325;
  border-color: #e98325;
}

.social-icons li.generic a:hover {
  background-color: #444;
  border-color: #444;
}

.social-icons.themed li a {
  background-color: var(--my-main-theme);
}
.social-icons.themed li a * {
  color: var(--my-main-theme-fg);
}

.social-icons.colored li a * {
  color: #fff;
}
.social-icons.colored li.facebook a {
  background-color: #4267b2;
}
.social-icons.colored li.facebook a:hover {
  background-color: #4d7bda;
}
.social-icons.colored li.youtube a {
  background-color: #ff0000;
}
.social-icons.colored li.youtube a:hover {
  background-color: #ff3333;
}
.social-icons.colored li.twitter a {
  background-color: #000000;
}
.social-icons.colored li.twitter a:hover {
  background-color: #1f1414;
}
.social-icons.colored li.github a {
  background-color: #777777;
}
.social-icons.colored li.github a:hover {
  background-color: #a77a7a;
}
.social-icons.colored li.xing a {
  background-color: #026466;
}
.social-icons.colored li.xing a:hover {
  background-color: #00989b;
}
.social-icons.colored li.rss a {
  background-color: #ee802f;
}
.social-icons.colored li.rss a:hover {
  background-color: #ff9b51;
}
.social-icons.colored li.whatsapp a {
  background-color: #25D366;
}
.social-icons.colored li.whatsapp a:hover {
  background-color: #36f57d;
}
.social-icons.colored li.linkedin a {
  background-color: #2867b2;
}
.social-icons.colored li.linkedin a:hover {
  background-color: #227eeb;
}
.social-icons.colored li.instagram a {
  background-color: #c13584;
}
.social-icons.colored li.instagram a:hover {
  background-color: #e6439f;
}
.social-icons.colored li.mail a {
  background-color: #999999;
}
.social-icons.colored li.mail a:hover {
  background-color: #c2a3a3;
}
.social-icons.colored li.wallsio a {
  background-color: #000000;
}
.social-icons.colored li.wallsio a:hover {
  background-color: #1f1414;
}
.social-icons.colored li.slideshare a {
  background-color: #e98325;
}
.social-icons.colored li.slideshare a:hover {
  background-color: #ff9d42;
}
.social-icons.colored li.generic a {
  background-color: #444;
}
.social-icons.colored li.generic a:hover {
  background-color: #704b4b;
}

.social-icons.colored li.instagram a,
.social-icons li.instagram a:hover {
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
}
.social-icons.colored li.instagram a:hover {
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fff69a 5%, #ff6f61 45%, #f529b6 60%, #285AEB 90%);
}
.social-icons.colored li.youtube a:hover {
  background-color: #c00;
}
.social-icons.colored li.wallsio a:hover {
  background-color: #444;
}

html.desktop:not(.opencms-page-editor) li.whatsapp {
  display: none;
}

/**********
 * Source file: _tab.scss
 */
.accordion .acco-header, .tab-list > li {
  position: relative;
}
.accordion .acco-header > .hash-link, .tab-list > li > .hash-link {
  display: none;
}
.accordion .acco-header:hover > .hash-link, .tab-list > li:hover > .hash-link {
  font-size: var(--my-body-font-size);
  font-weight: var(--my-body-font-weight);
  line-height: var(--my-body-line-height);
  position: absolute;
  right: calc(0.5 * var(--my-at-spacing-x));
  top: var(--my-at-spacing-y);
  display: flex;
  z-index: 5;
}

.accordion .acco-header .acco-toggle, .tab-list > li > .tab-toggle {
  display: block;
  width: 100%;
  position: relative;
  margin: 0;
  padding: var(--my-at-spacing-y) var(--my-at-spacing-x);
  font-family: var(--my-font-family-headings);
  font-weight: var(--my-font-weight-headings);
  font-size: 1.8rem;
  line-height: 1.2;
  text-transform: none;
  text-decoration: none;
  text-align: inherit;
  hyphens: auto;
  overflow-wrap: anywhere;
  word-break: break-word;
  border: var(--my-at-border);
  border-radius: var(--my-at-border-radius);
  text-overflow: ellipsis;
  overflow: hidden;
  z-index: 5;
  color: var(--my-at-header);
  background-color: var(--my-at-header-bg);
}

.accordion .acco-header .acco-toggle[aria-expanded=true], .tab-list > li > .tab-toggle.active {
  color: var(--my-at-header-active);
  background-color: var(--my-at-header-active-bg);
  border-color: var(--my-at-header-active-bg);
}

.accordion .acco-header .acco-toggle:hover, .tab-list > li > .tab-toggle:hover {
  color: var(--my-at-header-hover);
  background-color: var(--my-at-header-hover-bg);
  border-color: var(--my-at-header-hover-bg);
  text-decoration: none;
}

.variant-tabs,
.accordion {
  --my-at-header: var(--my-highlight-fg-color);
  --my-at-header-bg: var(--my-highlight-color);
  --my-at-header-active: var(--my-main-theme-fg);
  --my-at-header-active-bg: var(--my-main-theme);
  --my-at-header-hover: var(--my-main-theme-hover-fg);
  --my-at-header-hover-bg: var(--my-main-theme-hover);
  --my-at-spacing-x: 1rem;
  --my-at-spacing-y: 0.5rem;
  --my-at-content-padding-x: 1rem;
  --my-at-content-padding-y: 1rem;
  --my-at-margin-between: 0.5rem;
  --my-at-border-radius: var(--my-border-radius);
  --my-at-border-color: var(--my-grey-light);
  --my-at-border: 1px solid var(--my-at-border-color);
  --my-tab-content-margin-top: 1rem;
  --my-acco-body-margin-top: -1px;
  --my-acco-body-bg: transparent;
}

.tab-content {
  margin-top: var(--my-tab-content-margin-top);
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.tab-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.tab-list::after {
  display: block;
  clear: both;
  content: "";
}
.tab-list::after {
  clear: both;
}
.tab-list > li {
  margin-bottom: var(--my-at-margin-between);
}
.tab-list > li:last-of-type {
  margin-bottom: 0;
}
.acco-body > div > .row-12:last-child,
.acco-body > div > .row:last-child,
.tab-pane > div > .row-12:last-child,
.tab-pane > div > .row:last-child {
  margin-bottom: calc(-1 * var(--my-element-margin));
}

.acco-body > div > .element:last-child,
.acco-body > div > .detail-page:last-child,
.tab-pane > div > .element:last-child,
.tab-pane > div > .detail-page:last-child {
  margin-bottom: 0;
}

.accordion {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  overflow-wrap: anywhere;
  word-break: break-word;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
  margin-bottom: var(--my-at-margin-between);
}
.accordion:last-of-type {
  margin-bottom: 0;
}
.accordion .acco-header {
  margin: 0;
}
.accordion .acco-header .acco-toggle[aria-expanded=true] {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.accordion .acco-header:hover > .hash-link {
  top: 50%;
  transform: translateY(-50%);
  right: calc(3rem + var(--my-at-spacing-x));
}
.accordion .acco-header .acco-toggle {
  padding-right: calc(3rem + var(--my-at-spacing-x));
}
.accordion .acco-header .acco-toggle::after {
  font-family: var(--my-icon-font-family);
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f078";
  line-height: 1;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  height: 100%;
  transform: rotate(-180deg);
  transition: transform 0.33s;
  right: var(--my-at-spacing-x);
}
.accordion .acco-header .acco-toggle.collapsed::after {
  transform: rotate(0);
}
.accordion .acco-body {
  border: var(--my-at-border);
  border-radius: var(--my-at-border-radius);
  background-color: var(--my-acco-body-bg);
  margin-top: var(--my-acco-body-margin-top);
  padding: var(--my-at-content-padding-y) var(--my-at-content-padding-x);
  overflow: hidden;
  z-index: 5;
}
.accordion .acco-body.collapsing, .accordion .acco-body.show {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion .acco-body > .piece:not(:last-of-type) {
  margin-bottom: var(--my-element-margin);
}
.accordion .sub-header {
  margin-bottom: var(--my-headings-margin-bottom);
}

@media (min-width: 1014px) {
  .tab-list {
    border-bottom: solid 2px var(--my-at-header-active-bg);
  }
  .tab-list > li {
    float: left;
    margin-bottom: 0;
  }
  .tab-list > li > .tab-toggle {
    width: auto;
    color: var(--my-body-color);
    background-color: transparent;
    border: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.variant-tile-accordion .tile-accordion.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 0;
}
.variant-tile-accordion .collapse-trigger {
  display: flex;
  position: relative;
}
.variant-tile-accordion .collapse-trigger .tile-col {
  container: tile-col/inline-size;
  display: flex;
  flex-direction: column;
  z-index: 1;
}
.variant-tile-accordion .collapse-trigger .text-overlay,
.variant-tile-accordion .collapse-trigger .boxbg-overlay {
  --my-square-text-overlay-fg-shadow: none;
  --my-square-text-overlay-bg-opacity: 0.75;
  --my-square-text-overlay-bg: var(--my-body-bg);
  --my-square-text-overlay-bg-rgb: var(--my-body-bg-rgb);
  --my-square-text-overlay-fg: var(--my-body-color);
}
.variant-tile-accordion .collapse-trigger .text-overlay {
  display: flex;
  align-items: center;
}
.variant-tile-accordion .collapse-trigger .text-overlay .piece {
  width: 100%;
}
@container tile-col (max-width: 150px) {
  .variant-tile-accordion .collapse-trigger .text-overlay {
    --my-square-text-overlay-padding-h: 1rem;
  }
}
.variant-tile-accordion .collapse-trigger .piece-heading {
  font-weight: var(--my-font-weight-headings);
  font-family: var(--my-font-family-headings);
  font-style: var(--my-font-style-headings);
  overflow: visible;
  font-size: 1.6rem;
}
@container tile-col (min-width: 0px) {
  .variant-tile-accordion .collapse-trigger .piece-heading {
    font-size: calc(10px + 12 * clamp(0px, (100cqw - 100px) / 200, 1px));
  }
}
.variant-tile-accordion .collapse-trigger .hash-link {
  display: none;
  z-index: 25;
  position: absolute;
  top: 15px;
  right: calc(15px + 0.5 * var(--my-gutter-x));
}
.variant-tile-accordion .collapse-trigger .image-src-box > img {
  border-radius: 0;
}
.variant-tile-accordion .collapse-trigger:hover > .hash-link {
  display: block;
}
.variant-tile-accordion .collapse-target {
  margin-top: max(var(--my-gutter-y), 10px);
  margin-bottom: calc(10 * clamp(0px, 10px - var(--my-gutter-y), 1px));
}
.variant-tile-accordion .collapse-target.collapsing {
  transition: none;
}
.variant-tile-accordion .collapse-target > div > .detail-page:last-child,
.variant-tile-accordion .collapse-target > div > .element:last-child {
  margin-bottom: 0;
}
.variant-tile-accordion.show-button .collapse-trigger .trigger-item::after {
  font-family: var(--my-icon-font-family);
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f078";
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  line-height: 1;
  color: var(--my-body-color);
  background-color: var(--my-body-bg);
  font-size: 20px;
  width: 40px;
  height: 40px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--my-image-border-radius);
  border-bottom-right-radius: var(--my-image-border-radius);
  bottom: -40px;
  transition: bottom 0.33s, opacity 0.33s;
  transform: translateX(-50%);
  left: 50%;
  opacity: 1;
}
.variant-tile-accordion.show-button .collapse-trigger.collapsed .trigger-item::after {
  bottom: 0;
  opacity: 0;
}
.variant-tile-accordion.show-button .collapse-target::before {
  display: flex;
  content: "";
  height: 0;
  transition: height 0.33s;
}
.variant-tile-accordion.show-button .collapse-target.show::before {
  height: calc(40px);
}
.variant-tile-accordion.hide-button .collapse-target.collapsing {
  transition: height 0.1s;
}
.variant-tile-accordion.show-border .collapse-trigger .content-box {
  outline: 4px solid var(--my-body-bg);
  transition: outline-width 0.2s;
}
.variant-tile-accordion.show-border .collapse-trigger.collapsed .content-box {
  outline-width: 0;
}
.variant-tile-accordion.text-below .collapse-trigger .text-overlay {
  --my-square-text-overlay-bg-opacity: 1;
  position: relative;
  flex-grow: 1;
}
.variant-tile-accordion.text-below .collapse-trigger .content-box {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.variant-tile-accordion.text-below .collapse-trigger .content-box .effect-box .image-src-box,
.variant-tile-accordion.text-below .collapse-trigger .content-box .effect-box .image-src-box img {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
@media (max-width: 763.98px) {
  .variant-tile-accordion span.col-6:nth-of-type(n+1) {
    order: 10;
  }
  .variant-tile-accordion span.col-6:nth-of-type(n+3) {
    order: 30;
  }
  .variant-tile-accordion span.col-6:nth-of-type(n+5) {
    order: 50;
  }
  .variant-tile-accordion span.col-6:nth-of-type(n+7) {
    order: 70;
  }
  .variant-tile-accordion span.col-6:nth-of-type(n+9) {
    order: 90;
  }
  .variant-tile-accordion span.col-6:nth-of-type(n+11) {
    order: 110;
  }
  .variant-tile-accordion span.col-6:nth-of-type(n+13) {
    order: 130;
  }
  .variant-tile-accordion div.target-6:nth-of-type(n+1) {
    order: 20;
  }
  .variant-tile-accordion div.target-6:nth-of-type(n+3) {
    order: 40;
  }
  .variant-tile-accordion div.target-6:nth-of-type(n+5) {
    order: 60;
  }
  .variant-tile-accordion div.target-6:nth-of-type(n+7) {
    order: 80;
  }
  .variant-tile-accordion div.target-6:nth-of-type(n+9) {
    order: 100;
  }
  .variant-tile-accordion div.target-6:nth-of-type(n+11) {
    order: 120;
  }
  .variant-tile-accordion div.target-6:nth-of-type(n+13) {
    order: 140;
  }
}
@media (min-width: 764px) {
  .variant-tile-accordion span.col-md-3:nth-of-type(n+1) {
    order: 10;
  }
  .variant-tile-accordion span.col-md-3:nth-of-type(n+5) {
    order: 30;
  }
  .variant-tile-accordion span.col-md-3:nth-of-type(n+9) {
    order: 50;
  }
  .variant-tile-accordion span.col-md-3:nth-of-type(n+13) {
    order: 70;
  }
  .variant-tile-accordion div.target-md-3:nth-of-type(n+1) {
    order: 20;
  }
  .variant-tile-accordion div.target-md-3:nth-of-type(n+5) {
    order: 40;
  }
  .variant-tile-accordion div.target-md-3:nth-of-type(n+9) {
    order: 60;
  }
  .variant-tile-accordion div.target-md-3:nth-of-type(n+13) {
    order: 80;
  }
  .variant-tile-accordion span.col-lg-2:nth-of-type(n+1),
  .variant-tile-accordion span.col-md-4:nth-of-type(n+1) {
    order: 10;
  }
  .variant-tile-accordion span.col-lg-2:nth-of-type(n+4),
  .variant-tile-accordion span.col-md-4:nth-of-type(n+4) {
    order: 30;
  }
  .variant-tile-accordion span.col-lg-2:nth-of-type(n+7),
  .variant-tile-accordion span.col-md-4:nth-of-type(n+7) {
    order: 50;
  }
  .variant-tile-accordion span.col-lg-2:nth-of-type(n+10),
  .variant-tile-accordion span.col-md-4:nth-of-type(n+10) {
    order: 70;
  }
  .variant-tile-accordion span.col-lg-2:nth-of-type(n+13),
  .variant-tile-accordion span.col-md-4:nth-of-type(n+13) {
    order: 90;
  }
  .variant-tile-accordion div.target-lg-2:nth-of-type(n+1),
  .variant-tile-accordion div.target-md-4:nth-of-type(n+1) {
    order: 20;
  }
  .variant-tile-accordion div.target-lg-2:nth-of-type(n+4),
  .variant-tile-accordion div.target-md-4:nth-of-type(n+4) {
    order: 40;
  }
  .variant-tile-accordion div.target-lg-2:nth-of-type(n+7),
  .variant-tile-accordion div.target-md-4:nth-of-type(n+7) {
    order: 60;
  }
  .variant-tile-accordion div.target-lg-2:nth-of-type(n+10),
  .variant-tile-accordion div.target-md-4:nth-of-type(n+10) {
    order: 80;
  }
  .variant-tile-accordion div.target-lg-2:nth-of-type(n+13),
  .variant-tile-accordion div.target-md-4:nth-of-type(n+13) {
    order: 100;
  }
  .variant-tile-accordion span.col-md-6:nth-of-type(n+1) {
    order: 10;
  }
  .variant-tile-accordion span.col-md-6:nth-of-type(n+3) {
    order: 30;
  }
  .variant-tile-accordion span.col-md-6:nth-of-type(n+5) {
    order: 50;
  }
  .variant-tile-accordion span.col-md-6:nth-of-type(n+7) {
    order: 70;
  }
  .variant-tile-accordion span.col-md-6:nth-of-type(n+9) {
    order: 90;
  }
  .variant-tile-accordion span.col-md-6:nth-of-type(n+11) {
    order: 110;
  }
  .variant-tile-accordion span.col-md-6:nth-of-type(n+13) {
    order: 130;
  }
  .variant-tile-accordion div.target-md-6:nth-of-type(n+1) {
    order: 20;
  }
  .variant-tile-accordion div.target-md-6:nth-of-type(n+3) {
    order: 40;
  }
  .variant-tile-accordion div.target-md-6:nth-of-type(n+5) {
    order: 60;
  }
  .variant-tile-accordion div.target-md-6:nth-of-type(n+7) {
    order: 80;
  }
  .variant-tile-accordion div.target-md-6:nth-of-type(n+9) {
    order: 100;
  }
  .variant-tile-accordion div.target-md-6:nth-of-type(n+11) {
    order: 120;
  }
  .variant-tile-accordion div.target-md-6:nth-of-type(n+13) {
    order: 140;
  }
}
@media (min-width: 1014px) {
  .variant-tile-accordion span.col-lg-2:nth-of-type(n+1) {
    order: 10;
  }
  .variant-tile-accordion span.col-lg-2:nth-of-type(n+7) {
    order: 30;
  }
  .variant-tile-accordion span.col-lg-2:nth-of-type(n+13) {
    order: 50;
  }
  .variant-tile-accordion div.target-lg-2:nth-of-type(n+1) {
    order: 20;
  }
  .variant-tile-accordion div.target-lg-2:nth-of-type(n+7) {
    order: 40;
  }
  .variant-tile-accordion div.target-lg-2:nth-of-type(n+13) {
    order: 60;
  }
}

/**********
 * Source file:_tiles.scss
 */
.square-col {
  --my-sq-ratio: 1;
  float: left;
  position: relative;
  width: 100%;
  padding-bottom: calc((100% - 2 * var(--my-sq-margin)) * 1 * var(--my-sq-ratio) + 2 * var(--my-sq-ratio) * var(--my-sq-margin)) !important;
}
.square-col .content {
  position: absolute !important;
  overflow: auto;
  z-index: 5;
  border-radius: var(--my-border-radius);
  height: calc(100% - 2 * var(--my-sq-margin));
  width: calc(100% - 2 * var(--my-sq-margin));
  margin: var(--my-sq-margin);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (min-width: 764px) {
  .square-col .content .effect-box {
    height: 100%;
  }
  .square-col .content .effect-box .image-src-box {
    padding-bottom: unset !important;
    height: 100%;
  }
  .square-col .content .effect-box .image-src-box img {
    object-fit: cover;
  }
}
.square-col.square-1-1 {
  --my-sq-ratio: 1;
}
.square-col.square-16-9 {
  --my-sq-ratio: 0.6;
}
.square-col.square-4-3 {
  --my-sq-ratio: 0.75;
}
.square-col.clear-row {
  clear: both;
}
.square-col .table-square {
  display: table;
  height: 100%;
  width: 100%;
  margin-bottom: 0;
  color: inherit;
}
.square-col .table-cell {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
  width: 100%;
}

.square-m-0 {
  --my-sq-margin: 0px;
}

.square-m-1 {
  --my-sq-margin: 1px;
}

.square-m-2 {
  --my-sq-margin: 2px;
}

.square-m-5 {
  --my-sq-margin: 5px;
}

.square-m-10 {
  --my-sq-margin: 10px;
}

.square-m-15 {
  --my-sq-margin: 15px;
}

.square-m-20 {
  --my-sq-margin: 20px;
}

.square-col-list ul.list-entries.row-square,
.row-square {
  margin: calc(-1 * var(--my-sq-margin));
  padding-bottom: var(--my-element-margin);
}
.square-col-list ul.list-entries.row-square:last-of-type,
.row-square:last-of-type {
  padding-bottom: 0;
}

.square-m-0 .square-col .content,
.square-m-0 .square-col .content .image-src-box,
.square-m-0 .square-col .content .image-src-box img {
  border-radius: 0;
}

.square-col-list .square-col .content {
  overflow: hidden;
}
.square-col-list .square-col .content .list-badge {
  position: absolute;
  display: inline-block;
  padding: 2px 4px;
  top: 5px;
  right: 5px;
  text-shadow: none;
}
.square-col-list .square-col .content .list-badge::after {
  content: none;
}
@media (min-width: 764px) {
  .square-col-list .square-col.square-small .preface {
    display: none;
  }
}
@media (min-width: 1014px) {
  .square-col-list .square-col.square-small .preface {
    display: block;
  }
}
@media (min-width: 1200px) {
  .square-col-list .square-col.square-small .preface {
    display: none;
  }
}

.fixcol, .fixcol-xxl-150,
.fixcol-xxl-150-rest, .fixcol-xl-150,
.fixcol-xl-150-rest, .fixcol-lg-150,
.fixcol-lg-150-rest, .fixcol-md-150,
.fixcol-md-150-rest, .fixcol-sm-150,
.fixcol-sm-150-rest, .fixcol-xs-150,
.fixcol-xs-150-rest, .fixcol-xxl-125,
.fixcol-xxl-125-rest, .fixcol-xl-125,
.fixcol-xl-125-rest, .fixcol-lg-125,
.fixcol-lg-125-rest, .fixcol-md-125,
.fixcol-md-125-rest, .fixcol-sm-125,
.fixcol-sm-125-rest, .fixcol-xs-125,
.fixcol-xs-125-rest, .fixcol-xxl-75,
.fixcol-xxl-75-rest, .fixcol-xl-75,
.fixcol-xl-75-rest, .fixcol-lg-75,
.fixcol-lg-75-rest, .fixcol-md-75,
.fixcol-md-75-rest, .fixcol-sm-75,
.fixcol-sm-75-rest, .fixcol-xs-75,
.fixcol-xs-75-rest {
  float: left;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
}

.square-xs-12 {
  width: 100%;
  padding-bottom: calc((100% - 2 * var(--my-sq-margin)) * 1 * var(--my-sq-ratio) + 2 * var(--my-sq-ratio) * var(--my-sq-margin)) !important;
}

.square-xs-9 {
  width: 75%;
  padding-bottom: calc((100% - 2 * var(--my-sq-margin)) * 0.75 * var(--my-sq-ratio) + 1.5 * var(--my-sq-ratio) * var(--my-sq-margin)) !important;
}

.square-xs-8 {
  width: 66.6667%;
  padding-bottom: calc((100% - 2 * var(--my-sq-margin)) * 0.666667 * var(--my-sq-ratio) + 1.333334 * var(--my-sq-ratio) * var(--my-sq-margin)) !important;
}

.square-xs-6 {
  width: 50%;
  padding-bottom: calc((100% - 2 * var(--my-sq-margin)) * 0.5 * var(--my-sq-ratio) + 1 * var(--my-sq-ratio) * var(--my-sq-margin)) !important;
}

.square-xs-4 {
  width: 33.3333%;
  padding-bottom: calc((100% - 2 * var(--my-sq-margin)) * 0.333333 * var(--my-sq-ratio) + 0.666666 * var(--my-sq-ratio) * var(--my-sq-margin)) !important;
}

.square-xs-3 {
  width: 25%;
  padding-bottom: calc((100% - 2 * var(--my-sq-margin)) * 0.25 * var(--my-sq-ratio) + 0.5 * var(--my-sq-ratio) * var(--my-sq-margin)) !important;
}

.square-xs-2 {
  width: 16.6667%;
  padding-bottom: calc((100% - 2 * var(--my-sq-margin)) * 0.166667 * var(--my-sq-ratio) + 0.333334 * var(--my-sq-ratio) * var(--my-sq-margin)) !important;
}

.fixcol-xs-75 {
  width: 105px;
  max-width: 105px;
  flex: 0 0 105px;
}

.fixcol-xs-75-rest {
  width: calc(100% - 105px);
  max-width: calc(100% - 105px);
  flex: 0 0 calc(100% - 105px);
}

.fixcol-xs-125 {
  width: 155px;
  max-width: 155px;
  flex: 0 0 155px;
}

.fixcol-xs-125-rest {
  width: calc(100% - 155px);
  max-width: calc(100% - 155px);
  flex: 0 0 calc(100% - 155px);
}

.fixcol-xs-150 {
  width: 180px;
  max-width: 180px;
  flex: 0 0 180px;
}

.fixcol-xs-150-rest {
  width: calc(100% - 180px);
  max-width: calc(100% - 180px);
  flex: 0 0 calc(100% - 180px);
}

@media (min-width: 552px) {
  .square-sm-12 {
    width: 100%;
    padding-bottom: calc((100% - 2 * var(--my-sq-margin)) * 1 * var(--my-sq-ratio) + 2 * var(--my-sq-ratio) * var(--my-sq-margin)) !important;
  }
  .square-sm-9 {
    width: 75%;
    padding-bottom: calc((100% - 2 * var(--my-sq-margin)) * 0.75 * var(--my-sq-ratio) + 1.5 * var(--my-sq-ratio) * var(--my-sq-margin)) !important;
  }
  .square-sm-8 {
    width: 66.6667%;
    padding-bottom: calc((100% - 2 * var(--my-sq-margin)) * 0.666667 * var(--my-sq-ratio) + 1.333334 * var(--my-sq-ratio) * var(--my-sq-margin)) !important;
  }
  .square-sm-6 {
    width: 50%;
    padding-bottom: calc((100% - 2 * var(--my-sq-margin)) * 0.5 * var(--my-sq-ratio) + 1 * var(--my-sq-ratio) * var(--my-sq-margin)) !important;
  }
  .square-sm-4 {
    width: 33.3333%;
    padding-bottom: calc((100% - 2 * var(--my-sq-margin)) * 0.333333 * var(--my-sq-ratio) + 0.666666 * var(--my-sq-ratio) * var(--my-sq-margin)) !important;
  }
  .square-sm-3 {
    width: 25%;
    padding-bottom: calc((100% - 2 * var(--my-sq-margin)) * 0.25 * var(--my-sq-ratio) + 0.5 * var(--my-sq-ratio) * var(--my-sq-margin)) !important;
  }
  .square-sm-2 {
    width: 16.6667%;
    padding-bottom: calc((100% - 2 * var(--my-sq-margin)) * 0.166667 * var(--my-sq-ratio) + 0.333334 * var(--my-sq-ratio) * var(--my-sq-margin)) !important;
  }
  .fixcol-sm-75 {
    width: 105px;
    max-width: 105px;
    flex: 0 0 105px;
  }
  .fixcol-sm-75-rest {
    width: calc(100% - 105px);
    max-width: calc(100% - 105px);
    flex: 0 0 calc(100% - 105px);
  }
  .fixcol-sm-125 {
    width: 155px;
    max-width: 155px;
    flex: 0 0 155px;
  }
  .fixcol-sm-125-rest {
    width: calc(100% - 155px);
    max-width: calc(100% - 155px);
    flex: 0 0 calc(100% - 155px);
  }
  .fixcol-sm-150 {
    width: 180px;
    max-width: 180px;
    flex: 0 0 180px;
  }
  .fixcol-sm-150-rest {
    width: calc(100% - 180px);
    max-width: calc(100% - 180px);
    flex: 0 0 calc(100% - 180px);
  }
}
@media (min-width: 764px) {
  .square-md-12 {
    width: 100%;
    padding-bottom: calc((100% - 2 * var(--my-sq-margin)) * 1 * var(--my-sq-ratio) + 2 * var(--my-sq-ratio) * var(--my-sq-margin)) !important;
  }
  .square-md-9 {
    width: 75%;
    padding-bottom: calc((100% - 2 * var(--my-sq-margin)) * 0.75 * var(--my-sq-ratio) + 1.5 * var(--my-sq-ratio) * var(--my-sq-margin)) !important;
  }
  .square-md-8 {
    width: 66.6667%;
    padding-bottom: calc((100% - 2 * var(--my-sq-margin)) * 0.666667 * var(--my-sq-ratio) + 1.333334 * var(--my-sq-ratio) * var(--my-sq-margin)) !important;
  }
  .square-md-6 {
    width: 50%;
    padding-bottom: calc((100% - 2 * var(--my-sq-margin)) * 0.5 * var(--my-sq-ratio) + 1 * var(--my-sq-ratio) * var(--my-sq-margin)) !important;
  }
  .square-md-4 {
    width: 33.3333%;
    padding-bottom: calc((100% - 2 * var(--my-sq-margin)) * 0.333333 * var(--my-sq-ratio) + 0.666666 * var(--my-sq-ratio) * var(--my-sq-margin)) !important;
  }
  .square-md-3 {
    width: 25%;
    padding-bottom: calc((100% - 2 * var(--my-sq-margin)) * 0.25 * var(--my-sq-ratio) + 0.5 * var(--my-sq-ratio) * var(--my-sq-margin)) !important;
  }
  .square-md-2 {
    width: 16.6667%;
    padding-bottom: calc((100% - 2 * var(--my-sq-margin)) * 0.166667 * var(--my-sq-ratio) + 0.333334 * var(--my-sq-ratio) * var(--my-sq-margin)) !important;
  }
  .fixcol-md-75 {
    width: 105px;
    max-width: 105px;
    flex: 0 0 105px;
  }
  .fixcol-md-75-rest {
    width: calc(100% - 105px);
    max-width: calc(100% - 105px);
    flex: 0 0 calc(100% - 105px);
  }
  .fixcol-md-125 {
    width: 155px;
    max-width: 155px;
    flex: 0 0 155px;
  }
  .fixcol-md-125-rest {
    width: calc(100% - 155px);
    max-width: calc(100% - 155px);
    flex: 0 0 calc(100% - 155px);
  }
  .fixcol-md-150 {
    width: 180px;
    max-width: 180px;
    flex: 0 0 180px;
  }
  .fixcol-md-150-rest {
    width: calc(100% - 180px);
    max-width: calc(100% - 180px);
    flex: 0 0 calc(100% - 180px);
  }
}
@media (min-width: 1014px) {
  .square-lg-12 {
    width: 100%;
    padding-bottom: calc((100% - 2 * var(--my-sq-margin)) * 1 * var(--my-sq-ratio) + 2 * var(--my-sq-ratio) * var(--my-sq-margin)) !important;
  }
  .square-lg-9 {
    width: 75%;
    padding-bottom: calc((100% - 2 * var(--my-sq-margin)) * 0.75 * var(--my-sq-ratio) + 1.5 * var(--my-sq-ratio) * var(--my-sq-margin)) !important;
  }
  .square-lg-8 {
    width: 66.6667%;
    padding-bottom: calc((100% - 2 * var(--my-sq-margin)) * 0.666667 * var(--my-sq-ratio) + 1.333334 * var(--my-sq-ratio) * var(--my-sq-margin)) !important;
  }
  .square-lg-6 {
    width: 50%;
    padding-bottom: calc((100% - 2 * var(--my-sq-margin)) * 0.5 * var(--my-sq-ratio) + 1 * var(--my-sq-ratio) * var(--my-sq-margin)) !important;
  }
  .square-lg-4 {
    width: 33.3333%;
    padding-bottom: calc((100% - 2 * var(--my-sq-margin)) * 0.333333 * var(--my-sq-ratio) + 0.666666 * var(--my-sq-ratio) * var(--my-sq-margin)) !important;
  }
  .square-lg-3 {
    width: 25%;
    padding-bottom: calc((100% - 2 * var(--my-sq-margin)) * 0.25 * var(--my-sq-ratio) + 0.5 * var(--my-sq-ratio) * var(--my-sq-margin)) !important;
  }
  .square-lg-2 {
    width: 16.6667%;
    padding-bottom: calc((100% - 2 * var(--my-sq-margin)) * 0.166667 * var(--my-sq-ratio) + 0.333334 * var(--my-sq-ratio) * var(--my-sq-margin)) !important;
  }
  .fixcol-lg-75 {
    width: 105px;
    max-width: 105px;
    flex: 0 0 105px;
  }
  .fixcol-lg-75-rest {
    width: calc(100% - 105px);
    max-width: calc(100% - 105px);
    flex: 0 0 calc(100% - 105px);
  }
  .fixcol-lg-125 {
    width: 155px;
    max-width: 155px;
    flex: 0 0 155px;
  }
  .fixcol-lg-125-rest {
    width: calc(100% - 155px);
    max-width: calc(100% - 155px);
    flex: 0 0 calc(100% - 155px);
  }
  .fixcol-lg-150 {
    width: 180px;
    max-width: 180px;
    flex: 0 0 180px;
  }
  .fixcol-lg-150-rest {
    width: calc(100% - 180px);
    max-width: calc(100% - 180px);
    flex: 0 0 calc(100% - 180px);
  }
}
@media (min-width: 1200px) {
  .square-xl-12 {
    width: 100%;
    padding-bottom: calc((100% - 2 * var(--my-sq-margin)) * 1 * var(--my-sq-ratio) + 2 * var(--my-sq-ratio) * var(--my-sq-margin)) !important;
  }
  .square-xl-9 {
    width: 75%;
    padding-bottom: calc((100% - 2 * var(--my-sq-margin)) * 0.75 * var(--my-sq-ratio) + 1.5 * var(--my-sq-ratio) * var(--my-sq-margin)) !important;
  }
  .square-xl-8 {
    width: 66.6667%;
    padding-bottom: calc((100% - 2 * var(--my-sq-margin)) * 0.666667 * var(--my-sq-ratio) + 1.333334 * var(--my-sq-ratio) * var(--my-sq-margin)) !important;
  }
  .square-xl-6 {
    width: 50%;
    padding-bottom: calc((100% - 2 * var(--my-sq-margin)) * 0.5 * var(--my-sq-ratio) + 1 * var(--my-sq-ratio) * var(--my-sq-margin)) !important;
  }
  .square-xl-4 {
    width: 33.3333%;
    padding-bottom: calc((100% - 2 * var(--my-sq-margin)) * 0.333333 * var(--my-sq-ratio) + 0.666666 * var(--my-sq-ratio) * var(--my-sq-margin)) !important;
  }
  .square-xl-3 {
    width: 25%;
    padding-bottom: calc((100% - 2 * var(--my-sq-margin)) * 0.25 * var(--my-sq-ratio) + 0.5 * var(--my-sq-ratio) * var(--my-sq-margin)) !important;
  }
  .square-xl-2 {
    width: 16.6667%;
    padding-bottom: calc((100% - 2 * var(--my-sq-margin)) * 0.166667 * var(--my-sq-ratio) + 0.333334 * var(--my-sq-ratio) * var(--my-sq-margin)) !important;
  }
  .fixcol-xl-75 {
    width: 105px;
    max-width: 105px;
    flex: 0 0 105px;
  }
  .fixcol-xl-75-rest {
    width: calc(100% - 105px);
    max-width: calc(100% - 105px);
    flex: 0 0 calc(100% - 105px);
  }
  .fixcol-xl-125 {
    width: 155px;
    max-width: 155px;
    flex: 0 0 155px;
  }
  .fixcol-xl-125-rest {
    width: calc(100% - 155px);
    max-width: calc(100% - 155px);
    flex: 0 0 calc(100% - 155px);
  }
  .fixcol-xl-150 {
    width: 180px;
    max-width: 180px;
    flex: 0 0 180px;
  }
  .fixcol-xl-150-rest {
    width: calc(100% - 180px);
    max-width: calc(100% - 180px);
    flex: 0 0 calc(100% - 180px);
  }
}
.content-box {
  position: relative;
  border-radius: var(--my-border-radius);
  overflow: hidden;
  z-index: 5;
}

.tile-col {
  position: relative;
}
.tile-col .boxbg-overlay {
  --my-square-text-overlay-bg: #eee;
  --my-square-text-overlay-bg-rgb: 238, 238, 238;
  --my-square-text-overlay-fg: #444;
  --my-square-text-overlay-bg-opacity: 0.75;
  --my-square-text-overlay-fg-shadow: none;
}
.tile-col .full-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
.tile-col .full-overlay:not(.boxbg-overlay) {
  background-color: rgba(0, 0, 0, 0.3);
}
.tile-col .full-overlay:not(.boxbg-overlay) h1, .tile-col .full-overlay:not(.boxbg-overlay) h2, .tile-col .full-overlay:not(.boxbg-overlay) h3, .tile-col .full-overlay:not(.boxbg-overlay) h4, .tile-col .full-overlay:not(.boxbg-overlay) div.body, .tile-col .full-overlay:not(.boxbg-overlay) a.piece-text-link {
  color: #fff;
  text-shadow: 1px 1px 1px #000;
}
.tile-col .full-overlay.boxbg-overlay .piece {
  color: var(--my-square-text-overlay-fg);
  background-color: rgba(var(--my-square-text-overlay-bg-rgb), var(--my-square-text-overlay-bg-opacity));
  display: inline-block;
  padding: calc(0.5 * var(--my-box-padding));
  border-radius: var(--my-image-border-radius);
}
.tile-col .full-overlay .piece {
  margin: calc(0.5 * var(--my-box-padding));
}

.tile-col.element .copyright,
.tile-col.freefloat .copyright,
.list-entry .square-col .copyright {
  --my-copyright-color: #fff;
  --my-copyright-bg-color: transparent;
  --my-copyright-text-shadow: 1px 1px 1px #000;
  line-height: 1;
  padding: var(--my-copyright-padding);
  font-family: var(--my-copyright-font-family);
  font-size: var(--my-copyright-font-size);
  border-radius: var(--my-copyright-border-radius);
  text-align: var(--my-copyright-pos-right, right);
  right: var(--my-copyright-pos-right, 0);
  text-shadow: var(--my-copyright-text-shadow, none);
  display: inline-block;
  color: var(--my-copyright-color);
  background-color: var(--my-copyright-bg-color);
  opacity: var(--my-copyright-opacity);
  position: absolute;
  bottom: 0;
  pointer-events: none;
  z-index: 7;
  box-shadow: none;
  overflow-wrap: anywhere;
  word-break: break-word;
  hyphens: auto;
}

.tile-col,
.square-col {
  --my-square-text-overlay-bg: #000;
  --my-square-text-overlay-bg-rgb: 0, 0, 0;
  --my-square-text-overlay-bg-opacity: 0.65;
  --my-square-text-overlay-fg: #fff;
  --my-square-text-overlay-fg-shadow: 1px 1px 1px #000;
  --my-square-text-overlay-padding-v: 1rem;
  --my-square-text-overlay-padding-h: 2rem;
  --my-square-paragraph-margin: 0.5rem;
}
.tile-col .text-overlay,
.square-col .text-overlay {
  position: absolute;
  bottom: 0;
  padding: var(--my-square-text-overlay-padding-v) var(--my-square-text-overlay-padding-h);
  background-color: rgba(var(--my-square-text-overlay-bg-rgb), var(--my-square-text-overlay-bg-opacity, 1));
  text-decoration: none;
  width: 100%;
  z-index: 5;
  hyphens: auto;
  overflow-wrap: anywhere;
  word-break: break-word;
}
.tile-col .text-overlay h1, .tile-col .text-overlay h2, .tile-col .text-overlay h3, .tile-col .text-overlay h4, .tile-col .text-overlay div,
.square-col .text-overlay h1,
.square-col .text-overlay h2,
.square-col .text-overlay h3,
.square-col .text-overlay h4,
.square-col .text-overlay div {
  color: var(--my-square-text-overlay-fg);
  text-shadow: var(--my-square-text-overlay-fg-shadow);
  line-height: 1.2;
  margin: 0;
}
.tile-col .text-overlay h1,
.square-col .text-overlay h1 {
  font-size: 2rem;
}
.tile-col .text-overlay h2,
.square-col .text-overlay h2 {
  font-size: 1.6rem;
}
.tile-col .text-overlay h3,
.square-col .text-overlay h3 {
  font-size: 1.2rem;
}
.tile-col .text-overlay h4,
.square-col .text-overlay h4 {
  font-size: 1.2rem;
}
.tile-col .text-overlay h2 + h3,
.tile-col .text-overlay div,
.square-col .text-overlay h2 + h3,
.square-col .text-overlay div {
  font-size: 1.2rem;
}
.tile-col .text-overlay .heading + .body,
.tile-col .text-overlay h2 + h3,
.tile-col .text-overlay div.teaser-date + h2,
.square-col .text-overlay .heading + .body,
.square-col .text-overlay h2 + h3,
.square-col .text-overlay div.teaser-date + h2 {
  margin-top: var(--my-square-paragraph-margin);
}
.tile-col a:hover,
.square-col a:hover {
  text-decoration: none;
}
.tile-col a:hover h1, .tile-col a:hover h2, .tile-col a:hover h3, .tile-col a:hover h4, .tile-col a:hover h5, .tile-col a:hover h6,
.square-col a:hover h1,
.square-col a:hover h2,
.square-col a:hover h3,
.square-col a:hover h4,
.square-col a:hover h5,
.square-col a:hover h6 {
  text-decoration: none;
}
@media (max-width: 763.98px) {
  .tile-col.text-below-on-xs:not(.revealed),
  .square-col.text-below-on-xs:not(.revealed) {
    padding: 0 !important;
  }
  .tile-col.text-below-on-xs:not(.revealed).square-4-3.square-xs-12, .tile-col.text-below-on-xs:not(.revealed).square-16-9.square-xs-12,
  .square-col.text-below-on-xs:not(.revealed).square-4-3.square-xs-12,
  .square-col.text-below-on-xs:not(.revealed).square-16-9.square-xs-12 {
    padding-bottom: 0 !important;
  }
  .tile-col.text-below-on-xs:not(.revealed) .content,
  .square-col.text-below-on-xs:not(.revealed) .content {
    height: auto !important;
    position: relative !important;
  }
  .tile-col.text-below-on-xs:not(.revealed) .content .centered.image,
  .square-col.text-below-on-xs:not(.revealed) .content .centered.image {
    position: static;
    transition: none;
    transform: none;
  }
  .tile-col.text-below-on-xs:not(.revealed) .content .text-overlay,
  .square-col.text-below-on-xs:not(.revealed) .content .text-overlay {
    position: relative !important;
  }
  .tile-col.text-below-on-xs:not(.revealed) .image-src-box > img,
  .tile-col.text-below-on-xs:not(.revealed) .image-src-box,
  .square-col.text-below-on-xs:not(.revealed) .image-src-box > img,
  .square-col.text-below-on-xs:not(.revealed) .image-src-box {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .tile-col.text-below-on-xs:not(.revealed) .text-overlay,
  .square-col.text-below-on-xs:not(.revealed) .text-overlay {
    --my-square-text-overlay-fg: #fff;
    --my-square-text-overlay-bg: #000;
    --my-square-text-overlay-bg-rgb: 0, 0, 0;
    --my-square-text-overlay-bg-opacity: 1;
  }
}
.tile-col.min-height-px .text-overlay,
.square-col.min-height-px .text-overlay {
  min-height: calc(3.84rem + var(--my-default-margin-small));
}
@media (min-width: 764px) {
  .tile-col.square-small.min-height .text-overlay,
  .square-col.square-small.min-height .text-overlay {
    min-height: 20%;
  }
  .tile-col.square-large .text-overlay h2,
  .square-col.square-large .text-overlay h2 {
    font-size: 2rem;
  }
  .tile-col.square-large .text-overlay div,
  .tile-col.square-large .text-overlay h3,
  .square-col.square-large .text-overlay div,
  .square-col.square-large .text-overlay h3 {
    font-size: 1.6rem;
  }
}
@media (min-width: 1014px) {
  .tile-col.square-small.min-height .text-overlay,
  .square-col.square-small.min-height .text-overlay {
    min-height: 20%;
  }
  .tile-col.square-small.show-preface .text-overlay,
  .square-col.square-small.show-preface .text-overlay {
    min-height: 35%;
  }
}
@media (min-width: 1014px) {
  .tile-col.min-height.square-small .text-overlay,
  .square-col.min-height.square-small .text-overlay {
    min-height: 40%;
  }
  .tile-col.min-height.square-large .text-overlay,
  .square-col.min-height.square-large .text-overlay {
    min-height: 18.5%;
  }
}

/**********
 * Source file: _lists.scss
 */
.list-content .list-box {
  position: relative;
  display: flex;
  flex-direction: column;
}
.list-content .list-box.initial-load {
  opacity: 0.5;
}
.list-content .list-box.initial-load::after {
  content: " ";
  position: absolute;
  z-index: 10;
  display: block;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.list-content .list-append-position {
  display: block;
}
.list-content ul.list-entries {
  margin-bottom: 0;
  padding: 0;
  list-style-type: none;
}
.list-content.type-static-list ul.list-entries > .list-entry:last-of-type > .teaser:not(.teaser-text-tile) {
  margin-bottom: 0;
}
.list-content .type-list-group {
  position: relative;
}
.list-content .type-list-group:not(:last-of-type) {
  margin-bottom: var(--my-element-margin);
}
.list-content .list-spinner {
  display: none;
}
.list-content .list-link {
  margin-top: var(--my-element-margin);
}

.teaser {
  margin-bottom: var(--my-element-margin);
}
.teaser::after {
  display: block;
  clear: both;
  content: "";
}
.teaser.teaser-compact .teaser-btn {
  padding: 0.2rem 0.8rem;
}
.teaser .intro-headline {
  line-height: 1.25;
  margin-bottom: var(--my-paragraph-margin-bottom);
  font-size: 1.8rem;
}
@media (min-width: 1014px) {
  .teaser .intro-headline {
    font-size: 2rem;
  }
}
.teaser .teaser-btn:not(:last-child) {
  margin-right: var(--my-ws-margin);
}
.teaser .teaser-date,
.teaser .teaser-place {
  line-height: 1.2;
}
.teaser .teaser-date {
  color: var(--my-body-color);
  font-size: 80%;
  font-weight: var(--my-body-font-weight);
}
.teaser .teaser-date:has(+ .teaser-place) {
  --my-piece-paragraph-margin-bottom: calc(0.5 * 1rem);
}
.teaser .teaser-place {
  color: var(--my-intro-headings-color);
  font-size: var(--my-body-font-size);
  font-weight: var(--my-font-weight-bold);
}
.teaser .teaser-place-inline {
  color: var(--my-intro-headings-color);
  font-weight: var(--my-font-weight-bold);
}
.teaser .teaser-place-inline::after {
  content: " / ";
}
.teaser .heading > a {
  display: block;
}
.teaser .heading > a, .teaser .heading > a:focus, .teaser .heading > a:hover {
  text-decoration: none;
}
.teaser .text > a {
  display: block;
}
.teaser .text > a, .teaser .text > a:focus, .teaser .text > a:hover {
  color: var(--my-body-color);
  text-decoration: none;
}
.teaser .list-badge {
  margin-left: 10px;
  font-size: 0.7em;
  padding: 3px 6px;
  bottom: 2px;
}
.teaser .list-badge .ico {
  width: unset;
  margin-right: 0;
}

.list-content .teaser {
  margin-bottom: calc(2 * var(--my-element-margin));
}
.list-content .teaser.teaser-compact {
  margin-bottom: var(--my-element-margin);
}
.list-content.reduced-display .teaser .intro-headline > .intro, .list-content.minimal-display .teaser .intro-headline > .intro {
  font-size: 1.17rem;
}
@media (min-width: 1014px) {
  .list-content.reduced-display .teaser .intro-headline > .intro, .list-content.minimal-display .teaser .intro-headline > .intro {
    font-size: 1.3rem;
  }
}
.list-content.reduced-display .teaser .intro-headline {
  font-size: 1.6rem;
}
@media (min-width: 1014px) {
  .list-content.reduced-display .teaser .intro-headline {
    font-size: 1.8rem;
  }
}
.list-content.minimal-display .teaser {
  margin-bottom: var(--my-element-margin);
}
.list-content.minimal-display .teaser .intro-headline {
  margin-bottom: var(--my-default-margin-verysmall);
  font-size: inherit;
}
.list-content.minimal-display .teaser .teaser-btn {
  padding: 0.2rem 0.8rem;
}
.list-content.minimal-display .teaser .list-badge {
  font-size: 70%;
  padding: 2px 4px;
  bottom: 1px;
}

.teaser-tile {
  display: flex;
}
.teaser-tile:empty {
  display: none;
}
.teaser-tile .teaser.piece {
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 5;
}
.teaser-tile .teaser.piece > .body {
  flex-basis: auto;
  flex-grow: 1;
}
.teaser-tile .teaser.piece > .body .headline {
  overflow: hidden;
  text-overflow: ellipsis;
}
.teaser-tile .teaser.piece > .body {
  display: flex;
  flex-direction: column;
}
.teaser-tile .teaser.piece > .body > .heading .headline {
  line-height: 1.25;
}
.teaser-tile .teaser.piece > .body .type-list-group {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.teaser-tile .teaser.piece > .body .type-list-group .text {
  flex-grow: 1;
}
.teaser-tile .teaser.piece.box {
  padding: 0 0 var(--my-box-padding) 0;
}
.teaser-tile .teaser.piece.box.only-text, .teaser-tile .teaser.piece.box.pnv {
  padding-top: var(--my-box-padding);
}
.teaser-tile .teaser.piece.box > *:not(.visual) {
  padding: 0 var(--my-box-padding);
}
.teaser-tile .teaser.piece.box > .visual .content,
.teaser-tile .teaser.piece.box > .visual .calendar-sheet,
.teaser-tile .teaser.piece.box > .visual .image-src-box,
.teaser-tile .teaser.piece.box > .visual .image-src-box > img {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.teaser-tile .teaser.piece.box > .visual .calendar-sheet {
  border: none;
}

.tile-var .teaser.piece {
  margin-bottom: 0;
  width: 100%;
}
.tile-var .teaser.piece.full > .body {
  flex-basis: auto;
  flex-grow: 1;
}

@media (min-width: 1014px) {
  .list-content.default-display .teaser-tile.teaser-text-tile > .body > .heading .headline {
    min-height: 5rem;
  }
}

.masonry-list .teaser-tile .teaser.piece > .body {
  flex-grow: 0;
}

@media (max-width: 763.98px) {
  .mobile-reduced .teaser-text {
    display: none;
  }
}
.download-list a:hover {
  text-decoration: none;
}
.download-list a:hover .dl-content .dl-title {
  text-decoration: underline;
}
.download-list a:hover .dl-content .dl-desc {
  color: var(--my-body-color);
}
.download-list a:not(:hover) .dl-content .dl-desc,
.download-list a:not(:hover) .dl-content .dl-file {
  color: var(--my-body-color);
}
.download-list .dl-content {
  hyphens: auto;
  overflow: hidden;
  overflow-wrap: anywhere;
  word-break: break-word;
}
.download-list .dl-copy {
  font-size: 80%;
}
.download-list .dl-desc p {
  margin-bottom: 0;
}
.download-list .dl-cat .dl-cat-label {
  margin-right: var(--my-ws-margin);
  display: inline-block;
  line-height: 1;
  font-size: 1.2rem;
  color: var(--my-body-color);
  font-family: var(--my-btn-font-family);
}
.download-list .dl-cat .dl-cat-label::before {
  content: "\f02b";
  padding-right: var(--my-ws-margin);
  font-family: var(--my-icon-font-family);
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.download-list.dl-list-elaborate li.list-entry {
  padding-top: var(--my-default-margin-small);
  padding-bottom: var(--my-default-margin-small);
  border-bottom: 1px solid var(--my-highlight-color);
}
.download-list.dl-list-elaborate li.list-entry:last-child {
  padding-bottom: 0;
  border-bottom: none;
  margin-bottom: var(--my-element-margin);
}
.download-list.dl-list-elaborate li.list-entry:first-child {
  padding-top: 0;
}
.download-list.dl-list-elaborate .dl-content .dl-title {
  margin-top: 0;
  margin-bottom: var(--my-default-margin-verysmall);
}
.download-list.dl-list-elaborate .dl-content .dl-file {
  margin-bottom: var(--my-default-margin-verysmall);
}
.download-list.dl-list-elaborate .dl-content .dl-desc {
  margin-bottom: var(--my-default-margin-verysmall);
}
.download-list.dl-list-elaborate .dl-content .dl-btn {
  margin-top: var(--my-default-margin-verysmall);
  min-width: 200px;
}
.download-list.dl-list-elaborate .dl-content .dl-btn .ico {
  padding-right: var(--my-ws-margin);
}
.download-list.dl-list-elaborate .dl-stats .dl-type::after {
  content: " | ";
}
@media (min-width: 764px) {
  .download-list.dl-list-elaborate .dl-date-cat::after {
    display: block;
    clear: both;
    content: "";
  }
  .download-list.dl-list-elaborate .dl-date-cat > .dl-date {
    float: left;
  }
  .download-list.dl-list-elaborate .dl-date-cat > .dl-cat {
    padding-left: calc(2 * var(--my-ws-margin));
    float: right;
  }
  .download-list.dl-list-elaborate .dl-info {
    margin-top: var(--my-p-spacer);
    text-align: center;
  }
  .download-list.dl-list-elaborate .dl-info .ico {
    font-size: 4rem;
    margin-bottom: var(--my-default-margin-verysmall);
  }
  .download-list.dl-list-elaborate .dl-info .dl-info-text {
    display: none;
  }
}
@media (max-width: 763.98px) {
  .download-list.dl-list-elaborate .dl-info {
    text-align: left;
    margin-top: var(--my-default-margin-verysmall);
  }
  .download-list.dl-list-elaborate .dl-info .btn-info {
    margin: 0;
    padding: 0.6rem 1.2rem;
    display: inline-block;
    text-align: center;
    user-select: none;
    box-shadow: none;
    border: none;
    text-decoration: none;
    font-family: var(--my-btn-font-family);
    white-space: nowrap;
    font-weight: 400;
    border-radius: var(--my-btn-border-radius);
    color: var(--my-btn-fg);
    background-color: var(--my-btn-bg);
  }
  .download-list.dl-list-elaborate .dl-info .btn-info:focus, .download-list.dl-list-elaborate .dl-info .btn-info:hover, .download-list.dl-list-elaborate .dl-info .btn-info.active, .open .dropdown-toggle .download-list.dl-list-elaborate .dl-info .btn-info {
    color: var(--my-btn-hover-fg);
    background-color: var(--my-btn-hover-bg);
    text-decoration: none;
    cursor: pointer;
    transition-property: color, background-color, border-color;
    transition-duration: 0.33s;
    transition-timing-function: ease;
  }
  .download-list.dl-list-elaborate .dl-info .btn-info.disabled, .download-list.dl-list-elaborate .dl-info .btn-info[disabled], fieldset[disabled] .download-list.dl-list-elaborate .dl-info .btn-info {
    background-color: #ddd;
    color: #444;
    cursor: not-allowed;
  }
  .download-list.dl-list-elaborate .dl-info .btn-info {
    margin-top: var(--my-default-margin-verysmall);
    min-width: 200px;
  }
  .download-list.dl-list-elaborate .dl-info .btn-info .ico {
    padding-right: var(--my-ws-margin);
  }
  .download-list.dl-list-elaborate .dl-info .btn-info {
    text-align: left;
    min-width: 50%;
  }
  .download-list.dl-list-elaborate .dl-info .ico {
    min-width: calc(4 * var(--my-ws-margin));
  }
  .download-list.dl-list-elaborate .dl-content .dl-btn {
    min-width: 50%;
    text-align: left;
  }
  .download-list.dl-list-elaborate .dl-content .dl-btn .ico {
    min-width: calc(4 * var(--my-ws-margin));
  }
  .download-list.dl-list-elaborate .dl-stats {
    display: none;
  }
}
.download-list.dl-list-compact .list-entries {
  border-bottom: 1px solid var(--my-highlight-color);
  margin-bottom: var(--my-default-margin-small);
}
.download-list.dl-list-compact li:nth-child(odd) .dl-link {
  background-color: var(--my-highlight-color);
}
.download-list.dl-list-compact .dl-link {
  padding-top: var(--my-default-margin-small);
  padding-bottom: var(--my-default-margin-small);
}
.download-list.dl-list-compact a.dl-link:hover .dl-title {
  color: var(--my-link-hover-color);
}
.download-list.dl-list-compact.box a.dl-link:hover {
  transition: none;
}
.download-list.dl-list-compact .dl-teaser-compact {
  display: flex;
  width: 100%;
}
.download-list.dl-list-compact .dl-link {
  display: flex;
}
.download-list.dl-list-compact .dl-link.dl-link-disp {
  flex-grow: 10;
  width: 100%;
}
.download-list.dl-list-compact .dl-link .ico {
  font-size: 2rem;
}
.download-list.dl-list-compact .dl-type {
  padding: 0 var(--my-ws-margin);
}
.download-list.dl-list-compact .dl-dl {
  padding-right: var(--my-ws-margin);
}
.download-list.dl-list-compact .dl-content {
  flex-grow: 10;
  display: flex;
  flex-direction: column;
}
.download-list.dl-list-compact .dl-content .dl-title {
  font-weight: 600;
  margin: 0;
}
.download-list.dl-list-compact .dl-content .dl-title,
.download-list.dl-list-compact .dl-content .dl-file,
.download-list.dl-list-compact .dl-content .dl-desc {
  margin-right: 1rem;
}
.download-list.dl-list-compact .dl-date,
.download-list.dl-list-compact .dl-size {
  white-space: nowrap;
  min-width: 7rem;
}
.download-list.dl-list-compact .dl-info {
  min-width: 14rem;
  display: flex;
}
.download-list.dl-list-compact .dl-show-file .dl-link .ico {
  padding-top: 0.4rem;
  font-size: 2.5rem;
}
.download-list.dl-list-compact .dl-show-file .dl-info {
  min-width: 7rem;
  flex-direction: column;
}
@media (max-width: 763.98px) {
  .download-list.dl-list-compact .dl-teaser-compact {
    flex-direction: column;
  }
  .download-list.dl-list-compact .dl-link .dl-type {
    min-width: 50px;
  }
  .download-list.dl-list-compact .dl-link.dl-link-disp {
    padding-bottom: 0;
  }
  .download-list.dl-list-compact .dl-link.dl-link-down {
    flex-direction: row;
    justify-content: flex-start;
    padding-top: 0;
    padding-bottom: 1rem;
  }
  .download-list.dl-list-compact .dl-link.dl-link-down .ico {
    padding-top: 0;
    font-size: 1.8rem;
  }
  .download-list.dl-list-compact .dl-link.dl-link-down .dl-info {
    order: 2;
    flex-direction: row;
  }
  .download-list.dl-list-compact .dl-link.dl-link-down .dl-date,
  .download-list.dl-list-compact .dl-link.dl-link-down .dl-size {
    min-width: 7rem;
  }
  .download-list.dl-list-compact .dl-link.dl-link-down .dl-dl {
    order: 1;
    min-width: 50px;
    padding: 0 1rem;
    line-height: inherit;
  }
}

.list-pagination .btn-append {
  padding: 0.6rem 1.2rem;
  display: block;
  width: 100%;
}
.list-pagination:empty {
  display: none;
}

.list-content.pagination-small .pagination > li:not(.disabled) > a:not(:hover) {
  background-color: var(--my-body-bg);
  border-color: var(--my-highlight-border-color);
  color: var(--my-body-color);
}
.list-content.pagination-small .pagination > li:not(.disabled).active > a:not(:hover) {
  background-color: var(--my-highlight-border-color);
  border-color: var(--my-highlight-border-color);
  color: var(--my-highlight-border-fg-color);
}
@media (min-width: 1014px) {
  .list-content.pagination-small .pagination > li.previous {
    margin-right: calc(0.5 * var(--my-ws-margin));
  }
  .list-content.pagination-small .pagination > li.next {
    margin-left: calc(0.5 * var(--my-ws-margin));
  }
  .list-content.pagination-small .pagination > li.first.gap::after {
    margin: 0 calc(0.5 * var(--my-ws-margin));
  }
  .list-content.pagination-small .pagination > li > a {
    padding: 0.2rem 0.6rem;
    min-width: 2.8rem;
  }
}
.list-content.pagination-small .btn-append:not(:hover) {
  background-color: var(--my-highlight-color);
  border-color: var(--my-highlight-color);
  color: var(--my-highlight-fg-color);
}

.pagination-center .list-dynamic.list-paginate .list-pagination {
  display: flex;
  justify-content: center;
}
@media (min-width: 1014px) {
  .pagination-center .list-dynamic:not(.list-paginate) .list-pagination {
    display: flex;
    justify-content: center;
  }
  .pagination-center .list-dynamic:not(.list-paginate) .list-pagination .list-append-position {
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .pagination-center .list-dynamic:not(.list-paginate) .list-pagination .list-append-position {
    width: 40%;
  }
}

.pagination-right .list-dynamic.list-paginate .list-pagination {
  display: flex;
  justify-content: flex-end;
}

.list-entries.row-square ~ .list-pagination,
.list-entries.row ~ .list-pagination {
  margin-top: var(--my-element-margin);
}

.type-list-filter {
  --my-filter-body-bg: var(--my-white);
  --my-filter-body-fg: var(--my-text-dark);
  --my-filter-btn-bg: var(--my-btn-bg);
  --my-filter-btn-fg: var(--my-btn-fg);
  --my-filter-btn-hover-bg: var(--my-btn-hover-bg);
  --my-filter-btn-hover-fg: var(--my-btn-hover-fg);
  --my-filter-border-color: var(--my-grey-light);
  --my-filter-highlight-bg: var(--my-grey-lighter);
  --my-filter-highlight-fg: var(--my-text-dark);
  --my-filter-highlight-border: none;
  --my-filter-highlight-hover-bg: var(--my-grey-light);
  --my-filter-highlight-hover-fg: var(--my-text-dark);
  --my-filter-highlight-hover-border: none;
  --my-filter-disabled-bg: var(--my-filter-body-bg);
  --my-filter-disabled-fg: var(--my-grey);
  --my-filter-disabled-border: 1px solid var(--my-grey-lighter);
}
.type-list-filter button {
  padding-left: 15px;
  padding-right: 40px;
}
.type-list-filter .filterbox {
  border-radius: var(--my-border-radius);
  margin-bottom: var(--my-element-margin);
  color: var(--my-filter-body-fg);
  background-color: var(--my-filter-body-bg);
}
.type-list-filter .filterbox:empty {
  margin-bottom: 0;
}
.type-list-filter .filterbox:last-of-type {
  margin-bottom: 0;
}
.type-list-filter .filterbox .dialog {
  display: none;
}
.type-list-filter .filterbox.search {
  --my-body-bg: var(--my-filter-body-bg);
  --my-body-color: var(--my-filter-body-fg);
  --my-highlight-color: var(--my-filter-disabled-fg);
  padding: 0;
}
.type-list-filter .filterbox.search .styled-form {
  border-radius: var(--my-border-radius);
}
.type-list-filter .filterbox.search .styled-form .input {
  margin: 0;
}
.type-list-filter .filterbox.search .styled-form .input input {
  border-radius: var(--my-border-radius);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
}
.type-list-filter .filterbox.search input,
.type-list-filter .filterbox.search .icon-prepend {
  border-color: var(--my-filter-border-color);
}
.type-list-filter .filterbox .btn.li-label {
  display: block;
  position: relative;
  text-align: left;
  border-radius: var(--my-border-radius);
  color: var(--my-filter-btn-fg);
  background-color: var(--my-filter-btn-bg);
}
.type-list-filter .filterbox .btn.li-label:hover {
  color: var(--my-filter-btn-hover-fg);
  background-color: var(--my-filter-btn-hover-bg);
}
.type-list-filter .filterbox .btn.li-label::before {
  font-family: var(--my-icon-font-family);
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
  padding-right: 1.2rem;
}
.type-list-filter .filterbox .btn.li-label::after,
.type-list-filter .filterbox .nav-side li > a + a::after {
  font-family: var(--my-icon-font-family);
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f078";
  line-height: 1;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  height: 100%;
  transform: rotate(-180deg);
  transition: transform 0.33s;
  width: 40px;
  right: 0;
}
.type-list-filter .filterbox .btn.li-label.collapsed::after,
.type-list-filter .filterbox .nav-side li > a + a.collapsed::after {
  transform: rotate(0);
}
.type-list-filter .filterbox .nav-side li > a + a::after {
  content: "\f107";
}
.type-list-filter .filterbox.categories > div, .type-list-filter .filterbox.archive > div {
  border: 1px solid var(--my-grey-light);
  border-top: none;
  border-bottom-right-radius: var(--my-border-radius);
  border-bottom-left-radius: var(--my-border-radius);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
}
.type-list-filter .filterbox.categories > button.btn.li-label:not(.collapsed), .type-list-filter .filterbox.archive > button.btn.li-label:not(.collapsed) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.type-list-filter .filterbox.categories {
  --my-icon-margin: 2.4rem;
}
.type-list-filter .filterbox.categories > div {
  padding: 15px;
}
.type-list-filter .filterbox.categories .btn.li-label:before {
  content: "\f02c";
}
.type-list-filter .filterbox.categories ul {
  margin: 0;
  padding: 0;
  list-style: outside none none;
}
.type-list-filter .filterbox.categories ul li {
  margin-top: 0.4rem;
}
.type-list-filter .filterbox.categories ul li:first-child {
  margin-top: 0;
}
.type-list-filter .filterbox.categories ul li a {
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  font-size: 1.2rem;
  color: var(--my-body-color);
  font-family: var(--my-btn-font-family);
  padding: calc(0.5 * var(--my-ws-margin)) var(--my-ws-margin);
  border-radius: 0.5rem;
  color: var(--my-text-dark);
  background-color: var(--my-grey-lighter);
}
.type-list-filter .filterbox.categories ul li a:hover {
  background-color: #ddd;
  transition: background-color 0.33s;
}
.type-list-filter .filterbox.categories ul li a::before {
  content: "\f02b";
  padding-right: var(--my-ws-margin);
  font-family: var(--my-icon-font-family);
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.type-list-filter .filterbox.categories ul li a {
  padding-left: var(--my-icon-margin);
  line-height: 1.2;
  text-decoration: none;
}
.type-list-filter .filterbox.categories ul li a::before {
  display: inline-block;
  margin-left: calc(-1 * var(--my-icon-margin));
  width: var(--my-icon-margin);
  padding-left: 0.6rem;
  padding-right: 0;
}
.type-list-filter .filterbox.categories ul li.active a {
  font-weight: 600;
}
.type-list-filter .filterbox.categories ul li .li-count::before {
  padding-left: 0.4rem;
  content: "(";
}
.type-list-filter .filterbox.categories ul li .li-count::after {
  content: ")";
}
.type-list-filter .filterbox.categories ul li.disabled a {
  pointer-events: none;
  cursor: default;
  color: var(--my-filter-disabled-fg);
  background-color: var(--my-filter-disabled-bg);
  border: var(--my-filter-disabled-border);
}
.type-list-filter .filterbox.categories ul li.disabled .li-count {
  display: none;
}
.type-list-filter .filterbox.folders {
  padding: 0;
}
.type-list-filter .filterbox.folders .btn.li-label:before {
  content: "\f115";
}
.type-list-filter .filterbox.folders .nav-side {
  border-top: none;
  margin-left: 0;
  margin-right: 0;
}
.type-list-filter .filterbox.folders .nav-side li > a {
  padding-right: 40px;
}
.type-list-filter .filterbox.folders .nav-side li > a + a {
  padding-left: 0;
  padding-right: 0;
}
.type-list-filter .filterbox.folders .nav-side li.disabled a {
  pointer-events: none;
  cursor: default;
  color: var(--my-grey);
}
.type-list-filter .filterbox.archive .btn.li-label:before {
  content: "\f133";
}
.type-list-filter .filterbox.archive button.year {
  color: var(--my-filter-highlight-fg);
  background-color: var(--my-filter-highlight-bg);
  border: var(--my-filter-highlight-border);
  padding-left: calc(15px - 1px);
}
.type-list-filter .filterbox.archive button.year:hover {
  color: var(--my-filter-highlight-hover-fg);
  background-color: var(--my-filter-highlight-hover-bg);
  border: var(--my-filter-highlight-hover-border);
}
.type-list-filter .filterbox.archive button.year::before {
  content: "\f133";
}
.type-list-filter .filterbox.archive button.year::after {
  width: calc(40px - 2px);
}
.type-list-filter .filterbox.archive ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}
.type-list-filter .filterbox.archive ul.collapsing, .type-list-filter .filterbox.archive ul.show {
  display: flex;
  flex-wrap: wrap;
}
.type-list-filter .filterbox.archive ul li {
  display: inline-block;
  padding: var(--my-ws-margin) 0;
  width: 16.666666%;
  font-size: 1.2rem;
  text-align: center;
  cursor: pointer;
  font-family: var(--my-btn-font-family);
  background-color: var(--my-filter-body-bg);
}
.type-list-filter .filterbox.archive ul li a {
  color: var(--my-filter-highlight-fg);
}
.type-list-filter .filterbox.archive ul li a:hover {
  text-decoration: none;
}
.type-list-filter .filterbox.archive ul li.active a {
  font-weight: 600;
}
.type-list-filter .filterbox.archive ul li .li-entry {
  position: relative;
}
.type-list-filter .filterbox.archive ul li .li-count {
  display: none;
  position: absolute;
  left: calc(100% - 2px);
  top: -7px;
  z-index: 10;
  font-size: 10px;
}
.type-list-filter .filterbox.archive ul li .li-count:before {
  content: "(";
}
.type-list-filter .filterbox.archive ul li .li-count:after {
  content: ")";
}
.type-list-filter .filterbox.archive ul li:hover {
  color: var(--my-filter-highlight-hover-fg);
  background-color: var(--my-filter-highlight-hover-bg);
  transition: background-color 0.33s;
}
.type-list-filter .filterbox.archive ul li:hover .li-count {
  display: block;
}
.type-list-filter .filterbox.archive ul li.disabled {
  background-color: var(--my-filter-disabled-bg);
  pointer-events: none;
  cursor: default;
}
.type-list-filter .filterbox.archive ul li.disabled a {
  pointer-events: none;
  cursor: default;
}
.type-list-filter .filterbox.archive ul li.disabled .li-count {
  display: none;
}
.type-list-filter .filterbox.archive ul li.disabled .li-entry {
  color: var(--my-filter-disabled-fg);
}
.type-list-filter .filterbox.resetbuttons {
  display: flex;
  flex-direction: column;
  flex-basis: content;
  --my-filter-btn-size: calc(0.8rem + var(--my-body-font-size));
}
.type-list-filter .filterbox.resetbuttons .resetbutton {
  display: flex;
  font-family: var(--my-btn-font-family);
  font-weight: 400;
  text-align: left;
  align-items: center;
  color: var(--my-body-color);
  background-color: var(--my-body-bg);
  margin: 0 0 var(--my-ws-margin) 0;
  padding: 0;
  border: none;
}
.type-list-filter .filterbox.resetbuttons .resetbutton:before {
  font-family: var(--my-icon-font-family);
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f00d";
  color: var(--my-filter-btn-fg);
  background-color: var(--my-filter-btn-bg);
  height: var(--my-filter-btn-size);
  line-height: var(--my-filter-btn-size);
  width: var(--my-filter-btn-size);
  min-width: var(--my-filter-btn-size);
  border-radius: 100%;
  margin-right: var(--my-ws-margin);
  display: flex;
  justify-content: center;
}
.type-list-filter .filterbox.resetbuttons .resetbutton:hover, .type-list-filter .filterbox.resetbuttons .resetbutton:focus {
  color: var(--my-link-hover-color);
}
.type-list-filter .filterbox.resetbuttons .resetbutton:focus:before, .type-list-filter .filterbox.resetbuttons .resetbutton:hover:before {
  color: var(--my-filter-btn-hover-fg);
  background-color: var(--my-filter-btn-hover-bg);
}
.type-list-filter .filterbox.resetbuttons .resetbutton:last-of-type {
  margin-bottom: 0;
}
.type-list-filter.inline-list .filterbox ul li {
  display: inline-block;
}

.type-list-sortoptions.box {
  overflow: visible;
}
.type-list-sortoptions .dropdown-toggle {
  padding: 0.6rem 3.6rem 0.6rem 1.2rem;
}
.type-list-sortoptions .dropdown-toggle::after {
  font-family: var(--my-icon-font-family);
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f078";
  line-height: 1;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  height: 100%;
  transform: rotate(0);
  transition: transform 0.33s;
  right: 1.2rem;
  border: none;
  vertical-align: auto;
  margin: 0;
}
.type-list-sortoptions .dropdown-toggle.show::after {
  transform: rotate(-180deg);
}
.type-list-sortoptions .btn-group {
  display: flex;
  justify-content: flex-end;
}
.type-list-sortoptions .dropdown-menu {
  padding: 0;
  border-radius: 0;
  font-size: 1.5rem;
  color: var(--my-body-color);
  min-width: 16rem;
  font-weight: 300;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}
.type-list-sortoptions .dropdown-menu.show {
  animation-name: fadeTopMenu;
  animation-duration: 0.33s;
  animation-fill-mode: both;
  transform-origin: top;
  min-width: 100%;
}
.type-list-sortoptions .dropdown-menu > li > a {
  padding: 0.6rem 1.2rem;
  background-color: var(--my-body-bg);
  color: var(--my-body-color);
  text-decoration: none;
  clear: both;
  display: block;
  white-space: nowrap;
  line-height: 1.4;
}
.type-list-sortoptions .dropdown-menu > li > a:hover {
  color: var(--my-btn-fg);
  background-color: var(--my-btn-bg);
}
.type-list-sortoptions .dropdown-menu > .active > a,
.type-list-sortoptions .dropdown-menu > .active > a:hover,
.type-list-sortoptions .dropdown-menu > .active > a:focus {
  color: var(--my-btn-fg);
  background-color: var(--my-btn-bg);
}
.type-list-sortoptions .dropdown-menu .divider {
  margin: calc(0.5 * var(--my-ws-margin)) 0;
}
.type-list-sortoptions .list-option {
  position: relative;
  margin-bottom: var(--my-element-margin);
  margin-left: var(--my-ws-margin);
}
.type-list-sortoptions .list-option.btn.placeholder {
  background-color: transparent;
}
.type-list-sortoptions .list-option.btn.placeholder::before, .type-list-sortoptions .list-option.btn.placeholder::after {
  content: "";
  background-color: transparent;
}
@media (max-width: 763.98px) {
  .type-list-sortoptions .dropdown-menu,
  .type-list-sortoptions .dropdown-toggle {
    width: 100%;
  }
  .type-list-sortoptions .list-option {
    flex: 0 0 100%;
    margin-left: 0;
  }
}

/**********
 * Source file: _generics.scss
 */
.box,
.box-high {
  --my-body-bg: #eee;
  --my-body-bg-rgb: 238, 238, 238;
  --my-body-color: #444;
  --my-body-color-rgb: 68, 68, 68;
  --my-link-color: #990011;
  --my-link-color-rgb: 153, 0, 17;
  --my-link-hover-color: #dd0011;
  --my-link-hover-color-rgb: 221, 0, 17;
  --my-link-fg-color: #fff;
  --my-link-hover-fg-color: #fff;
  --my-main-theme: var(--my-link-color);
  --my-main-theme-rgb: var(--my-link-color-rgb);
  --my-main-theme-fg: var(--my-link-fg-color);
  --my-main-theme-hover: var(--my-link-hover-color);
  --my-main-theme-hover-fg: var(--my-link-hover-fg-color);
  --my-headings-color: var(--my-body-color);
  --my-intro-headings-color: var(--my-main-theme);
  --my-highlight-color: rgb(var(--my-highlight-color-rgb), 0.25);
  --my-highlight-fg-color: var(--my-body-color);
  --my-highlight-border-color: var(--my-body-color);
  --my-highlight-border-fg-color: var(--my-body-bg);
  --my-btn-bg: var(--my-link-color);
  --my-btn-fg: var(--my-link-fg-color);
  --my-btn-hover-bg: var(--my-link-hover-color);
  --my-btn-hover-fg: var(--my-link-hover-fg-color);
  --my-btn-border: var(--my-btn-bg);
  --my-btn-hover-border: var(--my-btn-hover-bg);
}

.box-colors {
  --my-body-bg: #fff;
  --my-body-bg-rgb: 255, 255, 255;
  --my-body-color: #444;
  --my-body-color-rgb: 68, 68, 68;
  --my-link-color: #990011;
  --my-link-color-rgb: 153, 0, 17;
  --my-link-hover-color: #dd0011;
  --my-link-hover-color-rgb: 221, 0, 17;
  --my-link-fg-color: #fff;
  --my-link-hover-fg-color: #fff;
  --my-main-theme: var(--my-link-color);
  --my-main-theme-rgb: var(--my-link-color-rgb);
  --my-main-theme-fg: var(--my-link-fg-color);
  --my-main-theme-hover: var(--my-link-hover-color);
  --my-main-theme-hover-fg: var(--my-link-hover-fg-color);
  --my-headings-color: var(--my-body-color);
  --my-intro-headings-color: var(--my-main-theme);
  --my-highlight-color: rgb(var(--my-highlight-color-rgb), 0.25);
  --my-highlight-fg-color: var(--my-body-color);
  --my-highlight-border-color: var(--my-body-color);
  --my-highlight-border-fg-color: var(--my-body-bg);
  --my-btn-bg: var(--my-link-color);
  --my-btn-fg: var(--my-link-fg-color);
  --my-btn-hover-bg: var(--my-link-hover-color);
  --my-btn-hover-fg: var(--my-link-hover-fg-color);
  --my-btn-border: var(--my-btn-bg);
  --my-btn-hover-border: var(--my-btn-hover-bg);
}

.box-oct-warning {
  --my-body-bg: #ffa826;
  --my-body-bg-rgb: 255, 168, 38;
  --my-body-color: #fff;
  --my-body-color-rgb: 255, 255, 255;
  --my-link-color: #990011;
  --my-link-color-rgb: 153, 0, 17;
  --my-link-hover-color: #dd0011;
  --my-link-hover-color-rgb: 221, 0, 17;
  --my-link-fg-color: #fff;
  --my-link-hover-fg-color: #fff;
}

.box-oct-error {
  --my-body-bg: #ff264a;
  --my-body-bg-rgb: 255, 38, 74;
  --my-body-color: #fff;
  --my-body-color-rgb: 255, 255, 255;
  --my-link-color: #cccccc;
  --my-link-color-rgb: 204, 204, 204;
  --my-link-hover-color: #fff;
  --my-link-hover-color-rgb: 255, 255, 255;
  --my-link-fg-color: #444;
  --my-link-hover-fg-color: #444;
}

.box-oct-info {
  --my-body-bg: #1bb39a;
  --my-body-bg-rgb: 27, 179, 154;
  --my-body-color: #fff;
  --my-body-color-rgb: 255, 255, 255;
  --my-link-color: #990011;
  --my-link-color-rgb: 153, 0, 17;
  --my-link-hover-color: #fff;
  --my-link-hover-color-rgb: 255, 255, 255;
  --my-link-fg-color: #fff;
  --my-link-hover-fg-color: #444;
}

.box-theme {
  --my-body-bg: #990011;
  --my-body-bg-rgb: 153, 0, 17;
  --my-body-color: #fff;
  --my-body-color-rgb: 255, 255, 255;
  --my-link-color: #cccccc;
  --my-link-color-rgb: 204, 204, 204;
  --my-link-hover-color: #fff;
  --my-link-hover-color-rgb: 255, 255, 255;
  --my-link-fg-color: #444;
  --my-link-hover-fg-color: #444;
}

.box-dark {
  --my-body-bg: #222;
  --my-body-bg-rgb: 34, 34, 34;
  --my-body-color: #fff;
  --my-body-color-rgb: 255, 255, 255;
  --my-link-color: #cccccc;
  --my-link-color-rgb: 204, 204, 204;
  --my-link-hover-color: #fff;
  --my-link-hover-color-rgb: 255, 255, 255;
  --my-link-fg-color: #444;
  --my-link-hover-fg-color: #444;
}

.box-inner {
  --my-body-bg: var(--my-highlight-color);
  --my-body-color: var(--my-highlight-fg-color);
  --my-body-bg-rgb: var(--my-highlight-color-rgb);
}

.box,
.box-inner,
.box-colors {
  color: var(--my-body-color);
  background-color: var(--my-body-bg);
}

.box,
.box-inner {
  padding: var(--my-box-padding);
  border-radius: var(--my-box-border-radius);
  overflow: hidden;
}
.box.fully-linked,
.box-inner.fully-linked {
  padding: 0;
}
.box.fully-linked > a,
.box-inner.fully-linked > a {
  padding: var(--my-box-padding);
}
.box.oc-drag-element,
.box-inner.oc-drag-element {
  overflow: visible;
}
.box.type-tab .tab-content,
.box-inner.type-tab .tab-content {
  padding-bottom: 0;
}

.box.box-ratio-1-1 {
  aspect-ratio: 1/1;
}
.box.box-ratio-4-3 {
  aspect-ratio: 4/3;
}
.box.box-ratio-3-2 {
  aspect-ratio: 3/2;
}
.box.box-ratio-16-9 {
  aspect-ratio: 16/9;
}
.box.box-ratio-35-45 {
  aspect-ratio: 35/45;
}
.box.box-ratio-2-3 {
  aspect-ratio: 2/3;
}

.box-theme.transparent {
  background-color: rgb(var(--my-body-bg-rgb), 0.7);
}

.bc-theme {
  --my-body-bg: #990011;
  background-color: var(--my-body-bg);
}
.bc-theme.transparent {
  background-color: rgb(var(--my-body-bg-rgb), 0.7);
}

.color-theme:not(.imageflip) {
  --my-main-theme: #990011;
  --my-main-theme-fg: #fff;
  --my-btn-bg: var(--my-main-theme);
  --my-btn-fg: var(--my-main-theme-fg);
}

.box-akzent1 {
  --my-body-bg: #235789;
  --my-body-bg-rgb: 35, 87, 137;
  --my-body-color: #fff;
  --my-body-color-rgb: 255, 255, 255;
  --my-link-color: #cccccc;
  --my-link-color-rgb: 204, 204, 204;
  --my-link-hover-color: #fff;
  --my-link-hover-color-rgb: 255, 255, 255;
  --my-link-fg-color: #444;
  --my-link-hover-fg-color: #444;
}
.box-akzent1.transparent {
  background-color: rgb(var(--my-body-bg-rgb), 0.7);
}

.bc-akzent1 {
  --my-body-bg: #235789;
  background-color: var(--my-body-bg);
}
.bc-akzent1.transparent {
  background-color: rgb(var(--my-body-bg-rgb), 0.7);
}

.color-akzent1:not(.imageflip) {
  --my-main-theme: #235789;
  --my-main-theme-fg: #fff;
  --my-btn-bg: var(--my-main-theme);
  --my-btn-fg: var(--my-main-theme-fg);
}

.box-akzent2 {
  --my-body-bg: #ac3931;
  --my-body-bg-rgb: 172, 57, 49;
  --my-body-color: #fff;
  --my-body-color-rgb: 255, 255, 255;
  --my-link-color: #cccccc;
  --my-link-color-rgb: 204, 204, 204;
  --my-link-hover-color: #fff;
  --my-link-hover-color-rgb: 255, 255, 255;
  --my-link-fg-color: #444;
  --my-link-hover-fg-color: #444;
}
.box-akzent2.transparent {
  background-color: rgb(var(--my-body-bg-rgb), 0.7);
}

.bc-akzent2 {
  --my-body-bg: #ac3931;
  background-color: var(--my-body-bg);
}
.bc-akzent2.transparent {
  background-color: rgb(var(--my-body-bg-rgb), 0.7);
}

.color-akzent2:not(.imageflip) {
  --my-main-theme: #ac3931;
  --my-main-theme-fg: #fff;
  --my-btn-bg: var(--my-main-theme);
  --my-btn-fg: var(--my-main-theme-fg);
}

.box-akzent3 {
  --my-body-bg: #ada8b6;
  --my-body-bg-rgb: 173, 168, 182;
  --my-body-color: #fff;
  --my-body-color-rgb: 255, 255, 255;
  --my-link-color: #444;
  --my-link-color-rgb: 68, 68, 68;
  --my-link-hover-color: #eee;
  --my-link-hover-color-rgb: 238, 238, 238;
  --my-link-fg-color: #fff;
  --my-link-hover-fg-color: #444;
}
.box-akzent3.transparent {
  background-color: rgb(var(--my-body-bg-rgb), 0.7);
}

.bc-akzent3 {
  --my-body-bg: #ada8b6;
  background-color: var(--my-body-bg);
}
.bc-akzent3.transparent {
  background-color: rgb(var(--my-body-bg-rgb), 0.7);
}

.color-akzent3:not(.imageflip) {
  --my-main-theme: #ada8b6;
  --my-main-theme-fg: #fff;
  --my-btn-bg: var(--my-main-theme);
  --my-btn-fg: var(--my-main-theme-fg);
}

.bo-theme {
  border-color: #990011;
}

.bo-akzent1 {
  border-color: #235789;
}

.bo-akzent2 {
  border-color: #ac3931;
}

.bo-akzent3 {
  border-color: #ada8b6;
}

.bo-none {
  border: none;
}

.p-0 {
  padding: 0 !important;
}

.ph-0,
.pl-0 {
  padding-left: 0 !important;
}

.ph-0,
.pr-0 {
  padding-right: 0 !important;
}

.pv-0,
.pt-0 {
  padding-top: 0 !important;
}

.pv-0,
.pb-0 {
  padding-bottom: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.mh-0,
.ml-0 {
  margin-left: 0 !important;
}

.mh-0,
.mr-0 {
  margin-right: 0 !important;
}

.mv-0,
.mt-0 {
  margin-top: 0 !important;
}

.mv-0,
.mb-0 {
  margin-bottom: 0 !important;
}

.m-neg-0 {
  margin: -0 !important;
}

.mh-neg-0,
.ml-neg-0 {
  margin-left: -0 !important;
}

.mh-neg-0,
.mr-neg-0 {
  margin-right: -0 !important;
}

.mv-neg-0,
.mt-neg-0 {
  margin-top: -0 !important;
}

.mv-neg-0,
.mb-neg-0 {
  margin-bottom: -0 !important;
}

.p-5 {
  padding: 0.5rem !important;
}

.ph-5,
.pl-5 {
  padding-left: 0.5rem !important;
}

.ph-5,
.pr-5 {
  padding-right: 0.5rem !important;
}

.pv-5,
.pt-5 {
  padding-top: 0.5rem !important;
}

.pv-5,
.pb-5 {
  padding-bottom: 0.5rem !important;
}

.m-5 {
  margin: 0.5rem !important;
}

.mh-5,
.ml-5 {
  margin-left: 0.5rem !important;
}

.mh-5,
.mr-5 {
  margin-right: 0.5rem !important;
}

.mv-5,
.mt-5 {
  margin-top: 0.5rem !important;
}

.mv-5,
.mb-5 {
  margin-bottom: 0.5rem !important;
}

.m-neg-5 {
  margin: -0.5rem !important;
}

.mh-neg-5,
.ml-neg-5 {
  margin-left: -0.5rem !important;
}

.mh-neg-5,
.mr-neg-5 {
  margin-right: -0.5rem !important;
}

.mv-neg-5,
.mt-neg-5 {
  margin-top: -0.5rem !important;
}

.mv-neg-5,
.mb-neg-5 {
  margin-bottom: -0.5rem !important;
}

.p-10 {
  padding: 1rem !important;
}

.ph-10,
.pl-10 {
  padding-left: 1rem !important;
}

.ph-10,
.pr-10 {
  padding-right: 1rem !important;
}

.pv-10,
.pt-10 {
  padding-top: 1rem !important;
}

.pv-10,
.pb-10 {
  padding-bottom: 1rem !important;
}

.m-10 {
  margin: 1rem !important;
}

.mh-10,
.ml-10 {
  margin-left: 1rem !important;
}

.mh-10,
.mr-10 {
  margin-right: 1rem !important;
}

.mv-10,
.mt-10 {
  margin-top: 1rem !important;
}

.mv-10,
.mb-10 {
  margin-bottom: 1rem !important;
}

.m-neg-10 {
  margin: -1rem !important;
}

.mh-neg-10,
.ml-neg-10 {
  margin-left: -1rem !important;
}

.mh-neg-10,
.mr-neg-10 {
  margin-right: -1rem !important;
}

.mv-neg-10,
.mt-neg-10 {
  margin-top: -1rem !important;
}

.mv-neg-10,
.mb-neg-10 {
  margin-bottom: -1rem !important;
}

.p-20 {
  padding: 2rem !important;
}

.ph-20,
.pl-20 {
  padding-left: 2rem !important;
}

.ph-20,
.pr-20 {
  padding-right: 2rem !important;
}

.pv-20,
.pt-20 {
  padding-top: 2rem !important;
}

.pv-20,
.pb-20 {
  padding-bottom: 2rem !important;
}

.m-20 {
  margin: 2rem !important;
}

.mh-20,
.ml-20 {
  margin-left: 2rem !important;
}

.mh-20,
.mr-20 {
  margin-right: 2rem !important;
}

.mv-20,
.mt-20 {
  margin-top: 2rem !important;
}

.mv-20,
.mb-20 {
  margin-bottom: 2rem !important;
}

.m-neg-20 {
  margin: -2rem !important;
}

.mh-neg-20,
.ml-neg-20 {
  margin-left: -2rem !important;
}

.mh-neg-20,
.mr-neg-20 {
  margin-right: -2rem !important;
}

.mv-neg-20,
.mt-neg-20 {
  margin-top: -2rem !important;
}

.mv-neg-20,
.mb-neg-20 {
  margin-bottom: -2rem !important;
}

.p-30 {
  padding: 3rem !important;
}

.ph-30,
.pl-30 {
  padding-left: 3rem !important;
}

.ph-30,
.pr-30 {
  padding-right: 3rem !important;
}

.pv-30,
.pt-30 {
  padding-top: 3rem !important;
}

.pv-30,
.pb-30 {
  padding-bottom: 3rem !important;
}

.m-30 {
  margin: 3rem !important;
}

.mh-30,
.ml-30 {
  margin-left: 3rem !important;
}

.mh-30,
.mr-30 {
  margin-right: 3rem !important;
}

.mv-30,
.mt-30 {
  margin-top: 3rem !important;
}

.mv-30,
.mb-30 {
  margin-bottom: 3rem !important;
}

.m-neg-30 {
  margin: -3rem !important;
}

.mh-neg-30,
.ml-neg-30 {
  margin-left: -3rem !important;
}

.mh-neg-30,
.mr-neg-30 {
  margin-right: -3rem !important;
}

.mv-neg-30,
.mt-neg-30 {
  margin-top: -3rem !important;
}

.mv-neg-30,
.mb-neg-30 {
  margin-bottom: -3rem !important;
}

.p-40 {
  padding: 4rem !important;
}

.ph-40,
.pl-40 {
  padding-left: 4rem !important;
}

.ph-40,
.pr-40 {
  padding-right: 4rem !important;
}

.pv-40,
.pt-40 {
  padding-top: 4rem !important;
}

.pv-40,
.pb-40 {
  padding-bottom: 4rem !important;
}

.m-40 {
  margin: 4rem !important;
}

.mh-40,
.ml-40 {
  margin-left: 4rem !important;
}

.mh-40,
.mr-40 {
  margin-right: 4rem !important;
}

.mv-40,
.mt-40 {
  margin-top: 4rem !important;
}

.mv-40,
.mb-40 {
  margin-bottom: 4rem !important;
}

.m-neg-40 {
  margin: -4rem !important;
}

.mh-neg-40,
.ml-neg-40 {
  margin-left: -4rem !important;
}

.mh-neg-40,
.mr-neg-40 {
  margin-right: -4rem !important;
}

.mv-neg-40,
.mt-neg-40 {
  margin-top: -4rem !important;
}

.mv-neg-40,
.mb-neg-40 {
  margin-bottom: -4rem !important;
}

.mt-pm {
  margin-top: var(--my-paragraph-margin-bottom) !important;
}

.mb-pm {
  margin-bottom: var(--my-paragraph-margin-bottom) !important;
}

.mt-xs {
  margin-top: var(--my-default-margin-verysmall-bottom) !important;
}

.mb-xs {
  margin-bottom: var(--my-default-margin-verysmall-bottom) !important;
}

.mt-sm {
  margin-top: var(--my-default-margin-small) !important;
}

.mb-sm {
  margin-bottom: var(--my-default-margin-small) !important;
}

.mt-md {
  margin-top: var(--my-default-margin) !important;
}

.mb-md {
  margin-bottom: var(--my-default-margin) !important;
}

.type-spacer[class^=box-], .type-spacer[class*=" box-"] {
  background-color: var(--my-body-bg);
}
.type-spacer.space-xs {
  padding-bottom: var(--my-default-margin-verysmall);
}
.type-spacer.space-sm {
  padding-bottom: var(--my-default-margin-small);
}
.type-spacer.space-md {
  padding-bottom: var(--my-default-margin);
}
.type-spacer.space-lg {
  padding-bottom: calc(2 * var(--my-default-margin));
}
.type-spacer.space-xl {
  padding-bottom: calc(4 * var(--my-default-margin));
}
.type-spacer.space-xxl {
  padding-bottom: calc(8 * var(--my-default-margin));
}
@media (min-width: 1014px) {
  .type-spacer.space-lg-none {
    padding-bottom: 0;
  }
  .type-spacer.space-lg-xs {
    padding-bottom: var(--my-default-margin-verysmall);
  }
  .type-spacer.space-lg-sm {
    padding-bottom: var(--my-default-margin-small);
  }
  .type-spacer.space-lg-md {
    padding-bottom: var(--my-default-margin);
  }
  .type-spacer.space-lg-lg {
    padding-bottom: calc(2 * var(--my-default-margin));
  }
  .type-spacer.space-lg-xl {
    padding-bottom: calc(4 * var(--my-default-margin));
  }
  .type-spacer.space-lg-xxl {
    padding-bottom: calc(8 * var(--my-default-margin));
  }
}

.opencms-page-editor .type-spacer {
  background-image: repeating-linear-gradient(90deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1) 1px, transparent 1px, transparent 20px);
}

.align-right.piece.full {
  text-align: right;
}
.align-right.ls-row > ul {
  display: block;
  float: right;
}

.mb-0 {
  margin-bottom: 0 !important;
}

@media (min-width: 552px) {
  .align-sm-right.piece.full {
    text-align: right;
  }
  .align-sm-right.ls-row > ul {
    display: block;
    float: right;
  }
  .mb-0-sm {
    margin-bottom: 0 !important;
  }
}
@media (min-width: 764px) {
  .align-md-right.piece.full {
    text-align: right;
  }
  .align-md-right.ls-row > ul {
    display: block;
    float: right;
  }
  .mb-0-md {
    margin-bottom: 0 !important;
  }
}
@media (min-width: 1014px) {
  .align-lg-right.piece.full {
    text-align: right;
  }
  .align-lg-right.ls-row > ul {
    display: block;
    float: right;
  }
  .mb-0-lg {
    margin-bottom: 0 !important;
  }
}
@media (min-width: 1200px) {
  .align-xl-right.piece.full {
    text-align: right;
  }
  .align-xl-right.ls-row > ul {
    display: block;
    float: right;
  }
  .mb-0-xl {
    margin-bottom: 0 !important;
  }
}
@media (min-width: 1400px) {
  .align-xxl-right.piece.full {
    text-align: right;
  }
  .align-xxl-right.ls-row > ul {
    display: block;
    float: right;
  }
  .mb-0-xxl {
    margin-bottom: 0 !important;
  }
}
/**********
 * Source file: _root.scss
 */
:root,
.box-body-base,
.box-body {
  --my-body-bg: #fff;
  --my-body-bg-rgb: 255, 255, 255;
  --my-body-color: #444;
  --my-body-color-rgb: 68, 68, 68;
  --my-link-color: #990011;
  --my-link-color-rgb: 153, 0, 17;
  --my-link-hover-color: #dd0011;
  --my-link-hover-color-rgb: 221, 0, 17;
  --my-link-fg-color: #fff;
  --my-link-hover-fg-color: #fff;
  --my-link-opacity: 1.0;
  --my-main-theme: #990011;
  --my-main-theme-rgb: 153, 0, 17;
  --my-main-theme-fg: #fff;
  --my-main-theme-hover: #dd0011;
  --my-main-theme-hover-fg: #fff;
  --my-headings-color: #222;
  --my-intro-headings-color: var(--my-main-theme);
  --my-highlight-color: #eee;
  --my-highlight-color-rgb: 238, 238, 238;
  --my-highlight-fg-color: #444;
  --my-highlight-border-color: #ddd;
  --my-highlight-border-fg-color: #444;
  --my-btn-bg: var(--my-main-theme);
  --my-btn-fg: var(--my-main-theme-fg);
  --my-btn-hover-bg: var(--my-main-theme-hover);
  --my-btn-hover-fg: var(--my-main-theme-hover-fg);
  --my-btn-border: var(--my-btn-fg);
  --my-btn-hover-border: var(--my-btn-hover-fg);
}

:root {
  --my-body-font-family: "Fira Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --my-body-font-size: 1.5rem;
  --my-body-font-weight: 300;
  --my-body-line-height: 1.5;
  --my-font-weight-bold: 600;
  --my-disabled-color: #ddd;
  --my-disabled-fg-color: #444;
  --my-main-theme-base: #990011;
  --my-white: #fff;
  --my-grey-lighter: #eee;
  --my-grey-light: #ddd;
  --my-grey: #aaa;
  --my-text-dark: #444;
  --my-text-light: #fff;
  --my-nav-toggle-bg: var(--my-main-theme);
  --my-nav-toggle-fg: var(--my-main-theme-fg);
  --my-font-family-headings: var(--my-body-font-family);
  --my-btn-font-family: var(--my-body-font-family);
  --my-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --my-font-weight-headings: 600;
  --my-font-style-headings: normal;
  --my-border-radius: 0;
  --my-btn-border-radius: 0;
  --my-image-border-radius: var(--my-border-radius);
  --my-box-border-radius: var(--my-border-radius);
  --my-default-margin: 2rem;
  --my-default-margin-small: 1rem;
  --my-default-margin-verysmall: 0.5rem;
  --my-element-margin: 2rem;
  --my-ws-margin: 1rem;
  --my-box-padding: 20px;
  --my-paragraph-margin-bottom: 1rem;
  --my-headings-margin-bottom: 1.5rem;
  --my-100dvh: 100vh;
}
@supports (height: 100dvh) {
  :root {
    --my-100dvh: 100dvh;
  }
}

/**********
 * Source file: _jsondata.scss
 */
#template-info {
  visibility: hidden;
}
#template-info::before {
  display: none;
  content: '{"main-theme":"#990011", "main-theme-hover":"#dd0011", "map-color":["#990011","#dd0011","#235789","#ac3931","#ada8b6","#ddd","#aaa","#eee","#888","#fff","#000","#aaa"], "map-center":"#990011", "map-cluster":"#ac3931", "map-style":[{"featureType":"all","elementType":"all","stylers":[{"saturation":"-100"}]},{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"color":"#990011"}]},{"featureType":"landscape.natural","elementType":"all","stylers":[{"lightness":"75"},{"gamma":"1.00"}]},{"featureType":"poi","elementType":"geometry.fill","stylers":[{"color":"#d8ded8"},{"saturation":"-30"},{"lightness":"0"},{"gamma":"1"}]},{"featureType":"poi.business","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"poi.park","elementType":"geometry.fill","stylers":[{"color":"#d8ded8"}]},{"featureType":"road","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#fafafa"}]},{"featureType":"water","elementType":"geometry.fill","stylers":[{"color":"#a5b1be"}]}]}';
}

#template-grid-info::before {
  display: none;
  content: "xs";
}
@media (min-width: 552px) {
  #template-grid-info::before {
    content: "sm";
  }
}
@media (min-width: 764px) {
  #template-grid-info::before {
    content: "md";
  }
}
@media (min-width: 1014px) {
  #template-grid-info::before {
    content: "lg";
  }
}
@media (min-width: 1200px) {
  #template-grid-info::before {
    content: "xl";
  }
}
@media (min-width: 1400px) {
  #template-grid-info::before {
    content: "xxl";
  }
}
#template-grid-info::after {
  display: none;
  content: '{"xsMax":551.98px, "smMin":552px, "smMax":763.98px, "mdMin":764px, "mdMax":1013.98px, "lgMin":1014px, "lgMax":1199.98px, "xlMin":1200px, "xlMax":1399.98px, "xxlMin":1400px, "bhTogTop":"30px","navMobMax":1, "navDeskMin":2, "navMobPos":"right","navFixHeader":"true"}';
}

.template-info.bg-colors::before {
  display: none;
  content: '[{"name": "theme","value": "#990011"}, {"name": "akzent1","value": "#235789"}, {"name": "akzent2","value": "#ac3931"}, {"name": "akzent3","value": "#ada8b6"}, {}]';
}
.template-info.text-colors::before {
  display: none;
  content: "[{}]";
}
.template-info.link-colors::before {
  display: none;
  content: "[{}]";
}

/**********
 * Source file: _blog.scss
 */
.blog-item.disabled {
  background-image: repeating-linear-gradient(135deg, transparent, transparent 10px, rgba(0, 0, 0, 0.1) 10px, rgba(0, 0, 0, 0.1) 20px);
}
.blog-item .bl-i {
  white-space: nowrap;
  hyphens: none;
}
.blog-item .bl-i::before {
  margin-right: var(--my-ws-margin);
  font-family: var(--my-icon-font-family);
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.blog-item .bl-date::before {
  content: "\f133";
}
.blog-item .bl-author::before {
  content: "\f2c0";
}
.blog-item .bl-comments::before {
  content: "\f0e6";
}
.blog-item .bl-i.bl-comments:empty {
  display: none;
}
.blog-item .bl-link > .bl-i::before {
  content: "\f054";
}
.blog-item .bl-link {
  display: block;
  margin-top: var(--my-default-margin);
  font-weight: 400;
}
.blog-item .bl-info > span {
  margin-right: var(--my-default-margin);
}
.blog-item .piece > .visual .subtitle {
  line-height: inherit;
  font-size: inherit;
}
.blog-item .bl-intro {
  margin-bottom: var(--my-default-margin-small);
}
.blog-item .bl-intro .body {
  margin-bottom: var(--my-default-margin-small);
}
.blog-item .bl-content .piece {
  margin-bottom: var(--my-default-margin-small);
}
.blog-item .bl-content a.btn {
  color: #990011;
  text-decoration: none;
  background-color: transparent;
  padding: 0;
}
.blog-item .bl-content a.btn:hover {
  color: #dd0011;
  text-decoration: underline;
}
.blog-item .intro-headline {
  margin-bottom: var(--my-default-margin-small);
}
.blog-item ul {
  list-style-type: disc;
  margin-bottom: var(--my-default-margin-small);
}

.list-content .blog-item {
  margin-bottom: calc(2 * var(--my-element-margin));
}

.full-visual .full-visual-image {
  position: sticky;
  top: 0;
}
.full-visual .full-visual-image .tile-col .image-src-box.presized {
  padding-bottom: 0 !important;
  height: calc(var(--fvwh, 100vh) - var(--fvhh, 0px));
}
.full-visual .full-visual-image .tile-col .image-src-box.presized > img {
  top: calc(-1 * var(--fvct, 0px));
  height: 100%;
  object-fit: cover;
  object-position: top;
}
.full-visual .full-visual-image .tile-col.element {
  margin-bottom: 0;
}
.full-visual .full-visual-image .tile-col .full-overlay {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.full-visual .full-visual-image .tile-col .full-overlay .piece {
  width: 100%;
  opacity: var(--fvco, 1);
  top: var(--fvct, 0px);
}
.full-visual .full-visual-image .tile-col .full-overlay .piece .text,
.full-visual .full-visual-image .tile-col .full-overlay .piece .heading {
  hyphens: none;
}
@media (min-width: 552px) {
  .full-visual .full-visual-image .tile-col .full-overlay .piece {
    margin-right: 10%;
    margin-left: 10%;
  }
}
@media (min-width: 1014px) {
  .full-visual .full-visual-image .tile-col .full-overlay .piece {
    margin-right: 25%;
    margin-left: 25%;
  }
}
.full-visual .full-visual-image .tile-col .full-overlay .piece::after {
  font-family: var(--my-icon-font-family);
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f078";
  display: block;
  text-align: center;
  color: #fff;
  text-shadow: 1px 1px 1px #000;
  transform: scaleY(0.6);
  font-size: 7rem;
  line-height: 5rem;
}
.full-visual .full-visual-image .tile-col .animate .piece {
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-name: bounce-full-visual;
  animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
}
.full-visual .full-visual-content {
  background-color: var(--my-body-bg);
  position: sticky;
}
.full-visual .full-visual-content header {
  margin-bottom: 0;
}
.full-visual .full-visual-content header + main {
  margin-top: var(--my-default-margin);
}

@keyframes bounce-full-visual {
  50% {
    transform: translateY(0);
  }
  63% {
    transform: translateY(25%);
  }
  75% {
    transform: translateY(0);
  }
  88% {
    transform: translateY(25%);
  }
  100% {
    transform: translateY(0);
  }
}
#mercury-page.full-visual-page {
  overflow: initial;
}

html.opencms-page-editor .full-visual-image {
  top: 52px;
}
html.opencms-page-editor .full-visual-image .tile-col .image-src-box.presized {
  height: calc(var(--fvwh, 100vh) - var(--fvhh, 0px) - 52px);
}

/**********
 * Source file: _calendar.scss
 */
.type-list-calendar.box .cal-pagination {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.type-list-calendar.box .cal-pagination:not(.btn-colored) {
  color: var(--my-body-color);
  background-color: rgba(var(--my-body-bg-rgb), 0.7);
}
.type-list-calendar.box .cal-pagination .cal-sheet-month {
  max-width: none;
}
.type-list-calendar.box table {
  background-color: rgba(var(--my-body-bg-rgb), 0.7);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.type-list-calendar.box table td,
.type-list-calendar.box table th {
  border-color: var(--my-grey-dark);
}
.type-list-calendar.box table td.cal-sup {
  color: var(--my-grey-dark);
}
.type-list-calendar .cal-pagination {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.type-list-calendar .cal-pagination > div {
  font-size: 20px;
  min-width: 34px;
}
.type-list-calendar .cal-pagination .cal-sheet-month {
  flex-grow: 1;
  text-align: center;
}
@media (min-width: 1014px) {
  .type-list-calendar .cal-pagination .cal-sheet-month {
    max-width: 300px;
  }
}
.type-list-calendar .cal-pagination .btn {
  padding: 4px;
  width: 34px;
  height: 34px;
}
.type-list-calendar table {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
}
.type-list-calendar table thead,
.type-list-calendar table tbody,
.type-list-calendar table tr td,
.type-list-calendar table tr th {
  color: var(--my-body-color);
  flex: 1 0 14.2857%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.type-list-calendar table th {
  border-top: none;
}
.type-list-calendar table tr {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.type-list-calendar table th,
.type-list-calendar table td {
  border-color: #aaa;
  border-top-width: 1px;
  height: 37px;
  padding: 0;
}
.type-list-calendar table th > *,
.type-list-calendar table td > * {
  display: block;
  border-radius: 0;
  padding: 4px;
  width: 34px;
  text-align: right;
  font-size: 20px;
  line-height: 1;
}
.type-list-calendar table td > a {
  position: relative;
  font-weight: 600;
}
.type-list-calendar table td > a:hover {
  text-decoration: none;
}
.type-list-calendar table td > a.active {
  border: 2px solid var(--my-main-theme);
  padding-right: 2px;
  color: var(--my-link-color);
  background-color: transparent;
}
.type-list-calendar table td > a.active:hover {
  color: var(--my-link-hover-color);
}
.type-list-calendar table td > .disabled {
  display: none;
}
.type-list-calendar table td.cal-today > * {
  font-weight: 600;
  color: var(--my-body-bg);
  background-color: var(--my-main-theme);
}
.type-list-calendar table td.cal-sup {
  color: var(--my-grey);
}
.type-list-calendar.cal-dc-ball table td > a > div {
  position: absolute;
  right: -11px;
  top: -5px;
  font-size: 11px;
  color: #fff;
  background-color: #b31b34;
  height: 16px;
  min-width: 16px;
  line-height: 16px;
  padding: 0 2px;
  text-align: center;
  border: none;
  border-radius: 100%;
  font-weight: 600;
}
.type-list-calendar.cal-dc-ball table td > a.active > div {
  right: -13px;
}
.type-list-calendar.cal-dc-point table td > a > div, .type-list-calendar.cal-dc-small table td > a > div {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  top: 4px;
  padding: 0;
  font-size: 10px;
  color: var(--my-body-color);
  font-weight: 600;
  line-height: 1;
}
.type-list-calendar.cal-dc-small table td.cal-today > a > div {
  right: -12px;
}
.type-list-calendar.cal-dc-small table td > a > div {
  width: 10px;
  right: -9px;
}
.type-list-calendar.cal-dc-small table td > a.active > div {
  right: -14px;
}
.type-list-calendar.cal-dc-point table td.cal-today > a > div {
  right: -7px;
}
.type-list-calendar.cal-dc-point table td > a > div {
  color: transparent;
  right: -4px;
  background-color: #b31b34;
  height: 5px;
  width: 5px;
  border-radius: 100%;
}
.type-list-calendar.cal-dc-point table td > a.active > div {
  right: -9px;
}
.type-list-calendar.cal-filterbox .cal-sheet {
  border-radius: var(--my-border-radius);
  background-color: var(--my-body-bg);
}
.type-list-calendar.cal-filterbox .cal-pagination {
  border-top-right-radius: var(--my-border-radius);
  border-top-left-radius: var(--my-border-radius);
}
.type-list-calendar.cal-filterbox .cal-pagination .btn {
  border-radius: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.type-list-calendar.cal-filterbox .cal-pagination .btn .ico,
.type-list-calendar.cal-filterbox .cal-pagination .btn .fa {
  font-size: 1.5rem;
}
.type-list-calendar.cal-filterbox .cal-pagination .prev .btn {
  border-top-left-radius: var(--my-border-radius);
}
.type-list-calendar.cal-filterbox .cal-pagination .next .btn {
  border-top-right-radius: var(--my-border-radius);
}
.type-list-calendar.cal-filterbox .cal-pagination .prev,
.type-list-calendar.cal-filterbox .cal-pagination .next {
  cursor: pointer;
}
.type-list-calendar.cal-filterbox .cal-sheet-month {
  font-family: "Fira Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.5rem;
  font-weight: 400;
  max-width: none;
  line-height: inherit;
  padding: 0.6rem 1.2rem;
  padding-left: 0;
  padding-right: 0;
}
.type-list-calendar.cal-filterbox table {
  border-bottom-right-radius: var(--my-border-radius);
  border-bottom-left-radius: var(--my-border-radius);
  border: 1px solid var(--my-grey-light);
  border-top: none;
}
.type-list-calendar.cal-filterbox table th,
.type-list-calendar.cal-filterbox table td {
  border-color: var(--my-grey-light);
}

@media (min-width: 1014px) {
  .col-lg-3 .type-list-calendar table th > *,
  .col-lg-3 .type-list-calendar table td > *,
  .col-lg-4 .type-list-calendar table th > *,
  .col-lg-4 .type-list-calendar table td > * {
    font-size: 18px;
    width: auto;
  }
  .col-lg-3 .type-list-calendar table th > *,
  .col-lg-4 .type-list-calendar table th > * {
    padding: 0;
  }
  .col-lg-3 .type-list-calendar table td > *,
  .col-lg-4 .type-list-calendar table td > * {
    padding: 3px;
  }
}
/**********
 * Source file:_dayreadings.scss
 */
.type-dayreadings .readingdate {
  margin-bottom: 8px;
}
.type-dayreadings .pericopes {
  margin-top: 12px;
  border-top: 1px solid #eee;
  display: inline-block;
  width: 100%;
}
.type-dayreadings .pericopes .title {
  margin-top: 12px;
}
.type-dayreadings .pericopes .title .type {
  font-weight: 600;
}
.type-dayreadings .pericopes .motto {
  font-style: italic;
  margin-top: 8px;
}
.type-dayreadings .verse {
  padding-top: 8px;
  clear: both;
}
.type-dayreadings .verse .number {
  float: left;
  display: inline-block;
  width: 10%;
  font-weight: 600;
}
.type-dayreadings .verse .text {
  float: right;
  display: inline-block;
  width: 90%;
}
.type-dayreadings .verselast div {
  padding-bottom: 0;
}

/**********
 * Source file: _event-ext.scss
 */
.event-dates {
  --my-dates-spacing: 30px;
}
.event-dates .dates-table {
  margin-bottom: var(--my-paragraph-margin-bottom);
  display: table;
}
.event-dates .dates-table .dates-hr {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--my-paragraph-margin-bottom);
}
.event-dates .dates-table .dates-month {
  font-weight: var(--my-font-weight-bold);
}
.event-dates .dates-table .dates-date {
  display: table-row;
}
.event-dates .dates-table .dates-time,
.event-dates .dates-table .dates-weekday {
  display: table-cell;
}
.event-dates .dates-table .dates-weekday {
  padding-right: var(--my-dates-spacing);
}

.booking-iframe iframe {
  width: 100%;
  min-height: 300px;
}

/**********
 * Source file: _geosearch.scss
 */
.type-geosearch .dropdown {
  position: static;
}
.type-geosearch .dropdown .dropdown-toggle {
  position: relative;
  text-align: left;
  white-space: normal !important;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.type-geosearch .dropdown .dropdown-toggle::after {
  font-family: var(--my-icon-font-family);
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f078";
  font-weight: normal;
  right: 1.2rem;
  transition: 0.33s;
  margin: 0;
  border: none;
}
.type-geosearch .dropdown.show .dropdown-toggle::after {
  transform: rotate(-180deg) translate(0, 0);
}
.type-geosearch .dropdown .dropdown-menu {
  max-width: 80vw;
}
.type-geosearch .dropdown .dropdown-toggle .dropdown-item-count {
  display: none;
}
.type-geosearch .dropdown .dropdown-menu {
  --my-dropdown-color: var(--my-btn-fg);
  --my-dropdown-bg: var(--my-btn-bg);
  --my-dropdown-link-color: var(--my-btn-fg);
  --my-dropdown-link-hover-color: var(--my-btn-hover-fg);
  --my-dropdown-link-hover-bg: var(--my-btn-hover-bg);
  --my-dropdown-padding-x: 2px;
  --my-dropdown-padding-y: 2px;
  --my-dropdown-item-padding-x: 15px;
  --my-dropdown-item-padding-y: 5px;
}
.type-geosearch .dropdown .dropdown-item {
  white-space: normal;
  line-height: 1.1;
  display: flex;
  justify-content: space-between;
}
.type-geosearch .dropdown .dropdown-item .dropdown-item-count {
  white-space: nowrap;
  padding-left: 15px;
}
.type-geosearch .dropdown .dropdown-item .dropdown-item-count::before {
  content: "(";
}
.type-geosearch .dropdown .dropdown-item .dropdown-item-count::after {
  content: ")";
}
.type-geosearch .dropdown .dropdown-item-1 {
  padding-left: 15px;
}
.type-geosearch .dropdown .dropdown-item-2 {
  padding-left: 30px;
}
.type-geosearch .dropdown .dropdown-item-3 {
  padding-left: 45px;
}
.type-geosearch .dropdown .dropdown-item-4 {
  padding-left: 60px;
}
.type-geosearch .dropdown .dropdown-item-5 {
  padding-left: 75px;
}
.type-geosearch .styled-form .submit-section {
  margin-top: calc(2 * var(--my-form-section-margin-bottom));
}
.type-geosearch .styled-form .btn {
  width: 100%;
}
.type-geosearch .box .styled-form.state-error .note-error {
  margin-top: -1px;
  border: 1px solid var(--my-grey);
  background-color: #ffeeee;
  padding: 4px 10px;
}
.type-geosearch .map-osm .maplibregl-popup-content .map-marker:nth-of-type(even) {
  background-color: var(--my-highlight-color);
  width: 100%;
}
.type-geosearch .map-google .gm-style-iw-d .map-marker:nth-of-type(even) {
  background-color: var(--my-highlight-color);
  width: 100%;
}
.type-geosearch .geosearch-container {
  margin-bottom: var(--my-element-margin);
}
.type-geosearch .geosearch-container:has(+ .geosearch-resultcount-component) {
  margin-bottom: var(--my-default-margin-small);
}
.type-geosearch .geosearch-display {
  border-radius: var(--my-border-radius);
  overflow: hidden;
}
.type-geosearch .geosearch-filter {
  border-radius: 0;
}
.type-geosearch .geosearch-results > .mapwindow {
  aspect-ratio: var(--my-map-aspect-ratio);
}
.type-geosearch .geosearch-results > .mapwindow.placeholder {
  border: 1px solid var(--my-highlight-border-color);
}
.type-geosearch .geosearch-results > .mapwindow.placeholder::after {
  content: "\f279";
}
.type-geosearch .geosearch-resultcount-component {
  visibility: collapse;
  margin-bottom: var(--my-element-margin);
}
.type-geosearch .geosearch-resultcount-component.show {
  visibility: visible;
}
.type-geosearch .geosearch-marker-reset {
  display: none;
}
.type-geosearch.no-resultcount .geosearch-resultcount-component {
  display: none;
}
@media (min-width: 1014px) {
  .type-geosearch .geosearch-container {
    aspect-ratio: var(--my-map-aspect-ratio-lg);
  }
  .type-geosearch .geosearch-display {
    display: flex;
    flex-direction: row;
    height: 100%;
  }
  .type-geosearch .geosearch-filter {
    flex: 0 0 33.333333%;
    display: flex;
    flex-direction: column;
  }
  .type-geosearch .geosearch-results {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  .type-geosearch .geosearch-results > .mapwindow {
    height: 100%;
    aspect-ratio: auto;
  }
}

.type-map .map-marker .eventbtn {
  display: block;
  margin-bottom: var(--my-contact-spacer-y);
}
.type-map .map-marker .events {
  --my-map-info-scale: 0.8;
  --my-map-font-size: calc(var(--my-map-info-scale) * 14px);
  font-size: var(--my-map-font-size);
}
.type-map .map-marker .events .evr {
  margin-top: var(--my-contact-spacer-y);
}
.type-map .map-marker .events .evd {
  font-weight: var(--my-map-header-weight);
}
@media (min-width: 552px) {
  .type-map .map-marker .events {
    display: table;
  }
  .type-map .map-marker .events .evr {
    display: table-row;
  }
  .type-map .map-marker .events .evc {
    display: table-cell;
    padding-top: 2px;
  }
  .type-map .map-marker .events .evd {
    white-space: nowrap;
  }
  .type-map .map-marker .events .evt {
    padding-left: 1ex;
  }
}

/**********
 * Source file:_gottesdienst.scss
 */
.type-gottesdienst.teaser.calendar-sheet-piece:not(.gd) .heading .intro-headline {
  margin-bottom: calc(0.5 * 1rem);
}
.type-gottesdienst.teaser.calendar-sheet-piece:not(.gd) .heading .headline {
  font-weight: 300;
  font-size: 1.8rem;
  line-height: 1.5;
}
.type-gottesdienst.teaser.calendar-sheet-piece:not(.gd).piece > .body .text > *:not(.book-info):not(.teaser-place) {
  margin-bottom: 0;
}
.type-gottesdienst.teaser.calendar-sheet-piece .type-list-group:not(:last-of-type), .type-gottesdienst.teaser.gd .type-list-group:not(:last-of-type) {
  margin-bottom: var(--my-default-margin-small);
}
.type-gottesdienst.teaser.gd .gd-date-heading {
  margin-bottom: var(--my-default-margin-small);
}
.type-gottesdienst.teaser.gd .gd-table {
  display: flex;
}
.type-gottesdienst.teaser.gd .gd-time {
  min-width: 5.5rem;
  margin-right: var(--my-ws-margin);
  white-space: nowrap;
}
.type-gottesdienst.teaser.gd .gd-icon {
  margin-top: var(--my-default-margin-verysmall);
  width: 40px;
  max-width: 40px;
}
.type-gottesdienst.teaser.gd .gd-btn {
  margin-top: var(--my-default-margin-verysmall);
}
.type-gottesdienst.teaser.gd .gd-link:hover {
  text-decoration: none;
}
.type-gottesdienst.teaser.gd.calendar-sheet-piece .gd-hd {
  margin-bottom: var(--my-default-margin-verysmall);
}
.type-gottesdienst.teaser.gd.no-calendar-sheet .gd-hd {
  font-weight: 600;
}
.type-gottesdienst.detail-page .headline .time, .type-gottesdienst.teaser:not(.gd) .headline .time {
  color: var(--my-intro-headings-color);
  white-space: nowrap;
}
.type-gottesdienst.detail-page .headline .time::after, .type-gottesdienst.teaser:not(.gd) .headline .time::after {
  color: var(--my-headings-color);
  content: " - ";
}

/**********
 * Source file:_liturgy.scss
 */
.type-gottesdienst.teaser .teaser-place:has(+ .gd-celebrant) {
  --my-piece-paragraph-margin-bottom: calc(0.5 * 1rem);
}

.type-liturgy .calendar-simple {
  margin-bottom: var(--my-headings-margin-bottom);
}
@media (min-width: 764px) {
  .type-liturgy .calendar-simple {
    font-size: 2.4rem;
  }
}

.direktorium .dday {
  position: relative;
}
.direktorium .dday:last-of-type, .direktorium .dday.lestrue {
  margin-bottom: var(--my-paragraph-margin-bottom);
}
.direktorium .grad1 .dheader,
.direktorium .grad2 .dheader,
.direktorium .grad3 .dheader,
.direktorium .grad4 .dheader,
.direktorium .grad5 .dheader {
  font-weight: 600;
}
.direktorium .farbew,
.direktorium .farber,
.direktorium .farbev,
.direktorium .farbeg,
.direktorium .farbet {
  padding-left: 1.5rem;
}
.direktorium .farbew::before,
.direktorium .farber::before,
.direktorium .farbev::before,
.direktorium .farbeg::before,
.direktorium .farbet::before {
  content: "";
  display: block;
  border: 1px solid var(--my-grey);
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  position: absolute;
  left: 0;
  top: 0.6rem;
}
.direktorium .farbew::before {
  background-color: #fff;
}
.direktorium .farber::before {
  background-color: #faa;
}
.direktorium .farbev::before {
  background-color: #dad;
}
.direktorium .farbeg::before {
  background-color: #afa;
}
.direktorium .farbet::before {
  border-color: transparent;
  background-color: inherit;
}
.direktorium .rangF.farbew::before, .direktorium .rangF.farber::before, .direktorium .rangF.farbev::before, .direktorium .rangF.farbeg::before, .direktorium .rangF.farbet::before {
  margin-bottom: 0.1rem;
}
.direktorium .rangH .dheader {
  font-size: calc(1.2 * var(--my-body-font-size));
  font-weight: 600;
}
.direktorium .rangH.farbew::before, .direktorium .rangH.farber::before, .direktorium .rangH.farbev::before, .direktorium .rangH.farbeg::before, .direktorium .rangH.farbet::before {
  margin-bottom: 0.2rem;
}
.direktorium .dbem,
.direktorium .dheader {
  margin-bottom: 0;
  font-family: var(--my-font-family-headings);
  color: var(--my-headings-color);
  line-height: 1.25;
}
.direktorium .dlesung {
  margin-top: var(--my-default-margin-verysmall);
  font-size: calc(0.9 * var(--my-body-font-size));
  line-height: calc(0.9 * var(--my-body-line-height));
  color: var(--my-body-color);
  filter: brightness(175%);
}
.direktorium .dlesung .dl1::before,
.direktorium .dlesung .dl2::before,
.direktorium .dlesung .dap::before,
.direktorium .dlesung .dev::before {
  display: inline-block;
  min-width: 11rem;
}
.direktorium .dlesung .dl1::before {
  content: "1. Lesung: ";
}
.direktorium .dlesung .dl2::before {
  content: "2. Lesung: ";
}
.direktorium .dlesung .dap:before {
  content: "Antwortpsalm: ";
}
.direktorium .dlesung .dev:before {
  content: "Evangelium: ";
}

/**********
 * Source file: _orgchart.scss
 */
#orgChartZoom-ZoomContainer,
.type-orgchart {
  --my-orgchart-border-color: var(--my-body-color);
  --my-orgchart-border: 2px solid var(--my-orgchart-border-color);
  --my-orgchart-border-radius: var(--my-border-radius);
  --my-orgchart-dep-seperator: 1px solid var(--my-orgchart-border-color);
  --my-orgchart-dep-indent: var(--my-box-padding);
  --my-orgchart-dep-member-font-size: 80%;
  --my-orgchart-selected-font-weight: var(--my-font-weight-bold);
  --my-orgchart-box-padding: var(--my-box-padding);
  --my-orgchart-item-padding: var(--my-default-margin-small);
}

.type-orgchart .dep-list,
.type-orgchart .dep-persons {
  margin-bottom: 0;
  list-style: none;
  padding-left: 0;
}
.type-orgchart .dep-list {
  --my-link-color: var(--my-body-color);
  border: var(--my-orgchart-border);
  border-radius: var(--my-orgchart-border-radius);
  display: flex;
  flex-direction: column;
  padding: calc(var(--my-orgchart-box-padding) - var(--my-orgchart-item-padding)) var(--my-orgchart-box-padding);
}
.type-orgchart .dep-item {
  display: flex;
  flex-direction: column;
  padding: var(--my-orgchart-item-padding) 0;
  border-bottom: var(--my-orgchart-dep-seperator);
}
.type-orgchart .dep-item:last-of-type {
  border-bottom: none;
}
.type-orgchart .dep-selected-person,
.type-orgchart .dep-name {
  font-weight: var(--my-orgchart-selected-font-weight);
}
.type-orgchart .dep-member {
  font-size: var(--my-orgchart-dep-member-font-size);
}
@media (max-width: 1013.98px) {
  .type-orgchart .lvl-2 {
    padding-left: var(--my-orgchart-dep-indent);
  }
  .type-orgchart .lvl-3 {
    padding-left: calc(2 * var(--my-orgchart-dep-indent));
  }
  .type-orgchart .lvl-4 {
    padding-left: calc(3 * var(--my-orgchart-dep-indent));
  }
  .type-orgchart .lvl-5 {
    padding-left: calc(4 * var(--my-orgchart-dep-indent));
  }
}
@media (min-width: 1014px) {
  .type-orgchart .lvl-2 .dep-name-col {
    padding-left: var(--my-orgchart-dep-indent);
  }
  .type-orgchart .lvl-3 .dep-name-col {
    padding-left: calc(2 * var(--my-orgchart-dep-indent));
  }
  .type-orgchart .lvl-4 .dep-name-col {
    padding-left: calc(3 * var(--my-orgchart-dep-indent));
  }
  .type-orgchart .lvl-5 .dep-name-col {
    padding-left: calc(4 * var(--my-orgchart-dep-indent));
  }
  .type-orgchart .dep-item {
    width: 100%;
    flex-direction: row;
  }
  .type-orgchart .dep-name-col {
    max-width: 60%;
    width: 60%;
  }
  .type-orgchart .dep-persons {
    max-width: 40%;
    width: 40%;
  }
}
.type-orgchart .chart-image {
  border: var(--my-orgchart-border);
  border-radius: var(--my-orgchart-border-radius);
}
.type-orgchart .chart-image img {
  border-radius: var(--my-orgchart-border-radius);
}

#orgChartZoom-ZoomContainer .zoomWindow {
  border-radius: var(--my-orgchart-border-radius);
}

/**********
 * Source file: _quiz.scss
 */
.type-quiz.detail-page {
  margin-bottom: var(--my-element-margin);
}
.type-quiz .quiz-start {
  padding-bottom: var(--my-element-margin);
}
.type-quiz .quiz-start,
.type-quiz .quiz-content {
  display: none;
}
.type-quiz .quiz-start.active,
.type-quiz .quiz-content.active {
  display: block;
}
.type-quiz .answers li {
  margin-bottom: var(--my-paragraph-margin-bottom);
}
.type-quiz .counter {
  margin-bottom: var(--my-default-margin-small);
}
.type-quiz .btn-quiz {
  font-weight: 600;
}
.type-quiz .next-item {
  margin-top: var(--my-default-margin);
  font-size: 120%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.type-quiz .next-item > .btn-quiz {
  display: inline;
}
.type-quiz .next-item > .btn-quiz::after {
  font-family: var(--my-icon-font-family);
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f138";
  display: inline-block;
  margin-left: var(--my-ws-margin);
  color: #990011;
  text-decoration: none;
}
.type-quiz .animation.enter {
  animation: fadeIn 1s;
}
.type-quiz .animation.enter.slow {
  animation: fadeIn 2s;
}
.type-quiz .animation.leave {
  animation: fadeOut 0.5s;
}
.type-quiz .animation.quick {
  animation: none;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
/**********
 * Source file: _readspeaker.scss
 */
html .element.type-rs {
  margin-bottom: var(--my-default-margin-small);
}
html .element.type-rs > .btn-wrapper {
  min-height: 30px;
}
html .element.type-rs > .btn-wrapper > .rsbtn {
  line-height: 1;
  padding-bottom: 0 !important;
}
html .element.type-rs > .btn-wrapper .rsbtn_play:not([data-rsevent-id]) {
  color: transparent;
}
html .element.type-rs.btn-right, html .element.type-rs.btn-center {
  display: flex;
}
html .element.type-rs.btn-right > .btn-wrapper .rsbtn.rsexpanded .rsbtn_exp, html .element.type-rs.btn-center > .btn-wrapper .rsbtn.rsexpanded .rsbtn_exp {
  display: inline-block;
}
html .element.type-rs.btn-right {
  justify-content: flex-end;
}
html .element.type-rs.btn-center {
  justify-content: center;
}
html .element.type-rs.presized > .btn-wrapper {
  max-height: 30px;
}
html .element.type-rs.presized.enlarged > .btn-wrapper {
  max-height: none;
}
html .element.type-rs .external-cookie-notice {
  padding: 2px 5px;
  justify-content: flex-start;
}

/**********
 * Source file: _additions-all.scss
 */
.add-shadow {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

@media (min-width: 1014px) {
  .area-header .header-group {
    border-bottom: none;
    position: relative;
    box-sizing: border-box;
  }
  .area-header .header-group::before {
    position: absolute;
    content: " ";
    width: 100%;
    height: 1px;
    background-image: repeating-linear-gradient(90deg, #444, #444 1px, transparent 1px, transparent 4px);
    left: 0;
    z-index: 1;
    bottom: -1px;
  }
}
/**********
 * Source file: _additions-ebk.scss
 */
.pp-banner ul.policy-links {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.pp-banner ul.policy-links li {
  display: inline;
  margin-right: var(--my-default-margin);
}

.ap-legaltext.mb-20 {
  margin-bottom: var(--my-element-margin) !important;
}
.ap-legaltext > .row {
  margin-right: 0;
  margin-left: 0;
}
.ap-legaltext > .row > div {
  margin-bottom: var(--my-element-margin);
}
.ap-legaltext > .row > div > table:last-of-type,
.ap-legaltext > .row > div > p:last-of-type {
  margin-bottom: 0;
}

.list-kaplan-compact .calendar-sheet {
  position: relative;
  display: inline-block;
}
.list-kaplan-compact .calendar-sheet::before {
  display: none;
}
.list-kaplan-compact .calendar-sheet > .day-number {
  font-size: 16px;
}
.list-kaplan-compact .calendar-sheet > .month-year, .list-kaplan-compact .calendar-sheet > .day-name {
  padding: 0;
}
.list-kaplan-compact.list-content .teaser {
  margin-bottom: 10px;
}
.list-kaplan-compact .type-gottesdienst.teaser.calendar-sheet-piece .type-list-group:not(:last-of-type) {
  margin-bottom: 0.5rem;
}
.list-kaplan-compact .type-gottesdienst.teaser.calendar-sheet-piece.gd .gd-hd {
  margin-bottom: 0.25rem;
  font-size: 1.5rem;
}
.list-kaplan-compact .piece.calendar-sheet-piece.flex.fixed > .visual,
.list-kaplan-compact .piece.calendar-sheet-piece.flex.fixed > .body {
  flex: 0 0 100%;
  min-width: auto;
  max-width: none;
  padding-left: 0;
  padding-right: 0;
}

.list-kaplan-compact .calendar-sheet-piece .image-src-box.presized {
  padding-bottom: 0 !important;
  height: auto;
}
.list-kaplan-compact .calendar-sheet {
  border-bottom: 2px solid var(--my-main-theme);
  width: 100%;
  padding: 5px 0;
}
.list-kaplan-compact .calendar-sheet .day-name {
  margin-right: 15px;
  float: right;
}
.list-kaplan-compact .calendar-sheet .month-year,
.list-kaplan-compact .calendar-sheet .day-number {
  margin-left: 15px;
  float: left;
}
.list-kaplan-compact .calendar-sheet .day-name,
.list-kaplan-compact .calendar-sheet .day-number,
.list-kaplan-compact .calendar-sheet .month-year {
  line-height: 20px;
}

.type-parish-detail a {
  font-weight: var(--my-font-weight-bold);
}
@media (min-width: 764px) {
  .type-parish-detail dl {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-column-gap: calc(2 * var(--my-ws-margin));
  }
  .type-parish-detail dt {
    text-align: right;
  }
}

/*# sourceMappingURL=ebk-agency-menu.css.map */
